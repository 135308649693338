define("challingo-mobile/templates/components/levelup-message", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "nroACjN6",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[23,[\"isOpen\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\"],[11,\"class\",\"levelup-plus-picture\"],[9],[0,\"\\n    \"],[7,\"span\"],[11,\"class\",\"levelup-plus-level\"],[9],[1,[21,\"level\"],false],[10],[0,\"\\n\"],[4,\"if\",[[23,[\"rank\"]]],null,{\"statements\":[[0,\"      \"],[7,\"span\"],[11,\"class\",\"levelup-plus-rank\"],[9],[0,\"\\n\"],[4,\"if\",[[23,[\"icon\"]]],null,{\"statements\":[[0,\"          \"],[1,[21,\"icon\"],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"        \"],[1,[27,\"t\",[[27,\"concat\",[\"ranks.\",[23,[\"rank\"]]],null]],null],false],[0,\"\\n      \"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[10],[0,\"\\n  \"],[7,\"h2\"],[11,\"class\",\"congrats\"],[9],[1,[27,\"t\",[\"levels.levelup_modal_title\"],null],false],[10],[0,\"\\n  \"],[7,\"p\"],[11,\"class\",\"text\"],[9],[0,\"\\n\"],[4,\"if\",[[23,[\"rank\"]]],null,{\"statements\":[[0,\"      \"],[1,[27,\"t\",[\"levels.levelup_message_rank\"],[[\"level\",\"rank\"],[[23,[\"level\"]],[27,\"t\",[[27,\"concat\",[\"ranks.\",[23,[\"rank\"]]],null]],null]]]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"      \"],[1,[27,\"t\",[\"levels.levelup_message\"],[[\"level\"],[[23,[\"level\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]}],[0,\"  \"],[10],[0,\"\\n\\n  \"],[7,\"div\"],[11,\"class\",\"direction-btns sticky\"],[9],[0,\"\\n    \"],[7,\"button\"],[11,\"class\",\"btn\"],[11,\"style\",\"touch-action: manipulation; -ms-touch-action: manipulation; cursor: pointer;\"],[11,\"type\",\"button\"],[9],[0,\"\\n      \"],[1,[27,\"t\",[\"ok\"],null],false],[0,\"\\n    \"],[3,\"action\",[[22,0,[]],\"btnAction\"]],[10],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "challingo-mobile/templates/components/levelup-message.hbs"
    }
  });

  _exports.default = _default;
});