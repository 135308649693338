define("challingo-mobile/templates/components/challenges/challenges-accepted", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "F0vsw1N0",
    "block": "{\"symbols\":[\"list\"],\"statements\":[[4,\"lazy-loading-list\",null,[[\"items\",\"pageBottomBar\",\"sessionId\"],[[23,[\"challenges\",\"userProfileAccepted\",\"all\"]],false,\"challenges-accepted\"]],{\"statements\":[[0,\"  \"],[1,[27,\"challenges/challenges-explore-list\",null,[[\"challenges\",\"longPressAction\",\"placeholder\",\"scrollObserverOnEnter\",\"showTitles\"],[[22,1,[]],[23,[\"acceptedChallyModal\"]],[27,\"t\",[\"challenge.no_accepted_chally\"],null],[27,\"action\",[[22,0,[]],\"loadNextPage\"],null],true]]],false],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "challingo-mobile/templates/components/challenges/challenges-accepted.hbs"
    }
  });

  _exports.default = _default;
});