define("challingo-mobile/templates/components/forms/settings-form", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "zhG3F9HF",
    "block": "{\"symbols\":[],\"statements\":[[7,\"h3\"],[9],[1,[27,\"t\",[\"settings.locale_title\"],null],false],[10],[7,\"br\"],[9],[10],[0,\"\\n\\n\"],[7,\"label\"],[9],[0,\"\\n  \"],[7,\"input\"],[11,\"name\",\"locale\"],[11,\"value\",\"en\"],[11,\"type\",\"radio\"],[9],[10],[0,\"\\n  \"],[1,[27,\"t\",[\"settings.locale_en\"],null],false],[0,\"\\n\"],[10],[7,\"br\"],[9],[10],[0,\"\\n\\n\"],[7,\"label\"],[9],[0,\"\\n  \"],[7,\"input\"],[11,\"name\",\"locale\"],[11,\"value\",\"cs\"],[11,\"type\",\"radio\"],[9],[10],[0,\"\\n  \"],[1,[27,\"t\",[\"settings.locale_cz\"],null],false],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "challingo-mobile/templates/components/forms/settings-form.hbs"
    }
  });

  _exports.default = _default;
});