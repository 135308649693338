define("challingo-mobile/templates/components/video-player", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "cOOYt5LD",
    "block": "{\"symbols\":[],\"statements\":[[1,[27,\"video-side-blured\",null,[[\"src\"],[[23,[\"media\",\"video\"]]]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "challingo-mobile/templates/components/video-player.hbs"
    }
  });

  _exports.default = _default;
});