define("challingo-mobile/utils/hashtags", ["exports", "challingo-mobile/utils/split-to-n"], function (_exports, _splitToN) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.parseHashtags = parseHashtags;
  _exports.wrapHashtags = wrapHashtags;
  _exports.loadNecessaryHashtags = loadNecessaryHashtags;
  _exports.saveHashtags = saveHashtags;
  _exports.hashtagRegEx = void 0;

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  var hashtagRegEx = /(^|\s)(#\S+)/ig;
  _exports.hashtagRegEx = hashtagRegEx;

  function parseHashtags(str) {
    var result = [];
    var m;

    while (m = hashtagRegEx.exec(str)) {
      result.push(m[2].substr(1));
    }

    return result;
  }

  function wrapHashtags(str) {
    var wrapStart = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : ' <span class="hashtag">';
    var wrapEnd = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : '</span> ';
    return str && str.replace(hashtagRegEx, "".concat(wrapStart, "$2").concat(wrapEnd));
  }

  function loadNecessaryHashtags(store, description, oldDescription) {
    var newHashtags = parseHashtags(description).map(function (hashtag) {
      return hashtag.toLowerCase();
    });
    var oldHashtags = parseHashtags(oldDescription).map(function (hashtag) {
      return hashtag.toLowerCase();
    });

    var uniqueHashtags = _toConsumableArray(new Set([].concat(_toConsumableArray(newHashtags), _toConsumableArray(oldHashtags))));

    if (!uniqueHashtags.length) {
      return new Promise(function (resolve) {
        resolve([]);
      });
    }

    var promises = [];
    (0, _splitToN.default)(uniqueHashtags, 10).forEach(function (hashtags) {
      promises.push(store.query('hashtag', {
        where: [['tagName', 'in', hashtags]]
      }));
    });
    return Ember.RSVP.all(promises).then(function (results) {
      var hashtags = [];

      if (results && Array.isArray(results)) {
        results.forEach(function (result) {
          if (result && result.forEach) {
            result.forEach(function (hashtag) {
              hashtags.push(hashtag);
            });
          }
        });
      }

      return new Promise(function (resolve) {
        resolve(hashtags);
      });
    });
  }

  function saveHashtags(description, oldDescription, challenge, challengeAccepted, allHashtags, store) {
    var promises = [];
    var newHashtags = parseHashtags(description).map(function (hashtag) {
      return hashtag.toLowerCase();
    });

    var uniqueNewHashtags = _toConsumableArray(new Set(newHashtags));

    var oldHashtags = parseHashtags(oldDescription).map(function (hashtag) {
      return hashtag.toLowerCase();
    });

    var uniqueOldHashtags = _toConsumableArray(new Set(oldHashtags));

    var hashtagsDelete = uniqueOldHashtags.filter(function (hashtag) {
      return !uniqueNewHashtags.includes(hashtag);
    });
    var hashtagsAdd = uniqueNewHashtags.filter(function (hashtag) {
      return !uniqueOldHashtags.includes(hashtag);
    }); //hashtagsDelete

    hashtagsDelete.forEach(function (challyHashtag) {
      var saveHashtag = allHashtags.find(function (hashtag) {
        return challyHashtag === hashtag.tagName;
      });

      if (saveHashtag) {
        if (challenge) {
          saveHashtag.challenges.removeObject(challenge);
        }

        if (challengeAccepted) {
          saveHashtag.challengesAccepted.removeObject(challengeAccepted);
        }

        promises.push(saveHashtag.save());
      }
    }); //hashtagsAdd

    hashtagsAdd.forEach(function (challyHashtag) {
      var saveHashtag = allHashtags.find(function (hashtag) {
        return challyHashtag === hashtag.tagName;
      });

      if (!saveHashtag) {
        saveHashtag = store.createRecord('hashtag');
        saveHashtag.set('tagName', challyHashtag);
      }

      if (challenge) {
        saveHashtag.challenges.pushObject(challenge);
      }

      if (challengeAccepted) {
        saveHashtag.challengesAccepted.pushObject(challengeAccepted);
      }

      promises.push(saveHashtag.save());
    });
    return promises;
  }
});