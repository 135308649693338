define("challingo-mobile/templates/password-forgot", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "jroDvr3I",
    "block": "{\"symbols\":[],\"statements\":[[1,[27,\"nav-bar\",null,[[\"title\",\"back\"],[[27,\"t\",[\"user.password_forgot\"],null],\"back\"]]],false],[0,\"\\n\\n\"],[7,\"br\"],[9],[10],[0,\"\\n\\n\"],[1,[21,\"forms/password-forgot-form\"],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "challingo-mobile/templates/password-forgot.hbs"
    }
  });

  _exports.default = _default;
});