define("challingo-mobile/routes/challenges", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    challenges: Ember.inject.service(),
    cordova: Ember.inject.service(),
    currentUser: Ember.inject.service(),
    pushNotification: Ember.inject.service(),
    router: Ember.inject.service(),
    settings: Ember.inject.service(),
    store: Ember.inject.service(),
    firstLoad: true,
    queryParams: {
      miniProfile: {}
    },
    beforeModel: function beforeModel() {
      var _this = this;

      var op = function op() {
        var user = _this.get('currentUser.user');

        if (user && user.then) {
          return user.then(function () {
            return _this.pushNotification.load();
          });
        } else if (user) {
          return _this.pushNotification.load();
        }
      };

      return this.settings.checkAppVersion(op, op);
    },
    model: function model() {
      var toLoad = {
        categories: this.store.findAll('category')
      };

      if (this.get('firstLoad')) {
        this.set('firstLoad', false);
        toLoad.challenges = this.challenges.newsfeed.load();
        toLoad.trendy = this.challenges.trendy.load();
      }

      return Ember.RSVP.hash(toLoad);
    },
    setupController: function setupController(controller) {
      var _this2 = this;

      this._super.apply(this, arguments);

      controller.set('routeAtSetup', null);
      Ember.run.next(function () {
        controller.set('routeAtSetup', _this2.router.currentRouteName);
      });
    }
  });

  _exports.default = _default;
});