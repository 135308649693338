define("challingo-mobile/templates/components/slider-cards", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "R7Gx1kd0",
    "block": "{\"symbols\":[\"item\",\"index\",\"&default\"],\"statements\":[[14,3],[0,\"\\n\\n\"],[4,\"if\",[[23,[\"items\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\"],[11,\"class\",\"wrapper\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"track\"],[9],[0,\"\\n\"],[4,\"each\",[[23,[\"items\"]]],null,{\"statements\":[[0,\"        \"],[7,\"div\"],[11,\"class\",\"slide\"],[9],[0,\"\\n          \"],[7,\"div\"],[12,\"aria-label\",[27,\"t\",[\"aria-labels.show\"],null]],[12,\"class\",[28,[\"card\",[27,\"if\",[[27,\"eq\",[[22,2,[]],0],null],\" medal-gold\"],null],[27,\"if\",[[27,\"eq\",[[22,2,[]],1],null],\" medal-silver\"],null],[27,\"if\",[[27,\"eq\",[[22,2,[]],2],null],\" medal-bronze\"],null],[27,\"if\",[[22,1,[\"media\",\"isVideo\"]],\" video\"],null],[27,\"if\",[[27,\"eq\",[[22,1,[\"id\"]],[23,[\"selected\",\"id\"]]],null],\" selected\"],null],[27,\"if\",[[23,[\"showUser\"]],\" user-spacing\"],null]]]],[11,\"role\",\"button\"],[11,\"style\",\"touch-action: manipulation; -ms-touch-action: manipulation; cursor: pointer;\"],[9],[0,\"\\n            \"],[7,\"div\"],[11,\"class\",\"cover\"],[12,\"style\",[27,\"background-image\",[[22,1,[\"photoSmall\"]]],null]],[9],[10],[0,\"\\n\"],[4,\"if\",[[22,1,[\"media\",\"isVideo\"]]],null,{\"statements\":[[0,\"              \"],[7,\"div\"],[11,\"class\",\"camera-icon\"],[9],[10],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"if\",[[23,[\"showUser\"]]],null,{\"statements\":[[0,\"              \"],[7,\"img\"],[11,\"alt\",\"\"],[11,\"class\",\"user-foto\"],[11,\"role\",\"presentation\"],[12,\"src\",[27,\"if\",[[22,1,[\"creator\"]],[22,1,[\"creator\",\"photoSmall\"]],[22,1,[\"user\",\"photoSmall\"]]],null]],[9],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"          \"],[3,\"action\",[[22,0,[]],\"toDetail\",[22,1,[]]]],[10],[0,\"\\n        \"],[10],[0,\"\\n\"]],\"parameters\":[1,2]},null],[0,\"    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "challingo-mobile/templates/components/slider-cards.hbs"
    }
  });

  _exports.default = _default;
});