define("challingo-mobile/routes/challenges/likedby", ["exports", "emberfire/services/realtime-listener"], function (_exports, _realtimeListener) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    queryParams: {
      challengeAcceptedId: {}
    },
    afterModel: function afterModel(model) {
      if (!model.get('isVirtualModel')) {
        (0, _realtimeListener.subscribe)(this, model);
        Ember.tryInvoke(model, 'loadLikes');
      }
    }
  });

  _exports.default = _default;
});