define("challingo-mobile/templates/search", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "KY34ewi1",
    "block": "{\"symbols\":[],\"statements\":[[1,[27,\"search-page\",null,[[\"challengesAcceptedReported\",\"challengesRandom\",\"challengesReported\",\"pageFilter\",\"searchValue\",\"scrollTop\"],[[23,[\"model\",\"challengesAcceptedReported\"]],[23,[\"model\",\"challengesRandom\"]],[23,[\"model\",\"challengesReported\"]],[23,[\"pageFilter\"]],[23,[\"searchValue\"]],[23,[\"scrollTop\"]]]]],false],[0,\"\\n\\n\"],[1,[21,\"bottom-bar\"],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "challingo-mobile/templates/search.hbs"
    }
  });

  _exports.default = _default;
});