define("challingo-mobile/routes/hashtag", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    challenges: Ember.inject.service(),
    model: function model(params) {
      var _this = this;

      var hashtag = params.hashtag;
      this.controllerFor('hashtag').setProperties({
        hashtag: hashtag
      });
      return this.store.query('hashtag', {
        where: [['tagName', '==', hashtag]]
      }).then(function (results) {
        if (results.get('length')) {
          var modelResolved = results.get('firstObject');

          _this.challenges.hashtag.resetHashtag(modelResolved);

          return _this.challenges.hashtag.load().then(function () {
            return modelResolved;
          });
        } else {
          return null;
        }
      });
    }
  });

  _exports.default = _default;
});