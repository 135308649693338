define("challingo-mobile/utils/status-bar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.resetStatusBar = resetStatusBar;

  function resetStatusBar() {
    if (window.StatusBar) {
      window.StatusBar.overlaysWebView(true);
      window.StatusBar.overlaysWebView(false);
      window.StatusBar.backgroundColorByHexString('#4e3296');
    }
  }
});