define("challingo-mobile/models/admin-notification", ["exports", "ember-data", "challingo-mobile/models/notification"], function (_exports, _emberData, _notification) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _notification.default.extend({
    user: _emberData.default.belongsTo('user', {
      inverse: null
    })
  });

  _exports.default = _default;
});