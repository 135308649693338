define("challingo-mobile/components/update-page", ["exports", "challingo-mobile/config/environment", "challingo-mobile/utils/detect-mobile-os", "challingo-mobile/components/welcome-page", "challingo-mobile/utils/open-external-url"], function (_exports, _environment, _detectMobileOs, _welcomePage, _openExternalUrl) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    intl: Ember.inject.service(),
    settings: Ember.inject.service(),
    siteSettings: Ember.computed.alias('settings.siteSettings'),
    classNames: ['update-app'],
    showAppButton: true,
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      (0, _welcomePage.hideSplashscreen)();
    },
    message: Ember.computed('siteSettings.maintenanceModeText', function () {
      return this.get('siteSettings.maintenanceModeText');
    }),
    title: Ember.computed('siteSettings.maintenanceModeTitle', function () {
      return this.get('siteSettings.maintenanceModeTitle') || this.intl.t('updateApp');
    }),
    actions: {
      switchToApp: function switchToApp() {
        var key = (0, _detectMobileOs.default)() === 'iOS' ? 'apple' : 'google';
        var storeUrl = _environment.default.APP.appStoreUrls[key];
        (0, _openExternalUrl.default)(storeUrl);
      }
    }
  });

  _exports.default = _default;
});