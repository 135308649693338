define("challingo-mobile/components/profile-actions", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    confirmDialog: Ember.inject.service(),
    currentUser: Ember.inject.service(),
    intl: Ember.inject.service(),
    modal: Ember.inject.service(),
    toastMessage: Ember.inject.service(),
    store: Ember.inject.service(),
    classNames: ['challenge-actions'],
    user: Ember.computed.alias('model'),
    isBlocked: Ember.computed('currentUser.blockeds', function () {
      var profileId = this.user.get('id');
      var allBlockeds = this.get('currentUser.user').get('blockeds');
      var selectedBlockeds = null;

      if (allBlockeds) {
        selectedBlockeds = allBlockeds.filter(function (blocked) {
          return blocked.get('id') == profileId;
        });
      }

      return selectedBlockeds.length > 0 ? true : false;
    }),
    actions: {
      blockUser: function blockUser() {
        var _this = this;

        Ember.tryInvoke(this, 'modalClose');
        var user = this.user;
        var currentUserId = this.currentUser.get('uid');
        this.confirmDialog.show(this.intl.t('blockeds.block'), function () {
          _this.store.findRecord('user', currentUserId).then(function (activeUser) {
            activeUser.get('blockeds').pushObject(user);
            activeUser.save({
              adapterOptions: {
                saveOnly: ['blockeds']
              }
            });
          });
        });
      },
      unblockUser: function unblockUser() {
        var _this2 = this;

        Ember.tryInvoke(this, 'modalClose');
        var user = this.user;
        var currentUserId = this.currentUser.get('uid');
        this.confirmDialog.show(this.intl.t('blockeds.unblock'), function () {
          _this2.store.findRecord('user', currentUserId).then(function (activeUser) {
            activeUser.get('blockeds').removeObject(user);
            activeUser.save({
              adapterOptions: {
                saveOnly: ['blockeds']
              }
            });
          });
        });
      }
    }
  });

  _exports.default = _default;
});