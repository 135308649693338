define("challingo-mobile/utils/strip-accents", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = stripAccents;

  function stripAccents(str) {
    var pattern = 'AAAAAAACEEEEIIIIDNOOOOO.OUUUUY..aaaaaaaceeeeiiii' + 'dnooooo.ouuuuy.yAaAaAaCcCcCcCcDdDdEeEeEeEeEeGgGg' + 'GgGgHhHhIiIiIiIiIiIiJjKkkLlLlLlLlJlNnNnNnnNnOoOo' + 'OoOoRrRrRrSsSsSsSsTtTtTtUuUuUuUuUuUuWwYyYZzZzZz';
    var out = '';

    for (var i = 0; i < str.length; i++) {
      var chIndex = str[i].charCodeAt(0) - 192;

      if (chIndex >= 0 && chIndex < pattern.length) {
        out += pattern.charAt(chIndex);
      } else {
        out += str[i];
      }
    }

    return out;
  }
});