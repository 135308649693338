define("challingo-mobile/components/confirm-dialog", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    confirmDialog: Ember.inject.service(),
    classNames: ['confirm-dialog'],
    classNameBindings: ['isOpen:open'],
    isOpen: Ember.computed.alias('confirmDialog.isOpen'),
    message: Ember.computed.alias('confirmDialog.message'),
    callback: Ember.computed.alias('confirmDialog.callback'),
    actions: {
      close: function close() {
        this.confirmDialog.close();
      },
      confirm: function confirm() {
        this.callback();
        this.send('close');
      }
    }
  });

  _exports.default = _default;
});