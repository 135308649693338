define("challingo-mobile/components/liked-by-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    users: null,
    showUsers: null,
    componentWidth: null,
    // itemWidth je velikost a margin-right .user v scss
    itemWidth: 28,
    countedUsers: null,
    countedItem: false,
    otherCountedLikes: null,
    _processUsers: function _processUsers() {
      var usersSum = this.users.length;
      var spaces = Math.floor(this.componentWidth / this.itemWidth);

      if (usersSum > spaces) {
        this.setProperties({
          countedItem: true,
          otherCountedLikes: usersSum - spaces + 1,
          showUsers: this.users.slice(0, spaces - 1)
        });
      } else {
        this.setProperties({
          countedItem: false,
          showUsers: this.users
        });
      }
    },
    didUpdateAttrs: function didUpdateAttrs() {
      this._super.apply(this, arguments);

      this._processUsers();
    },
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      this.set('componentWidth', this.element.offsetWidth);

      this._processUsers();
    }
  });

  _exports.default = _default;
});