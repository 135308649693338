define("challingo-mobile/routes/calendar", ["exports", "challingo-mobile/utils/logger"], function (_exports, _logger) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    currentUser: Ember.inject.service(),
    modal: Ember.inject.service(),
    session: Ember.inject.service(),
    beforeModel: function beforeModel() {
      if (!this.get('session.isAuthenticated')) {
        this.modal.show('modals/login-popup', null, {
          goBack: true,
          noStyle: true
        });
        return [];
      }
    },
    model: function model() {
      var _this = this;

      var userId = this.get('currentUser.uid');
      return Ember.RSVP.hash({
        challenges: this.store.query('user-challenge', {
          orderBy: {
            createdAt: 'desc'
          },
          where: [['hidden', '==', false], ['user', '==', userId]]
        }).then(function (userChallenges) {
          return Ember.RSVP.all((userChallenges || []).map(function (item) {
            var challyId = item && item.belongsTo('challenge').id();
            return item.get(challyId ? 'challenge' : 'challengeAccepted');
          }));
        }, function (e) {
          _logger.default.error(e);
        }),
        drawnChallies: this.store.query('drawn-chally', {
          orderBy: {
            createdAt: 'desc'
          },
          where: ['user', '==', userId]
        }).then(function (drawnChallies) {
          return drawnChallies.map(function (drawnChally) {
            return Ember.Object.create({
              challenge: _this.store.findRecord('challenge', drawnChally.belongsTo('challenge').id()),
              drawnChally: drawnChally
            });
          });
        }, function (e) {
          _logger.default.error(e);
        }),
        user: this.store.findRecord('user', this.get('currentUser.uid'))
      });
    }
  });

  _exports.default = _default;
});