define("challingo-mobile/utils/get-has-many-ids", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = getHasManyIds;

  function getHasManyIds(item, relationName) {
    if (!item || typeof item.hasMany !== 'function') {
      return [];
    }

    return item.hasMany(relationName).ids() || [];
  }
});