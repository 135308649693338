define("challingo-mobile/services/search", ["exports", "challingo-mobile/utils/logger", "challingo-mobile/utils/fulltext-search", "ember-inflector"], function (_exports, _logger, _fulltextSearch, _emberInflector) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    currentUser: Ember.inject.service(),
    store: Ember.inject.service(),
    attributesToRetrieve: Ember.A(['challenge', 'completed', 'createdAt', 'creator', 'hidden', 'latestChallengesCompleted', 'latestChallengesCreated', 'mediaHeight', 'mediaType', 'mediaUrl', 'mediaWidth', 'name', 'rank', 'user']),
    pageAcceptedChallenges: 0,
    pageChallenges: 0,
    pageUsers: 0,
    totalPages: null,
    hitsPerPage: 20,
    searchStatus: 0,
    searchValue: '',
    acceptedChallenges: null,
    challenges: null,
    users: null,
    resolveRelationships: function resolveRelationships(record, config) {
      var _this = this;

      if (config.belongsTo) {
        config.belongsTo.forEach(function (relation) {
          if (!relation.model || !relation.id) {
            return;
          }

          var relRecord = _this.store.peekRecord(relation.model, relation.id);

          if (relRecord) {
            record.set(relation.property, relRecord);
          } else {
            _this.store.findRecord(relation.model, relation.id).then(function (relRecord) {
              if (record && !record.get('isDestroyed') && !record.get('isDestroying')) {
                record.set(relation.property, relRecord);
              }
            }, function (e) {
              _logger.default.log(e);
            });
          }
        });
      }

      if (config.hasMany) {
        config.hasMany.forEach(function (relation) {
          var ids = relation.ids || [];
          record.set(relation.property, Ember.A([]));
          ids.forEach(function (id) {
            var relRecord = _this.store.peekRecord(relation.model, id);

            if (relRecord) {
              record.get(relation.property).pushObject(relRecord);
            } else {
              _this.store.findRecord(relation.model, id).then(function (relRecord) {
                if (record && !record.get('isDestroyed') && !record.get('isDestroying')) {
                  record.get(relation.property).pushObject(relRecord);
                }
              }, function (e) {
                _logger.default.log(e);
              });
            }
          });
        });
      }
    },
    searchCallback: function searchCallback(data) {
      var _this2 = this;

      if (data && data.results && data.results.length) {
        data.results.forEach(function (result) {
          if (result.query !== _this2.get('searchValue')) {
            return;
          }

          _this2.totalPages[result.index] = result.nbPages;

          if (result.nbHits) {
            var dbName = Ember.String.dasherize((0, _emberInflector.singularize)(result.index));
            var dbNameVirtual = "".concat(dbName, "-virtual");
            result.hits.forEach(function (item) {
              var record = _this2.store.peekRecord(dbName, item.objectID);

              var isVirtual = !record;

              if (!record) {
                record = _this2.store.peekRecord(dbNameVirtual, item.objectID);
              }

              if (!record) {
                var props = {
                  createdAt: item.createdAt,
                  id: item.objectID,
                  isVirtualModel: true,
                  mediaHeight: item.mediaHeight || null,
                  mediaType: item.mediaType,
                  mediaUrl: item.mediaUrl,
                  mediaWidth: item.mediaWidth || null,
                  type: dbName
                };
                var propsToCopy = [];

                switch (result.index) {
                  case 'challenges':
                    propsToCopy.push('hidden'); //, 'creator'

                    break;

                  case 'acceptedChallenges':
                    propsToCopy.push('hidden', 'completed'); //, 'user'

                    break;

                  case 'users':
                    propsToCopy.push('name', 'rank'); // 'latestChallengesCompleted', 'latestChallengesCreated',

                    break;
                }

                propsToCopy.forEach(function (prop) {
                  return props[prop] = item[prop];
                });
                record = _this2.store.createRecord(dbNameVirtual, props);
              }

              if (isVirtual) {
                switch (result.index) {
                  case 'challenges':
                    _this2.resolveRelationships(record, {
                      belongsTo: [{
                        id: item.creator,
                        model: 'user',
                        property: 'creator'
                      }]
                    });

                    break;

                  case 'acceptedChallenges':
                    _this2.resolveRelationships(record, {
                      belongsTo: [{
                        id: item.user,
                        model: 'user',
                        property: 'user'
                      }, {
                        id: item.challenge,
                        model: 'challenge',
                        property: 'challenge'
                      }]
                    });

                    break;

                  case 'users':
                    _this2.resolveRelationships(record, {
                      hasMany: [{
                        ids: item.latestChallengesCompleted,
                        model: 'accepted-challenge',
                        property: 'latestChallengesCompleted'
                      }, {
                        ids: item.latestChallengesCreated,
                        model: 'challenge',
                        property: 'latestChallengesCreated'
                      }]
                    });

                    break;
                }
              }

              _this2[result.index].pushObject(record);
            });
          }
        });
        this.set('searchStatus', 2);
      }
    },
    newSearch: function newSearch(searchValue) {
      var _this3 = this;

      if (searchValue !== '') {
        var options = {
          attributesToRetrieve: this.get('attributesToRetrieve'),
          hitsPerPage: this.get('hitsPerPage'),
          page: 0
        };
        return (0, _fulltextSearch.default)(searchValue, ['acceptedChallenges', 'challenges', 'users'], options).then(function (data) {
          _this3.reset();

          _this3.setProperties({
            searchValue: searchValue
          });

          _this3.searchCallback(data);
        });
      } else {
        this.reset();
      }
    },
    reset: function reset() {
      var _this4 = this;

      var clearProperties = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : true;
      var clearVirtual = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : true;

      if (clearProperties) {
        this.setProperties({
          pageAcceptedChallenges: 0,
          pageChallenges: 0,
          pageUsers: 0,
          totalPages: {},
          searchStatus: 0,
          searchValue: '',
          acceptedChallenges: Ember.A([]),
          challenges: Ember.A([]),
          users: Ember.A([])
        });
      }

      if (clearVirtual) {
        ['challenge-virtual', 'accepted-challenge-virtual', 'user-virtual'].forEach(function (modelName) {
          _this4.store.peekAll(modelName).forEach(function (record) {
            Ember.tryInvoke(record, 'rollbackAttributes');
          });

          _this4.store.unloadAll(modelName);
        });
      }
    },
    nextPage: function nextPage() {
      var _this5 = this;

      if (this.get('searchStatus') === 2) {
        ['pageChallenges', 'pageAcceptedChallenges', 'pageUsers'].forEach(function (prop) {
          _this5.incrementProperty(prop);
        });
        var options = {
          attributesToRetrieve: this.get('attributesToRetrieve'),
          hitsPerPage: this.get('hitsPerPage'),
          page: this.get('pageChallenges')
        };
        var collections = ['acceptedChallenges', 'challenges', 'users'].filter(function (collection) {
          var key = 'page' + collection[0].toUpperCase() + collection.slice(1);
          return _this5.totalPages[collection] && _this5.totalPages[collection] > _this5.get(key);
        });

        if (!collections.length) {
          return null;
        }

        return (0, _fulltextSearch.default)(this.get('searchValue'), collections, options).then(this.searchCallback.bind(this));
      }
    },
    init: function init() {
      this._super.apply(this, arguments);

      this.reset();
    }
  });

  _exports.default = _default;
});