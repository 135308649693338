define("challingo-mobile/routes/page-not-found", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    beforeModel: function beforeModel() {
      this.replaceWith('challenges', {
        queryParams: {
          miniProfile: null
        }
      });
    }
  });

  _exports.default = _default;
});