define("challingo-mobile/utils/detect-interactive", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = detectInteractive;
  _exports.interactiveKey = void 0;
  var interactiveKey = 'window-is-interactive';
  _exports.interactiveKey = interactiveKey;

  function detectInteractive() {
    var trackEvents = ['click', 'scroll', 'touchstart'];

    var handler = function handler() {
      if (typeof window !== 'undefined') {
        window[interactiveKey] = true;
        trackEvents.forEach(function (eventName) {
          window.removeEventListener(eventName, handler);
        });
      }
    };

    if (typeof window !== 'undefined') {
      trackEvents.forEach(function (eventName) {
        window.addEventListener(eventName, handler, false);
      });
    }
  }
});