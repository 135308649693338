define("challingo-mobile/templates/challenge/complete", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "QE+tvrj1",
    "block": "{\"symbols\":[],\"statements\":[[1,[27,\"nav-bar\",null,[[\"title\",\"back\"],[[27,\"t\",[\"challenge.fulfillment\"],null],\"cancel\"]]],false],[0,\"\\n\\n\"],[1,[27,\"forms/challenge-complete-form\",null,[[\"model\"],[[23,[\"model\"]]]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "challingo-mobile/templates/challenge/complete.hbs"
    }
  });

  _exports.default = _default;
});