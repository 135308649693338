define("challingo-mobile/routes/conversation", ["exports", "emberfire/services/realtime-listener"], function (_exports, _realtimeListener) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    currentUser: Ember.inject.service(),
    store: Ember.inject.service(),
    deactivate: function deactivate() {
      (0, _realtimeListener.unsubscribe)(this);
    },
    model: function model(params) {
      var _this = this;

      var currentUserId = this.get('currentUser.uid');
      var otherUserId = params.user_id;
      var data = {
        messagesOut: this.store.query('direct-message', {
          orderBy: {
            createdAt: 'desc'
          },
          where: [['userFrom', '==', currentUserId], ['userTo', '==', otherUserId]]
        }),
        otherUser: this.store.findRecord('user', otherUserId)
      };

      if (currentUserId !== otherUserId) {
        data.messagesIn = this.store.query('direct-message', {
          orderBy: {
            createdAt: 'desc'
          },
          where: [['userFrom', '==', otherUserId], ['userTo', '==', currentUserId]]
        });
      }

      return Ember.RSVP.hash(data).then(function (model) {
        ['messagesIn', 'messagesOut'].forEach(function (prop) {
          if (model && model[prop]) {
            (0, _realtimeListener.subscribe)(_this, model[prop]);
          }
        });
        return model;
      });
    }
  });

  _exports.default = _default;
});