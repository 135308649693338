define("challingo-mobile/components/challenges/challenge-simple-item", ["exports", "challingo-mobile/components/viewport-component"], function (_exports, _viewportComponent) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _viewportComponent.default.extend({
    currentUser: Ember.inject.service(),
    mediaPreview: Ember.inject.service(),
    router: Ember.inject.service(),
    classNames: ['challenge-simple-item'],
    item: null,
    showTitle: true,
    useViewport: true,
    challenge: Ember.computed('isChallenge', 'item', 'item.challenge', function () {
      return this.isChallenge ? this.item : this.get('item.challenge');
    }),
    challengeAccepted: Ember.computed('isChallengeAccepted', 'item', function () {
      return this.isChallengeAccepted ? this.item : undefined;
    }),
    isChallengeAccepted: Ember.computed.equal('item.type', 'accepted-challenge'),
    isChallenge: Ember.computed.equal('item.type', 'challenge'),
    media: Ember.computed.alias('item.media'),
    supportsWebp: Ember.computed.alias('currentUser.hasWebP'),
    photo: Ember.computed('item.{boomerang,photoThumb}', '{isInViewport,useViewport}', 'media.isVideo', function () {
      var photo = this.get('supportsWebp') && this.get('media.isVideo') ? this.get('item.boomerang') : this.get('item.photoThumb');
      var style = "background-image: url('".concat(photo, "');");

      if (this.get('useViewport') && !this.get('isInViewport')) {
        return;
      }

      return Ember.String.htmlSafe(style);
    }),
    showHourglassIcon: Ember.computed('currentUser.{drawnChally.challenge.id,youHaveToWait}', 'item.id', function () {
      return this.get('currentUser.drawnChally.challenge.id') === this.get('item.id') && this.get('currentUser.youHaveToWait');
    }),
    showIconAccept: Ember.computed('currentUser.uid', 'item.{completedFromDrawn,user.id}', function () {
      if (this.get('currentUser.uid') !== this.get('item.user.id')) {
        return false;
      }

      return Boolean(this.get('item.completedFromDrawn'));
    }),
    toDetailDisable: false,
    user: Ember.computed('item.{user,creator}', function () {
      return this.get('item.user') ? this.get('item.user') : this.get('item.creator');
    }),
    videoLoad: function videoLoad() {
      var _this = this;

      Ember.run.scheduleOnce('afterRender', function () {
        if (_this.get('media.isVideo')) {
          var videoEl = _this.element && _this.element.querySelector('video');

          if (videoEl) {
            videoEl.load();
          }
        }
      });
    },
    didUpdateAttrs: function didUpdateAttrs() {
      this._super.apply(this, arguments);

      if (this.detail) {
        this.videoLoad();
      }
    },
    didInsertElement: function didInsertElement() {
      var _this2 = this;

      this._super.apply(this, arguments);

      if (this.detail) {
        this.videoLoad();
      }

      if (this.get('longPressAction')) {
        this.element && this.element.addEventListener('press', function () {
          _this2.longPressAction(_this2.get('item'));
        });
        longClick(this.element, 500);
      }
    },
    actions: {
      toProfile: function toProfile() {
        if (this.get('toDetailDisable')) {
          return;
        }

        this.router.transitionTo('profile.show', this.get('user.id'));
      },
      toDetail: function toDetail(challengeAcceptedId) {
        if (this.get('toDetailDisable')) {
          return;
        }

        if (this.detail) {
          this.set('mediaPreview.media', this.media);

          if (this.get('media.isVideo')) {
            var videoEl = this.element && this.element.querySelector('video');

            if (videoEl) {
              try {
                videoEl.pause();
              } catch (e) {
                e;
              }
            }
          }
        } else {
          this.router.transitionTo('challenges.show', this.get('challenge.id'), {
            queryParams: {
              challengeAcceptedId: challengeAcceptedId,
              miniProfile: null
            }
          });
        }
      }
    }
  });

  _exports.default = _default;
});