define("challingo-mobile/utils/detect-mobile-os", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = detectMobileOS;
  _exports.iOSgetVersion = void 0;

  function detectMobileOS() {
    if (!navigator || !window) {
      return null;
    }

    var userAgent = navigator.userAgent || navigator.vendor || window.opera;

    if (/windows phone/i.test(userAgent)) {
      return "Windows Phone";
    }

    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      return "iOS";
    }

    var iosPlatforms = ['iPad Simulator', 'iPhone Simulator', 'iPod Simulator', 'iPad', 'iPhone', 'iPod', 'Macintosh', 'MacIntel', 'MacPPC'];

    if (typeof navigator !== 'undefined' && iosPlatforms.includes(navigator.platform)) {
      return "iOS";
    }

    if (/android/i.test(userAgent)) {
      return "Android";
    }

    return null;
  }

  var iOSgetVersion = function iOSgetVersion() {
    if (detectMobileOS() === 'iOS') {
      var v = navigator.appVersion.match(/(\d+)_(\d+)_?(\d+)?/);
      return v && parseInt(v[1], 10);
    }

    return null;
  };

  _exports.iOSgetVersion = iOSgetVersion;
});