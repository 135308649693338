define("challingo-mobile/utils/search-component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    search: Ember.inject.service(),
    _searchTimer: null,
    resetSearchUponInit: true,
    resetSearchUponDestroy: true,
    searchValue: '',
    init: function init() {
      this._super.apply(this, arguments);

      if (this.get('resetSearchUponInit')) {
        this.send('resetSearch');
      }
    },
    performSearchFunc: function performSearchFunc() {
      this.send('performSearch');
    },
    searchFinished: Ember.computed.equal('search.searchStatus', 2),
    searchObserver: Ember.observer('searchValue', function () {
      var sv = this.get('searchValue');

      if (!sv) {
        Ember.run.cancel(this._searchTimer);
        this.send('resetSearch');
      } else {
        this._searchTimer = Ember.run.debounce(this, this.performSearchFunc, 100);
      }
    }),
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);

      if (this.get('resetSearchUponDestroy')) {
        this.send('resetSearch');
      }
    },
    actions: {
      performSearch: function performSearch() {
        var searchValue = this.get('searchValue');

        if (searchValue === '') {
          this.send('resetSearch');
        } else {
          this.search.newSearch(searchValue).finally(function () {});
        }
      },
      resetSearch: function resetSearch() {
        this.set('searchValue', '');
        this.search.reset();
      }
    }
  });

  _exports.default = _default;
});