define("challingo-mobile/components/challenges/challenges-search-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['challenges-search-list'],
    challenges: null,
    scrollObserverOnEnter: null,
    scrollObserverOnExit: null,
    showHeading: true,
    lengthMinus2: Ember.computed('challenges.length', function () {
      return this.get('challenges.length') - 2;
    })
  });

  _exports.default = _default;
});