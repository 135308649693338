define("challingo-mobile/services/sentry", ["exports", "@sentry/browser"], function (_exports, Sentry) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    captureException: function captureException() {
      Sentry.captureException.apply(Sentry, arguments);
    },
    captureMessage: function captureMessage() {
      Sentry.captureMessage.apply(Sentry, arguments);
    }
  });

  _exports.default = _default;
});