define("challingo-mobile/utils/challenges/followings", ["exports", "challingo-mobile/utils/challenges/collection", "challingo-mobile/utils/logger"], function (_exports, _collection, _logger) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var callbackGenerator = function callbackGenerator(context) {
    return function (items) {
      if (items && items.length) {
        items.compact().forEach(function (item) {
          return item.get('media');
        });
        context.get('users').pushObjects(items.compact().toArray());
      }
    };
  };

  var _default = _collection.default.extend({
    user: null,
    all: Ember.computed('users.[]', function () {
      return (this.users || []).uniqBy('id');
    }),
    load: function load() {
      var _this = this;

      return this.store.query('follower', {
        limit: this.get('perPage'),
        orderBy: {
          'createdAt': 'desc'
        },
        startAfter: this.get('startAfter.user'),
        where: [['follower', '==', this.get('user.id')]]
      }).then(function (followerItems) {
        var dates = followerItems.mapBy('createdAt');

        if (dates.length) {
          var date = dates.pop();

          _this.set("startAfter.user", date.toISOString());
        }

        return Ember.RSVP.hash({
          users: Ember.RSVP.all(followerItems.map(function (item) {
            return item.get('following');
          })).then(callbackGenerator(_this))
        });
      }, function (e) {
        _logger.default.error(e);
      });
    },
    resetUser: function resetUser(user) {
      if (user && user.id !== this.get('user.id')) {
        this.setProperties({
          user: user
        });
        this.unload();
      }
    },
    unload: function unload() {
      this._super.apply(this, arguments);

      this.setProperties({
        users: Ember.A([]),
        startAfter: {
          user: null
        }
      });
    }
  });

  _exports.default = _default;
});