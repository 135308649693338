define("challingo-mobile/routes/category", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    challenges: Ember.inject.service(),
    model: function model(params) {
      var _this = this;

      var category_id = params.category_id;
      return this.store.findRecord('category', category_id).then(function (category) {
        if (category) {
          _this.challenges.category.resetCategory(category);

          return _this.challenges.category.load().then(function () {
            return category;
          });
        } else {
          return null;
        }
      });
    }
  });

  _exports.default = _default;
});