define("challingo-mobile/services/menu-main", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    isOpen: false,
    menuWasOpen: false,
    open: function open() {
      this.setProperties({
        isOpen: true,
        menuWasOpen: true
      });
      document.body.classList.add('no-scroll');
    },
    close: function close() {
      this.set('isOpen', false);
      document.body.classList.remove('no-scroll');
    }
  });

  _exports.default = _default;
});