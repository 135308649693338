define("challingo-mobile/templates/components/ok-message", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "I0uWzycH",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[23,[\"isOpen\"]]],null,{\"statements\":[[0,\"  \"],[7,\"i\"],[11,\"class\",\"challingo-icons ci-challenge hero\"],[9],[10],[0,\"\\n  \"],[7,\"h2\"],[11,\"class\",\"congrats\"],[9],[1,[27,\"t\",[\"challenge.congratulations\"],null],false],[10],[0,\"\\n  \"],[7,\"p\"],[11,\"class\",\"text\"],[9],[1,[21,\"text\"],false],[10],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"plus\"],[9],[0,\"\\n    \"],[7,\"p\"],[9],[1,[27,\"t\",[\"challenge.plus1\"],null],false],[10],[0,\"\\n  \"],[10],[0,\"\\n\\n  \"],[1,[27,\"direction-btns\",null,[[\"btnRight\",\"btnRightAction\"],[[27,\"hash\",null,[[\"link\",\"text\"],[\"action\",[27,\"t\",[\"ok\"],null]]]],[27,\"action\",[[22,0,[]],\"btnRightAction\"],null]]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "challingo-mobile/templates/components/ok-message.hbs"
    }
  });

  _exports.default = _default;
});