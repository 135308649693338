define("challingo-mobile/templates/components/followers-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "2LWZXZZF",
    "block": "{\"symbols\":[\"list\",\"followItem\"],\"statements\":[[7,\"div\"],[11,\"class\",\"menu\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"nav-bar\"],[9],[0,\"\\n    \"],[7,\"a\"],[11,\"href\",\"#\"],[11,\"class\",\"back\"],[11,\"style\",\"touch-action: manipulation; -ms-touch-action: manipulation; cursor: pointer;\"],[9],[0,\"\\n      \"],[7,\"i\"],[11,\"class\",\"challingo-icons moonicon-back\"],[9],[10],[0,\"\\n    \"],[3,\"action\",[[22,0,[]],\"closeFollow\"]],[10],[0,\"\\n\\n    \"],[7,\"div\"],[11,\"class\",\"title\"],[9],[0,\"\\n      \"],[1,[27,\"t\",[[27,\"if\",[[23,[\"followedBy\"]],\"follow.followers\",\"follow.followings\"],null]],null],false],[0,\"\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\\n\"],[4,\"if\",[[23,[\"spacer\"]]],null,{\"statements\":[[0,\"    \"],[7,\"div\"],[11,\"class\",\"nav-bar-spacer\"],[9],[0,\" \"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"content\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"followers list-with-observer\"],[9],[0,\"\\n\"],[4,\"lazy-loading-list\",null,[[\"items\",\"itemsPerPage\",\"sessionId\",\"pageBottomBar\"],[[23,[\"model\"]],10,[27,\"if\",[[23,[\"followedBy\"]],\"followers\",\"followings\"],null],false]],{\"statements\":[[4,\"each\",[[22,1,[]]],null,{\"statements\":[[0,\"          \"],[1,[27,\"followers-item\",null,[[\"item\"],[[22,2,[]]]]],false],[0,\"\\n\"]],\"parameters\":[2]},null],[0,\"        \"],[1,[27,\"scroll-observer\",null,[[\"onEnterViewport\"],[[23,[\"scrollObserverOnEnter\"]]]]],false],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "challingo-mobile/templates/components/followers-list.hbs"
    }
  });

  _exports.default = _default;
});