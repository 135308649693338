define("challingo-mobile/components/modal-component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    modal: Ember.inject.service(),
    classNames: ['modal-component', 'clickable'],
    classNameBindings: ['isOpen:open', 'noStyle:no-style'],
    isOpen: Ember.computed.alias('modal.isOpen'),
    componentName: Ember.computed.alias('modal.componentName'),
    model: Ember.computed.alias('modal.model'),
    noStyle: Ember.computed.bool('modal.options.noStyle'),
    click: function click(event) {
      if (event.target.classList.contains('modal-component')) {
        this.send('close');
      }
    },
    actions: {
      close: function close() {
        this.modal.close();
      }
    }
  });

  _exports.default = _default;
});