define("challingo-mobile/components/comments-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    confirmDialog: Ember.inject.service(),
    intl: Ember.inject.service(),
    toastMessage: Ember.inject.service(),
    tagName: 'div',
    classNames: ['comments-list'],
    challenge: null,
    comments: null,
    limit: 0,
    list: null,
    commentPage: false,
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);

      var comments = this.comments,
          limit = this.limit;
      var list = limit ? comments.slice(-limit) : comments;
      this.setProperties({
        list: list
      });
    },
    actions: {
      deleteComment: function deleteComment(item) {
        var _this = this;

        Ember.tryInvoke(this, 'modalClose');
        var comments = this.get('comments');
        this.confirmDialog.show(this.intl.t('comments.delete_confirm'), function () {
          item.destroyRecord().then(function () {
            comments.removeObject(item);

            _this.get('challenge').save({
              adapterOptions: {
                saveOnly: ['comments']
              }
            });

            _this.toastMessage.show(_this.intl.t('comments.delete_success'));
          }, function () {
            _this.toastMessage.show(_this.intl.t('comments.delete_error'));
          });
        });
      }
    }
  });

  _exports.default = _default;
});