define("challingo-mobile/templates/challenges/dm", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "3jdnpxQh",
    "block": "{\"symbols\":[],\"statements\":[[1,[27,\"send-dm-page\",null,[[\"challenge\",\"challengeAcceptedId\"],[[23,[\"model\"]],[23,[\"challengeAcceptedId\"]]]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "challingo-mobile/templates/challenges/dm.hbs"
    }
  });

  _exports.default = _default;
});