define("challingo-mobile/components/user-profile", ["exports", "challingo-mobile/utils/open-external-url"], function (_exports, _openExternalUrl) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    challenges: Ember.inject.service(),
    confirmDialog: Ember.inject.service(),
    currentUser: Ember.inject.service(),
    intl: Ember.inject.service(),
    menuProfile: Ember.inject.service(),
    modal: Ember.inject.service(),
    router: Ember.inject.service(),
    session: Ember.inject.service(),
    classNames: ['user-profile'],
    user: null,
    fullProfil: false,
    profileSection: 'chally',
    usernameStyle: Ember.computed('user.name', 'fullProfil', function () {
      var nameLength = this.get('user.name.length') || 0;

      if (this.fullProfil || nameLength < 12) {
        return null;
      }

      var fontSize = 0.55 + 0.6 * Math.max(0, 20 - nameLength) / 8;
      return Ember.String.htmlSafe("font-size: ".concat(fontSize, "em;"));
    }),
    chally: Ember.computed('user.{completedCount,createdCount}', function () {
      var completed = this.get('user.completedCount') || 0;
      var created = this.get('user.createdCount') || 0;
      return completed + created;
    }),
    isOwnProfile: Ember.computed('user.id', 'currentUser.user.id', function () {
      return this.get('user.id') == this.get('currentUser.user.id');
    }),
    _profileMenuObserver: Ember.observer('router.currentURL', function () {
      var _this = this;

      var currentURL = this.get('router.currentURL');

      if (currentURL && !currentURL.includes('profileMenuOpen=true')) {
        Ember.run.later(function () {
          if (["profile.show"].includes(_this.router.currentRouteName) && _this.menuProfile.isOpen) {
            _this.menuProfile.close();
          }
        }, 50);
      }
    }),
    init: function init() {
      this._super.apply(this, arguments);

      this.router.currentURL;
    },
    actions: {
      chooseSection: function chooseSection(section) {
        this.set('profileSection', section);
      },
      follow: function follow() {
        if (!this.get('session.isAuthenticated')) {
          this.modal.show('modals/login-popup', null, {
            goBack: true,
            noStyle: true
          });
          return null;
        }

        Ember.tryInvoke(this.user, 'follow');
      },
      loadNextProfilePage: function loadNextProfilePage() {
        this.challenges.userProfile.nextPage();
      },
      menuProfileOpen: function menuProfileOpen() {
        if (!this.get('session.isAuthenticated')) {
          this.modal.show('modals/login-popup', null, {
            goBack: true,
            noStyle: true
          });
          return null;
        }

        this.modal.show('profile-actions', this.user);
      },
      open: function open() {
        this.menuProfile.open();
        this.router.transitionTo({
          queryParams: {
            profileMenuOpen: true
          }
        });
      },
      openDM: function openDM() {
        if (!this.get('session.isAuthenticated')) {
          this.modal.show('modals/login-popup', null, {
            goBack: true,
            noStyle: true
          });
          return null;
        }

        this.router.transitionTo('conversation', this.get('user.id'));
      },
      openLinktreeUrl: function openLinktreeUrl() {
        var url = this.get('user.linktreeUrl');

        if (!url.match(/^http/)) {
          url = "https://".concat(url);
        }

        (0, _openExternalUrl.default)(url);
      },
      showOwnProfileFromMini: function showOwnProfileFromMini() {
        if (!this.get('fullProfil') && this.get('isOwnProfile')) {
          this.router.transitionTo('profile.show', this.get('currentUser.user.id'));
        }
      },
      unfollow: function unfollow() {
        var _this2 = this;

        this.confirmDialog.show(this.intl.t('follow.delete_following'), function () {
          Ember.tryInvoke(_this2.user, 'unfollow');
        });
      },
      userEdit: function userEdit(id) {
        if (id === this.get('currentUser.user.id')) {
          this.router.transitionTo('users.edit', id);
        }
      }
    }
  });

  _exports.default = _default;
});