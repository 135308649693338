define("challingo-mobile/components/media/media-preview", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    cordova: Ember.inject.service(),
    mediaPreview: Ember.inject.service(),
    classNames: ['media-preview'],
    classNameBindings: ['isOpen:open'],
    media: Ember.computed.alias('mediaPreview.media'),
    mediaObserver: Ember.observer('media', function () {
      var _this = this;

      Ember.run.scheduleOnce('afterRender', function () {
        if (_this.get('media.isVideo')) {
          var video = _this.element && _this.element.querySelector('video');

          if (video) {
            video.load();
          }
        }
      });
    }),
    isOpen: Ember.computed.bool('media'),
    actions: {
      close: function close() {
        this.set('media', null);
        Ember.tryInvoke(this.mediaPreview, 'closeCallback');
      }
    }
  });

  _exports.default = _default;
});