define("challingo-mobile/templates/components/forms/user-photo-form", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "/1dGvGka",
    "block": "{\"symbols\":[],\"statements\":[[1,[27,\"media/media-upload\",null,[[\"error\",\"headingText\",\"media\",\"showCreatingHeading\"],[[23,[\"error\"]],[27,\"t\",[\"photo.change\"],null],[23,[\"media\"]],true]]],false],[0,\"\\n\\n\"],[4,\"if\",[[23,[\"mediaSelected\"]]],null,{\"statements\":[[0,\"  \"],[7,\"button\"],[11,\"class\",\"btn btn-action create-chally\"],[12,\"disabled\",[21,\"loading\"]],[11,\"style\",\"touch-action: manipulation; -ms-touch-action: manipulation; cursor: pointer;\"],[11,\"type\",\"submit\"],[9],[0,\"\\n    \"],[1,[27,\"t\",[\"photo.change\"],null],false],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "challingo-mobile/templates/components/forms/user-photo-form.hbs"
    }
  });

  _exports.default = _default;
});