define("challingo-mobile/utils/export-models", ["exports", "ember-inflector"], function (_exports, _emberInflector) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = exportModels;

  /*
  Call from some Ember object (component, route, etc.) Usage example:
  
  const modelData = exportModels(this);
  console.log(JSON.stringify(modelData));
  */
  function exportModels(EmberObject) {
    var modelData = {};
    var ignoreModels = ['accepted-challenge-virtual', 'challenge-virtual', 'model-with-media', 'notification', 'user-virtual', 'user-wall'];
    var models = Ember.getOwner(EmberObject).lookup('data-adapter:main').getModelTypes(); // console.log(models);

    models.filter(function (item) {
      return !ignoreModels.includes(item.name);
    }).forEach(function (item) {
      modelData[item.name] = {
        namePlural: (0, _emberInflector.pluralize)(item.name),
        nameCamelized: (0, _emberInflector.pluralize)(item.name).camelize(),
        attributes: [],
        relationshipsByName: []
      };
      item.klass.attributes.forEach(function (value, key) {
        modelData[item.name].attributes.push({
          name: key,
          type: value.type
        });
      });
      item.klass.relationshipsByName.forEach(function (value, key) {
        modelData[item.name].relationshipsByName.push({
          name: key,
          type: value.kind
        });
      });
    });
    return modelData;
  }
});