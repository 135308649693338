define("challingo-mobile/components/video-player", ["exports", "challingo-mobile/utils/detect-interactive"], function (_exports, _detectInteractive) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'video',
    playsinline: true,
    loop: true,
    autoplay: false,
    attributeBindings: ['autoplay', 'muted', 'loop', 'oncanplay', 'playsinline', 'posterUrl:poster', 'preload'],
    width: null,
    height: null,
    posterUrl: Ember.computed('{media.video,width,height}', function () {
      var height = this.get('height') || Math.floor(this.element.offsetHeight);
      var width = this.get('width') || Math.floor(this.element.offsetWidth);
      var url = this.get('media.video');
      url = url.substr(0, url.lastIndexOf('.')) + '.jpg';

      if (width && height) {
        return url.replace('vc_auto,c_pad,b_blurred:1000,q_99', "so_0,c_pad,b_auto:predominant_gradient:2,h_".concat(height, ",w_").concat(width));
      }

      return url;
    }),
    didInsertElement: function didInsertElement() {
      var _this = this;

      this._super.apply(this, arguments);

      this.setProperties({
        height: Math.floor(this.element.offsetHeight),
        width: Math.floor(this.element.offsetWidth)
      });

      if (this.autoplay && (typeof window === 'undefined' || !window[_detectInteractive.interactiveKey])) {
        var video = this && this.element;

        if (video) {
          video.play().catch(function () {
            _this.set('muted', true);
          });
        }
      }
    }
  });

  _exports.default = _default;
});