define("challingo-mobile/routes/signup", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    session: Ember.inject.service(),
    beforeModel: function beforeModel() {
      if (this.get('session.isAuthenticated')) {
        this.replaceWith('challenges', {
          queryParams: {
            miniProfile: null
          }
        });
      }
    }
  });

  _exports.default = _default;
});