define("challingo-mobile/components/forms/challenge-new-form", ["exports", "challingo-mobile/utils/hashtags", "challingo-mobile/utils/badges-list", "challingo-mobile/routes/application", "challingo-mobile/utils/get-xp-tables", "challingo-mobile/utils/log-analytics-event", "challingo-mobile/utils/jquery-polyfills"], function (_exports, _hashtags, _badgesList, _application, _getXpTables, _logAnalyticsEvent, _jqueryPolyfills) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  var _default = Ember.Component.extend({
    badgeMessage: Ember.inject.service(),
    challenges: Ember.inject.service(),
    currentUser: Ember.inject.service(),
    firebaseApp: Ember.inject.service(),
    intl: Ember.inject.service(),
    levelupMessage: Ember.inject.service(),
    modal: Ember.inject.service(),
    pushNotification: Ember.inject.service(),
    router: Ember.inject.service(),
    store: Ember.inject.service(),
    toastMessage: Ember.inject.service(),
    xpMessage: Ember.inject.service(),
    tagName: 'form',
    classNames: ['challenge-form'],
    _onScroll: null,
    loading: false,
    newChallengeBody: '',
    scrolledDown: false,
    taggedUsers: null,
    categories: Ember.computed.alias('model.categories'),
    categoryName: Ember.computed('challenge.category.id', 'user.localeAugmented', function () {
      var locale = this.get('user.localeAugmented') || _application.defaultLocale;

      return this.get("challenge.category.name_".concat(locale));
    }),
    challenge: Ember.computed.alias('model.challenge'),
    media: Ember.computed.alias('model.media'),
    mediaSelected: Ember.computed.alias('model.media.url'),
    user: Ember.computed.alias('currentUser.user'),
    _scrollHandler: function _scrollHandler() {
      this.set('scrolledDown', document.body.scrollTop > 10);
    },
    toast: function toast(msg) {
      if (this && this.toastMessage && this.toastMessage.show) {
        this.toastMessage.show(this.intl.t(msg));
      }
    },
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      this._onScroll = this._scrollHandler.bind(this);
      window.addEventListener('scroll', this._onScroll, true);
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);

      window.removeEventListener('scroll', this._onScroll, true);
      this._onScroll = null;
    },
    init: function init() {
      this._super.apply(this, arguments);

      this.setProperties({
        'challenge.description': '',
        'newChallengeBody': '',
        taggedUsers: []
      });
    },
    submit: function submit() {
      var _this = this;

      this.set('loading', true);
      var user = this.user;
      var media = this.media;
      var title = this.get('challenge.title');
      var description = this.get('newChallengeBody') || this.get('challenge.description');
      var tags = this.get('challenge.tags');

      if (media.get('isEmpty') || !title || !description) {
        this.set('error', this.get('intl').t("media.required"));
        this.set('loading', false);
        return false;
      }

      media.save().then(function (savedMedia) {
        _this.challenge.setProperties({
          creator: user,
          description: description,
          _media: savedMedia,
          mediaHeight: savedMedia.get('height') || null,
          mediaType: savedMedia.get('type'),
          mediaUrl: savedMedia.get('url'),
          mediaWidth: savedMedia.get('width') || null
        });

        _this.challenge.save().then(function (challengeSaved) {
          var currentXP = user.get('xp') || 0,
              currentLevel = user.get('level') || 1,
              countAccepted = 0,
              xpTables = (0, _getXpTables.default)(),
              earnedXP = Math.round(xpTables.levelFix[currentLevel - 1] + Math.sqrt(xpTables.levelAdvance[currentLevel - 1]) - Math.pow(countAccepted, 0.25)),
              nextXP = Math.min(currentXP + earnedXP, xpTables.levelAdvanceCum[xpTables.levelAdvanceCum.length - 1]);
          user.setProperties({
            xp: nextXP
          });
          var nextLevel = user.get('level');
          var showProps = null;
          var msgShown = user.get('badgesMessagesShown') || {};

          if (user.get('createdCount') >= 50 && !msgShown.creative) {
            msgShown.creative = true;
            user.set('badgesMessagesShown', msgShown);
            var icon = (0, _badgesList.default)().filter(function (item) {
              return item.id == 'creative';
            })[0].icon;
            showProps = {
              badgeIcon: icon,
              rank: 'badges.creative',
              rankIcon: icon,
              titleString: 'badges.badge_messages.creative_title',
              textString: 'badges.badge_messages.creative_text'
            };
          }

          Ember.RSVP.all(_this.taggedUsers.uniqBy('id').map(function (tagUser) {
            return _this.store.findRecord('user', tagUser.id);
          })).then(function (tagUsers) {
            tagUsers.forEach(function (tagUser) {
              _this.pushNotification.send(tagUser, 'mention', _this.intl.t('push-notifications.mention', {
                locale: tagUser && tagUser.get('localeAugmented')
              }), _this.get('currentUser.user'), _this.challenge);
            });
          });

          var nominated = _this.challenge.get('nominated');

          if (nominated) {
            nominated.forEach(function (nominatedUser) {
              _this.pushNotification.send(nominatedUser, 'call_to', _this.intl.t('push-notifications.call_to', {
                locale: nominatedUser && nominatedUser.get('localeAugmented')
              }), _this.get('currentUser.user'), _this.challenge);
            });
          }

          (0, _logAnalyticsEvent.default)(_this.firebaseApp, 'chally_created', {
            chally_id: challengeSaved.id,
            user_id: _this.get('currentUser.uid')
          });

          var categoryChally = _this.store.createRecord('category-challenge', {
            category: _this.challenge.category,
            challenge: _this.challenge
          });

          (0, _hashtags.loadNecessaryHashtags)(_this.store, description + ' ' + tags, '').then(function (hashtags) {
            var promises = [_this.store.findRecord('challenge', challengeSaved.id, {
              reload: true
            }), user.save({
              adapterOptions: {
                saveOnly: ['badgesMessagesShown', 'xp']
              }
            }), categoryChally.save(), _this.store.createRecord('user-challenge', {
              challenge: challengeSaved,
              challengeAccepted: null,
              createdAt: new Date(),
              hidden: false,
              user: user
            }).save()].concat(_toConsumableArray((0, _hashtags.saveHashtags)(description + ' ' + tags, '', _this.challenge, null, hashtags, _this.store)));
            Ember.RSVP.all(promises).then(function (results) {
              var collectionUser = _this.challenges.userProfile.user;

              if (collectionUser && collectionUser.id === user.id) {
                _this.challenges.userProfile.challenges.pushObject(results[0]);
              }

              _this.challenges.newsfeed.challenges.pushObject(results[0]);

              _this.router.transitionTo('challenges', {
                queryParams: {
                  miniProfile: null
                }
              });

              _this.xpMessage.show(earnedXP, _this.intl.t('challenge.new_submit_success', {
                htmlSafe: true
              }));

              if (currentLevel != nextLevel) {
                _this.levelupMessage.show(nextLevel);
              }

              if (showProps) {
                _this.badgeMessage.show(showProps);
              }
            }, function () {
              if (!_this.isDestroyed) {
                _this.set('loading', false);
              }
            });
          });
        }, function () {
          _this.toast('challenge.new_submit_error');

          if (!_this.isDestroyed) {
            _this.set('loading', false);
          }
        });
      }, function () {
        _this.toast('challenge.new_submit_error');

        if (!_this.isDestroyed) {
          _this.set('loading', false);
        }
      });
      return false;
    },
    actions: {
      openCategoryModal: function openCategoryModal(e) {
        if (e.target) {
          e.target.blur();
        }

        this.modal.show('category-select', {
          categories: this.get('categories'),
          challenge: this.get('challenge')
        });
      },
      scrollToForm: function scrollToForm() {
        var el = document.getElementById('challenge-form-scroll-down-anchor');

        if (el) {
          var os = (0, _jqueryPolyfills.offset)(el);

          if (os && os.top) {
            document.body.scrollTo({
              left: 0,
              top: os.top,
              behavior: 'smooth'
            });
          }
        }
      }
    }
  });

  _exports.default = _default;
});