define("challingo-mobile/components/category-page", ["exports", "challingo-mobile/mixins/swipe-events"], function (_exports, _swipeEvents) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_swipeEvents.default, {
    challenges: Ember.inject.service(),
    classNames: ['category-page'],
    swipeRight: function swipeRight() {
      window.history.back();
    },
    actions: {
      loadNextCategoryPage: function loadNextCategoryPage() {
        this.challenges.category.nextPage();
      }
    }
  });

  _exports.default = _default;
});