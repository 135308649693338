define("challingo-mobile/templates/components/challenges/challenge-item-mini", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "hUmq5z7t",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[27,\"and\",[[27,\"not\",[[23,[\"supportsWebp\"]]],null],[23,[\"media\",\"isVideo\"]],[27,\"or\",[[27,\"not\",[[23,[\"useViewport\"]]],null],[23,[\"isInViewport\"]]],null]],null]],null,{\"statements\":[[0,\"  \"],[7,\"div\"],[11,\"class\",\"video-cover\"],[9],[0,\"\\n    \"],[7,\"i\"],[11,\"class\",\"challingo-icons ci-add-a-video loading-icon\"],[9],[10],[0,\"\\n    \"],[7,\"video\"],[11,\"autoplay\",\"\"],[11,\"loop\",\"\"],[11,\"muted\",\"\"],[11,\"playsinline\",\"\"],[12,\"poster\",[23,[\"media\",\"photo\"]]],[11,\"preload\",\"none\"],[9],[0,\"\\n      \"],[1,[27,\"video-boomerang\",null,[[\"src\"],[[23,[\"media\",\"video\"]]]]],false],[0,\"\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "challingo-mobile/templates/components/challenges/challenge-item-mini.hbs"
    }
  });

  _exports.default = _default;
});