define("challingo-mobile/routes/users/edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model: function model(params) {
      return Ember.RSVP.hash({
        user: this.store.findRecord('user', params.user_id),
        media: this.store.createRecord('media')
      });
    },
    resetController: function resetController(controller) {
      this._super.apply(this, arguments);

      ['model.media', 'model.user'].forEach(function (prop) {
        controller.get(prop).rollbackAttributes();
      });
    }
  });

  _exports.default = _default;
});