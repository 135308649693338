define("challingo-mobile/services/challenges", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    collections: Ember.A(['category', 'follower', 'following', 'hashtag', 'newsfeed', 'searchall', 'trendy', 'userProfile', 'userProfileAccepted', 'userProfileCompleted', 'userProfileCreated', 'usersall']),
    perPage: 20,
    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);

      var owner = Ember.getOwner(this);
      this.collections.forEach(function (collectionName) {
        _this.set(collectionName, owner.lookup("object:".concat(collectionName)));
      });
      this.unload();
    },
    unload: function unload() {
      var _this2 = this;

      this.collections.forEach(function (collectionName) {
        Ember.tryInvoke(_this2.get(collectionName), 'unload');
      });
    }
  });

  _exports.default = _default;
});