define("challingo-mobile/templates/feedback", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "6b3Jot6g",
    "block": "{\"symbols\":[],\"statements\":[[1,[27,\"nav-bar\",null,[[\"title\",\"back\"],[[27,\"t\",[\"feedback.send_us\"],null],\"back\"]]],false],[0,\"\\n\\n\"],[7,\"div\"],[11,\"class\",\"page-offset\"],[9],[0,\"\\n  \"],[1,[21,\"forms/feedback-form\"],false],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "challingo-mobile/templates/feedback.hbs"
    }
  });

  _exports.default = _default;
});