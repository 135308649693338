define("challingo-mobile/components/conditional-link", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    session: Ember.inject.service(),
    model: null,
    route: '',
    tagName: '',
    unauthenticatedClass: 'unauthenticated'
  });

  _exports.default = _default;
});