define("challingo-mobile/routes/profile/followers", ["exports", "challingo-mobile/utils/logger", "emberfire/services/realtime-listener"], function (_exports, _logger, _realtimeListener) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    challenges: Ember.inject.service(),
    afterModel: function afterModel(model) {
      if (model && !model.get('isVirtualModel')) {
        (0, _realtimeListener.subscribe)(this, model);
      }
    },
    model: function model(params) {
      var _this = this;

      return this.store.findRecord('user', params.profile_id).then(function (user) {
        if (user) {
          _this.challenges.follower.resetUser(user);

          return _this.challenges.follower.load().then(function () {
            return user;
          });
        } else {
          return null;
        }
      }, function (e) {
        _logger.default.error(e);
      });
    }
  });

  _exports.default = _default;
});