define("challingo-mobile/utils/badges-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = badgesList;
  var badges = [{
    id: 'doer',
    icon: '✊'
  }, {
    id: 'creative',
    icon: '👨‍🎨'
  }, {
    id: 'yesman',
    icon: '👍'
  }, {
    id: 'keeper',
    icon: '🤟'
  }, {
    id: 'savage',
    icon: '☠️'
  }];

  function badgesList() {
    return badges.map(function (badge) {
      return {
        id: badge.id,
        image: "assets/images/svg/badges/".concat(badge.id, ".svg"),
        icon: badge.icon
      };
    });
  }
});