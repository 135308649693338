define("challingo-mobile/utils/get-storable-url", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = getStorableUrl;

  function getStorableUrl(locationType, useQueryParams) {
    var url;

    if (locationType === 'hash') {
      if (useQueryParams) {
        url = window.location.hash.slice(1);
      } else {
        url = window.location.hash.slice(1).replace(/\?.*/g, '');
      }
    } else {
      if (useQueryParams) {
        url = window.location.pathname + window.location.search;
      } else {
        url = window.location.pathname;
      }
    }

    if (url === '') {
      url = '/';
    }

    return url;
  }
});