define("challingo-mobile/templates/components/xp-message", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "8hwiLNVD",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[23,[\"isOpen\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\"],[11,\"class\",\"xp-plus-picture\"],[9],[0,\"\\n    \"],[7,\"span\"],[11,\"class\",\"xp-plus-count\"],[9],[0,\"+\"],[1,[21,\"xp\"],false],[0,\" \"],[1,[27,\"t\",[\"levels.xp\"],null],false],[10],[0,\"\\n  \"],[10],[0,\"\\n  \"],[7,\"h2\"],[11,\"class\",\"congrats\"],[9],[1,[27,\"t\",[\"levels.xp_modal_title\"],null],false],[10],[0,\"\\n  \"],[7,\"p\"],[11,\"class\",\"text\"],[9],[1,[21,\"text\"],false],[10],[0,\"\\n\\n  \"],[7,\"div\"],[11,\"class\",\"direction-btns sticky\"],[9],[0,\"\\n    \"],[7,\"button\"],[11,\"class\",\"btn\"],[11,\"style\",\"touch-action: manipulation; -ms-touch-action: manipulation; cursor: pointer;\"],[11,\"type\",\"button\"],[9],[0,\"\\n      \"],[1,[27,\"t\",[\"cool\"],null],false],[0,\"\\n    \"],[3,\"action\",[[22,0,[]],\"btnAction\"]],[10],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "challingo-mobile/templates/components/xp-message.hbs"
    }
  });

  _exports.default = _default;
});