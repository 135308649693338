define("challingo-mobile/templates/application", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "fMJwdMO7",
    "block": "{\"symbols\":[],\"statements\":[[1,[21,\"outlet\"],false],[0,\"\\n\\n\"],[1,[21,\"media/media-preview\"],false],[0,\"\\n\"],[1,[21,\"modal-component\"],false],[0,\"\\n\"],[1,[21,\"confirm-dialog\"],false],[0,\"\\n\"],[1,[21,\"toast-message\"],false],[0,\"\\n\"],[1,[21,\"ok-message\"],false],[0,\"\\n\"],[1,[21,\"ok-message-bar\"],false],[0,\"\\n\"],[1,[21,\"badge-message\"],false],[0,\"\\n\"],[1,[21,\"levelup-message\"],false],[0,\"\\n\"],[1,[21,\"xp-message\"],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "challingo-mobile/templates/application.hbs"
    }
  });

  _exports.default = _default;
});