define("challingo-mobile/components/forms/challenge-description-edit-form", ["exports", "challingo-mobile/utils/hashtags", "challingo-mobile/helpers/tag2null"], function (_exports, _hashtags, _tag2null) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  var _default = Ember.Component.extend({
    currentUser: Ember.inject.service(),
    intl: Ember.inject.service(),
    pushNotification: Ember.inject.service(),
    store: Ember.inject.service(),
    tagName: 'form',
    classNames: ['challenge-description-edit-form'],
    model: null,
    description: '',
    editChallengeBody: '',
    tags: '',
    loading: false,
    challenge: Ember.computed.alias('model'),
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      this.setProperties({
        description: String((0, _tag2null.tag2null)([this.get('challenge.description')])),
        tags: this.get('challenge.tags')
      });
    },
    init: function init() {
      this._super.apply(this, arguments);

      this.setProperties({
        taggedUsers: []
      });
    },
    submit: function submit() {
      var _this = this;

      var challenge = this.challenge;
      var currentUser = this.get('currentUser.user');
      var oldDescription = challenge.get('description') || '';
      var oldTags = challenge.get('tags');
      var newDescription = this.description || '';
      var newTags = this.tags;

      if (!newDescription) {
        return false;
      }

      this.set('loading', true);
      challenge.setProperties({
        description: this.get('editChallengeBody') || newDescription,
        tags: newTags
      });
      challenge.save({
        adapterOptions: {
          saveOnly: ['description', 'tags']
        }
      }).finally(function () {
        (0, _hashtags.loadNecessaryHashtags)(_this.store, newDescription + ' ' + newTags, oldDescription + ' ' + oldTags).then(function (allHashtags) {
          var promises = _toConsumableArray((0, _hashtags.saveHashtags)(newDescription + ' ' + newTags, oldDescription + ' ' + oldTags, challenge.type == 'challenge' ? challenge : null, //challenge
          challenge.type == 'challenge' ? null : challenge, //acceptedChallenge
          allHashtags, _this.store));

          Ember.RSVP.all(promises).then(function () {
            var re = new RegExp("(@\\S+){([^}]+)}", "gi");
            var newDescriptionRaw = _this.get('challenge.description') || '';

            var oldTagged = _toConsumableArray(oldDescription.matchAll(re));

            var newTagged = _toConsumableArray(newDescriptionRaw.matchAll(re));

            var notifyIds = [];
            newTagged.forEach(function (newTaggedItem) {
              var notifyUserId = newTaggedItem[2];

              if (!oldTagged.find(function (oldTaggedItem) {
                return oldTaggedItem[2] === notifyUserId;
              }) && !notifyIds.includes(notifyUserId)) {
                notifyIds.push(notifyUserId);
              }
            });
            Ember.RSVP.all(notifyIds.map(function (tagUserId) {
              return _this.store.findRecord('user', tagUserId);
            })).then(function (tagUsers) {
              tagUsers.forEach(function (tagUser) {
                _this.pushNotification.send(tagUser, 'mention', _this.intl.t('push-notifications.mention', {
                  locale: tagUser && tagUser.get('localeAugmented')
                }), currentUser, challenge);
              });
            });

            if (!_this.isDestroyed) {
              _this.set('loading', false);
            }

            Ember.tryInvoke(_this, 'modalClose');
          });
        });
      });
      return false;
    }
  });

  _exports.default = _default;
});