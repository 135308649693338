define("challingo-mobile/components/notifications-page", ["exports", "challingo-mobile/components/welcome-page", "ember-uuid"], function (_exports, _welcomePage, _emberUuid) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    currentUser: Ember.inject.service(),
    pushNotification: Ember.inject.service(),
    store: Ember.inject.service(),
    classNames: ['notifications-page'],
    newNotificationBody: '',
    loading: false,
    submit: function submit() {
      var _this = this;

      var body = this.newNotificationBody;
      var user = this.get('currentUser.user');

      if (!body) {
        return false;
      }

      this.set('loading', true);
      var id = (0, _emberUuid.v4)();
      var notification = this.store.createRecord('admin-notification', {
        id: id,
        body: body,
        user: user
      });
      notification.save().then(function (savedItem) {
        _this.setProperties({
          loading: false,
          newNotificationBody: ''
        });

        _this.pushNotification.addedNotifications.pushObject(savedItem);

        var el = _this.element && _this.element.querySelector('.lazy-loading-list');

        if (el) {
          el.scrollTo({
            behavior: 'smooth',
            top: 0
          });
        }
      });
      return false;
    },
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      (0, _welcomePage.hideSplashscreen)();
    }
  });

  _exports.default = _default;
});