define("challingo-mobile/components/lazy-loading-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['lazy-loading-list'],
    classNameBindings: ['pageNavBar', 'pageNavBarBig', 'pageBottomBar', 'pageBottomBar2x'],
    items: null,
    itemsPerPage: 5,
    sessionId: null,
    pageNavBar: true,
    pageBottomBar: false,
    pageBottomBar2x: false,
    page: 1,
    scrollTop: 0,
    scrollHandler: null,
    loading: false,
    pageBoundary: Ember.computed('items.[]', 'page', function () {
      var pageItems = this.page * this.itemsPerPage,
          itemsLength = this.get('items.length');
      var top = pageItems - this.itemsPerPage * 2,
          bottom = pageItems + this.itemsPerPage;

      if (top < 0) {
        top = 0;
      } else if (bottom > itemsLength) {
        bottom = itemsLength;
      }

      return Ember.Object.create({
        top: 0,
        bottom: bottom
      });
    }),
    list: Ember.computed('items.[]', 'pageBoundary.{top,bottom}', function () {
      var pageBoundary = this.pageBoundary;
      return (this.items || []).slice(pageBoundary.get('top'), pageBoundary.get('bottom'));
    }),
    startLoading: function startLoading() {
      this.set('loading', true);
      var i = this.element && this.element.querySelector('.loading i');

      if (i) {
        i.classList.remove('hidden');
      }
    },
    pageUp: function pageUp() {
      var _this = this;

      this.startLoading();
      this.decrementProperty('page');
      Ember.run.scheduleOnce('afterRender', function () {
        var el1 = _this.element && _this.element.querySelector('.loading');

        var el2 = _this.element && _this.element.querySelector('.list');

        var height1 = el1 ? el1.offsetHeight : 0;
        var height2 = el2 ? el2.offsetHeight : 0;
        var loadingHeight = _this.page === 2 ? height1 : 0;

        _this.element.scrollTo(0, height2 / 3 - loadingHeight);
      });
    },
    pageDown: function pageDown() {
      this.startLoading();
      this.incrementProperty('page');
    },
    sessionLoad: function sessionLoad() {
      var _this2 = this;

      var sessionId = this.sessionId,
          session = window.session && window.session[sessionId];

      if (!sessionId) {
        return;
      }

      if (session) {
        this.set('page', session.page);
        Ember.run.scheduleOnce('afterRender', function () {
          _this2.element.scrollTo(0, session.scrollTop);
        });
      }
    },
    sessionSave: function sessionSave() {
      var sessionId = this.sessionId,
          page = this.page,
          scrollTop = this.element.scrollTop;

      if (!sessionId) {
        return;
      }

      if (!window.session) {
        window.session = [];
      }

      window.session[sessionId] = {
        page: page,
        scrollTop: scrollTop
      };
    },
    init: function init() {
      this._super.apply(this, arguments);

      this.sessionLoad();
    },
    didInsertElement: function didInsertElement() {
      var _this3 = this;

      this._super.apply(this, arguments);

      this.set('scrollHandler', function () {
        var el = _this3.element && _this3.element.querySelector('.list');

        var scrollTop = _this3.element && _this3.element.scrollTop || 0;
        var scrollBottom = scrollTop + _this3.element.offsetHeight;
        var listHeight = el ? el.offsetHeight : 0;

        var itemsLength = _this3.get('items.length');

        _this3.set('scrollTop', scrollTop);

        if (!_this3.loading) {
          if (scrollTop <= 0) {
            if (_this3.get('pageBoundary.top') > 0) {
              _this3.pageUp();
            }
          } else if (scrollBottom >= listHeight) {
            if (_this3.get('pageBoundary.bottom') < itemsLength) {
              _this3.pageDown();
            }
          }
        }
      });
      this.element && this.element.addEventListener('scroll', this.scrollHandler);
    },
    didRender: function didRender() {
      this._super.apply(this, arguments);

      this.set('loading', false);
      var i = this.element && this.element.querySelector('.loading i');

      if (i) {
        i.classList.add('hidden');
      }
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);

      this.sessionSave();
      this.element.removeEventListener('scroll', this.scrollHandler);
    }
  });

  _exports.default = _default;
});