define("challingo-mobile/templates/components/challenges/new-chally-accepted", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "mYI7aCay",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"new-chally-accepted__container u-text-center\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"new-chally-accepted__box\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"new-chally-accepted__content\"],[9],[0,\"\\n\"],[4,\"if\",[[23,[\"challenge\"]]],null,{\"statements\":[[0,\"        \"],[1,[27,\"challenges/challenge-simple-item\",null,[[\"item\",\"showTitle\",\"toDetailDisable\"],[[23,[\"challenge\"]],false,true]]],false],[0,\"\\n        \"],[7,\"h3\"],[11,\"class\",\"new-chally-accepted__title u-uppercase\"],[9],[0,\"\\n          \"],[1,[27,\"t\",[\"new-chally-accepted.title\"],null],false],[0,\"\\n        \"],[10],[0,\"\\n        \"],[7,\"p\"],[11,\"class\",\"u-muted\"],[9],[0,\"\\n          \"],[1,[27,\"t\",[\"new-chally-accepted.info\"],[[\"htmlSafe\",\"title\"],[true,[23,[\"challenge\",\"title\"]]]]],false],[0,\"\\n        \"],[10],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[23,[\"isSearching\"]]],null,{\"statements\":[[0,\"          \"],[7,\"div\"],[11,\"class\",\"loader-elm\"],[9],[10],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"          \"],[7,\"h3\"],[11,\"class\",\"new-chally-accepted__title u-uppercase\"],[9],[0,\"\\n            \"],[1,[27,\"t\",[\"new-chally-accepted.not-found\"],null],false],[0,\"\\n          \"],[10],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[]}],[0,\"    \"],[10],[0,\"\\n    \"],[7,\"button\"],[11,\"class\",\"new-chally-accepted__continue\"],[12,\"disabled\",[21,\"isSearching\"]],[12,\"onclick\",[27,\"action\",[[22,0,[]],\"continue\"],null]],[11,\"style\",\"touch-action: manipulation; -ms-touch-action: manipulation; cursor: pointer;\"],[11,\"type\",\"button\"],[9],[0,\"\\n      \"],[1,[27,\"t\",[[27,\"if\",[[23,[\"loading\"]],\"wait\",[27,\"if\",[[23,[\"isSearching\"]],\"searching\",\"continue\"],null]],null]],null],false],[0,\"\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "challingo-mobile/templates/components/challenges/new-chally-accepted.hbs"
    }
  });

  _exports.default = _default;
});