define("challingo-mobile/components/profile-page", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    currentUser: Ember.inject.service(),
    classNames: ['profile-page'],
    bades: Ember.computed.alias('model.badges'),
    user: Ember.computed.alias('model.user'),
    tabs: Object.freeze(['challenge-completed', 'accepted-challenge', 'added-challenge']),
    selected: Ember.Object.create({
      tab: 0
    }),
    scrolledTitle: null,
    _onScroll: null,
    _scrollHandler: function _scrollHandler() {
      if (document.body.scrollTop > 100) {
        this.set('scrolledTitle', this.get('user.name'));
      } else {
        this.set('scrolledTitle', null);
      }
    },
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      this._onScroll = this._scrollHandler.bind(this);
      document.body.addEventListener('scroll', this._onScroll, true);
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);

      document.body.removeEventListener('scroll', this._onScroll, true);
      this._onScroll = null;
    }
  });

  _exports.default = _default;
});