define("challingo-mobile/templates/components/notifications-page", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "nGJ4Z4tA",
    "block": "{\"symbols\":[\"list\"],\"statements\":[[7,\"div\"],[11,\"role\",\"main\"],[9],[10],[0,\"\\n\\n\"],[4,\"if\",[[23,[\"pushNotification\",\"allNotifications\",\"length\"]]],null,{\"statements\":[[4,\"lazy-loading-list\",null,[[\"items\",\"itemsPerPage\",\"pageNavBar\",\"pageBottomBar2x\",\"sessionId\"],[[23,[\"pushNotification\",\"allNotifications\"]],10,false,[23,[\"currentUser\",\"isAdmin\"]],\"notifications-page-1\"]],{\"statements\":[[0,\"    \"],[1,[27,\"notifications-list\",null,[[\"notifications\",\"hasDelete\"],[[22,1,[]],[23,[\"currentUser\",\"isAdmin\"]]]]],false],[0,\"\\n\"]],\"parameters\":[1]},null]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"if\",[[23,[\"currentUser\",\"isAdmin\"]]],null,{\"statements\":[[0,\"  \"],[7,\"form\"],[11,\"class\",\"new-notification\"],[9],[0,\"\\n    \"],[1,[27,\"input\",null,[[\"type\",\"value\",\"placeholder\",\"autocomplete\",\"maxlength\"],[\"text\",[23,[\"newNotificationBody\"]],[27,\"t\",[\"notifications.new_placeholder\"],null],\"off\",200]]],false],[0,\"\\n    \"],[7,\"button\"],[11,\"class\",\"btn btn-primary loading-hidden\"],[12,\"disabled\",[21,\"loading\"]],[11,\"style\",\"touch-action: manipulation; -ms-touch-action: manipulation; cursor: pointer;\"],[11,\"type\",\"submit\"],[9],[0,\"\\n      \"],[1,[27,\"t\",[\"ok\"],null],false],[0,\"\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "challingo-mobile/templates/components/notifications-page.hbs"
    }
  });

  _exports.default = _default;
});