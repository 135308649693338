define("challingo-mobile/templates/profile/show", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "dBgH1dKo",
    "block": "{\"symbols\":[],\"statements\":[[1,[27,\"profile-page\",null,[[\"model\"],[[23,[\"model\"]]]]],false],[0,\"\\n\\n\"],[1,[21,\"bottom-bar\"],false],[0,\"\\n\\n\"],[1,[21,\"menu-profile\"],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "challingo-mobile/templates/profile/show.hbs"
    }
  });

  _exports.default = _default;
});