define("challingo-mobile/components/star-rating", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['star-rating'],
    editable: true,
    selected: null,
    stars: Object.freeze([1, 2, 3, 4, 5]),
    displaySelected: Ember.computed('selected', function () {
      return Math.min(5, Math.max(0, Math.round(this.get('selected'))));
    }),
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      if (this.get('editable')) {
        this.set('selected', null);
      }
    },
    actions: {
      select: function select(event) {
        if (this.get('editable')) {
          this.set('selected', parseInt(event.target.value));
        }
      }
    }
  });

  _exports.default = _default;
});