define("challingo-mobile/routes/challenge/complete", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model: function model(params) {
      return Ember.RSVP.hash({
        challengeAccepted: this.store.findRecord('accepted-challenge', params.accepted_challenge_id),
        media: this.store.createRecord('media')
      });
    },
    resetController: function resetController(controller) {
      this._super.apply(this, arguments);

      controller.get('model.media').rollbackAttributes();
    }
  });

  _exports.default = _default;
});