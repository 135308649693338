define("challingo-mobile/components/category-select", ["exports", "challingo-mobile/routes/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    currentUser: Ember.inject.service(),
    modal: Ember.inject.service(),
    classNames: ['user-rank'],
    categorySort: null,
    categories: Ember.computed.alias('model.categories'),
    categoriesSorted: Ember.computed.sort('categories', 'categorySort'),
    challenge: Ember.computed.alias('model.challenge'),
    locale: Ember.computed.alias('currentUser.user.localeAugmented'),
    init: function init() {
      this._super.apply(this, arguments);

      var locale = this.get('locale') || _application.defaultLocale;

      this.set('categorySort', ["name_".concat(locale, ":asc")]);
    },
    actions: {
      selectCategory: function selectCategory(category) {
        this.get('challenge').set('category', category);
        this.modal.close();
      }
    }
  });

  _exports.default = _default;
});