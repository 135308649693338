define("challingo-mobile/templates/components/liked-by-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "+CKQ5Eoo",
    "block": "{\"symbols\":[\"user\"],\"statements\":[[7,\"div\"],[11,\"class\",\"liked-by-users\"],[9],[0,\"\\n\"],[4,\"each\",[[23,[\"showUsers\"]]],null,{\"statements\":[[0,\"    \"],[7,\"img\"],[11,\"alt\",\"\"],[11,\"class\",\"user\"],[11,\"role\",\"presentation\"],[12,\"src\",[22,1,[\"photoSmall\"]]],[9],[10],[0,\"\\n\"]],\"parameters\":[1]},null],[4,\"if\",[[23,[\"countedItem\"]]],null,{\"statements\":[[0,\"    \"],[7,\"div\"],[11,\"class\",\"counted-item\"],[9],[0,\"+\"],[1,[21,\"otherCountedLikes\"],false],[10],[0,\"\\n\"]],\"parameters\":[]},null],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "challingo-mobile/templates/components/liked-by-list.hbs"
    }
  });

  _exports.default = _default;
});