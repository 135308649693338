define("challingo-mobile/services/toast-message", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    isOpen: false,
    text: '',
    isBlue: false,
    show: function show() {
      var _this = this;

      var text = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
      var blue = arguments.length > 1 ? arguments[1] : undefined;
      var time = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 3500;
      Ember.run.scheduleOnce('afterRender', function () {
        _this.setProperties({
          text: text,
          isOpen: true
        });

        blue ? _this.set('isBlue', true) : _this.set('isBlue', false);
        Ember.run.later(function () {
          _this.hide();
        }, time);
      });
    },
    hide: function hide() {
      this.setProperties({
        isOpen: false,
        isBlue: false
      });
    }
  });

  _exports.default = _default;
});