define("challingo-mobile/routes/blocked-users", ["exports", "emberfire/services/realtime-listener"], function (_exports, _realtimeListener) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    currentUser: Ember.inject.service(),
    model: function model() {
      return this.store.findRecord('user', this.get('currentUser.uid'));
    },
    afterModel: function afterModel(model) {
      if (!model.get('isVirtualModel')) {
        (0, _realtimeListener.subscribe)(this, model);
      }
    }
  });

  _exports.default = _default;
});