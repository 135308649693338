define("challingo-mobile/components/user-item", ["exports", "challingo-mobile/components/viewport-component", "emberfire/services/realtime-listener"], function (_exports, _viewportComponent, _realtimeListener) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  var _default = _viewportComponent.default.extend({
    confirmDialog: Ember.inject.service(),
    currentUser: Ember.inject.service(),
    intl: Ember.inject.service(),
    modal: Ember.inject.service(),
    router: Ember.inject.service(),
    session: Ember.inject.service(),
    classNames: ['user-item'],
    user: null,
    lastChallenges: Ember.computed('user.{latestChallengesCreated.@each.sorting,latestChallengesCompleted.@each.sorting}', function () {
      var userCreatedChallenges = this.get('user.latestChallengesCreated');
      var userCompleteChallenges = this.get('user.latestChallengesCompleted');
      var usersChallenges = [].concat(_toConsumableArray(userCompleteChallenges.toArray()), _toConsumableArray(userCreatedChallenges.toArray())).sortBy('sorting').reverse();
      return usersChallenges.slice(0, 4);
    }),
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      var user = this.get('user');

      if (user) {
        Ember.tryInvoke(user, 'checkIsFollowed');

        if (!this.get('user.isVirtualModel')) {
          (0, _realtimeListener.subscribe)(this, this.get('user'));
        }
      }
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);
    },
    actions: {
      goToDetail: function goToDetail(challenge) {
        var isAccepted = challenge.type === 'accepted-challenge';
        this.router.transitionTo('challenges.show', isAccepted ? challenge.get('challenge.id') : challenge.get('id'), {
          queryParams: {
            challengeAcceptedId: isAccepted ? challenge.id : null,
            miniProfile: null
          }
        });
      },
      follow: function follow() {
        if (!this.get('session.isAuthenticated')) {
          this.modal.show('modals/login-popup', null, {
            goBack: true,
            noStyle: true
          });
          return;
        }

        Ember.tryInvoke(this.user, 'follow');
      },
      unfollow: function unfollow() {
        var _this = this;

        this.confirmDialog.show(this.intl.t('follow.delete_following'), function () {
          Ember.tryInvoke(_this.user, 'unfollow');
        });
      }
    }
  });

  _exports.default = _default;
});