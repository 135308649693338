define("challingo-mobile/templates/components/users-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "bew+9nju",
    "block": "{\"symbols\":[\"user\",\"index\"],\"statements\":[[7,\"div\"],[11,\"class\",\"heading\"],[9],[1,[27,\"t\",[[23,[\"headingText\"]]],null],false],[10],[0,\"\\n\"],[4,\"if\",[[23,[\"users\"]]],null,{\"statements\":[[4,\"each\",[[23,[\"users\"]]],null,{\"statements\":[[0,\"    \"],[1,[27,\"user-item\",null,[[\"user\"],[[22,1,[]]]]],false],[0,\"\\n\"],[4,\"if\",[[27,\"and\",[[27,\"or\",[[23,[\"scrollObserverOnEnter\"]],[23,[\"scrollObserverOnExit\"]]],null],[27,\"eq\",[[22,2,[]],[23,[\"lengthMinus2\"]]],null]],null]],null,{\"statements\":[[0,\"      \"],[1,[27,\"scroll-observer\",null,[[\"onEnterViewport\",\"onExitViewport\"],[[23,[\"scrollObserverOnEnter\"]],[23,[\"scrollObserverOnExit\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[1,2]},null]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[7,\"div\"],[11,\"class\",\"not-found\"],[9],[0,\"\\n    \"],[1,[27,\"t\",[\"search.nousersfound\"],null],false],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "challingo-mobile/templates/components/users-list.hbs"
    }
  });

  _exports.default = _default;
});