define("challingo-mobile/services/levelup-message", ["exports", "challingo-mobile/utils/level-ranks"], function (_exports, _levelRanks) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    isOpen: false,
    text: '',
    show: function show() {
      var level = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 1;
      var ranksTable = (0, _levelRanks.default)();
      var rank = null,
          icon = null;
      ranksTable.forEach(function (item) {
        if (item.level == level) {
          rank = item.rank;
          icon = item.icon;
        }
      });
      this.setProperties({
        icon: icon,
        level: level,
        rank: rank,
        isOpen: true
      });
    }
  });

  _exports.default = _default;
});