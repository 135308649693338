define("challingo-mobile/templates/calendar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "X1X+X5hw",
    "block": "{\"symbols\":[],\"statements\":[[1,[27,\"calendar-page\",null,[[\"challenges\",\"drawnChallies\"],[[23,[\"model\",\"challenges\"]],[23,[\"model\",\"drawnChallies\"]]]]],false],[0,\"\\n\\n\"],[1,[21,\"bottom-bar\"],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "challingo-mobile/templates/calendar.hbs"
    }
  });

  _exports.default = _default;
});