define("challingo-mobile/services/menu-profile", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    currentUser: Ember.inject.service(),
    router: Ember.inject.service(),
    isOpen: false,
    stayOpen: false,
    open: function open() {
      this.set('isOpen', true);
      document.body.classList.add('no-scroll');
    },
    close: function close() {
      var resetQueryParams = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : true;
      this.set('isOpen', false);
      document.body.classList.remove('no-scroll');

      if (resetQueryParams) {
        this.router.transitionTo({
          queryParams: {
            profileMenuOpen: null
          }
        });
      }
    },
    check: function check() {
      if (location.hash === '#/profile/' + this.get('currentUser.user.id')) {
        this.open();
      }
    }
  });

  _exports.default = _default;
});