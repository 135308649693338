define("challingo-mobile/templates/components/circle-progress", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ZeDGR0/t",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[12,\"class\",[28,[\"progress-circle p\",[21,\"percent\"],\" \",[27,\"if\",[[27,\"gt\",[[23,[\"percent\"]],49],null],\"over50\"],null]]]],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"left-half-clipper\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"first50-bar\"],[9],[10],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"value-bar\"],[9],[10],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "challingo-mobile/templates/components/circle-progress.hbs"
    }
  });

  _exports.default = _default;
});