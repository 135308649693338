define("challingo-mobile/templates/components/challenges/challenge-accepted-actions", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "FJleduwk",
    "block": "{\"symbols\":[],\"statements\":[[7,\"a\"],[11,\"href\",\"#\"],[11,\"class\",\"link\"],[11,\"style\",\"touch-action: manipulation; -ms-touch-action: manipulation; cursor: pointer;\"],[9],[0,\"\\n  \"],[1,[27,\"t\",[\"challenge.give_up\"],null],false],[0,\"\\n\"],[3,\"action\",[[22,0,[]],\"giveUp\",[23,[\"item\"]]]],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "challingo-mobile/templates/components/challenges/challenge-accepted-actions.hbs"
    }
  });

  _exports.default = _default;
});