define("challingo-mobile/utils/get-xp-tables", ["exports", "challingo-mobile/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = getXpTables;

  function getXpTables() {
    //Generate data tables
    var maxLevel = _environment.default.APP.maxLevel,
        levelFix = Array(maxLevel).fill(0).map(function (item, index) {
      return 100 + 10 * Math.floor(index / 5);
    });
    var levelAdvance = levelFix.map(function (item, index) {
      return item * index;
    });
    var levelAdvanceCum = [];
    levelAdvance[1] = 50;
    levelAdvance.reduce(function (acc, item, index) {
      return levelAdvanceCum[index] = acc + item;
    }, 0);
    return {
      maxLevel: maxLevel,
      levelFix: levelFix,
      levelAdvance: levelAdvance,
      levelAdvanceCum: levelAdvanceCum
    };
  }
});