define("challingo-mobile/utils/split-to-n", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = splitToN;

  function splitToN(arr) {
    var n = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 10;

    if (!arr || !Array.isArray(arr)) {
      return [];
    }

    var result = [];

    for (var i = 0; i < arr.length; i += n) {
      result.push(arr.slice(i, i + n));
    }

    return result;
  }
});