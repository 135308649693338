define("challingo-mobile/templates/components/share-actions", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "NSVR+IpT",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[23,[\"session\",\"isAuthenticated\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\"],[11,\"class\",\"u-flex u-relative\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"share-actions__send-icon challingo-icons moonicon-send\"],[9],[10],[0,\"\\n    \"],[7,\"a\"],[11,\"href\",\"#\"],[11,\"class\",\"link\"],[11,\"style\",\"touch-action: manipulation; -ms-touch-action: manipulation; cursor: pointer;\"],[9],[0,\"\\n      \"],[1,[27,\"t\",[\"dm.send_as_dm\"],null],false],[0,\"\\n    \"],[3,\"action\",[[22,0,[]],\"sendDM\"]],[10],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[7,\"a\"],[11,\"href\",\"javascript:void(0);\"],[11,\"class\",\"link link-copy-to-clipboard\"],[11,\"style\",\"touch-action: manipulation; -ms-touch-action: manipulation; cursor: pointer;\"],[9],[0,\"\\n  \"],[1,[27,\"t\",[\"copy_to_clipboard\"],null],false],[0,\"\\n\"],[10],[0,\"\\n\\n\"],[4,\"if\",[[23,[\"session\",\"isAuthenticated\"]]],null,{\"statements\":[[0,\"  \"],[7,\"a\"],[11,\"href\",\"#\"],[11,\"class\",\"link\"],[11,\"style\",\"touch-action: manipulation; -ms-touch-action: manipulation; cursor: pointer;\"],[9],[0,\"\\n    \"],[1,[27,\"t\",[\"download\"],null],false],[0,\"\\n  \"],[3,\"action\",[[22,0,[]],\"download\"]],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "challingo-mobile/templates/components/share-actions.hbs"
    }
  });

  _exports.default = _default;
});