define("challingo-mobile/components/blocked-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    menuProfile: Ember.inject.service(),
    router: Ember.inject.service(),
    classNames: ['blocked'],
    actions: {
      back: function back() {
        history.back();
      },
      hideProfileMenu: function hideProfileMenu(id) {
        this.menuProfile.close();
        this.router.transitionTo('profile.show', id);
      }
    }
  });

  _exports.default = _default;
});