define("challingo-mobile/components/badge-message", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    badgeMessage: Ember.inject.service(),
    classNames: ['badge-message'],
    classNameBindings: ['isOpen:open'],
    isOpen: Ember.computed.alias('badgeMessage.isOpen'),
    badgeIcon: Ember.computed.alias('badgeMessage.badgeIcon'),
    rank: Ember.computed.alias('badgeMessage.rank'),
    rankIcon: Ember.computed.alias('badgeMessage.rankIcon'),
    titleString: Ember.computed.alias('badgeMessage.titleString'),
    textString: Ember.computed.alias('badgeMessage.textString'),
    actions: {
      btnAction: function btnAction() {
        this.setProperties({
          isOpen: false
        });
      }
    }
  });

  _exports.default = _default;
});