define("challingo-mobile/components/video-boomerang", ["exports", "challingo-mobile/utils/jquery-polyfills"], function (_exports, _jqueryPolyfills) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    settings: Ember.inject.service(),
    attributeBindings: ['urlSource:src'],
    tagName: 'source',
    duration: 1,
    height: null,
    width: null,
    urlSource: Ember.computed('{src,width,height}', function () {
      var quality = this.settings.siteSettings && this.settings.siteSettings.boomerangQuality || 85;

      var _this$getProperties = this.getProperties('duration', 'height', 'width'),
          duration = _this$getProperties.duration,
          height = _this$getProperties.height,
          width = _this$getProperties.width;

      var url = (this.get('src') || '').replace('/upload', "/upload/e_boomerang/e_volume:mute,c_fit,eo_".concat(duration, ",q_").concat(quality));

      if (width && height) {
        return url.replace("eo_".concat(duration), "eo_".concat(duration, ",h_").concat(2 * height, ",w_").concat(2 * width));
      }

      return url;
    }),
    didInsertElement: function didInsertElement() {
      var _this = this;

      this._super.apply(this, arguments);

      Ember.run.scheduleOnce('afterRender', function () {
        var p = (0, _jqueryPolyfills.parents)(_this.element, 'video');

        if (p && p.length) {
          _this.setProperties({
            height: Math.floor(p[0].offsetHeight),
            width: Math.floor(p[0].offsetWidth)
          });
        }
      });
    }
  });

  _exports.default = _default;
});