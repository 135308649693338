define("challingo-mobile/models/device", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    regToken: _emberData.default.attr('string'),
    devId: _emberData.default.attr('string'),
    user: _emberData.default.belongsTo('user', {
      inverse: 'devices'
    }),
    cordova: _emberData.default.attr('string'),
    platform: _emberData.default.attr('string'),
    version: _emberData.default.attr('string'),
    manufacturer: _emberData.default.attr('string')
  });

  _exports.default = _default;
});