define("challingo-mobile/components/badges-view", ["exports", "challingo-mobile/utils/badges-list"], function (_exports, _badgesList) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['badges'],
    user: null,
    allBadges: null,
    init: function init() {
      this._super.apply(this, arguments);

      this.set('config', {
        badgeData: (0, _badgesList.default)()
      });
    }
  });

  _exports.default = _default;
});