define("challingo-mobile/utils/challenges/newsfeed", ["exports", "challingo-mobile/utils/challenges/collection", "challingo-mobile/utils/logger"], function (_exports, _collection, _logger) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var callbackGenerator = function callbackGenerator(context, collection) {
    return function (items) {
      if (items && items.length) {
        items.compact().forEach(function (item) {
          return item.get('media');
        });
        context.get(collection).pushObjects(items.compact().toArray());
      }
    };
  };

  var _default = _collection.default.extend({
    nf: Ember.computed.alias('all'),
    load: function load() {
      var _this = this;

      var currentUserId = this.get('currentUser.user.id');

      if (!currentUserId) {
        return;
      }

      return this.store.query('user-wall', {
        limit: this.get('perPage'),
        orderBy: {
          'createdAt': 'desc'
        },
        startAfter: this.get('startAfter.wall'),
        where: [['user', '==', currentUserId], ['hidden', '==', false]]
      }).then(function (wallItems) {
        var dates = wallItems.mapBy('createdAt');

        if (dates.length) {
          var date = dates.pop();

          _this.set("startAfter.wall", date.toISOString());
        }

        return Ember.RSVP.hash({
          challenges: Ember.RSVP.all(wallItems.map(function (item) {
            return item.get('challenge');
          })).then(callbackGenerator(_this, 'challenges')),
          challengesAccepted: Ember.RSVP.all(wallItems.map(function (item) {
            return item.get('challengeAccepted');
          })).then(callbackGenerator(_this, 'challengesAccepted'))
        });
      }, function (e) {
        _logger.default.error(e);
      });
    },
    loadForUser: function loadForUser(userId) {
      return Ember.RSVP.hash({
        challenges: this.store.query('challenge', {
          limit: this.get('perPage'),
          orderBy: {
            'createdAt': 'desc'
          },
          where: [['creator', '==', userId], ['hidden', '==', false]]
        }).then(callbackGenerator(this, 'challenges')),
        challengesAccepted: this.store.query('accepted-challenge', {
          limit: this.get('perPage'),
          orderBy: {
            'completed': 'desc'
          },
          where: [['user', '==', userId], ['hidden', '==', false]]
        }).then(callbackGenerator(this, 'challengesAccepted'))
      });
    },
    refresh: function refresh() {
      var _this2 = this;

      if (!this.get('startAfter.wall')) {
        Ember.run.later(function () {
          _this2.load();
        }, 100);
        return;
      }

      var currentUserId = this.get('currentUser.user.id');

      if (!currentUserId) {
        return;
      }

      return this.store.query('user-wall', {
        orderBy: {
          'createdAt': 'desc'
        },
        endAt: this.get('startAfter.wall'),
        where: [['user', '==', currentUserId], ['hidden', '==', false]]
      }).then(function (wallItems) {
        var challengeIds = _this2.challenges.mapBy('id');

        var acceptedIds = _this2.challengesAccepted.mapBy('id');

        var filtered = wallItems.filter(function (item) {
          var isChallenge = Boolean(item.challenge.content);
          var isAccepted = Boolean(item.challengeAccepted.content);
          return isChallenge && !challengeIds.includes(item.get('challenge.content.id')) || isAccepted && !acceptedIds.includes(item.get('challengeAccepted.content.id'));
        });
        return Ember.RSVP.hash({
          challenges: Ember.RSVP.all(filtered.map(function (item) {
            return item.get('challenge');
          })).then(callbackGenerator(_this2, 'challenges')),
          challengesAccepted: Ember.RSVP.all(filtered.map(function (item) {
            return item.get('challengeAccepted');
          })).then(callbackGenerator(_this2, 'challengesAccepted'))
        });
      }, function () {});
    },
    unload: function unload() {
      this._super.apply(this, arguments);

      this.setProperties({
        startAfter: {
          wall: null
        }
      });
    }
  });

  _exports.default = _default;
});