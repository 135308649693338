define("challingo-mobile/utils/challenges/user-profile-accepted", ["exports", "challingo-mobile/utils/challenges/user-profile"], function (_exports, _userProfile) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _userProfile.default.extend({
    modelName: 'user-accepted-challenge',
    sortFunction: function sortFunction() {
      return 0;
    }
  });

  _exports.default = _default;
});