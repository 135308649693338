define("challingo-mobile/models/direct-message", ["exports", "ember-data", "anchorme"], function (_exports, _emberData, _anchorme) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    currentUser: Ember.inject.service(),
    intl: Ember.inject.service(),
    challenge: _emberData.default.belongsTo('challenge', {
      inverse: null
    }),
    challengeAccepted: _emberData.default.belongsTo('accepted-challenge', {
      inverse: null
    }),
    createdAt: _emberData.default.attr('date', {
      defaultValue: function defaultValue() {
        return new Date();
      }
    }),
    messageText: _emberData.default.attr('string'),
    status: _emberData.default.attr('string'),
    userFrom: _emberData.default.belongsTo('user', {
      inverse: null
    }),
    userTo: _emberData.default.belongsTo('user', {
      inverse: null
    }),
    attachment: Ember.computed('{challenge,challengeAccepted,hasAttachment}', function () {
      if (!this.get('hasAttachment')) {
        return null;
      }

      var challengeId = this.belongsTo('challenge').id();
      return challengeId ? this.get('challenge') : this.get('challengeAccepted');
    }),
    displayMessageText: Ember.computed('userFrom.name', 'hasAttachment', function () {
      var hasAttachment = this.get('hasAttachment');
      var messageText = hasAttachment ? this.intl.t('dm.shared_chally', {
        user: this.get('userFrom.name')
      }) : this.get('messageText');
      return messageText;
    }),
    displayStatus: Ember.computed('userFrom.id', 'status', function () {
      var currentUserId = this.get('currentUser.uid');
      return this.belongsTo('userFrom').id() === currentUserId ? 'read' : this.get('status');
    }),
    hasAttachment: Ember.computed('{challenge,challengeAccepted}', function () {
      return Boolean(this.belongsTo('challenge').id() || this.belongsTo('challengeAccepted').id());
    }),
    messageTextWithLinks: Ember.computed('displayMessageText', function () {
      var messageText = this.displayMessageText || '';
      return Ember.String.htmlSafe((0, _anchorme.default)(messageText));
    }),
    markRead: function markRead() {
      this.set('status', 'read');
      return this.save({
        adapterOptions: {
          saveOnly: ['status']
        }
      });
    }
  });

  _exports.default = _default;
});