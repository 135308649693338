define("challingo-mobile/utils/logger", ["exports", "challingo-mobile/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Console = window && window.console || console;
  var consoleObject = {};

  if (_environment.default.environment === 'development' && Console) {
    consoleObject['log'] = Console.log;
    consoleObject['debug'] = Console.debug;
    consoleObject['error'] = Console.error;
    consoleObject['warn'] = Console.warn;
  } else {
    consoleObject['log'] = function () {};

    consoleObject['debug'] = function () {};

    consoleObject['error'] = Console.error;

    consoleObject['warn'] = function () {};
  }

  var _default = consoleObject;
  _exports.default = _default;
});