define("challingo-mobile/components/users-chally", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    challenges: Ember.inject.service(),
    classNames: ['users-chally', 'full-height'],
    selected: Ember.Object.create({
      tab: 0
    }),
    tabs: Object.freeze([{
      name: 'challenge',
      icon: 'trophy'
    }, {
      name: 'accepted-challenge',
      icon: 'rocket'
    }, {
      name: 'added-challenge',
      icon: 'hourglass'
    }]),
    user: null,
    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);

      var user = this.get('user');
      var collections = ['userProfileAccepted', 'userProfileCompleted', 'userProfileCreated'];

      if (user) {
        collections.forEach(function (collectionName) {
          _this.challenges[collectionName].resetUser(user);

          _this.challenges[collectionName].load();
        });
      }
    },
    actions: {
      back: function back() {
        history.back();
      },
      loadNextPageAccepted: function loadNextPageAccepted() {
        this.challenges.userProfileAccepted.nextPage();
      },
      loadNextPageCompleted: function loadNextPageCompleted() {
        this.challenges.userProfileCompleted.nextPage();
      },
      loadNextPageCreated: function loadNextPageCreated() {
        this.challenges.userProfileCreated.nextPage();
      }
    }
  });

  _exports.default = _default;
});