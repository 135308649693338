define("challingo-mobile/utils/challenges/hashtag", ["exports", "firebase/firestore", "challingo-mobile/utils/challenges/collection", "firebase/app", "challingo-mobile/utils/get-has-many-ids"], function (_exports, _firestore, _collection, _app, _getHasManyIds) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _collection.default.extend({
    hashtag: null,
    perPage: 10,
    load: function load() {
      var _this = this;

      var callbackGenerator = function callbackGenerator(collection) {
        return function (items) {
          if (items && items.length) {
            items.compact().forEach(function (item) {
              return item.get('media');
            });

            _this.get(collection).pushObjects(items.compact().toArray());

            _this.incrementProperty("startAfter.".concat(collection), items.length);
          }
        };
      };

      var hashtag = this.get('hashtag');

      if (!hashtag) {
        return;
      }

      var challengeIds = (0, _getHasManyIds.default)(hashtag, 'challenges');
      var acceptedIds = (0, _getHasManyIds.default)(hashtag, 'challengesAccepted');
      var n1 = this.get('startAfter.challenges');
      var n2 = this.get('startAfter.challengesAccepted');
      var hashObject = {};
      var useChallengeIds = challengeIds.slice(n1, n1 + this.get('perPage'));
      var useAcceptedIds = acceptedIds.slice(n2, n2 + this.get('perPage'));

      if (useChallengeIds && useChallengeIds.length) {
        hashObject.challenges = this.store.query('challenge', {
          where: [[_app.default.firestore.FieldPath.documentId(), 'in', useChallengeIds]]
        }).then(callbackGenerator('challenges'));
      }

      if (useAcceptedIds && useAcceptedIds.length) {
        hashObject.challengesAccepted = this.store.query('accepted-challenge', {
          where: [[_app.default.firestore.FieldPath.documentId(), 'in', useAcceptedIds]]
        }).then(callbackGenerator('challengesAccepted'));
      }

      return Ember.RSVP.hash(hashObject);
    },
    resetHashtag: function resetHashtag(hashtag) {
      if (hashtag && hashtag.id !== this.get('hashtag.id')) {
        this.setProperties({
          hashtag: hashtag
        });
        this.unload();
      }
    },
    sortFunction: function sortFunction() {
      return 0;
    },
    unload: function unload() {
      this._super.apply(this, arguments);

      this.setProperties({
        challenges: Ember.A([]),
        challengesAccepted: Ember.A([]),
        startAfter: {
          challenges: 0,
          challengesAccepted: 0
        }
      });
    }
  });

  _exports.default = _default;
});