define("challingo-mobile/utils/level-ranks", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = levelRanks;

  function levelRanks() {
    return [{
      level: 1,
      rank: 'newbie',
      icon: ''
    }, {
      level: 2,
      rank: 'rookie',
      icon: ''
    }, {
      level: 5,
      rank: 'player',
      icon: '🎮'
    }, {
      level: 15,
      rank: 'pro',
      icon: '🏅'
    }, {
      level: 25,
      rank: 'expert',
      icon: '🥇'
    }, {
      level: 35,
      rank: 'coach',
      icon: '👨‍💼'
    }, {
      level: 45,
      rank: 'master',
      icon: '🏆'
    }, {
      level: 55,
      rank: 'elder',
      icon: '🧐'
    }, {
      level: 65,
      rank: 'chief',
      icon: '👮‍♀️'
    }, {
      level: 75,
      rank: 'beast',
      icon: '😈'
    }, {
      level: 85,
      rank: 'legend',
      icon: '😎'
    }, {
      level: 100,
      rank: 'challenger',
      icon: '🤴'
    }];
  }
});