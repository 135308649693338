define("challingo-mobile/services/badge-message", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    isOpen: false,
    daysInRow: 0,
    show: function show(props) {
      this.setProperties(Object.assign(props || {}, {
        isOpen: true
      }));
    }
  });

  _exports.default = _default;
});