define("challingo-mobile/initializers/register-collections", ["exports", "challingo-mobile/utils/challenges/category", "challingo-mobile/utils/challenges/followers", "challingo-mobile/utils/challenges/followings", "challingo-mobile/utils/challenges/hashtag", "challingo-mobile/utils/challenges/newsfeed", "challingo-mobile/utils/challenges/searchall", "challingo-mobile/utils/challenges/trendy", "challingo-mobile/utils/challenges/user-profile", "challingo-mobile/utils/challenges/user-profile-accepted", "challingo-mobile/utils/challenges/user-profile-completed", "challingo-mobile/utils/challenges/user-profile-created", "challingo-mobile/utils/challenges/usersall"], function (_exports, _category, _followers, _followings, _hashtag, _newsfeed, _searchall, _trendy, _userProfile, _userProfileAccepted, _userProfileCompleted, _userProfileCreated, _usersall) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initialize = initialize;
  _exports.default = void 0;

  function initialize(application) {
    var data = [{
      name: 'category',
      collection: _category.default
    }, {
      name: 'follower',
      collection: _followers.default
    }, {
      name: 'following',
      collection: _followings.default
    }, {
      name: 'hashtag',
      collection: _hashtag.default
    }, {
      name: 'newsfeed',
      collection: _newsfeed.default
    }, {
      name: 'searchall',
      collection: _searchall.default
    }, {
      name: 'trendy',
      collection: _trendy.default
    }, {
      name: 'userProfile',
      collection: _userProfile.default
    }, {
      name: 'userProfileAccepted',
      collection: _userProfileAccepted.default
    }, {
      name: 'userProfileCompleted',
      collection: _userProfileCompleted.default
    }, {
      name: 'userProfileCreated',
      collection: _userProfileCreated.default
    }, {
      name: 'usersall',
      collection: _usersall.default
    }];
    data.forEach(function (item) {
      application.register("object:".concat(item.name), item.collection);
    });
  }

  var _default = {
    initialize: initialize
  };
  _exports.default = _default;
});