define("challingo-mobile/routes/profile/show", ["exports", "challingo-mobile/utils/logger", "emberfire/services/realtime-listener"], function (_exports, _logger, _realtimeListener) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    challenges: Ember.inject.service(),
    currentUser: Ember.inject.service(),
    menuMain: Ember.inject.service(),
    menuProfile: Ember.inject.service(),
    modal: Ember.inject.service(),
    session: Ember.inject.service(),
    queryParams: {
      profileMenuOpen: {}
    },
    model: function model(params) {
      var _this = this;

      if (params.profile_id !== this.get('currentUser.uid')) {
        this.menuProfile.close(false);
      }

      return this.store.findRecord('user', params.profile_id).then(function (user) {
        Ember.tryInvoke(user, 'checkIsFollowed');

        _this.challenges.userProfile.resetUser(user);

        return Ember.RSVP.hash({
          badges: _this.store.findAll('badge'),
          challenges: _this.challenges.userProfile.load(),
          user: user
        });
      }, function (e) {
        _logger.default.error(e);
      });
    },
    afterModel: function afterModel(model) {
      if (model && model.user && !model.user.get('isVirtualModel')) {
        (0, _realtimeListener.subscribe)(this, model.user);
      }
    },
    actions: {
      didTransition: function didTransition() {
        this.menuMain.close();
      }
    }
  });

  _exports.default = _default;
});