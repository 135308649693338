define("challingo-mobile/templates/components/forms/challenge-description-edit-form", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "3BOg42MQ",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"textarea-label-style\"],[9],[1,[27,\"t\",[\"description\"],null],false],[0,\" \"],[7,\"span\"],[11,\"class\",\"require\"],[9],[0,\"*\"],[10],[10],[0,\"\\n\"],[7,\"div\"],[11,\"class\",\"textarea-style\"],[9],[0,\"\\n  \"],[1,[27,\"user-tag\",null,[[\"maximumLength\",\"multiline\",\"placeholder\",\"required\",\"searchInput\",\"searchText\",\"selected\"],[2200,true,\"challenge.new_description\",true,[23,[\"description\"]],[23,[\"editChallengeBody\"]],[23,[\"taggedUsers\"]]]]],false],[0,\"\\n\"],[10],[0,\"\\n\\n\"],[7,\"div\"],[11,\"class\",\"textarea-label-style\"],[9],[1,[27,\"t\",[\"tags\"],null],false],[10],[0,\"\\n\"],[7,\"div\"],[11,\"class\",\"textarea-style\"],[9],[0,\"\\n  \"],[1,[27,\"user-tag\",null,[[\"searchInput\",\"selected\",\"required\",\"placeholder\",\"multiline\"],[[23,[\"tags\"]],[23,[\"taggedUsers\"]],false,\"challenge.new_tags\",true]]],false],[0,\"\\n\"],[10],[0,\"\\n\\n\"],[7,\"button\"],[11,\"class\",\"btn btn-action\"],[12,\"disabled\",[21,\"loading\"]],[11,\"style\",\"touch-action: manipulation; -ms-touch-action: manipulation; cursor: pointer;\"],[11,\"type\",\"submit\"],[9],[0,\"\\n  \"],[1,[27,\"t\",[\"challenge.edit_description\"],null],false],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "challingo-mobile/templates/components/forms/challenge-description-edit-form.hbs"
    }
  });

  _exports.default = _default;
});