define("challingo-mobile/components/bubble-popup", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['bubble-popup'],
    position: 'bottom',
    text: '',
    isOpen: false,
    click: function click(event) {
      if (!this.text) {
        return;
      }

      event.stopPropagation();
      this.toggleProperty('isOpen');
    }
  });

  _exports.default = _default;
});