define("challingo-mobile/translations/cs", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "accepted-challenge": "Přijaté",
    "added-challenge": "Vytvořené",
    "all": "{count, plural, =1 {jeden} =2 {všechny} =3 {všechny} =4 {všechny} other {všech}}",
    "aria-labels": {
      "cancel": "Zrušit",
      "close": "Zavřit",
      "delete": "Smazat",
      "description": "Popis",
      "like": "Like",
      "management": "Spravujte chally",
      "scroll-down": "Posunout dolů",
      "show": "Zobrazit",
      "user-level": "Level"
    },
    "available_soon": "Brzy dostupné",
    "back": "Zpět",
    "badges": {
      "badge_messages": {
        "creative_text": "Skvěle! Získal/a si nový \"badge\" Creative",
        "creative_title": "Gratulujeme! Vytvořil/a si 50 chally!",
        "doer_text": "Skvěle! Získal/a si nový \"badge\" Doer",
        "doer_title": "Gratulujeme! Splnil/a si 50 chally!",
        "influencer_text": "Skvěle! Získal/a si nový \"badge\" Influencer",
        "influencer_title": "Gratulujeme! Máš 10 k Followers!",
        "keeper_text": "Skvěle! Získal/a si nový \"badge\" Keeper",
        "keeper_title": "Gratulujeme! Splnil/a si měsíc Denního Trendu!",
        "savage_text": "Skvěle! Získal/a si nový \"badge\" Savage",
        "savage_title": "Gratulujeme! Splnil/a si rok Denního Trendu!",
        "yesman_text": "Skvěle! Získal/a si nový \"badge\" Yes Man",
        "yesman_title": "Gratulujeme! Splnil/a si týden Denního Trendu!"
      },
      "badges": "Badges",
      "creative": "Creative",
      "doer": "Doer",
      "earn_messages": {
        "creative": "vytvoř {count}/50 chally",
        "doer": "splň {count}/50 chally",
        "keeper": "měsíční trend chally",
        "savage": "roční trend chally",
        "yesman": "týdenní trend chally"
      },
      "keeper": "Keeper",
      "no_badges": "Žádné badges",
      "savage": "Savage",
      "yesman": "Yes Man"
    },
    "blockeds": {
      "block": "Opravdu chcete blokovat vybraného uživatele?",
      "blocked_users": "Blokovaní uživatelé",
      "unblock": "Opravdu chcete odblokovat tohoto uživatele?"
    },
    "calendar": {
      "daily_trend": "Denní trend",
      "months": {
        "1": "Leden",
        "10": "Říjen",
        "11": "Listopad",
        "12": "Prosinec",
        "2": "Únor",
        "3": "Březen",
        "4": "Duben",
        "5": "Květen",
        "6": "Červen",
        "7": "Červenec",
        "8": "Srpen",
        "9": "Září"
      },
      "page_text": "Vytvoř nebo splň chally dnes a splň tvůj Daily Trend",
      "page_text_today": "Vytvoř nebo splň alespoň jedno chally nebo tvůj Daily Trend",
      "page_title": "Žádné chally {day}",
      "today": "dnes"
    },
    "cancel": "Zrušit",
    "category": "Kategorie",
    "challenge": {
      "accept": "Přijmout chally",
      "accept_confirm": "Chcete přijmout tuto chally?",
      "accept_random_chally": "Přijměte náhodnou chally",
      "accept_submit_error": "Nepodařilo se přijmout chally.",
      "accept_submit_success": "<i class=\"challingo-icons ci-added-challenge\"></i><span>Máš novou chally!</span>",
      "challingo": "Challingo výzva",
      "chally_name": "Jméno",
      "complete": "Splnit chally",
      "complete_description": "Napište popis a @tagujte přátele",
      "complete_short": "Splnit",
      "complete_short_excl": "chally!",
      "complete_submit_error": "Nepodařilo se splnit chally.",
      "complete_submit_success": "Výzva byla splněna!",
      "complete_tags": "Napište #tagy chally",
      "congratulations": "Gratulujeme!",
      "create_short": "Vytvořit",
      "create_short_excl": "novou chally!",
      "delete": "Smazat chally",
      "delete_confirm": "Opravdu chcete smazat tuto chally?",
      "delete_error": "Nepodařilo se smazat chally.",
      "delete_success": "Výzva byla smazána.",
      "difficulty": "Obtížnost",
      "difficulty_choose": "Vyberte úroveň obtížnosti",
      "difficulty_levels": {
        "text_1": "Tato chally je velmi snadná.",
        "text_2": "Tato chally je snadná.",
        "text_3": "Tato chally je náročná.",
        "text_4": "Tato chally je těžká. Je velice obtížné ji splnit.",
        "text_5": "Tato chally je šílená. Je téměř nemožné ji splnit.",
        "title_1": "Velmi snadné",
        "title_2": "Snadné",
        "title_3": "Náročné",
        "title_4": "Těžké",
        "title_5": "Šílené"
      },
      "edit_description": "Změnit popisek",
      "enter": "Zadat chally",
      "for_whom": "Pro koho je výzva určena",
      "friends": "Přátelé",
      "fulfillment": "Splnění chally",
      "give_up": "Vzdávám se",
      "give_up_confirm": "Určitě chceš vzdát tuto chally?",
      "give_up_error": "Nepodařilo se vzdát chally.",
      "give_up_success": "Výzva byla vzdána.",
      "hide": "Skrýt chally",
      "hide_error": "Chally se nepodařilo skrýt.",
      "hide_question": "Opravdu chcete skrýt chally?",
      "hide_success": "Výzva byla skryta.",
      "incompleted_challenges": "Máte {count} nesplněných chally",
      "is_ok": "Chally je OK",
      "latest_chally_likes": "Vaše poslední chally měla {count} lajků",
      "markRandom": "Přidat do losování",
      "meet": "Splňte chally",
      "new": "Vytvořte chally",
      "new_description": "Napište popis a @tagujte přátele",
      "new_friends_tag": "Označte vaše přátele s @tag",
      "new_submit_error": "Nepodařilo se zadat chally.",
      "new_submit_success": "Nová výzva byla<br> vytvořena!",
      "new_tags": "Napište #xtagy chally",
      "new_title": "Napište název výzvy",
      "no_accepted_chally": "Nepřijal jste zatím žádné chally.",
      "no_created_chally": "Žádné chally",
      "nominate": "Nominovaní",
      "nominate_max": "Maximální množství nominovaných uživatelů je {count}",
      "nominated": "Nominovaní",
      "plus1": "+1 chally",
      "private": "Soukromá",
      "proceed_to_complete": "Splnit chally",
      "public": "Veřejná",
      "random_error": "Nepodařilo odstranit nahodnou chally z losování",
      "random_question_add": "Chcete přidat nahodnou chally do losování?",
      "random_question_remove": "Chcete odstranit nahodnou chally z losování?",
      "random_success_add": "Náhodná chally byla přidána do losování",
      "random_success_remove": "Náhodná chally byla odstraněna z losování",
      "rate": "Ohodnoťte chally",
      "report_error": "Chally se nepodařilo nahlásit.",
      "report_question": "Chcete nahlásit tuto chally?",
      "report_success": "Výzva byla nahlášena.",
      "unmarkRandom": "Odebrat z losování",
      "unreport_error": "Chally se nepodařilo schválit.",
      "unreport_question": "Chcete schválit tuto chally?",
      "unreport_success": "Výzva byla schválena.",
      "you_have_to_wait": "Musíte počkat {count} hodin"
    },
    "challenge-completed": "Splněné",
    "challenges": {
      "another_completed": "Další splněné výzvy",
      "chally": "Chally",
      "completed": "Splněných",
      "created": "Vytvořených"
    },
    "chally": "Chally",
    "comments": {
      "delete_confirm": "Opravdu chcete smazat komentář?",
      "delete_error": "Nepodařilo se smazat komenář.",
      "delete_success": "Komentář byl smazán.",
      "empty_list": "Žádné komentáře",
      "few": "komentáře",
      "new_placeholder": "text ...",
      "one": "komentář",
      "other": "komentářů"
    },
    "comments_count": "{count, plural, =1 {komentář} =2 {komentáře} =3 {komentáře} =4 {komentáře} other {komentářů}}",
    "confirm": "Potvrdit",
    "continue": "Pokračovat",
    "continue_with": {
      "facebook": "Pokračovat přes Facebook",
      "google": "Pokračovat přes Gmail"
    },
    "cool": "Ok",
    "copy_to_clipboard": "Zkopírovat odkaz",
    "copy_to_clipboard_message": "Odkaz byl zkopírován do schránky.",
    "description": "Popis",
    "direct-message": "Osobní zpráva",
    "direct-messages": "Osobní zprávy",
    "disclaimer": {
      "pdf1": "assets/files/zasady_ochrany_osobnich_udaju_challingo.pdf",
      "pdf2": "assets/files/podminky_pouziti_sluzeb_challingo.pdf",
      "terms_of_use": "podmínky použití služeb",
      "text1": "Registrací souhlasíte se zněním našeho dokumentu",
      "text2": "a potvrzujete, že jste si přečetli",
      "user_informations": "zásady ochrany osobních údajů"
    },
    "dm": {
      "new_placeholder": "text ...",
      "select_max": "Maximální množství uživatelů je {count}",
      "send_as_dm": "Poslat jako soukromou zprávu",
      "shared_chally": "<strong>{user}</strong> sdílel novou chally",
      "success": "Chally byla sdílena přes soukromé zprávy"
    },
    "download": "Stáhnout chally",
    "empty_list": "Prázdný seznam",
    "empty_list_chally": "Nejdříve musíte přijmout chally pro její splnění.",
    "error": {
      "message": "Ajaj. Vyskytla se chyba.",
      "type": "Chyba"
    },
    "explore": "Objevuj",
    "feedback": {
      "send_us": "Pošlete nám feedback",
      "submit_error": "Feedback se nepodařilo odeslat.",
      "submit_success": "Feedback byl odeslán.",
      "type": "Feedback"
    },
    "file": {
      "errors": {
        "required": "Prosíme, nahrajte soubor"
      }
    },
    "follow": {
      "delete_following": "Opravdu chcete zrušit sledování vybraného uživatele?",
      "facebook": "sleduj Challingo na Facebooku a hlasuj",
      "follow": "Sledovat",
      "followers": "Sledující",
      "following": "Sleduji",
      "followings": "Sleduji"
    },
    "form": {
      "errors": {
        "email": "Zadejte platný e-mail.",
        "required": "Vyplňte prosím toto pole.",
        "server": "Nepodařilo se ověřit data."
      }
    },
    "history": "History",
    "icons_titles": {
      "accepted-challenge": "Vytvořené",
      "added-challenge": "Přijaté",
      "challenge": "Splněné",
      "explore": "Explore",
      "news-feed": "Newsfeed"
    },
    "join": "připojit",
    "levels": {
      "level_x": "level {level}",
      "levelup_message": "Dobrá práce! Nyní jste level {level}!",
      "levelup_message_rank": "Dobrá práce! Nyní jste level {level} a získal jste {rank} rank!",
      "levelup_modal_title": "Level Up!",
      "xp": "xp",
      "xp_modal_title": "Gratulujeme!"
    },
    "liked_by": "Líbí se",
    "login_popup": {
      "dont_have_account": "Nemáte účet?",
      "have_account": "Máte již účet?",
      "info": "Chcete-li využívat všechny funkce aplikace Challingo, vytvořte si účet.",
      "switch": {
        "button": "Přepnout do aplikace",
        "not-now": "Nyní Ne",
        "text-1": "OOOPS!",
        "text-2": "Vypadá to, že nemáte staženou aplikaci.<br>Pravý Challenger potřebuje veškeré funkce."
      },
      "title": {
        "login": "Přihlaste se k účtu",
        "signup": "Registrace účtu"
      }
    },
    "media": {
      "camera": "Video",
      "gallery": "Galerie",
      "image_upload_error": "Vaše fotka přesahuje maximální limit 10 MB",
      "max_size_error": "Max. velikost souboru je",
      "new_chally": "Vytvořit chally",
      "one_option": "Prosím vyberte jednu z možností",
      "photo": "Foto",
      "remove_error": "Nepodařilo se odebrat foto / video.",
      "required": "Prosíme, nahrajte soubor",
      "type_error": "Vložte foto nebo video.",
      "upload_error": "Soubor nemohl být nahrán z vašeho cloud úložiště. Nejdříve jej stáhněte do svého telefonu, potom nahrajte znovu, děkujeme.",
      "video_upload_error": "Vaše video přesahuje maximální limit 100 MB"
    },
    "menu": "Home",
    "messages": {
      "nodirectmessages": "Soukromé zprávy připravujeme."
    },
    "new-chally-accepted": {
      "info": "Máte <b>{title}</b> Chally. Hodně štěstí! 🤞",
      "not-found": "Chally nenalezena",
      "title": "Nová chally přijata!"
    },
    "news-feed": "Sleduji",
    "next": "Další",
    "no": "Ne",
    "notifications": {
      "empty_list": "Žádné notifikace",
      "new_placeholder": "Vložte notifikaci...",
      "plus99": "99+"
    },
    "ok": "Ok",
    "or": "nebo",
    "password_forgot": {
      "info": "Zadejte e-mail spojený s vaším účtem, na který vám zašleme instrukce pro změnu hesla."
    },
    "permission": {
      "denied": "Aplikace nemá povolena potřebná oprávnění",
      "failed": "Selhal požadavek na získání opravnění"
    },
    "photo": {
      "change": "Změnit fotku",
      "name": "Foto",
      "profile": "Profilové foto",
      "upload": "Nahrát foto"
    },
    "prepare_preview": "Připravuje se náhled...",
    "privacy": {
      "consent": {
        "1": " Souhlasíte se zpracováním těchto osobních údajů:",
        "2": "Prohlašujete, že veškeré osobní a jiné údaje poskytnuté Společnosti jsou přesné, úplné a pravdivé a zavazujete se, že případné změny Společnosti bezodkladně oznámíte.",
        "3": "Prohlašujete, že jste si vědomi, že poskytnutí souhlasu se zpracováním vašich osobních údajů je dobrovolné a že jej můžete kdykoliv odvolat písemným oznámením zaslaným poštou na adresu sídla Společnosti nebo e-mailem na info@challingo.com Berete však na vědomí, že odvolání tohoto souhlasu může mít za následek ukončení poskytování Služeb ze strany Společnosti.",
        "a": "jméno a příjmení;",
        "b": "uživatelské jméno a heslo;",
        "c": "e-mailová adresa;",
        "d": "fotografie či jiná podobizna;",
        "e": "komunikace mezi vámi a Společností; a",
        "f": "další informace a Obsah, který uvedete ve svém Účtu",
        "g": "Zpracováním osobních údajů se rozumí zejména jejich shromažďování, ukládání na nosiče, používání, uchovávání, třídění a předávání.",
        "title": "Souhlas ke zpracování osobních údajů a jejich rozsah"
      },
      "intro": "Tyto Zásady ochrany osobních a jiných údajů (dále jen <b>„Zásady“</b>) upravují vzájemná práva a povinnosti mezi vámi a společností Challingo s.r.o., IČ: 06047688, se sídlem na adrese Rybná 716/24, Staré Město, 110 00 Praha 1, Česká republika, zapsané v obchodním rejstříku vedeném u Městského soudu v Praze pod sp. zn. C 274936 (<b>„Společnost“</b>) související s nakládáním s vašimi osobními a jinými údaji, které Společnosti poskytnete při používání služeb Challingo (<b>„Služby“</b>) nebo v souvislosti s jejich používáním. Tyto Zásady jsou nedílnou součástí Podmínek použití služeb Challingo (<b>„Podmínky“</b>).",
      "introductory": {
        "1": "Registrací prostřednictvím webových stránek Challingo.com nebo souvisejících mobilních aplikací vyjadřujete souhlas s Podmínkami včetně těchto Zásad. Nesouhlasíte-li s nimi, nejste oprávnění Služby používat.",
        "2": "Veškeré pojmy použité v těchto Zásadách, které začínají velkými písmeny, mají stejný význam jako tytéž pojmy použité v Podmínkách.",
        "3": "Tyto Zásady upravují vzájemná práva a povinnosti související s nakládáním s vašimi osobními a jinými údaji, které Společnosti poskytnete při používání Služeb nebo v souvislosti s jejich používáním. Společnost nakládá s osobními údaji každého uživatele, který je fyzickou osobou, v souladu se zákonem č. 101/2000 Sb., o ochraně osobních údajů, ve znění pozdějších předpisů. V případě, že si ve svém Účtu nastavíte sdílení vašich osobních údajů se třetími stranami, činíte tak na vlastní odpovědnost a berete na vědomí, že zpracování vašich osobních údajů těmito třetími stranami nepodléhá těmto Zásadám.",
        "4": "Tyto Zásady jsou platné a účinné ode dne zahájení používání služeb. Znění těchto Zásad může Společnost kdykoliv jednostranně měnit či doplňovat. Na případnou změnu či doplnění Zásad budete upozorněni způsobem uvedeným v článku V. Podmínek. Účinnost změny či doplnění může nastat okamžikem zveřejnění nové verze Zásad, nebo konkrétním datem určeným Společností. Používáním Služeb po datu účinnosti nové verze Zásad vyjadřujete souhlas s aktualizovaným zněním Zásad. Tímto ustanovením nejsou dotčena práva a povinnosti vzniklá za dobu účinnosti předchozího znění Zásad.",
        "5": "Tyto Zásady včetně jejich předchozích verzí jsou dostupné prostřednictvím webových stránek Challingo.com a souvisejících mobilních aplikací, kde je umožněno jejich zobrazení každému.",
        "title": "Úvodní ustanovení"
      },
      "other": {
        "1": "Berete na vědomí, že při používání Služeb může Společnost ukládat soubory cookie a jiné datové soubory do zařízení, na kterých Služby používáte, za účelem shromažďování údajů o tom, jakým způsobem Služby využíváte, z jakých zařízení a prohlížečů k nim přistupujete a za účelem přizpůsobování Služeb. Dále berete na vědomí, že při používání Služeb může Společnost shromažďovat informace o vaší geografické poloze, IP adrese, internetovém prohlížeči, poskytovateli internetu, zařízení a operačním systému. Postup dle tohoto bodu slouží k přizpůsobení a vývoji Služeb a k analytickým a statistickým účelům, nikoliv k vaší přímé identifikaci. Shromažďování některých informací nebo ukládání souborů na vaše zařízení můžete omezit jejich nastavením, avšak může to mít vliv na poskytování Služeb.",
        "2": "Společnost je oprávněna vkládat do vašeho Obsahu metadata.",
        "3": "Anonymizované analytické a statistické údaje je Společnost oprávněna sdílet se třetími osobami.",
        "title": "Ostatní údaje a data"
      },
      "purpose": {
        "1": "Společnost bude osobní údaje zpracovávat za účelem:",
        "2": "Souhlasíte se zpracováním vašich osobních údajů na území České republiky.",
        "3": "Společnost bude osobní údaje zpracovávat po dobu neurčitou, vždy však po dobu vedení vašeho Účtu a po jeho zrušení po dobu trvání práv a povinností vzešlých z Podmínek a z poskytování Služeb. Po uplynutí doby zpracování osobních údajů je Společnost oprávněna údaje zpracovávat pro analytické účely anonymizovaným způsobem tak, že nebude možné vás na jejich základě identifikovat.",
        "4": "Společnost bude vaše osobní údaje zpracovávat automatizovaným nebo jiným způsobem a prostředky.",
        "5": "Zpracováním osobních údajů může Společnost jako správce pověřit třetí osobu jakožto zpracovatele.",
        "6": "Společnost může vaše osobní údaje v nezbytném rozsahu poskytnout obchodním partnerům, které umožňují poskytování Služeb nebo se na jejich poskytování podílejí, a to pouze za účelem poskytování Služeb. S žádnými jinými třetími osobami Společnost vaše osobní údaje nebude sdílet ani je třetím osobám neprodá. Berete však na vědomí, že v určitých případech může být Společnost na základě zákona, jiné právní normy nebo rozhodnutí orgánu veřejné moci povinna vaše osobní údaje poskytnout orgánům veřejné moci.",
        "a": "vedení vašeho Účtu a poskytování Služeb;",
        "b": "realizace práv a povinností plynoucích z Podmínek a z poskytování Služeb;",
        "c": "vývoje, testování, monitorování a vylepšování Služeb;",
        "d": "analyzování a přizpůsobování zobrazovaného Obsahu;",
        "e": "zobrazování a zasílání reklamy a obchodních sdělení.",
        "title": "Účel, doba a způsob zpracování osobních údajů"
      },
      "rights": {
        "1": "Máte právo na přístup k vašim osobním údajům a právo na jejich opravu.",
        "2": "Pokud se domníváte, že Společnost nebo jiný zpracovatel provádí zpracování vašich osobních údajů v rozporu s ochranou soukromého a osobního života nebo v rozporu se zákonem, zejména jsou-li osobní údaje nepřesné s ohledem na účel jejich zpracování, můžete požádat Společnost nebo zpracovatele o vysvětlení, a požadovat, aby Společnost nebo zpracovatel odstranil takto vzniklý stav. Bude-li vaše žádost oprávněná, Společnost nebo zpracovatel jsou povinni neprodleně odstranit závadný stav.",
        "3": "Požádáte-li o informaci o zpracování svých osobních údajů, je vám ji Společnost povinna předat. Společnost má v takovém případě právo požadovat přiměřenou úhradu nepřevyšující náklady nezbytné na poskytnutí informace.",
        "title": "Vaše práva při ochraně osobních údajů"
      },
      "title": "ZÁSADY OCHRANY OSOBNÍCH A JINÝCH ÚDAJŮ"
    },
    "profile_edit": {
      "bio": "Bio",
      "button": "Uložit profil",
      "linktree": "URL socíální sítě",
      "name": "Jméno",
      "no_rank": "No rank",
      "rank": "Rank"
    },
    "profile_menu": {
      "account": "Účet",
      "block": "Blokovat",
      "blocked": "Blokovaní uživatelé",
      "edit": "Upravit profil",
      "language": "Jazyk",
      "password": "Změnit heslo",
      "settings": "Nastavení",
      "title": "Možnosti",
      "unblock": "Odblokovat"
    },
    "push-notifications": {
      "accept": "přijal vaši chally.",
      "call_to": "vás vyzval/a ke splnění chally.",
      "comment": "okomentoval/a vaši chally.",
      "complete": "splnil vaši chally.",
      "dm": "vám poslal zprávu",
      "follow": "vás začal/a sledovat.",
      "like": "se líbí vaše chally.",
      "mention": "vás zmínil/a v komentáři.",
      "welcome": "Vítejte v Challingo"
    },
    "random": "Random",
    "ranks": {
      "beast": "Beast",
      "challenger": "Challenger",
      "chief": "Chief",
      "coach": "Coach",
      "elder": "Elder",
      "expert": "Expert",
      "influencer": "Influencer",
      "influencer_message": "Máte 10 000 sledujících",
      "legend": "Legend",
      "master": "Master",
      "newbie": "Newbie",
      "norank": "No rank",
      "player": "Player",
      "pro": "Pro",
      "rookie": "Rookie"
    },
    "report": "Nahlásit",
    "reported": "Nahlášeno",
    "score": "Score",
    "search": {
      "nochallyfound": "Nebyly nalezeny žádné chally.",
      "nousersfound": "Nebyl nalezen žádný uživatel.",
      "search": "Vyhledat"
    },
    "searching": "Hledám...",
    "send": "Poslat",
    "send_separately": "Poslat jednotlivě",
    "settings": {
      "locale_cz": "CZ",
      "locale_en": "EN",
      "locale_title": "Vyberte jazyk",
      "title": "Nastavení"
    },
    "show-more": "Zobrazit více",
    "tags": "Tagy",
    "terms": {
      "choice": {
        "1": "Tyto Podmínky a jejich výklad se budou řídit výhradně právním řádem České republiky, a to i v případě, že právní vztah založený těmito Podmínkami nebo související s použitím Služeb bude obsahovat mezinárodní prvek. Výslovně se vylučuje aplikace Úmluvy OSN o smlouvách o mezinárodní koupi zboží.",
        "2": "K rozhodování veškerých sporů vzešlých z těchto Podmínek jsou příslušné obecné soudy České republiky.",
        "3": "K mimosoudnímu řešení spotřebitelských sporů je příslušná Česká obchodní inspekce, se sídlem Štěpánská 567/15, 120 00 Praha 2, IČ: 000 20 869, internetová adresa: www.coi.cz.",
        "4": "Společnost si vyhrazuje právo, nikoliv povinnost, zasahovat do sporů mezi uživateli. Zavazujete se, že Společnosti poskytnete přiměřenou součinnost k odstraňování Obsahu porušujícího tyto Podmínky a při řešení sporů mezi vámi a ostatními uživateli.",
        "5": "Se svými stížnostmi může obracet přímo na Společnost nebo na příslušný orgán dohledu nebo státního dozoru.",
        "title": "Volba práva a řešení sporů"
      },
      "closing": {
        "1": "Žádné vaše pohledávky nebo jejich části vůči Společnosti vzešlé z těchto Podmínek nebo z používání Služeb nejste oprávněn/a postoupit třetí osobě bez předchozího písemného souhlasu Společnosti. Svá práva a povinnosti z těchto Podmínek nebo z jejich části nesmíte postoupit třetí osobě bez předchozího písemného souhlasu Společnosti.",
        "2": "Udělujete Společnosti souhlas k postoupení pohledávek nebo jejich částí vůči vaší osobě vzešlých z těchto Podmínek nebo z používání Služeb na třetí osobu. Udělujete Společnosti souhlas k postoupení jejích práv a povinností z těchto Podmínek nebo jejích částí na třetí osobu.",
        "3": "Společnost není vůči vám vázána žádnými kodexy chování ve smyslu ustanovení § 1826 odst. 1 písm. e) zákona č. 89/2012 Sb., občanský zákoník, ve znění pozdějších předpisů.",
        "4": "Tyto Podmínky byly vytvořeny v českém a anglickém jazyce. V případě rozporu jazykových verzí má přednost verze v českém jazyce.",
        "5": "Je-li, nebo stane-li se některé z ustanovení těchto Podmínek neplatným, neúčinným, nebo nevymahatelným, zůstanou ostatní ustanovení oddělená, platná, účinná a vymahatelná. Namísto takových neplatných, neúčinných nebo nevymahatelných ustanovení nastoupí ustanovení, jehož smysl se neplatnému, neúčinnému nebo nevymahatelnému ustanovení co nejvíce přibližuje.",
        "title": "Závěrečná ustanovení"
      },
      "communication": {
        "1": "Souhlasíte, že k veškeré komunikaci s vámi Společnost bude využívat prostředí vašeho Účtu a/nebo kontaktní údaje, které jí poskytnete. Tato komunikace může zahrnovat mimo jiné oznámení o změně či doplnění těchto Podmínek, řešení sporů s jinými uživateli či potvrzení o odstoupení od těchto Podmínek. Souhlasíte, že vás Společnost tímto způsobem může kontaktovat i za jakýmkoliv jiným účelem souvisejícím se Službami a jejich poskytováním.",
        "2": "V případě, že se budete domnívat, že někdo užívá Služby v rozporu s právními předpisy a normami nebo těmito Podmínkami, můžete toto jednání nahlásit prostřednictvím možnosti „Nahlásit“ u každého příspěvku. Stejně můžete postupovat v případě, že se domníváte, že někdo tímto jednáním narušil vaše autorská práva.",
        "title": "Komunikace a nahlašování nevhodného obsahu"
      },
      "company_disclaimer": {
        "1": "Používání Služeb je na vaše vlastní riziko a odpovědnost. Rozhodnete-li se postupovat na základě nějaké výzvy, jde o vaše svobodné rozhodnutí. Při plnění výzev a používání Služeb jste povinen/povinna řídit se místně platnými právními předpisy a normami.",
        "2": "\tSpolečnost neodpovídá za žádný Obsah uživatelů a třetích stran. Společnost tento Obsah nevytváří, neposkytuje, neschvaluje, nepropaguje, nezaručuje a nemá povinnost jej monitorovat nebo kontrolovat. ",
        "3": "Společnost vůči vám ani jiným uživatelům prostřednictvím výzev uživatelů nikdy nečiní právně závazné jednání, a to ani v případě, že Společnost takovou výzvu prostřednictvím svého oficiálního účtu sdílela nebo jinak šířila. Žádnou z výzev uživatelů nelze chápat jako veřejný příslib, vypsání ceny, slib odškodnění nebo obdobný závazek ze strany Společnosti. Učiní-li takový závazek některý z uživatelů, Společnost nezaručuje jeho vymahatelnost ani za ni neodpovídá. Společnost nikdy v žádném případě na sebe takovýto závazek kteréhokoliv uživatele nepřijímá. Spory ohledně vymahatelnosti výzev uživatelů jsou soukromou záležitostí uživatelů a Společnost není povinna do nich zasahovat nebo poskytovat k jejich vyřešení jakoukoliv součinnost, ledaže tak vyplyne z právních předpisů nebo z rozhodnutí veřejných orgánů. Společnosti lze připsat učinění pouze těch výzev, jejichž původní zveřejnění proběhlo prostřednictvím oficiálního účtu Společnosti pod názvem Challingo a které jsou označeny logem Společnosti. Je zakázáno, aby kterýkoliv jiný uživatel používal toto označení při vytváření výzev nebo sdílení či jiném šíření jiných výzev než těch uvedených v předchozí větě.",
        "4": "Společnost nečiní žádná prohlášení ohledně vlastností Služeb, jejich jakosti nebo provedení. Služby jsou poskytovány tak, jak jsou, se všemi vadami, a Společnost negarantuje u Služeb jejich dostupnost, funkčnost, přesnost, bezvadnost ani jejich vhodnost k určitému účelu. Společnost není povinna odstraňovat vady Služeb. Společnost se zříká veškerých výslovných či implicitních záruk vztahujících se k funkčnosti, účelu, přesnosti, jakosti, vhodnosti, provedení, užitečnosti a bezpečnosti Služeb a k nepřítomnosti virů ve Službách. V případě, že budete mít ke Službám nějaké připomínky nebo tipy na jejich vylepšení, můžete se na nás obracet skrze funkci v aplikaci „Pošlete nám feedback“ či na e-mailovou adresu <a href=\"mailto:info@challingo.com;\">info@challingo.com;</a>.",
        "5": "Společnost není odpovědná za jakoukoliv škodu nebo nemajetkovou újmu mající původ nebo související s:",
        "6": " Zavazujete se, že uhradíte veškerou škodu a nemajetkovou újmu, jež by Společnosti nebo ostatním uživatelům vznikla v důsledku vašeho porušení těchto Podmínek.",
        "a": "Obsahem uživatelů;",
        "b": "chováním a vystupováním uživatelů;",
        "c": "ztrátou vašeho Obsahu;",
        "d": "nedostupností, nefunkčností, chybovostí nebo nepřesností Služeb;",
        "e": "přítomností virů ve Službách;",
        "f": "poškozením vašeho hardware a/nebo software v důsledku použití Služeb;",
        "g": "plněním výzev; a/nebo",
        "h": "neplněním závazků uživatelů učiněných prostřednictvím výzev.",
        "title": "Zřeknutí se odpovědnosti Společnosti a vaše odpovědnost"
      },
      "intro": "Tyto Podmínky použití služeb Challingo (dále jen <b>„Podmínky“</b>) společnosti Challingo s.r.o., IČ: 06047688, se sídlem na adrese Rybná 716/24, Staré Město, 110 00 Praha 1, Česká republika, zapsané v obchodním rejstříku vedeném u Městského soudu v Praze pod sp. zn. C 274936 (dále jen <b>„Společnost“</b>) upravují vzájemná práva a povinnosti mezi Společností a vámi jako uživatelem služeb poskytovaných Společností, které vznikají při používání těchto služeb nebo v souvislosti s jejich používáním. Každý uživatel je povinen se s těmito Podmínkami seznámit a vyjádřit s nimi souhlas ještě před zahájením používání služeb. V opačném případě nejste oprávněni služby využívat.",
      "introductory": {
        "1": " Společnost prostřednictvím webové stránky Challingo.com a souvisejících mobilních aplikací poskytuje služby umožňující jejím uživatelům vytvářet a mezi sebou sdílet výzvy a jiný obsah (dále jen <b>„Služby“</b>). Tyto výzvy uživatelů a jiný jejich obsah nejsou vytvářeny a poskytovány Společností a ta za ně nenese odpovědnost. Používání Služeb je na vaši vlastní odpovědnost a riziko a řídí se těmito Podmínkami, jež mají povahu smlouvy uzavírané mezi vámi a Společností. Nedílnou součástí těchto Podmínek jsou Zásady ochrany osobních a jiných údajů.",
        "2": "Pro používání Služeb je nutné se registrovat u Společnosti prostřednictvím webových stránek Challingo.com nebo souvisejících mobilních aplikací. Registrací stvrzujete, že jste se seznámil/a s těmito Podmínkami, porozuměl/a jste jim a souhlasíte s tím, že představují závazné stanovení práv a povinností mezi vámi a Společností vzniklých při používání Služeb nebo v souvislosti s jejich používáním. Nesouhlasíte-li s těmito Podmínkami, nejste oprávněn/a Služby používat. Ke Službám je možné přistupovat různými způsoby a tyto se mohou obměňovat. Tyto Podmínky jsou závazné pro jakékoliv použití Služeb bez ohledu na způsob a místo přístupu k nim a nahrazují veškerá jiná ústní i písemná prohlášení a ujednání mezi vámi a Společností. Registrací stvrzujete, že v místě, odkud registraci provádíte, a ve všech místech, kde budete ke Službám přistupovat, není používání Služeb z vaší strany protiprávní a že jste způsobilý/á se těmito Podmínkami zavázat po dobu neurčitou.",
        "3": "Použití některých Služeb nebo jejich funkcí se může řídit zvláštními podmínkami, jež mohou tyto Podmínky v částech nebo celku doplňovat nebo nahrazovat. Není-li v těchto zvláštních podmínkách uvedeno jinak, mají tyto zvláštní podmínky povahu dodatku k těmto Podmínkám a tyto Podmínky se užijí v rozsahu, ve kterém nejsou v rozporu s těmito zvláštními podmínkami.",
        "4": "Použití některých Služeb nebo jejich funkcí může být územně omezeno.",
        "5": "Společnost si vyhrazuje právo poskytovat Služby některým uživatelům za individuálně sjednaných podmínek.",
        "6": "Tyto Podmínky jsou platné a účinné ode dne zahájení používání Služeb. Znění těchto Podmínek může Společnost kdykoliv jednostranně měnit či doplňovat. Na případnou změnu či doplnění Podmínek budete upozorněni způsobem uvedeným v článku V. těchto Podmínek. Účinnost změny či doplnění může nastat okamžikem zveřejnění nové verze Podmínek, nebo konkrétním datem určeným Společností. Používáním Služeb po datu účinnosti nové verze Podmínek vyjadřujete souhlas s aktualizovaným zněním Podmínek. Tímto ustanovením nejsou dotčena práva a povinnosti vzniklá za dobu účinnosti předchozího znění Podmínek.",
        "7": "Tyto Podmínky včetně jejich předchozích verzí jsou dostupné prostřednictvím webových stránek Challingo.com a souvisejících mobilních aplikací, kde je umožněno jejich zobrazení každému. Podmínky uzavřené s jednotlivými uživateli Společnost ukládá, avšak neumožňuje uživatelům přístup k nim.",
        "title": "Úvodní ustanovení"
      },
      "registration": {
        "1": "Registraci provedete vyplněním požadovaných údajů prostřednictvím webových stránek Challingo.com nebo souvisejících mobilních aplikací. Po vyplnění těchto údajů vám budou poskytnuty přihlašovací údaje a bude vám vytvořen uživatelský účet (dále jen <b>„Účet“</b>). Výslovně žádáte Společnost, aby vám Služby začala poskytovat ihned po provedení registrace. Údaje o požadavcích na software a hardware potřebný pro používání Služeb naleznete na stránkách Challingo.com v sekci O nás. Používáním Služeb vás provede prostředí vašeho Účtu.",
        "10": "Společnost je oprávněna vám přístup k vašemu Účtu dočasně odepřít či Účet zcela zrušit bez náhrady, bez uvedení důvodu a bez oznámení. K těmto krokům Společnost přistoupí zejména v případě porušení či podezření z porušení těchto Podmínek nebo závazných právních předpisů a norem a dále v případě, kdy se ke svému Účtu nepřihlásíte po dobu dvou let. V případě, že vám bude Účet Společností zrušen, nejste oprávněni si založit nový bez souhlasu Společnosti. Zrušení vašeho Účtu Společností má následky odstoupení od těchto Podmínek (a tedy odstoupení od poskytování Služeb) ze strany Společnosti.",
        "11": "Váš Účet si můžete sami kdykoliv trvale zrušit bez uvedení důvodu a tím tak odstoupit od těchto Podmínek. Oznámení o odstoupení můžete učinit například prostřednictvím vašeho Účtu v sekci „Pošlete nám feedback“ nebo zasláním vzorového formuláře na e-mailovou nebo poštovní adresu Společnosti. Odstoupení je účinné okamžikem, kdy oznámení o odstoupení dojde Společnosti. Po jeho přijetí vám Společnost zašle potvrzení o přijetí oznámení o odstoupení od těchto Podmínek.",
        "12": "Zrušení vašeho Účtu nemá vliv na práva a povinnosti vzniklé před jeho zrušením.",
        "13": "Po zrušení vašeho Účtu je Společnost oprávněna uchovávat Obsah a informace související s vaším Účtem po přiměřenou dobu a dále po dobu, po kterou to ve vztahu ke konkrétním informacím vyžadují platné právní předpisy.",
        "14": "Berete na vědomí, že v případě, že jste Obsah sdílel/a s jinými uživateli Služeb, může být tento dostupný i po zrušení vašeho Účtu bez časového omezení.",
        "15": "Zrušení vašeho Účtu znamená ztrátu a odvolání všech oprávnění a výhod poskytnutých Společností v souvislosti s vaším Účtem.",
        "16": "Služby jsou poskytovány prostřednictvím komunikačních prostředků na dálku. Náklady na použití těchto prostředků, jako je internetové a telefonické připojení, si hradíte sami, přičemž tyto se neliší od základních sazeb.",
        "2": "Služby mohou využívat pouze osoby starší 13 let. Pokud je vám méně než 18 let, jste oprávnění Služby využívat pouze se souhlasem zákonného zástupce a pod jeho dozorem.",
        "3": "Každá osoba může mít pouze jeden Účet.",
        "4": "Přístup k Účtu je chráněn přihlašovacími údaji. Přihlašovací údaje nesmíte zpřístupnit třetí osobě ani žádné třetí osobě nesmíte umožnit používání vašeho Účtu. Zavazujete se, že podniknete přiměřené kroky k ochraně vašich přihlašovacích údajů a Účtu. Není povoleno vytvářet Účet pro jinou osobu, používat Účet jiné osoby či s Účtem disponovat jinak, než jak vyplývá z těchto Podmínek a z účelu Služeb.",
        "5": "Prohlašujete, že veškeré údaje vámi poskytnuté Společnosti při registraci nebo jinak jsou přesné, úplné, a pravdivé, a že veškeré jejich případné změny bezodkladně Společnosti oznámíte.",
        "6": "Nakládání s osobními a jinými údaji, které poskytnete Společnosti, se řídí Zásadami ochrany osobních a jiných údajů.",
        "7": "Společnost negarantuje dostupnost Služeb ani přístup k vašemu Účtu. Dostupnost Služeb a přístup k vašemu Účtu mohou být omezeny zejména s ohledem na plánované či nutné údržby a změny systému.",
        "8": "Společnost nenese odpovědnost za ztrátu jakýchkoliv informací, textů, uživatelských jmen, přezdívek, hesel, zpráv a jiné komunikace, označení, obrázků, fotografií, grafik, videí, zvuků, skladeb, hlasových záznamů, aplikací, programů, autorských děl, odkazů, dat, soborů či jakéhokoliv jiného obsahu (dále jen <b>„Obsah“</b>) vytvořeného, nahraného, zveřejněného, uchovávaného, sdíleného či šířeného prostřednictvím vašeho Účtu. Pojem Obsah užitý v těchto Podmínkách zahrnuje i výzvy.",
        "9": "Společnost je oprávněna Obsah odstraňovat bez náhrady, bez uvedení důvodu a bez oznámení. K těmto krokům Společnost přistoupí zejména v případě porušení či podezření z porušení těchto Podmínek nebo závazných právních předpisů a norem. Společnost je oprávněna Obsah monitorovat a stanovovat pro něj technická i jiná omezení.",
        "title": "Registrace, účet a dostupnost Služeb"
      },
      "title": "PODMÍNKY POUŽITÍ SLUŽEB CHALLINGO",
      "use": {
        "1": "Služby poskytované Společností umožňují jejím uživatelům vytvářet, nahrávat, zveřejňovat, uchovávat, sdílet a šířit Obsah a jinak spolu komunikovat, avšak Společnost sama tento Obsah nevytváří, neposkytuje, neschvaluje, nepropaguje, nezaručuje a nenese za něj odpovědnost. Použití Služeb je pouze na vaši vlastní odpovědnost a riziko. Plnění výzev je vaším dobrovolným rozhodnutím a činíte tak na vlastní odpovědnost a riziko. Společnost prostřednictvím výzev uživatelů vůči vám ani jiným uživatelům nečiní žádný právně vymahatelný závazek. Při vytváření, plnění, sdílení a šíření výzev a při používání Služeb obecně jste povinni dodržovat platné právní předpisy a normy. Berete na vědomí, že vytvoření, sdílení či šíření výzev může naplňovat znaky právního jednání, jímž se zavážete k určitému právně vymahatelnému plnění.",
        "10": "Při používání Služeb se doporučuje obezřetnosti s ohledem na vaše soukromí. Je na vašem rozhodnutí, odpovědnosti a riziku, jaký Obsah prostřednictvím Účtu zveřejníte, s kým jej budete sdílet a mezi koho šířit. Zveřejňování, sdílení a šíření Obsahu můžete ovlivnit nastavením vašeho Účtu. Berete na vědomí, že určitá nastavení vašeho Účtu umožňují následné sdílení a šíření vašeho Obsahu bez vašeho následného souhlasu nebo vědomí, a to i po změně nastavení nebo zrušení Účtu. Berete na vědomí, že Služby jsou poskytovány prostřednictvím sítě internet, na které může dojít k prolomení bezpečnosti a prostřednictvím které se mohou šířit počítačové viry. Propojení Služeb a vašeho Účtu se službami a produkty třetích stran je na vaši odpovědnost a riziko. Využívání služeb a produktů třetích stran může podléhat zvláštním podmínkám těchto třetích stran.",
        "11": "Je zakázáno jakkoliv zasahovat do software a prostředí Služeb a měnit je. Je zakázáno jakýmkoliv způsobem narušovat nebo ohrožovat fungování Služeb, a to zejména implementováním nebo šířením virů, spyware, malware, a zásahem do serverů nebo zdrojových kódů.",
        "12": "Je zakázáno jakýmkoliv způsobem používat Služby pomocí automatizovaných postupů a algoritmů. Je zakázáno používat Služby způsobem, jež by mohl vést k jejich přetížení.",
        "2": "Za veškerý Obsah vytvořený, nahraný, zveřejněný, uchovávaný, sdílený nebo šířený prostřednictvím vašeho Účtu nesete plnou zodpovědnost. Společnost Obsah nekontroluje. Dále nesete plnou zodpovědnost za veškerou vaší komunikaci a jinou interakci s ostatními uživateli.",
        "3": "Je zakázáno vytvářet, nahrávat, zveřejňovat, uchovávat, sdílet nebo šířit Obsah, který obsahuje, zpodobňuje, znázorňuje, propaguje, schvaluje, nebo nabádá k:",
        "4": "Je zakázáno nahrávat, zveřejňovat, uchovávat, sdílet nebo šířit Obsah, který zasahuje do autorských nebo jiných práv třetích osob.",
        "5": "K veškerému Obsahu, který prostřednictvím vašeho Účtu zveřejníte, udělujete Společnosti nevýhradní oprávnění ke všem způsobům užití (dále jen <b>„Licence“</b>). Společnost je oprávněna předmět Licence užít v původní, zpracované, upravené i jinak pozměněné podobě. Licenci Společnosti udělujete pro území celého světa bez časového a množstevního omezení. Licenci udělujete Společnosti jako bezúplatnou a bez nároku na dodatečnou odměnu. Dále udělujete Společnosti souhlas k postoupení Licence nebo její části třetí osobě a souhlas k poskytnutí oprávnění tvořící Licenci nebo její část třetí osobě.",
        "6": "Udělujete Společnosti souhlas ke komerčnímu použití vašich atributů osobní povahy, jako je vaše jméno, přezdívka, fotografie či podobizna a jiné, které zveřejníte prostřednictvím vašeho Účtu.",
        "7": "Udělujete Společnosti souhlas s umisťováním reklamy a komerčních sdělení do vašeho Obsahu.",
        "8": "Prohlašujete:",
        "8a": "že jste oprávněni nahrát, zveřejnit, uchovávat, sdílet a šířit prostřednictvím vašeho Účtu veškerý Obsah, u kterého tak učiníte, a že tímto jednáním neoprávněně nezasahujete do autorských či jiných práv třetích osob;",
        "8b": "že jste oprávnění udělit Společnosti Licence v rozsahu uvedeném v bodu 5. tohoto článku; a",
        "8c": "že uhradíte veškerou škodu a nemajetkovou újmu, dlužné autorské honoráře, odměny a jiné nároky, která vzniknou třetím osobám a/nebo Společnosti v případě, že se vaše prohlášení uvedené pod písmeny a) a/nebo b) tohoto bodu ukážou jako nepravdivé.",
        "9": "Při užívání Služeb jste povinen/povinna ctít ostatní uživatele, neomezovat je v užívání Služeb a nezasílat jim nevyžádaný obsah (spam).",
        "a": "násilí a ohrožování života a zdraví;",
        "b": "nenávisti a diskriminaci;",
        "c": "urážkám, ponižování, šikaně a zneužívání;",
        "d": "sledování, obtěžování, vyhrožování a vydírání;",
        "e": "zásahům do soukromí, zosobňování jiných osob, neoprávněnému sdílení osobních či jiných soukromých nebo tajných údajů;",
        "f": "nahotě, pornografii a jiného sexuálně explicitního obsahu;",
        "g": "užívání zakázaných látek a užívání zakázaných látek mladistvými;",
        "h": "podvodům a šíření poplašných zpráv;",
        "i": "obchodování se zakázanými věcmi; a",
        "j": "jinému nezákonnému nebo protiprávnímu jednání.",
        "title": " Používání Služeb, výzvy a Obsah"
      }
    },
    "to_homepage": "Jít na homepage",
    "updateApp": "Stahněte si novou verzi aplikace",
    "user": {
      "auth/email-already-in-use": "E-mailovou adresu již používá jiný účet.",
      "auth/invalid-email": "E-mailová adresa je špatně zadaná.",
      "auth/network-request-failed": "Žádost o síť se nezdařila.",
      "auth/too-many-requests": "Příliš mnoho pokusů.",
      "auth/user-not-found": "Uživatel neexistuje.",
      "auth/weak-password": "Heslo musí obsahovat alespoň 6 znaků.",
      "auth/wrong-password": "Heslo je neplatné.",
      "bad_linktree_url": "Povolené URL: Instagram, Facebook, Twitter, TikTok, Youtube, LinkedIn, Linktr.ee, Twitch, Pinterest",
      "bio_too_long": "Bio je příliš dlouhé",
      "create_account": "Vytvořit účet",
      "email": "E-mail",
      "email_question": "Jaký je váš e-mail?",
      "email_used": "E-mail již používá jiný účet.",
      "email_was_sent": "E-mail byl odeslán.",
      "emoji_in_username": "Emoji ve jméně jsou zakázané",
      "login": "Přihlásit se",
      "login_to_continue": "Přihlásit se a pokračujte",
      "logout": "Odhlásit se",
      "name": "Přezdívka",
      "name_error_space": "Nelze použít mezeru ve jméně.",
      "name_question": "Jaká je vaše přezdívka?",
      "name_used": "Přezdívku již používá jiný účet.",
      "new": "Nový",
      "password": "Heslo",
      "password_changed": "Heslo bylo změněno. Nyní se můžete přihlásit.",
      "password_confirm": "Potvrzení hesla",
      "password_current": "Aktuální heslo",
      "password_current_error": "Aktuální heslo nesouhlasí.",
      "password_forgot": "Nepamatuji si heslo",
      "password_info": "Vytvořte si heslo",
      "passwords_must_match": "Hesla se musí shodovat.",
      "passwords_required": "Vyplňte prosím heslo a potvrzení hesla.",
      "signin": "Přihlásit",
      "signup": "Registrovat",
      "submit_error": "Nepodařilo se vytvořit uživatele.",
      "type": "Uživatel",
      "username_too_long": "Jméno je příliš dlouhé",
      "username_too_short": "Jméno je příliš krátké",
      "welcome": "Vítejte v Challingo!",
      "welcome_tip": "V několika jednoduchých krocích vám pomůžeme vytvořit <b>nový účet</b>."
    },
    "users": "Uživatelé",
    "users-recommended": "Doporučení uživatelé",
    "video": {
      "max_length_error": "Max. délka videa je"
    },
    "view": "Zobrazit",
    "wait": "Čekejte...",
    "welcome-home": {
      "btn": "Začněte sledovat",
      "doSomething": "do something",
      "info": "Začněte sledovat uživatele a objevujte více chally",
      "logoAlt": "challingo",
      "title": "Poslední věc ..."
    },
    "yes": "Ano",
    "zero": "0"
  };
  _exports.default = _default;
});