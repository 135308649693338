define("challingo-mobile/components/share-actions", ["exports", "challingo-mobile/config/environment", "challingo-mobile/utils/detect-mobile-os"], function (_exports, _environment, _detectMobileOs) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    intl: Ember.inject.service(),
    router: Ember.inject.service(),
    session: Ember.inject.service(),
    toastMessage: Ember.inject.service(),
    classNames: ['share-actions'],
    model: null,
    challengeId: Ember.computed('model', function () {
      var model = this.model;
      return model.get('type') === 'challenge' ? model.get('id') : model.get('challenge.id');
    }),
    acceptedId: Ember.computed('model', function () {
      var model = this.model;
      return model.get('type') === 'accepted-challenge' ? model.get('id') : null;
    }),
    copy: function copy() {
      this.clipboard();
      this.toastMessage.show(this.intl.t('copy_to_clipboard_message'));
      Ember.tryInvoke(this, 'modalClose');
      return false;
    },
    copyToClipboard: function copyToClipboard(url) {
      var cbFallback = function cbFallback() {
        var tempInput = document.createElement('input');
        tempInput.style = 'position: absolute; left: -1000px; top: -1000px';
        tempInput.value = url;
        document.body.appendChild(tempInput);
        tempInput.select();
        document.execCommand('copy');
        document.body.removeChild(tempInput);
      };

      if ((0, _detectMobileOs.default)() === 'iOS') {
        if (navigator.clipboard) {
          try {
            navigator.clipboard.writeText(url).then(function () {}, function () {
              cbFallback();
            });
          } catch (e) {
            e;
          }
        } else {
          cbFallback();
        }
      } else {
        cbFallback();
      }
    },
    clipboard: function clipboard() {
      if (this.get('model.shortUrl')) {
        this.copyToClipboard(this.get('model.shortUrl'));
      } else {
        var challengeURL = "https://".concat(_environment.default.firebase.authDomain, "/#/challenges/").concat(this.challengeId);
        var destination = this.acceptedId ? "".concat(challengeURL, "?challengeAcceptedId=").concat(this.acceptedId) : challengeURL;
        this.copyToClipboard(destination);
      }
    },
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      var clipboardLink = this.element && this.element.querySelector('.link-copy-to-clipboard');

      if (clipboardLink) {
        clipboardLink.addEventListener('click', this.copy.bind(this));
      }
    },
    actions: {
      download: function download() {
        var url = this.get('model.media.downloadUrl');

        if (url) {
          window.location = url;
        }

        Ember.tryInvoke(this, 'modalClose');
      },
      sendDM: function sendDM() {
        Ember.tryInvoke(this, 'modalClose');
        this.router.transitionTo('challenges.dm', this.get('challengeId'), {
          queryParams: {
            challengeAcceptedId: this.get('acceptedId')
          }
        });
      }
    }
  });

  _exports.default = _default;
});