define("challingo-mobile/components/direction-btns", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['direction-btns'],
    classNameBindings: ['linePrimary', 'sticky'],
    btnLeft: null,
    btnRight: null,
    hasLine: false,
    linePrimary: false,
    sticky: true,
    loading: false,
    showColumnSpace: true,
    btnLeftClass: Ember.computed('btnLeft.style', function () {
      var style = this.get('btnLeft.style') || 'default';
      return "btn btn-".concat(style, " btn-full btn-edges-left pull-left");
    }),
    btnRightClass: Ember.computed('btnRight.style', function () {
      var style = this.get('btnRight.style') || 'default';
      return "btn btn-".concat(style, " btn-full btn-edges-right pull-right");
    }),
    actions: {
      back: function back() {
        history.back();
      },
      btnRightAction: function btnRightAction() {
        Ember.tryInvoke(this, 'btnRightAction');
      },
      btnLeftAction: function btnLeftAction() {
        Ember.tryInvoke(this, 'btnLeftAction');
      }
    }
  });

  _exports.default = _default;
});