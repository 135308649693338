define("challingo-mobile/mixins/remember-scroll", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    scrollSelector: 'body',
    _scrollTo: function _scrollTo(y) {
      var el = typeof this.scrollSelector === 'string' ? document.querySelector(this.scrollSelector) : this.scrollSelector;

      if (el && el.scrollTo) {
        el.scrollTo(0, y);
      }
    },
    activate: function activate() {
      var _this = this;

      this._super.apply(this, arguments);

      if (this.get('lastScroll')) {
        Ember.run.next(function () {
          _this._scrollTo(_this.get('lastScroll'));
        });
      } else {
        this._scrollTo(0);
      }
    }
  });

  _exports.default = _default;
});