define("challingo-mobile/templates/signup", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "2DIdQukb",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"full-height\"],[9],[0,\"\\n\"],[4,\"forms/signup-form\",null,null,{\"statements\":[[0,\"    \"],[1,[27,\"nav-bar\",null,[[\"title\",\"back\"],[[27,\"t\",[\"user.create_account\"],null],\"back\"]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "challingo-mobile/templates/signup.hbs"
    }
  });

  _exports.default = _default;
});