define("challingo-mobile/components/challenges/challenges-accepted", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    challenges: Ember.inject.service(),
    currentUser: Ember.inject.service(),
    modal: Ember.inject.service(),
    acceptedChallyModal: null,
    classNames: ['challenges-accepted'],
    _acceptedChallyModal: function _acceptedChallyModal(chally) {
      this.modal.show('challenges/challenge-accepted-actions', chally);
    },
    init: function init() {
      this._super.apply(this, arguments);

      this.set('acceptedChallyModal', Ember.run.bind(this, this._acceptedChallyModal));
    },
    actions: {
      loadNextPage: function loadNextPage() {
        this.challenges.userProfileAccepted.nextPage();
      }
    }
  });

  _exports.default = _default;
});