define("challingo-mobile/routes/logout", ["exports", "challingo-mobile/routes/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _typeof(obj) { "@babel/helpers - typeof"; if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

  var _default = Ember.Route.extend({
    challenges: Ember.inject.service(),
    cordova: Ember.inject.service(),
    currentUser: Ember.inject.service(),
    pushNotification: Ember.inject.service(),
    router: Ember.inject.service(),
    session: Ember.inject.service(),
    _finalizeLogout: function _finalizeLogout() {
      var _this = this;

      return this.session.invalidate().then(function () {
        if (window && window.location) {
          window.location = _typeof(window.cordova) === 'object' ? 'index.html' : '/';
        } else {
          _this.challenges.unload();

          _this.currentUser.unload();

          _this.pushNotification.unload();

          window.session = [];
          return _this.router.transitionTo('challenges').then(function () {
            (0, _application.loaderHide)();
          });
        }
      });
    },
    beforeModel: function beforeModel(transition) {
      var _this2 = this;

      Ember.tryInvoke(transition, 'abort');
      (0, _application.loaderShow)();

      if (window && window.device && window.device.uuid) {
        var devices = this.get('currentUser.user.devices');
        var promises = [];
        devices.forEach(function (device) {
          if (device && device.devId === window.device.uuid) {
            devices.removeObject(device);
            promises.push(device.destroyRecord());
          }
        });

        if (promises.length) {
          promises.push(this.get('currentUser.user').save({
            adapterOptions: {
              saveOnly: ['devices']
            }
          }));
          Ember.RSVP.all(promises).then(function () {
            return _this2._finalizeLogout();
          });
        } else {
          return this._finalizeLogout();
        }
      } else {
        return this._finalizeLogout();
      }
    }
  });

  _exports.default = _default;
});