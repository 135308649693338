define("challingo-mobile/routes/challenges/show", ["exports", "challingo-mobile/mixins/remember-scroll", "challingo-mobile/routes/challenges/likedby"], function (_exports, _rememberScroll, _likedby) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _likedby.default.extend(_rememberScroll.default, {
    bookmark: Ember.inject.service(),
    cordova: Ember.inject.service(),
    router: Ember.inject.service(),
    settings: Ember.inject.service(),
    store: Ember.inject.service(),
    queryParams: {
      challengeAcceptedId: {
        replace: true
      },
      scrollToDescription: {},
      toComments: {}
    },
    beforeModel: function beforeModel() {
      return this.settings.checkAppVersion(function () {}, function () {});
    },
    setupController: function setupController(controller) {
      this._super.apply(this, arguments);

      controller.set('bookmark', this.bookmark);
    },
    resetController: function resetController(controller) {
      this._super.apply(this, arguments);

      controller.setProperties({
        challengeAcceptedId: undefined,
        scrollToDescription: undefined,
        toComments: undefined
      });
    }
  });

  _exports.default = _default;
});