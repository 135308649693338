define("challingo-mobile/templates/error", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "OwZULB3u",
    "block": "{\"symbols\":[],\"statements\":[[1,[27,\"nav-bar\",null,[[\"title\",\"back\"],[[27,\"t\",[\"error.type\"],null],\"back\"]]],false],[0,\"\\n\\n\"],[7,\"div\"],[11,\"class\",\"text-center\"],[9],[0,\"\\n  \"],[7,\"br\"],[9],[10],[7,\"br\"],[9],[10],[0,\"\\n  \"],[7,\"h3\"],[9],[1,[27,\"t\",[\"error.message\"],null],false],[10],[0,\"\\n  \"],[7,\"br\"],[9],[10],[7,\"br\"],[9],[10],[0,\"\\n\"],[4,\"link-to\",[\"challenges\",[27,\"query-params\",null,[[\"miniProfile\"],[null]]]],[[\"class\"],[\"btn btn-primary\"]],{\"statements\":[[0,\"    \"],[1,[27,\"t\",[\"to_homepage\"],null],false],[0,\"\\n\"]],\"parameters\":[]},null],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "challingo-mobile/templates/error.hbs"
    }
  });

  _exports.default = _default;
});