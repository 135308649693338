define("challingo-mobile/templates/components/profile-actions", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "m4Z/wuXO",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[23,[\"isBlocked\"]]],null,{\"statements\":[[0,\"  \"],[7,\"a\"],[11,\"href\",\"#\"],[11,\"class\",\"link\"],[11,\"style\",\"touch-action: manipulation; -ms-touch-action: manipulation; cursor: pointer;\"],[9],[0,\"\\n    \"],[1,[27,\"t\",[\"profile_menu.unblock\"],null],false],[0,\"\\n  \"],[3,\"action\",[[22,0,[]],\"unblockUser\"]],[10],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[7,\"a\"],[11,\"href\",\"#\"],[11,\"class\",\"link\"],[11,\"style\",\"touch-action: manipulation; -ms-touch-action: manipulation; cursor: pointer;\"],[9],[0,\"\\n    \"],[1,[27,\"t\",[\"profile_menu.block\"],null],false],[0,\"\\n  \"],[3,\"action\",[[22,0,[]],\"blockUser\"]],[10],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "challingo-mobile/templates/components/profile-actions.hbs"
    }
  });

  _exports.default = _default;
});