define("challingo-mobile/services/ok-message", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    isOpen: false,
    text: '',
    show: function show() {
      var text = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
      this.setProperties({
        text: text,
        isOpen: true
      });
    }
  });

  _exports.default = _default;
});