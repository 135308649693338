define("challingo-mobile/components/challenges/challenge-accepted-actions", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    challenges: Ember.inject.service(),
    confirmDialog: Ember.inject.service(),
    intl: Ember.inject.service(),
    modal: Ember.inject.service(),
    store: Ember.inject.service(),
    toastMessage: Ember.inject.service(),
    classNames: ['challenge-actions'],
    item: Ember.computed.alias('model'),
    toast: function toast(msg) {
      this.toastMessage.show(this.intl.t(msg));
    },
    actions: {
      giveUp: function giveUp(item) {
        var _this = this;

        Ember.tryInvoke(this, 'modalClose');
        this.confirmDialog.show(this.intl.t('challenge.give_up_confirm'), function () {
          var acceptedChallenge = item.get('acceptedByUser') || item.get('challenge.acceptedByUser');

          if (!acceptedChallenge) {
            _this.toast('challenge.give_up_error');
          } else {
            acceptedChallenge.set('hidden', true);
            acceptedChallenge.save({
              adapterOptions: {
                saveOnly: ['hidden']
              }
            }).then(function () {
              var id = acceptedChallenge.belongsTo('challenge').id();

              _this.challenges.userProfileAccepted.removeChallenge(id);

              _this.store.query('user-accepted-challenge', {
                where: [['challengeAccepted', '==', acceptedChallenge.id]]
              }).then(function (items) {
                if (items && items.length) {
                  items.forEach(function (item) {
                    if (item) {
                      item.set('hidden', true);
                      item.save({
                        adapterOptions: {
                          saveOnly: ['hidden']
                        }
                      });
                    }
                  });
                }
              });

              _this.toast('challenge.give_up_success');
            }, function () {
              _this.toast('challenge.give_up_error');
            });
          }
        });
      }
    }
  });

  _exports.default = _default;
});