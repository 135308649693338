define("challingo-mobile/templates/users/edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "H35RKMl1",
    "block": "{\"symbols\":[],\"statements\":[[1,[27,\"nav-bar\",null,[[\"back\",\"title\"],[\"back\",[27,\"t\",[\"profile_menu.edit\"],null]]]],false],[0,\"\\n\\n\"],[1,[27,\"forms/user-edit-form\",null,[[\"media\",\"user\"],[[23,[\"model\",\"media\"]],[23,[\"model\",\"user\"]]]]],false],[0,\"\\n\\n\"],[1,[21,\"bottom-bar\"],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "challingo-mobile/templates/users/edit.hbs"
    }
  });

  _exports.default = _default;
});