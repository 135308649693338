define("challingo-mobile/app", ["exports", "@sentry/integrations", "@sentry/browser", "challingo-mobile/resolver", "challingo-mobile/config/environment", "ember-load-initializers"], function (_exports, Integrations, Sentry, _resolver, _environment, _emberLoadInitializers) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var App = Ember.Application.extend({
    modulePrefix: _environment.default.modulePrefix,
    podModulePrefix: _environment.default.podModulePrefix,
    Resolver: _resolver.default
  });

  if (_environment.default.environment !== 'development' && Sentry) {
    Sentry.default.init({
      dsn: _environment.default.sentryDsn,
      integrations: [new Integrations.Ember(), new Integrations.CaptureConsole({
        levels: ['error']
      })],
      environment: _environment.default.environment,
      release: _environment.default.APP.version,
      debug: _environment.default.environment === 'development',
      beforeSend: function beforeSend(event, hint) {
        var error = hint && hint.originalException;

        if (error && error.name === 'TransitionAborted') {
          return null;
        }

        return event;
      }
    });
  }

  (0, _emberLoadInitializers.default)(App, _environment.default.modulePrefix);
  var _default = App;
  _exports.default = _default;
});