define("challingo-mobile/mixins/scroll-events", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    scrollTarget: null,
    scrollHandler: null,
    scrollSetup: function scrollSetup(target) {
      this.set('scrollTarget', target);
    },
    didInsertElement: function didInsertElement() {
      var _this = this;

      this._super.apply(this, arguments);

      var previousScroll = 0;
      this.set('scrollHandler', function (event) {
        var scrollTop = event.target.scrollTop;
        var direction = scrollTop > previousScroll ? 'down' : 'up';

        _this.scroll(scrollTop, direction, previousScroll);

        previousScroll = scrollTop;
      });
      var el = document.querySelector(this.scrollTarget);

      if (el) {
        el.addEventListener('scroll', this.scrollHandler);
      }
    },
    willDestroyElement: function willDestroyElement() {
      var el = document.querySelector(this.scrollTarget);

      if (el) {
        el.removeEventListener('scroll', this.scrollHandler);
      }
    }
  });

  _exports.default = _default;
});