define("challingo-mobile/models/challenge-virtual", ["exports", "challingo-mobile/models/challenge"], function (_exports, _challenge) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _challenge.default.extend({});

  _exports.default = _default;
});