define("challingo-mobile/templates/components/forms/feedback-form", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "wLNPqQPk",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"textarea-style\"],[9],[0,\"\\n  \"],[1,[27,\"textarea\",null,[[\"value\",\"placeholder\",\"required\",\"rows\"],[[23,[\"message\"]],[27,\"t\",[\"feedback.type\"],null],true,\"6\"]]],false],[0,\"\\n\"],[10],[0,\"\\n\\n\"],[7,\"div\"],[11,\"class\",\"action-bar\"],[9],[0,\"\\n  \"],[7,\"button\"],[11,\"class\",\"btn btn-action\"],[12,\"disabled\",[21,\"loading\"]],[11,\"style\",\"touch-action: manipulation; -ms-touch-action: manipulation; cursor: pointer;\"],[11,\"type\",\"submit\"],[9],[0,\"\\n    \"],[1,[27,\"t\",[\"send\"],null],false],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "challingo-mobile/templates/components/forms/feedback-form.hbs"
    }
  });

  _exports.default = _default;
});