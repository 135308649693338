define("challingo-mobile/components/scroll-restore", ["exports", "challingo-mobile/config/environment", "challingo-mobile/utils/get-storable-url"], function (_exports, _environment, _getStorableUrl) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var getTarget = function getTarget(t) {
    return typeof t === 'string' ? document.querySelector(t) : t;
  };

  var _default = Ember.Component.extend({
    config: _environment.default,
    configLocationType: Ember.computed.alias('config.locationType'),
    defaultScrollPosition: 'top',
    page: 1,
    scrollData: Ember.Object.create({
      position: 0,
      pageUrl: ''
    }),
    scrollDataPageUrl: Ember.computed.alias('scrollData.pageUrl'),
    scrollDataPosition: Ember.computed.alias('scrollData.position'),
    target: window,
    timeout: null,
    useQueryParams: false,
    useUpdateAttrs: false,
    _initPageScroll: function _initPageScroll() {
      var url = (0, _getStorableUrl.default)(this.configLocationType, this.useQueryParams);
      this.set('scrollData.pageUrl', url);

      var scrollData = this._positionLSData();

      var target = getTarget(this.target);

      var func = function func() {
        target && target.scrollTo && target.scrollTo({
          top: scrollData.position
        });
      };

      this.scrollData.setProperties(scrollData);
      this.set('page', scrollData.page);

      if (scrollData) {
        var to = this.timeout;

        if (to) {
          Ember.run.later(func, to);
        } else {
          Ember.run.next(func);
        }
      }
    },
    didUpdateAttrs: function didUpdateAttrs() {
      if (this.useUpdateAttrs) {
        this._initPageScroll();
      }
    },
    didInsertElement: function didInsertElement() {
      var _this = this;

      this._initPageScroll();

      var target = getTarget(this.target);
      this.set('scrollHandler', function () {
        _this._positionLSData(true);

        _this.set('scrollData.position', target.scrollTop);
      });
      target && target.addEventListener('scroll', this.scrollHandler);
    },
    willDestroyElement: function willDestroyElement() {
      var target = getTarget(this.target);
      target && target.removeEventListener('scroll', this.scrollHandler);
    },
    _positionLSData: function _positionLSData(update) {
      var data = JSON.parse(window['challigo_scroll_data'] || '{}');
      var url = this.scrollDataPageUrl;
      var origData = Ember.Object.create(data[url]);

      if (update) {
        var newData = {
          page: this.page
        };
        var scrollTop = this.scrollDataPosition;

        if (scrollTop) {
          newData['position'] = scrollTop;
        }

        data[url] = {};
        Ember.assign(data[url], origData, newData);
        window['challigo_scroll_data'] = JSON.stringify(data);
      }

      var defaultScroll = 0;

      if (this.defaultScrollPosition !== 'top') {
        var target = getTarget(this.target);
        defaultScroll = target.scrollHeight;
      }

      return data[url] || {
        page: 1,
        position: defaultScroll,
        pageUrl: url
      };
    }
  });

  _exports.default = _default;
});