define("challingo-mobile/components/media/media-upload", ["exports", "challingo-mobile/config/environment", "challingo-mobile/utils/logger", "challingo-mobile/utils/detect-mobile-os", "challingo-mobile/utils/log-analytics-event", "challingo-mobile/utils/jquery-polyfills", "challingo-mobile/utils/status-bar"], function (_exports, _environment, _logger, _detectMobileOs, _logAnalyticsEvent, _jqueryPolyfills, _statusBar) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var getSubmitButton = function getSubmitButton(el) {
    var p = (0, _jqueryPolyfills.parents)(el, 'form');

    if (p && p.length) {
      return p[0].querySelector('[type="submit"]');
    } else {
      return null;
    }
  };

  var _default = Ember.Component.extend({
    cordova: Ember.inject.service(),
    currentUser: Ember.inject.service(),
    firebaseApp: Ember.inject.service(),
    intl: Ember.inject.service(),
    toastMessage: Ember.inject.service(),
    permissions: Object.freeze(['READ_EXTERNAL_STORAGE', 'WRITE_EXTERNAL_STORAGE']),
    classNames: ['media-upload'],
    classNameBindings: ['media.url:selected'],
    media: null,
    accept: 'image/*,video/*',
    maxSize: Object.freeze({
      image: 20000,
      video: 100000
    }),
    // kilobytes
    duration: 60,
    // seconds
    publicId: null,
    deleteToken: null,
    required: true,
    userProfile: false,
    hasChildren: false,
    showCreatingHeading: false,
    progress: 0,
    headingText: null,
    progressStyle: Ember.computed('progress', function () {
      return Ember.String.htmlSafe("width: ".concat(this.progress, "%;"));
    }),
    error: '',
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);

      if (!this.get('media.url') && this.deleteToken) {
        this.send('remove');
      }
    },
    _upload: function _upload(file, type) {
      var _this2 = this;

      var submit = getSubmitButton(this.element);

      if (submit) {
        submit.classList.add('disabled');
      }

      var success = function success(result) {
        if (_this2.media && !_this2.media.isDeleted) {
          _this2.media.setProperties({
            url: result.secure_url,
            type: result.resource_type,
            width: result.width,
            height: result.height
          });
        }

        if (!_this2.isDestroyed && !_this2.isDestroying) {
          _this2.setProperties({
            publicId: result.public_id,
            deleteToken: result.delete_token
          });

          _this2.set('progress', 0);
        }

        if (submit) {
          submit.classList.remove('disabled');
        }
      };

      var error = function error() {
        if (!_this2.isDestroyed && !_this2.isDestroying) {
          _this2.set('progress', 0);
        }

        if (submit) {
          submit.classList.remove('disabled');
        }

        _this2.toastMessage.show(_this2.intl.t("media.".concat(type, "_upload_error")));
      };

      var formData = new FormData();
      formData.append('file', file);
      formData.append('upload_preset', _environment.default.cloudinary.uploadPreset[type]);
      var xhr = new window.XMLHttpRequest();
      xhr.upload.addEventListener('progress', function (event) {
        if (!_this2.isDestroyed && !_this2.isDestroying && Number.isFinite(event.loaded) && Number.isFinite(event.total) && event.total !== 0) {
          _this2.set('progress', Math.round(event.loaded / event.total * 100));
        }
      }, false);

      xhr.onreadystatechange = function () {
        if (xhr.readyState === 4) {
          if (xhr.status === 200) {
            var result = JSON.parse(xhr.responseText);
            success(result);
          } else {
            error();
          }
        }
      };

      xhr.open('POST', _environment.default.cloudinary.url + '/upload');
      xhr.send(formData);
    },
    _fileUpload: function _fileUpload(fileData) {
      var _this3 = this;

      var type = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'video';
      var offset = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 50;

      if (typeof fileData.file === 'function') {
        fileData.file(function (f) {
          _this3._fileUploadRest(f, type, offset);
        }, function (error) {
          _logger.default.error(error);
        });
      } else {
        this._loadFile(fileData.localURL, false).then(function (f) {
          _this3._fileUploadRest(f, type, offset);
        }, function (error) {
          _logger.default.error(error);
        });
      }
    },
    _fileUploadRest: function _fileUploadRest(fileData, type) {
      var _this4 = this;

      var submit = getSubmitButton(this.element);

      var success = function success(resultString) {
        var result = JSON.parse(resultString);

        if (_this4.media && !_this4.media.isDeleted) {
          _this4.media.setProperties({
            url: result.secure_url,
            type: result.resource_type,
            width: result.width,
            height: result.height
          });
        }

        if (!_this4.isDestroyed && !_this4.isDestroying) {
          _this4.setProperties({
            publicId: result.public_id,
            deleteToken: result.delete_token
          });

          _this4.set('progress', 0);
        }

        if (submit) {
          submit.classList.remove('disabled');
        }
      };

      var fail = function fail(error) {
        if (!_this4.isDestroyed && !_this4.isDestroying) {
          _this4.set('progress', 0);
        }

        if (submit) {
          submit.classList.remove('disabled');
        }

        _this4.toastMessage.show(_this4.intl.t("media.".concat(type, "_upload_error")));

        _logger.default.error(error);
      };

      var getProgressHandler = function getProgressHandler() {
        var offset = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 0;
        var div = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 1;
        return function (progressEvent) {
          if (!_this.isDestroyed && !_this.isDestroying) {
            if (Number.isFinite(progressEvent.loaded) && Number.isFinite(progressEvent.total) && progressEvent.total !== 0) {
              _this.set('progress', Math.round(progressEvent.loaded / progressEvent.total * 100 / div + offset));
            }
          }
        };
      };

      var reader = new FileReader();

      var _this = this;

      reader.onloadend = function () {
        var blob = new Blob([new Uint8Array(this.result)], {
          type: fileData.type
        });
        var oReq = new XMLHttpRequest();
        var uri = encodeURI(_environment.default.cloudinary.url + '/upload');
        oReq.open('POST', uri, true);

        oReq.onload = function () {
          success(oReq.response);
        };

        oReq.upload.addEventListener('progress', getProgressHandler(50, 2));
        oReq.addEventListener('error', fail); // options.fileName = fileURL.substr(fileURL.lastIndexOf('/') + 1);

        var formData = new FormData();
        formData.append('upload_preset', _environment.default.cloudinary.uploadPreset[type]);
        formData.append('file', blob);
        oReq.send(formData);
      };

      reader.onprogress = getProgressHandler(0, 2);
      reader.readAsArrayBuffer(fileData);
    },
    _transcodeVideo: function _transcodeVideo(fileData, fileInfo) {
      var _this5 = this;

      _logger.default.log('input', fileData);

      var originalWidth = fileInfo.width;
      var originalHeight = fileInfo.height;
      var targetWidth = 1280;
      var targetHeight = 1280;

      if (originalHeight > originalWidth) {
        var targetRatio = targetHeight / originalHeight;
        targetWidth = Math.round(originalWidth * targetRatio / 2) * 2;
        targetHeight = Math.round(originalHeight * targetRatio / 2) * 2;
      }

      return new Ember.RSVP.Promise(function (resolve, reject) {
        // parameters passed to transcodeVideo
        window.VideoEditor.transcodeVideo(function (transcodedVideoUrl) {
          _this5._loadFile(transcodedVideoUrl).then(function (file) {
            _logger.default.log('output', file);

            resolve(file);
          });
        }, function (e) {
          reject(e);
        }, {
          fileUri: fileData.toURL ? fileData.toURL() : fileData.fullPath,
          outputFileName: "video-".concat(Date.now()),
          outputFileType: window.VideoEditorOptions.OutputFileType.MPEG4,
          optimizeForNetworkUse: window.VideoEditorOptions.OptimizeForNetworkUse.YES,
          saveToLibrary: false,
          maintainAspectRatio: true,
          width: targetWidth,
          height: targetHeight,
          videoBitrate: 1000000,
          // 1 megabit
          audioChannels: 2,
          audioSampleRate: 44100,
          audioBitrate: 128000,
          // 128 kilobits
          progress: function progress(info) {
            if (!_this5.isDestroyed && !_this5.isDestroying) {
              _this5.set('progress', Math.round((info < 1 ? info * 100 : info) / 2));
            }
          }
        });
      });
    },
    _loadFile: function _loadFile(url) {
      var addPrefix = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : true;
      return new Ember.RSVP.Promise(function (resolve, reject) {
        window.resolveLocalFileSystemURL(addPrefix ? "file://".concat(url) : url, function (fileEntry) {
          fileEntry.file(resolve, reject);
        }, reject);
      });
    },
    _processVideo: function _processVideo(file) {
      var _this6 = this;

      var maxSize = this.maxSize['video'];
      var duration = this.duration;
      return new Ember.RSVP.Promise(function (resolve, reject) {
        _this6._getVideoInfo(file).then(function (info) {
          _logger.default.log('info', info);

          if (info.duration <= duration + 5) {
            // 5s tolerance
            if (file.size > maxSize * 1000) {
              (0, _logAnalyticsEvent.default)(_this6.firebaseApp, 'video_transcoded', {
                duration: info.duration,
                file_size: file.size,
                user_id: _this6.get('currentUser.uid')
              });

              _this6._transcodeVideo(file, info).then(function (fileData) {
                if (fileData.size < maxSize * 1000) {
                  _this6._fileUpload(fileData, 'video');

                  resolve(fileData);
                } else {
                  if (!_this6.isDestroyed && !_this6.isDestroying) {
                    _this6.set('error', "".concat(_this6.intl.t('media.max_size_error'), " ").concat(maxSize / 1000, " MB."));
                  }

                  reject();
                }
              }, function (e) {
                _logger.default.error(e);

                _this6.set('progress', 0);

                if (file.size > maxSize) {
                  if (!_this6.isDestroyed && !_this6.isDestroying) {
                    _this6.set('error', "".concat(_this6.intl.t('media.max_size_error'), " ").concat(maxSize / 1000, " MB."));
                  }

                  reject(e);
                } else {
                  _this6._fileUpload(file, 'video', 0);

                  resolve(file);
                }
              });
            } else {
              _this6._fileUpload(file, 'video', 0);

              resolve(file);
            }
          } else {
            if (!_this6.isDestroyed && !_this6.isDestroying) {
              _this6.set('error', "".concat(_this6.intl.t('video.max_length_error'), " ").concat(duration, " sec."));
            }
          }
        }, function () {
          if (!_this6.isDestroyed && !_this6.isDestroying) {
            _this6.set('error', _this6.intl.t('media.video_upload_error'));
          }
        });
      });
    },
    _getVideoInfo: function _getVideoInfo(fileObject) {
      return new Ember.RSVP.Promise(function (resolve, reject) {
        window.VideoEditor.getVideoInfo(resolve, reject, {
          fileUri: fileObject.toURL ? fileObject.toURL() : fileObject.fullPath
        });
      });
    },
    _requestPermissions: function _requestPermissions() {
      var _this7 = this;

      return new Ember.RSVP.Promise(function (resolve, reject) {
        if (window.cordova && window.cordova.plugins && window.cordova.plugins.permissions) {
          var androidPerms = window.cordova.plugins.permissions;

          var requiredPerms = _this7.permissions.map(function (permission) {
            return androidPerms[permission];
          });

          androidPerms.requestPermissions(requiredPerms, function (data) {
            if (data.hasPermission) {
              resolve();
            } else {
              _logger.default.debug(data);

              reject(Error('Permission denied!'));
            }
          }, function (error) {
            _logger.default.debug(error);

            reject(error);
          });
        } else {
          reject(Error('Plugin missing!'));
        }
      });
    },
    actions: {
      emptyFile: function emptyFile() {
        return event.target.setCustomValidity(this.intl.t('file.errors.required'));
      },
      remove: function remove() {
        var _this8 = this;

        var error = function error() {
          _this8.toastMessage.show(_this8.intl.t('media.remove_error'));
        };

        var complete = function complete() {
          _this8.media.resetUrl();

          if (!_this8.isDestroyed && !_this8.isDestroying) {
            _this8.setProperties({
              deleteToken: null,
              publicId: null
            });
          }
        };

        fetch(_environment.default.cloudinary.url + '/delete_by_token', {
          body: JSON.stringify({
            public_id: this.publicId,
            token: this.deleteToken
          }),
          headers: {
            'Content-Type': 'application/json'
          },
          method: 'POST'
        }).then(function (response) {
          if (response.status !== 200) {
            error();
          } else {
            complete();
          }
        }).catch(error);
      },
      captureVideo: function captureVideo() {
        var _this9 = this;

        if (!this.isDestroyed && !this.isDestroying) {
          this.set('error', '');
        }

        this._requestPermissions().then(function () {
          navigator.device.capture.captureVideo(function (data) {
            (0, _detectMobileOs.default)() === 'iOS' && (0, _statusBar.resetStatusBar)();
            var file = data[0];
            Ember.run.later(function () {
              var submit = getSubmitButton(_this9.element);

              if (submit) {
                submit.classList.add('disabled');
              }

              _this9._processVideo(file).catch(function () {
                if (!_this9.isDestroyed && !_this9.isDestroying) {
                  _this9.set('progress', 0);
                }

                if (submit) {
                  submit.classList.remove('disabled');
                }

                _this9.toastMessage.show(_this9.intl.t('media.video_upload_error'));
              });
            }, 300);
          }, function (e) {
            _logger.default.error(e);
          }, {
            duration: _this9.duration,
            quality: 1,
            ios_quality: 'high'
          });
        }, function (e) {
          _logger.default.error(e);
        });
      },
      captureImage: function captureImage() {
        var _this10 = this;

        if (!this.isDestroyed && !this.isDestroying) {
          this.set('error', '');
        }

        this._requestPermissions().then(function () {
          navigator.camera.getPicture(function (fileURI) {
            (0, _detectMobileOs.default)() === 'iOS' && (0, _statusBar.resetStatusBar)();

            if (fileURI) {
              window.resolveLocalFileSystemURL((fileURI.indexOf('://') !== -1 ? '' : 'file://') + fileURI, function (fileEntry) {
                fileEntry.file(function (fileObj) {
                  fileEntry['size'] = fileObj.size;

                  _this10._fileUpload(fileEntry, 'image');
                }, function (error) {
                  _logger.default.error(error);
                });
              }, function (error) {
                _logger.default.error(error);
              });
            } else {
              if (!_this10.isDestroyed && !_this10.isDestroying) {
                _this10.set('error', _this10.intl.t('media.upload_error'));
              }
            }
          }, function (error) {
            _logger.default.error(error);
          }, {
            correctOrientation: true,
            destinationType: navigator.camera.DestinationType.NATIVE_URI,
            mediaType: navigator.camera.MediaType.PICTURE,
            quality: 99,
            sourceType: navigator.camera.PictureSourceType.CAMERA
          });
        }, function (e) {
          _logger.default.error(e);
        });
      },
      addFromGallery: function addFromGallery() {
        var _this11 = this;

        if (!this.isDestroyed && !this.isDestroying) {
          this.set('error', '');
        }

        navigator.camera.getPicture(function (fileURI) {
          (0, _detectMobileOs.default)() === 'iOS' && (0, _statusBar.resetStatusBar)();

          if (fileURI) {
            window.resolveLocalFileSystemURL((fileURI.indexOf('://') !== -1 ? '' : 'file://') + fileURI, function (fileEntry) {
              fileEntry.file(function (fileObj) {
                fileEntry['size'] = fileObj.size;
                var type = fileObj.type.split('/').shift();

                if (type === 'image') {
                  _this11._fileUpload(fileEntry, 'image');
                } else if (type === 'video') {
                  _this11._processVideo(fileEntry);
                }
              }, function (error) {
                _logger.default.error(error);
              });
            }, function (error) {
              _logger.default.error(error);
            });
          } else {
            if (!_this11.isDestroyed && !_this11.isDestroying) {
              _this11.set('error', _this11.intl.t('media.upload_error'));
            }
          }
        }, function (error) {
          _logger.default.error(error);
        }, {
          correctOrientation: true,
          destinationType: navigator.camera.DestinationType.NATIVE_URI,
          sourceType: navigator.camera.PictureSourceType.PHOTOLIBRARY,
          mediaType: navigator.camera.MediaType.ALLMEDIA
        });
      },
      fileChange: function fileChange(event) {
        if (!this.isDestroyed && !this.isDestroying) {
          this.set('error', '');
        }

        var file = event.target.files[0],
            type = file.type.split('/').shift(),
            maxSize = this.maxSize[type];

        if (file && file.size / 1000 > maxSize) {
          if (!this.isDestroyed && !this.isDestroying) {
            this.set('error', "".concat(this.intl.t('media.max_size_error'), " ").concat(maxSize / 1000, " MB."));
          }

          event.target.value = '';
          return;
        }

        if (type === 'image') {
          this._upload(file, type);
        } else if (type === 'video') {
          var _this = this,
              duration = this.duration,
              video = document.createElement('video');

          video.preload = 'metadata';

          video.onloadedmetadata = function () {
            window.URL.revokeObjectURL(this.src);

            if (video.duration > duration) {
              if (!_this.isDestroyed && !_this.isDestroying) {
                _this.set('error', "".concat(_this.get('intl').t('video.max_length_error'), " ").concat(duration, " sec."));
              }

              event.target.value = '';
            } else {
              _this._upload(file, type);
            }
          };

          video.src = window.URL.createObjectURL(file);
        } else {
          if (!this.isDestroyed && !this.isDestroying) {
            this.set('error', this.intl.t('media.type_error'));
          }

          event.target.value = '';
        }
      }
    }
  });

  _exports.default = _default;
});