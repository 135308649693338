define("challingo-mobile/components/notifications-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    confirmDialog: Ember.inject.service(),
    intl: Ember.inject.service(),
    router: Ember.inject.service(),
    store: Ember.inject.service(),
    classNameBindings: ['hasDelete'],
    classNames: ['notifications-list'],
    hasDelete: false,
    notifications: null,
    tagName: 'table',
    actions: {
      delete: function _delete(notification) {
        notification.destroyRecord();
      },
      toDetail: function toDetail(challenge, challengeAcceptedId) {
        this.router.transitionTo('challenges.show', challenge, {
          queryParams: {
            challengeAcceptedId: challengeAcceptedId,
            miniProfile: null
          }
        });
      },
      follow: function follow(userId) {
        this.store.findRecord('user', userId).then(function (user) {
          Ember.tryInvoke(user, 'follow');
        });
      },
      unfollow: function unfollow(userId) {
        var _this = this;

        this.store.findRecord('user', userId).then(function (user) {
          _this.confirmDialog.show(_this.intl.t('follow.delete_following'), function () {
            Ember.tryInvoke(user, 'unfollow');
          });
        });
      }
    }
  });

  _exports.default = _default;
});