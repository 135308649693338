define("challingo-mobile/templates/components/media/media-preview", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "pZwSXiU4",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[23,[\"isOpen\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\"],[12,\"aria-label\",[27,\"t\",[\"aria-labels.close\"],null]],[11,\"class\",\"close\"],[11,\"role\",\"button\"],[11,\"style\",\"touch-action: manipulation; -ms-touch-action: manipulation; cursor: pointer;\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"circle\"],[9],[0,\"\\n      \"],[7,\"i\"],[11,\"class\",\"challingo-icons ci-close\"],[9],[10],[0,\"\\n    \"],[10],[0,\"\\n  \"],[3,\"action\",[[22,0,[]],\"close\"]],[10],[0,\"\\n\\n\"],[4,\"if\",[[23,[\"media\",\"isPhoto\"]]],null,{\"statements\":[[4,\"pinch-zoom\",null,[[\"conditionalPinch\"],[false]],{\"statements\":[[0,\"      \"],[7,\"img\"],[12,\"alt\",[27,\"t\",[\"aria-labels.close\"],null]],[12,\"aria-label\",[27,\"t\",[\"aria-labels.close\"],null]],[11,\"class\",\"photo\"],[11,\"role\",\"button\"],[12,\"src\",[23,[\"media\",\"photo\"]]],[11,\"style\",\"touch-action: manipulation; -ms-touch-action: manipulation; cursor: pointer;\"],[9],[3,\"action\",[[22,0,[]],\"close\"]],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[23,[\"media\",\"isVideo\"]]],null,{\"statements\":[[0,\"    \"],[7,\"div\"],[11,\"class\",\"video-cover\"],[9],[0,\"\\n      \"],[7,\"video\"],[11,\"controls\",\"\"],[11,\"autoplay\",\"\"],[11,\"playsinline\",\"\"],[12,\"poster\",[23,[\"media\",\"photo\"]]],[9],[0,\"\\n        \"],[1,[27,\"video-side-blured\",null,[[\"src\"],[[23,[\"media\",\"video\"]]]]],false],[0,\"\\n      \"],[10],[0,\"\\n    \"],[10],[0,\"\\n  \"]],\"parameters\":[]},null]],\"parameters\":[]}],[0,\"  \"],[1,[27,\"cordova-back-button-handler\",null,[[\"action\"],[[27,\"action\",[[22,0,[]],[27,\"mut\",[[23,[\"media\"]]],null],null],null]]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "challingo-mobile/templates/components/media/media-preview.hbs"
    }
  });

  _exports.default = _default;
});