define("challingo-mobile/templates/hashtag", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "gPOElxd3",
    "block": "{\"symbols\":[],\"statements\":[[1,[27,\"hashtag-page\",null,[[\"hashtag\",\"model\"],[[23,[\"hashtag\"]],[23,[\"model\"]]]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "challingo-mobile/templates/hashtag.hbs"
    }
  });

  _exports.default = _default;
});