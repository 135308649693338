define("challingo-mobile/models/category", ["exports", "ember-data", "challingo-mobile/routes/application"], function (_exports, _emberData, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    currentUser: Ember.inject.service(),
    name_cs: _emberData.default.attr('string'),
    name_en: _emberData.default.attr('string'),
    name: Ember.computed('currentUser.user.localeAugmented', '{name_cs,name_en}', function () {
      var locale = this.get('currentUser.user.localeAugmented') || _application.defaultLocale;

      return this.get("name_".concat(locale));
    })
  });

  _exports.default = _default;
});