define("challingo-mobile/templates/components/star-rating", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "KhDoP/24",
    "block": "{\"symbols\":[\"star\"],\"statements\":[[4,\"each\",[[23,[\"stars\"]]],null,{\"statements\":[[0,\"  \"],[7,\"label\"],[9],[0,\"\\n    \"],[7,\"img\"],[11,\"alt\",\"\"],[11,\"role\",\"none\"],[12,\"src\",[28,[\"assets/images/svg/ico-star-\",[27,\"if\",[[27,\"lte\",[[22,1,[]],[23,[\"displaySelected\"]]],null],\"active\",\"empty\"],null],\".svg\"]]],[9],[10],[0,\"\\n\\n    \"],[1,[27,\"input\",null,[[\"type\",\"name\",\"value\",\"required\",\"click\"],[\"radio\",\"rating\",[22,1,[]],true,[27,\"action\",[[22,0,[]],\"select\"],null]]]],false],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "challingo-mobile/templates/components/star-rating.hbs"
    }
  });

  _exports.default = _default;
});