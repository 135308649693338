define("challingo-mobile/models/push-notification", ["exports", "ember-data", "challingo-mobile/models/notification"], function (_exports, _emberData, _notification) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _notification.default.extend({
    acceptedChallenge: _emberData.default.belongsTo('accepted-challenge', {
      inverse: null
    }),
    challenge: _emberData.default.belongsTo('challenge', {
      inverse: null
    }),
    fromUser: _emberData.default.belongsTo('user', {
      inverse: null
    }),
    forUser: _emberData.default.belongsTo('user', {
      inverse: null
    }),
    isReceived: _emberData.default.attr('boolean', {
      defaultValue: false
    }),
    type: _emberData.default.attr('string'),
    hidden: Ember.computed('acceptedChallenge.hidden', 'challenge.hidden', function () {
      return this.get('acceptedChallenge.hidden') || this.get('challenge.hidden') || false;
    })
  });

  _exports.default = _default;
});