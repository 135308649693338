define("challingo-mobile/adapters/application", ["exports", "emberfire/adapters/firestore", "ember-inflector"], function (_exports, _firestore, _emberInflector) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  function _iterableToArrayLimit(arr, i) { var _i = arr && (typeof Symbol !== "undefined" && arr[Symbol.iterator] || arr["@@iterator"]); if (_i == null) return; var _arr = []; var _n = true; var _d = false; var _s, _e; try { for (_i = _i.call(arr); !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  var _default = _firestore.default.extend({
    // enablePersistence: true,
    // persistenceSettings: { synchronizeTabs: true },
    // settings: { timestampsInSnapshots: true }
    query: function query(store, type, options) {
      var _this = this;

      _emberInflector.default.inflector.plural(/^(userWall)$/, '$1');

      return (0, _firestore.rootCollection)(this, type).then(function (collection) {
        return queryDocs(collection, queryOptionsToQueryFn(options));
      }).then(function (q) {
        return includeCollectionRelationships(q, store, _this, options, type);
      });
    }
  });

  _exports.default = _default;

  var isQueryOnly = function isQueryOnly(arg) {
    return arg.query !== undefined;
  };

  var isWhereOp = function isWhereOp(arg) {
    return typeof arg[0] === "string" || arg[0].length === undefined;
  };

  var noop = function noop(ref) {
    return ref;
  };

  var getDocs = function getDocs(query) {
    return query.get();
  };

  var queryDocs = function queryDocs(referenceOrQuery, query) {
    return getDocs((query || noop)(referenceOrQuery));
  };

  var queryOptionsToQueryFn = function queryOptionsToQueryFn(options) {
    return function (collectionRef) {
      var ref = collectionRef;

      if (options) {
        if (isQueryOnly(options)) {
          return options.query(collectionRef);
        }

        if (options.filter) {
          Object.keys(options.filter).forEach(function (field) {
            ref = ref.where(field, '==', options.filter[field]);
          });
        }

        if (options.where) {
          var runWhereOp = function runWhereOp(_ref) {
            var _ref2 = _slicedToArray(_ref, 3),
                field = _ref2[0],
                op = _ref2[1],
                value = _ref2[2];

            return ref = ref.where(field, op, value);
          };

          if (isWhereOp(options.where)) {
            runWhereOp(options.where);
          } else {
            options.where.forEach(runWhereOp);
          }
        }

        if (options.orderBy) {
          if (typeof options.orderBy === "string") {
            ref = ref.orderBy(options.orderBy);
          } else {
            Object.keys(options.orderBy).forEach(function (field) {
              ref = ref.orderBy(field, options.orderBy[field]);
            });
          }
        }

        if (options.startAt) {
          if (Array.isArray(options.startAt)) {
            var _ref3;

            ref = (_ref3 = ref).startAt.apply(_ref3, _toConsumableArray(options.startAt));
          } else {
            ref = ref.startAt(options.startAt);
          }
        }

        if (options.startAfter) {
          if (Array.isArray(options.startAfter)) {
            var _ref4;

            ref = (_ref4 = ref).startAfter.apply(_ref4, _toConsumableArray(options.startAfter));
          } else {
            ref = ref.startAfter(options.startAfter);
          }
        }

        if (options.endAt) {
          if (Array.isArray(options.endAt)) {
            var _ref5;

            ref = (_ref5 = ref).endAt.apply(_ref5, _toConsumableArray(options.endAt));
          } else {
            ref = ref.endAt(options.endAt);
          }
        }

        if (options.endBefore) {
          if (Array.isArray(options.endBefore)) {
            var _ref6;

            ref = (_ref6 = ref).endBefore.apply(_ref6, _toConsumableArray(options.endBefore));
          } else {
            ref = ref.endBefore(options.endBefore);
          }
        }

        if (options.limit) {
          ref = ref.limit(options.limit);
        }
      }

      return ref;
    };
  };

  var includeCollectionRelationships = function includeCollectionRelationships(collection, store, adapter, snapshot, type) {
    if (snapshot && snapshot.include) {
      var includes = snapshot.include.split(',');
      var relationshipsToInclude = includes.map(function (e) {
        return type.relationshipsByName.get(e);
      }).filter(function (r) {
        return !!r && !r.options.embedded;
      });
      return Promise.all(relationshipsToInclude.map(function (r) {
        if (r.meta.kind == 'hasMany') {
          return Promise.all(collection.docs.map(function (d) {
            return adapter.findHasMany(store, {
              id: d.id
            }, '', r);
          }));
        } else {
          var belongsToIds = _toConsumableArray(new Set(collection.docs.map(function (d) {
            return d.data()[r.meta.key];
          }).filter(function (id) {
            return !!id;
          })));

          return Promise.all(belongsToIds.map(function (id) {
            return adapter.findBelongsTo(store, {
              id: id
            }, '', r);
          }));
        }
      })).then(function (allIncludes) {
        relationshipsToInclude.forEach(function (r, i) {
          var relationship = r.meta;
          var pluralKey = (0, _emberInflector.pluralize)(relationship.key);
          var key = relationship.kind == 'belongsTo' ? relationship.key : pluralKey;
          var includes = allIncludes[i];
          collection.docs.forEach(function (doc) {
            if (relationship.kind == 'belongsTo') {
              var result = includes.find(function (r) {
                return r.id == doc.data()[key];
              });

              if (result) {
                if (!doc._document._included) {
                  doc._document._included = {};
                }

                doc._document._included[key] = result;
              }
            } else {
              if (!doc._document._included) {
                doc._document._included = {};
              }

              doc._document._included[pluralKey] = includes;
            }
          });
        });
        return collection;
      });
    } else {
      return Ember.RSVP.resolve(collection);
    }
  };
});