define("challingo-mobile/utils/save-comment", ["exports", "challingo-mobile/utils/hashtags"], function (_exports, _hashtags) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  function _default(context, _ref) {
    var pushCondition = _ref.pushCondition,
        sendPushTo = _ref.sendPushTo;
    var item = context.item;
    var body = (context.newCommentBody || context.searchInput || '').trim();
    var user = context.get('currentUser.user');

    if (!body) {
      return false;
    }

    context.set('loading', true);
    var comment = context.store.createRecord('comment', {
      body: body,
      user: user
    });
    item.get('comments').pushObject(comment);
    comment.save().then(function () {
      (0, _hashtags.loadNecessaryHashtags)(context.store, body, '').then(function (hashtags) {
        var promises = [item.save({
          adapterOptions: {
            saveOnly: ['comments']
          }
        })].concat(_toConsumableArray((0, _hashtags.saveHashtags)(body, '', null, null, hashtags, context.store)));
        Ember.RSVP.all(promises).then(function () {
          context.scrollToBottom();
          context.setProperties({
            newCommentBody: '',
            loading: false
          });
        });
      });
    });

    if (pushCondition) {
      context.pushNotification.send(sendPushTo, 'comment', context.intl.t('push-notifications.comment', {
        locale: sendPushTo && sendPushTo.get('localeAugmented')
      }), context.get('currentUser.user'), context.isChallengeAccepted ? null : context.challenge, context.challengeAccepted);
    }

    Ember.RSVP.all(context.taggedUsers.uniqBy('id').map(function (tagUser) {
      return context.store.findRecord('user', tagUser.id);
    })).then(function (tagUsers) {
      tagUsers.forEach(function (tagUser) {
        context.pushNotification.send(tagUser, 'mention', context.intl.t('push-notifications.mention', {
          locale: tagUser && tagUser.get('localeAugmented')
        }), context.get('currentUser.user'), context.isChallengeAccepted ? null : context.challenge, context.challengeAccepted);
      });
    });
    context.setProperties({
      searchInput: '',
      taggedUsers: []
    });
    return false;
  }
});