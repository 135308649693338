define("challingo-mobile/components/management-chally", ["exports", "challingo-mobile/utils/logger", "emberfire/services/realtime-listener"], function (_exports, _logger, _realtimeListener) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    currentUser: Ember.inject.service(),
    intl: Ember.inject.service(),
    modal: Ember.inject.service(),
    router: Ember.inject.service(),
    session: Ember.inject.service(),
    store: Ember.inject.service(),
    lastChallyLikes: Ember.computed('lastChally.{challengeAccepted.likes,challenge.likes}', function () {
      return this.get('lastChally.challengeAccepted.likes') || this.get('lastChally.challenge.likes') || 0;
    }),
    user: Ember.computed.alias('currentUser.user'),
    youHaveToWait: Ember.computed.alias('currentUser.youHaveToWait'),
    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);

      this.store.query('user-challenge', {
        limit: 1,
        orderBy: {
          'createdAt': 'desc'
        },
        where: [['hidden', '==', false], ['user', '==', this.get('currentUser.uid')]]
      }).then(function (results) {
        if (results && results.length && !_this.isDestroyed && !_this.isDestroying) {
          var item = results.get('firstObject');
          (0, _realtimeListener.subscribe)(_this, item);

          _this.set('lastChally', item);
        }
      }, function (e) {
        _logger.default.log(e);
      });
    },
    actions: {
      acceptRandomChally: function acceptRandomChally() {
        if (!this.get('session.isAuthenticated')) {
          this.modal.show('modals/login-popup', null, {
            noStyle: true
          });
          Ember.tryInvoke(this, 'onClose');
          return;
        }

        if (this.get('youHaveToWait')) {
          return;
        }

        this.modal.show('challenges/new-chally-accepted', null, {
          noStyle: true
        });
        Ember.tryInvoke(this, 'onClose');
      },
      goTo: function goTo(route) {
        this.router.transitionTo(route);
      }
    }
  });

  _exports.default = _default;
});