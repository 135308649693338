define("challingo-mobile/utils/challenges/searchall", ["exports", "challingo-mobile/utils/challenges/collection"], function (_exports, _collection) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _collection.default.extend({
    load: function load() {
      var _this = this;

      var callbackGenerator = function callbackGenerator(collection, dateAttr) {
        return function (items) {
          if (items && items.length) {
            // Preload media
            items.forEach(function (item) {
              return item.get('media');
            });

            var collectionArr = _this.get(collection);

            var itemsArray = items.toArray();
            collectionArr.pushObjects(itemsArray);
            var filtered = itemsArray.filter(function (item) {
              return item.get(dateAttr);
            });

            if (filtered && filtered.length) {
              var last = filtered[filtered.length - 1];
              var date = last.get(dateAttr);

              _this.set("startAfter.".concat(collection), date.toISOString());
            }
          }
        };
      };

      return Ember.RSVP.hash({
        challenges: this.store.query('challenge', {
          limit: this.get('perPage'),
          orderBy: {
            'createdAt': 'desc'
          },
          startAfter: this.get('startAfter.challenges'),
          where: [['hidden', '==', false]]
        }).then(callbackGenerator('challenges', 'createdAt')),
        challengesAccepted: this.store.query('accepted-challenge', {
          limit: this.get('perPage'),
          orderBy: {
            'completed': 'desc'
          },
          startAfter: this.get('startAfter.challengesAccepted'),
          where: [['hidden', '==', false]]
        }).then(callbackGenerator('challengesAccepted', 'completed'))
      });
    }
  });

  _exports.default = _default;
});