define("challingo-mobile/components/forms/settings-form", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    currentUser: Ember.inject.service(),
    intl: Ember.inject.service(),
    tagName: 'form',
    classNames: ['settings-form'],
    checkRadioLocale: function checkRadioLocale() {
      var el = this.element && this.element.querySelector("[value=\"".concat(this.get('intl.locale'), "\"]"));

      if (el) {
        el.checked = true;
      }
    },
    localeObserver: Ember.observer('intl.locale', function () {
      this.checkRadioLocale();
    }),
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      this.checkRadioLocale();
    },
    change: function change(event) {
      var locale = event.target.value;
      var user = this.get('currentUser.user');

      if (locale === 'cz') {
        locale = 'cs';
      }

      this.set('intl.locale', locale);

      _moment.default.locale(locale);

      user.set('locale', locale);
      user.save({
        adapterOptions: {
          saveOnly: ['locale']
        }
      });
    }
  });

  _exports.default = _default;
});