define("challingo-mobile/services/settings", ["exports", "challingo-mobile/utils/logger", "challingo-mobile/utils/semantic-version-check", "emberfire/services/realtime-listener"], function (_exports, _logger, _semanticVersionCheck, _realtimeListener) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    loadFailed: false,
    cordova: Ember.inject.service(),
    router: Ember.inject.service(),
    session: Ember.inject.service(),
    store: Ember.inject.service(),
    siteSettings: null,
    loadSettings: function loadSettings() {
      var _this = this;

      return this.store.findRecord('setting', 'site').then(function (settings) {
        _this.set('siteSettings', settings);

        (0, _realtimeListener.subscribe)(_this, settings);
        return settings;
      }, function (error) {
        _logger.default.error(error);

        _this.set('loadFailed', true);

        throw error;
      });
    },
    checkAppVersion: function checkAppVersion(normalOperation, errorHandler) {
      var _this2 = this;

      if (this.loadFailed || !this.siteSettings) {
        return errorHandler && errorHandler();
      }

      if (this.siteSettings.isInMaintenanceMode) {
        this.router.replaceWith('update-app');
      } else if (this.cordova.get('enabled')) {
        return window.cordova.getAppVersion.getVersionNumber().then(function (version) {
          if (version && _this2.siteSettings.minRequiredVersion && (0, _semanticVersionCheck.default)(version, _this2.siteSettings.minRequiredVersion) === -1) {
            _this2.router.replaceWith('update-app');
          } else {
            return normalOperation();
          }
        });
      } else {
        return normalOperation();
      }
    }
  });

  _exports.default = _default;
});