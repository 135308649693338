define("challingo-mobile/services/current-user", ["exports", "emberfire/services/realtime-listener", "challingo-mobile/routes/application", "moment"], function (_exports, _realtimeListener, _application, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    intl: Ember.inject.service(),
    session: Ember.inject.service(),
    store: Ember.inject.service(),
    uidReg: null,
    hasWebP: false,
    uid: Ember.computed('session.data.authenticated.user.uid', 'uidReg', function () {
      return this.get('session.data.authenticated.user.uid') || this.get('uidReg');
    }),
    data: null,
    drawnChallies: null,
    drawnChalliesChallyIds: Ember.computed('drawnChallies.@each.{challenge}', function () {
      return (this.get('drawnChallies') || []).map(function (chally) {
        return chally.belongsTo('challenge').id();
      });
    }),
    drawnChally: Ember.computed.alias('drawnChallies.firstObject'),
    deferred: false,
    youHaveToWait: Ember.computed('drawnChally.{challenge.id,createdAt}', 'deferred', function () {
      var challengeId = this.get('drawnChally.challenge.id');

      if (!challengeId) {
        return null;
      }

      var challenge = this.store.peekRecord('challenge', challengeId);

      if (challenge && challenge.get('isCompletedByUser')) {
        return null;
      }

      var date = this.get('drawnChally.createdAt') && (0, _moment.default)(this.get('drawnChally.createdAt'));
      var hours = date && date.add(1, 'day').diff((0, _moment.default)(), 'hours');
      return hours && hours >= 0 && hours <= 24 ? hours.toString() : null;
    }),
    user: Ember.computed('uid', 'data', function () {
      var _this = this;

      var data = this.data,
          uid = this.uid;

      if (!uid) {
        return null;
      } else if (data) {
        return data;
      }

      return this.store.findRecord('user', uid).then(function (current) {
        if (!current) {
          return null;
        }

        var locale = current.get('locale') || _application.defaultLocale;

        if (locale === 'cz') {
          locale = 'cs';
        }

        _this.setProperties({
          'intl.locale': locale,
          data: current
        });

        _moment.default.locale(locale);

        (0, _realtimeListener.subscribe)(_this, current);
        return current;
      });
    }),
    createdAt: Ember.computed('{uid,user}', function () {
      var user = this.get('user');
      return user && user.get ? user.get('createdAt') : null;
    }),
    _checkWebPSupport: function _checkWebPSupport() {
      var _this2 = this;

      var img = new Image();

      img.onload = function () {
        _this2.set('hasWebP', img.height > 0 && img.width > 0);
      };

      img.onerror = function () {
        _this2.set('hasWebP', false);
      };

      img.src = 'https://www.gstatic.com/webp/gallery/1.webp';
    },
    isAdmin: Ember.computed.bool('user.admin'),
    init: function init() {
      var _this3 = this;

      this._super.apply(this, arguments);

      this._checkWebPSupport();

      Ember.run.later(function () {
        _this3.set('deferred', true);
      }, 1000);
    },
    unload: function unload() {
      var _this4 = this;

      this.set('data', null);
      ['accepted-challenge', 'admin-notification', 'challenge', 'comment', 'device', 'media', 'notification', 'push-notification', 'user'].forEach(function (modelName) {
        _this4.store.peekAll(modelName).forEach(function (model) {
          model & model.unloadRecord();
        });
      });
      (0, _realtimeListener.unsubscribe)(this);
    }
  });

  _exports.default = _default;
});