define("challingo-mobile/templates/components/category-page", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "DTO81DeX",
    "block": "{\"symbols\":[\"list\"],\"statements\":[[1,[27,\"nav-bar\",null,[[\"back\",\"spacer\",\"title\"],[\"back\",false,[23,[\"model\",\"name\"]]]]],false],[0,\"\\n\\n\"],[4,\"lazy-loading-list\",null,[[\"items\",\"itemsPerPage\"],[[23,[\"challenges\",\"category\",\"all\"]],[23,[\"challenges\",\"perPage\"]]]],{\"statements\":[[0,\"  \"],[1,[27,\"challenges/challenges-explore-list\",null,[[\"challenges\",\"placeholder\",\"scrollObserverOnEnter\",\"showTitles\"],[[22,1,[]],[27,\"t\",[\"search.nochallyfound\"],null],[27,\"action\",[[22,0,[]],\"loadNextCategoryPage\"],null],true]]],false],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "challingo-mobile/templates/components/category-page.hbs"
    }
  });

  _exports.default = _default;
});