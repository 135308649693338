define("challingo-mobile/templates/challenges/comments", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "YFA8QISS",
    "block": "{\"symbols\":[],\"statements\":[[1,[27,\"nav-bar\",null,[[\"title\",\"back\",\"spacer\"],[[27,\"t\",[\"comments.few\"],null],\"back\",false]]],false],[0,\"\\n\\n\"],[1,[27,\"comments-page\",null,[[\"challenge\",\"challengeAcceptedId\"],[[23,[\"model\"]],[23,[\"challengeAcceptedId\"]]]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "challingo-mobile/templates/challenges/comments.hbs"
    }
  });

  _exports.default = _default;
});