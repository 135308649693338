define("challingo-mobile/components/video-side-blured", ["exports", "challingo-mobile/utils/jquery-polyfills"], function (_exports, _jqueryPolyfills) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'source',
    attributeBindings: ['urlSource:src'],
    width: null,
    height: null,
    urlSource: Ember.computed('{src,width,height}', function () {
      // const height = this.get('height');
      var url = this.get('src'); // const width = this.get('width');

      /*
      if (width && height) {
        return url.replace(
          'b_blurred:1000',
          `b_blurred:1000,h_${height},w_${width}`
        );
      }
      */

      return url;
    }),
    didInsertElement: function didInsertElement() {
      var _this = this;

      this._super.apply(this, arguments);

      Ember.run.scheduleOnce('afterRender', function () {
        var p = (0, _jqueryPolyfills.parents)(_this.element, 'video');

        if (p && p.length) {
          _this.setProperties({
            height: Math.floor(p[0].offsetHeight),
            width: Math.floor(p[0].offsetWidth)
          });
        }
      });
    }
  });

  _exports.default = _default;
});