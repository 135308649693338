define("challingo-mobile/templates/components/badges-view", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "gezyZSAl",
    "block": "{\"symbols\":[\"badge\"],\"statements\":[[4,\"each\",[[23,[\"config\",\"badgeData\"]]],null,{\"statements\":[[4,\"if\",[[27,\"get\",[[23,[\"user\",\"badgesEarned\"]],[22,1,[\"id\"]]],null]],null,{\"statements\":[[0,\"    \"],[7,\"div\"],[11,\"class\",\"badge\"],[9],[0,\"\\n      \"],[7,\"img\"],[11,\"class\",\"badge-img\"],[12,\"src\",[22,1,[\"image\"]]],[12,\"alt\",[27,\"t\",[[27,\"concat\",[\"badges.\",[22,1,[\"id\"]]],null]],null]],[9],[10],[0,\"\\n      \"],[7,\"div\"],[11,\"class\",\"badge-name\"],[9],[0,\"\\n        \"],[1,[27,\"t\",[[27,\"concat\",[\"badges.\",[22,1,[\"id\"]]],null]],null],false],[0,\"\\n      \"],[10],[0,\"\\n    \"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[1]},null],[0,\"\\n\"],[4,\"unless\",[[23,[\"user\",\"hasBadges\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\"],[11,\"class\",\"page-empty\"],[9],[0,\"\\n    \"],[7,\"i\"],[11,\"class\",\"challingo-icons ci-challenge\"],[9],[10],[0,\"\\n    \"],[7,\"div\"],[9],[0,\"\\n      \"],[1,[27,\"t\",[\"badges.no_badges\"],null],false],[0,\"\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "challingo-mobile/templates/components/badges-view.hbs"
    }
  });

  _exports.default = _default;
});