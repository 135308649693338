define("challingo-mobile/templates/components/forms/challenge-nominate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "z+wHe9O1",
    "block": "{\"symbols\":[\"user\"],\"statements\":[[7,\"div\"],[11,\"class\",\"liked-by-users\"],[9],[0,\"\\n\"],[4,\"each\",[[23,[\"model\",\"nominated\"]]],null,{\"statements\":[[0,\"    \"],[7,\"img\"],[11,\"alt\",\"\"],[11,\"class\",\"user\"],[11,\"role\",\"presentation\"],[12,\"src\",[22,1,[\"photoSmall\"]]],[9],[10],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"\\n  \"],[7,\"img\"],[12,\"alt\",[27,\"t\",[\"challenge.nominate\"],null]],[11,\"class\",\"user\"],[11,\"role\",\"button\"],[11,\"src\",\"assets/images/svg/user-plus.svg\"],[11,\"style\",\"touch-action: manipulation; -ms-touch-action: manipulation; cursor: pointer;\"],[9],[3,\"action\",[[22,0,[]],\"nominateUser\"]],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "challingo-mobile/templates/components/forms/challenge-nominate.hbs"
    }
  });

  _exports.default = _default;
});