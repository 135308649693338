define("challingo-mobile/components/slider-cards", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    router: Ember.inject.service(),
    classNames: ['slider-cards'],
    items: null,
    selected: null,
    challenge: null,
    showUser: true,
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      var cardSelected = this.element && this.element.querySelector('.card.selected');
      cardSelected && cardSelected.scrollIntoView(false);
    },
    actions: {
      toDetail: function toDetail(item) {
        this.router.transitionTo({
          queryParams: {
            challengeAcceptedId: item.get('id') === this.get('challenge.id') ? null : item.get('id')
          }
        });
      }
    }
  });

  _exports.default = _default;
});