define("challingo-mobile/templates/components/update-page", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "p18Ndn1B",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"update-app-text\"],[9],[0,\"\\n  \"],[7,\"img\"],[11,\"src\",\"assets/images/logo-welcome-3x.png\"],[12,\"alt\",[27,\"t\",[\"welcome-home.logoAlt\"],null]],[11,\"width\",\"220\"],[11,\"height\",\"48\"],[9],[10],[0,\"\\n  \"],[7,\"h1\"],[9],[1,[21,\"title\"],false],[10],[0,\"\\n  \"],[7,\"p\"],[9],[1,[21,\"message\"],false],[10],[0,\"\\n\\n\"],[4,\"if\",[[23,[\"showAppButton\"]]],null,{\"statements\":[[0,\"    \"],[7,\"button\"],[11,\"class\",\"switchtoapp__button\"],[11,\"style\",\"touch-action: manipulation; -ms-touch-action: manipulation; cursor: pointer;\"],[11,\"type\",\"button\"],[9],[0,\"\\n      \"],[1,[27,\"t\",[\"login_popup.switch.button\"],null],false],[0,\"\\n    \"],[3,\"action\",[[22,0,[]],\"switchToApp\"]],[10],[0,\"\\n\"]],\"parameters\":[]},null],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "challingo-mobile/templates/components/update-page.hbs"
    }
  });

  _exports.default = _default;
});