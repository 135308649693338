define("challingo-mobile/services/bookmark", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    hash: '',
    page: Ember.computed('hash', function () {
      return this.hash.replace(/^(#\/)/, '').split('/').join('.') || 'back';
    }),
    save: function save() {
      this.set('hash', location.hash);
    }
  });

  _exports.default = _default;
});