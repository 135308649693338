define("challingo-mobile/routes/profile", ["exports", "challingo-mobile/mixins/remember-scroll"], function (_exports, _rememberScroll) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_rememberScroll.default, {});

  _exports.default = _default;
});