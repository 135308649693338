define("challingo-mobile/components/challenges/challenge-detail", ["exports", "challingo-mobile/utils/jquery-polyfills", "challingo-mobile/mixins/swipe-events", "challingo-mobile/components/welcome-page", "challingo-mobile/utils/save-comment"], function (_exports, _jqueryPolyfills, _swipeEvents, _welcomePage, _saveComment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_swipeEvents.default, {
    confirmDialog: Ember.inject.service(),
    currentUser: Ember.inject.service(),
    intl: Ember.inject.service(),
    modal: Ember.inject.service(),
    okMessage: Ember.inject.service(),
    pushNotification: Ember.inject.service(),
    router: Ember.inject.service(),
    session: Ember.inject.service(),
    store: Ember.inject.service(),
    toastMessage: Ember.inject.service(),
    distanceLimit: 60,
    classNames: ['challenge-detail'],
    challenge: null,
    challengeAcceptedId: null,
    challengeAccepted: Ember.computed('challenge.accepted.[]', 'challengeAcceptedId', function () {
      return this.get('challenge.accepted').findBy('id', this.challengeAcceptedId);
    }),
    selectedChallenge: Ember.computed('challengeAccepted', 'challengeAcceptedId', function () {
      return this.challengeAcceptedId ? this.challengeAccepted : this.challenge;
    }),
    isChallengeAccepted: Ember.computed.bool('challengeAcceptedId'),
    isChallenge: Ember.computed.not('isChallengeAccepted'),
    item: Ember.computed('isChallenge', 'challenge', 'challengeAccepted', function () {
      var item = this.isChallenge ? this.challenge : this.challengeAccepted;
      Ember.tryInvoke(item, 'loadLikes');
      return item;
    }),
    toComments: false,
    user: Ember.computed.alias('currentUser.user'),
    userCreator: Ember.computed('isChallenge', 'challenge.creator', 'challengeAccepted.user', function () {
      return this.isChallenge ? this.get('challenge.creator') : this.get('challengeAccepted.user');
    }),
    isCreator: Ember.computed('user.id', 'challenge.creator.id', function () {
      return this.get('user.id') === this.get('challenge.creator.id');
    }),
    sortedSliderCards: Ember.computed('challenge.likes', 'challenge.completed.@each.{sorting,likes,hidden}', function () {
      var out = this.get('challenge.completed').slice();
      out.pushObject(this.challenge);
      return out.uniqBy('id').sortBy('sorting').reverse().sortBy('likes').filterBy('hidden', false).reverse();
    }),
    kingsChallenge: Ember.computed.alias('sortedSliderCards.firstObject'),
    withoutKings: Ember.computed('sortedSliderCards.[]', 'kingsChallenge', function () {
      var out = this.sortedSliderCards.slice();
      return out.removeObject(this.kingsChallenge).slice(0, 50);
    }),
    withoutAuthor: Ember.computed('sortedSliderCards.[]', function () {
      var out = this.sortedSliderCards.slice();
      var challengeId = this.get('challenge.id');
      return out.filter(function (item) {
        return item.id !== challengeId;
      });
    }),
    completedChallengeNext: function completedChallengeNext() {
      var index = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 0;
      var withoutAuthor = this.withoutAuthor;

      if (index == -1 && this.get('selectedChallenge.id') === this.get('challenge.id')) {
        history.back();
      } else if (Ember.isPresent(withoutAuthor)) {
        var completedIds = withoutAuthor.mapBy('id');
        var acceptedIndex = completedIds.indexOf(this.challengeAcceptedId || this.get('challenge.id'));
        var challengeAtIndex = completedIds.objectAt(acceptedIndex + index);
        var isChallenge = challengeAtIndex === this.get('challenge.id');
        var nextChallenge = challengeAtIndex || null;
        this.set('challengeAcceptedId', isChallenge ? null : nextChallenge);
      }
    },
    hasAction: Ember.computed('challenge.acceptedByUser.completed', 'isCreator', function () {
      return !(this.get('challenge.acceptedByUser.completed') || this.isCreator);
    }),
    loading: false,
    taggedUsers: null,
    init: function init() {
      this._super.apply(this, arguments);

      this.setProperties({
        taggedUsers: []
      });
    },
    resetInputSize: function resetInputSize() {
      if (autosize) {
        var input = this.element && this.element.querySelector('textarea');
        input && autosize.update(input);
      }
    },
    swipeLeft: function swipeLeft() {
      if (event && event.target) {
        var p = (0, _jqueryPolyfills.parents)(event.target, '.slider-cards');

        if (!p || !p.length) {
          this.completedChallengeNext(1);
        }
      }
    },
    swipeRight: function swipeRight() {
      if (event && event.target) {
        var p = (0, _jqueryPolyfills.parents)(event.target, '.slider-cards');

        if (!p || !p.length) {
          this.completedChallengeNext(-1);
        }
      }
    },
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      (0, _welcomePage.hideSplashscreen)();

      if (this.scrollToDescription === 'true') {
        var pageEl = document.body;
        var descEl = document.getElementById('detail-description');

        if (pageEl && descEl) {
          var os = (0, _jqueryPolyfills.offset)(descEl);

          if (os && os.top) {
            pageEl.scrollTo(0, os.top);
          }
        }
      }

      if (this.toComments) {
        this.scrollToBottom();
      }
    },
    scrollToBottom: function scrollToBottom() {
      Ember.run.scheduleOnce('afterRender', function () {
        var pageEl = document.body;
        Ember.tryInvoke(pageEl, 'scrollTo', [0, pageEl.offsetHeight]);
      });
    },
    submit: function submit() {
      var _this = this;

      var promise = (0, _saveComment.default)(this, {
        pushCondition: !this.get('isCreator'),
        sendPushTo: this.userCreator
      });
      Ember.run.next(function () {
        _this.resetInputSize();
      });
      return promise;
    },
    actions: {
      accept: function accept() {
        var _this2 = this;

        Ember.tryInvoke(this.challenge, 'accept', [function () {
          _this2.set('loading', true);
        }, function () {
          if (!_this2.isDestroyed && !_this2.isDestroying) {
            _this2.set('loading', false);
          }
        }]);
      },
      complete: function complete() {
        this.router.transitionTo('challenge.complete', this.get('challenge.acceptedByUser.id'));
      },
      newCommentClick: function newCommentClick() {
        if (!this.get('session.isAuthenticated')) {
          this.modal.show('modals/login-popup', null, {
            noStyle: true
          });
          return;
        }
      },
      toChallenge: function toChallenge(challenge) {
        this.router.transitionTo({
          queryParams: {
            challengeAcceptedId: challenge.get('id') === this.get('challenge.id') ? null : challenge.get('id')
          }
        });
      },
      toComments: function toComments() {
        this.router.transitionTo('challenges.comments', this.challenge, {
          queryParams: {
            challengeAcceptedId: this.get('challengeAccepted.id')
          }
        });
      },
      toLikedBy: function toLikedBy() {
        this.router.transitionTo('challenges.likedby', this.challenge, {
          queryParams: {
            challengeAcceptedId: this.get('challengeAccepted.id')
          }
        });
      },
      toNominated: function toNominated() {
        this.router.transitionTo('challenges.nominated', this.challenge, {
          queryParams: {
            challengeAcceptedId: this.get('challengeAccepted.id')
          }
        });
      },
      showHashtag: function showHashtag() {
        if (event && event.target) {
          if (event.target.classList.contains('hashtag')) {
            var hashtagRaw = event.target.innerHTML;

            if (hashtagRaw.match(/^#/)) {
              var hashtag = hashtagRaw.substr(1).toLowerCase();
              this.router.transitionTo('hashtag', hashtag);
            }
          }

          if (event.target.classList.contains('tag-name')) {
            var matches = event.target.getAttribute('href').match(/#\/profile\/(.+)/);

            if (matches && matches.length) {
              var userId = matches[1];
              this.router.transitionTo('profile.show', userId);
            }
          }
        }
      }
    }
  });

  _exports.default = _default;
});