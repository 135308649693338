define("challingo-mobile/templates/components/comments-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "rPg0ws51",
    "block": "{\"symbols\":[\"item\"],\"statements\":[[7,\"div\"],[12,\"class\",[27,\"if\",[[23,[\"commentPage\"]],\"comment-page-offset\"],null]],[9],[0,\"\\n\"],[4,\"each\",[[23,[\"list\"]]],null,{\"statements\":[[0,\"    \"],[1,[27,\"comment-item\",null,[[\"item\",\"onDelete\"],[[22,1,[]],[27,\"action\",[[22,0,[]],\"deleteComment\",[22,1,[]]],null]]]],false],[0,\"\\n\"]],\"parameters\":[1]},{\"statements\":[[0,\"    \"],[7,\"div\"],[11,\"class\",\"page-empty\"],[9],[0,\"\\n      \"],[7,\"i\"],[11,\"class\",\"challingo-icons ci-comment\"],[9],[10],[0,\"\\n      \"],[7,\"div\"],[9],[1,[27,\"t\",[\"comments.empty_list\"],null],false],[10],[0,\"\\n    \"],[10],[0,\"\\n\"]],\"parameters\":[]}],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "challingo-mobile/templates/components/comments-list.hbs"
    }
  });

  _exports.default = _default;
});