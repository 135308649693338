define("challingo-mobile/components/comments-page", ["exports", "challingo-mobile/mixins/swipe-events", "challingo-mobile/utils/save-comment"], function (_exports, _swipeEvents, _saveComment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_swipeEvents.default, {
    currentUser: Ember.inject.service(),
    intl: Ember.inject.service(),
    modal: Ember.inject.service(),
    pushNotification: Ember.inject.service(),
    session: Ember.inject.service(),
    store: Ember.inject.service(),
    classNames: ['comments-page'],
    challenge: null,
    challengeAcceptedId: null,
    searchInput: '',
    newCommentBody: '',
    loading: false,
    taggedUsers: null,
    init: function init() {
      this._super.apply(this, arguments);

      this.setProperties({
        taggedUsers: []
      });
    },
    newCommentBodyWithTags: '',
    challengeAccepted: Ember.computed('challenge.accepted.[]', 'challengeAcceptedId', function () {
      return this.get('challenge.accepted').findBy('id', this.challengeAcceptedId);
    }),
    item: Ember.computed('challenge', 'challengeAccepted', function () {
      return this.challengeAcceptedId ? this.challengeAccepted : this.challenge;
    }),
    isChallengeAccepted: Ember.computed.equal('item.type', 'accepted-challenge'),
    isChallenge: Ember.computed.equal('item.type', 'challenge'),
    user: Ember.computed('isChallenge', 'challenge.creator', 'challengeAccepted.user', function () {
      return this.isChallenge ? this.get('challenge.creator') : this.get('challengeAccepted.user');
    }),
    resetInputSize: function resetInputSize() {
      if (autosize) {
        var input = this.element && this.element.querySelector('textarea');
        input && autosize.update(input);
      }
    },
    scrollToBottom: function scrollToBottom() {
      var _this = this;

      Ember.run.scheduleOnce('afterRender', function () {
        var el = _this.element && _this.element.querySelector('.page-fixed');

        if (el) {
          el.scrollTo(0, _this.element && _this.element.querySelector('.comments-list').offsetHeight || 0);
        }
      });
    },
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      this.scrollToBottom();
    },
    submit: function submit() {
      var _this2 = this;

      var promise = (0, _saveComment.default)(this, {
        pushCondition: true,
        sendPushTo: this.user
      });
      Ember.run.next(function () {
        _this2.resetInputSize();
      });
      return promise;
    },
    swipeLeft: function swipeLeft() {},
    swipeRight: function swipeRight() {
      history.back();
    },
    actions: {
      newCommentClick: function newCommentClick() {
        if (!this.get('session.isAuthenticated')) {
          this.modal.show('modals/login-popup', null, {
            noStyle: true
          });
          return;
        }
      }
    }
  });

  _exports.default = _default;
});