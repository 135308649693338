define("challingo-mobile/components/users-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    router: Ember.inject.service(),
    classNames: ['users-list'],
    headingText: 'users',
    marginBottom: true,
    users: null,
    lengthMinus2: Ember.computed('users.length', function () {
      return this.get('users.length') - 2;
    }),
    actions: {
      transitionTo: function transitionTo() {
        var _this$router;

        (_this$router = this.router).transitionTo.apply(_this$router, arguments);
      }
    }
  });

  _exports.default = _default;
});