define("challingo-mobile/components/nav-bar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    currentUser: Ember.inject.service(),
    modal: Ember.inject.service(),
    router: Ember.inject.service(),
    session: Ember.inject.service(),
    back: '',
    hasCalendar: false,
    messages: false,
    spacer: true,
    title: '',
    transitionTo: '',
    transitionToDefault: 'challenges',
    transparent: false,
    unreadDMCount: 0,
    dmLastActivity: Ember.computed.alias('currentUser.user.dmLastActivity'),
    dmActivityObserver: Ember.observer('dmLastActivity', function () {
      var _this = this;

      var user = this.get('currentUser.user');

      if (user && user.getUnreadDMCount) {
        user.getUnreadDMCount().then(function (count) {
          _this.set('unreadDMCount', count);
        });
      }
    }),
    actions: {
      back: function back() {
        var _this2 = this;

        if (this.transitionTo) {
          this.send('transitionTo', this.transitionTo);
        } else {
          var prevLocation = location.href;
          history.back();
          Ember.run.later(function () {
            var currentLocation = location.href;

            if (currentLocation === prevLocation && !_this2.isDestroyed && !_this2.isDestroying) {
              _this2.send('transitionTo', _this2.transitionToDefault);
            }
          }, 250);
        }
      },
      checkLoginDM: function checkLoginDM() {
        if (!this.get('session.isAuthenticated')) {
          this.modal.show('modals/login-popup', null, {
            goBack: true,
            noStyle: true
          });
        }
      },
      openCalendar: function openCalendar() {
        if (!this.get('session.isAuthenticated')) {
          this.modal.show('modals/login-popup', null, {
            goBack: true,
            noStyle: true
          });
          return;
        }

        this.send('transitionTo', 'calendar');
      },
      transitionTo: function transitionTo() {
        var _this$router;

        (_this$router = this.router).transitionTo.apply(_this$router, arguments);
      }
    }
  });

  _exports.default = _default;
});