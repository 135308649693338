define("challingo-mobile/templates/challenges", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "NQhlFtSJ",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[27,\"or\",[[27,\"eq\",[[23,[\"router\",\"currentRouteName\"]],\"challenges.index\"],null],[27,\"eq\",[[23,[\"routeAtSetup\"]],\"challenges.index\"],null]],null]],null,{\"statements\":[[0,\"  \"],[1,[27,\"challenges/challenges-page\",null,[[\"categories\",\"isPageHidden\"],[[23,[\"model\",\"categories\"]],[27,\"not\",[[27,\"eq\",[[23,[\"router\",\"currentRouteName\"]],\"challenges.index\"],null]],null]]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[1,[21,\"outlet\"],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "challingo-mobile/templates/challenges.hbs"
    }
  });

  _exports.default = _default;
});