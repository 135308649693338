define("challingo-mobile/templates/components/comment-item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Ah53Ty51",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"user\"],[9],[0,\"\\n\"],[4,\"link-to\",[\"profile.show\",[23,[\"item\",\"user\",\"id\"]]],null,{\"statements\":[[0,\"    \"],[7,\"div\"],[11,\"class\",\"photo\"],[12,\"style\",[27,\"background-image\",[[23,[\"item\",\"user\",\"photoSmall\"]]],null]],[9],[10],[0,\"\\n\"]],\"parameters\":[]},null],[10],[0,\"\\n\"],[7,\"div\"],[11,\"class\",\"content\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"heading-line\"],[9],[0,\"\\n    \"],[7,\"span\"],[11,\"class\",\"name\"],[9],[0,\"\\n      \"],[4,\"link-to\",[\"profile.show\",[23,[\"item\",\"user\",\"id\"]]],null,{\"statements\":[[0,\" \"],[1,[23,[\"item\",\"user\",\"name\"]],false],[0,\" \"]],\"parameters\":[]},null],[0,\"\\n    \"],[10],[0,\"\\n\"],[4,\"if\",[[23,[\"item\",\"createdAt\"]]],null,{\"statements\":[[0,\"      \"],[7,\"span\"],[11,\"class\",\"time\"],[9],[0,\"\\n        \"],[1,[27,\"moment-from-now\",[[23,[\"item\",\"createdAt\"]]],[[\"interval\"],[1000]]],false],[0,\"\\n      \"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[10],[0,\"\\n  \"],[7,\"p\"],[11,\"class\",\"body\"],[9],[1,[27,\"tag2link\",[[23,[\"item\",\"body\"]]],null],false],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "challingo-mobile/templates/components/comment-item.hbs"
    }
  });

  _exports.default = _default;
});