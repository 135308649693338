define("challingo-mobile/templates/components/page-slider", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "gDyBjdgy",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"div\"],[12,\"class\",[28,[\"page-rail\",[27,\"if\",[[23,[\"moving\"]],\" moving\"],null]]]],[12,\"style\",[21,\"railStyle\"]],[9],[0,\"\\n  \"],[14,1],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "challingo-mobile/templates/components/page-slider.hbs"
    }
  });

  _exports.default = _default;
});