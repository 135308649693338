define("challingo-mobile/routes/challenges/accepted", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    challenges: Ember.inject.service(),
    currentUser: Ember.inject.service(),
    model: function model() {
      var user = this.get('currentUser.user');
      this.challenges.userProfileAccepted.resetUser(user);
      return this.challenges.userProfileAccepted.load();
    }
  });

  _exports.default = _default;
});