define("challingo-mobile/components/bottom-bar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.scrollHtmlBody = void 0;
  var scrollOptions = {
    behavior: 'smooth',
    top: 0
  };

  var scrollHtmlBody = function scrollHtmlBody() {
    Array.from(document.querySelectorAll('html,body')).forEach(function (node) {
      node.scrollTo(scrollOptions);
    });
  };

  _exports.scrollHtmlBody = scrollHtmlBody;

  var scrollBySelector = function scrollBySelector(selector) {
    var el = document.querySelector(selector);

    if (el) {
      el.scrollTo(scrollOptions);
    }
  };

  var _default = Ember.Component.extend({
    currentUser: Ember.inject.service(),
    modal: Ember.inject.service(),
    pushNotification: Ember.inject.service(),
    router: Ember.inject.service(),
    session: Ember.inject.service(),
    spacer: false,
    isOpenedManagement: false,
    transparent: false,
    actions: {
      openManagement: function openManagement() {
        var _this = this;

        var callback = function callback() {
          _this.toggleProperty('isOpenedManagement');
        };

        if (!this.get('session.isAuthenticated')) {
          this.modal.show('modals/login-popup', null, {
            goBack: true,
            noStyle: true
          });
          return;
        }

        if (this.isOpenedManagement) {
          var el = this.element && this.element.querySelector('.management-chally');

          if (el) {
            el.classList.add('close-animation');
          }

          Ember.run.later(callback, 300);
        } else {
          callback();
        }
      },
      challengesScrollTop: function challengesScrollTop() {
        if (this.get('router.currentRouteName') === 'challenges.index') {
          scrollBySelector('.page-slide.active .lazy-loading-list');
        }
      },
      closeManagement: function closeManagement() {
        this.set('isOpenedManagement', false);
      },
      searchScrollTop: function searchScrollTop() {
        if (this.get('router.currentRouteName') === 'search') {
          if (document.querySelector('.search-page .users-list')) {
            scrollHtmlBody();
          }

          var listNode = document.querySelector('.page-slide.active .lazy-loading-list') || document.querySelector('.lazy-loading-list');

          if (listNode) {
            listNode.scrollTo(scrollOptions);
          }
        }
      },
      notificationScrollTop: function notificationScrollTop() {
        if (!this.get('session.isAuthenticated')) {
          this.modal.show('modals/login-popup', null, {
            goBack: true,
            noStyle: true
          });
        }

        if (this.get('router.currentRouteName') === 'notification') {
          scrollBySelector('.lazy-loading-list');
        }
      },
      profileScrollTop: function profileScrollTop() {
        if (!this.get('session.isAuthenticated')) {
          this.modal.show('modals/login-popup', null, {
            goBack: true,
            noStyle: true
          });
        }

        if (this.get('router.currentRouteName') === 'profile.show') {
          scrollHtmlBody();
        }
      }
    }
  });

  _exports.default = _default;
});