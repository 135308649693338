define("challingo-mobile/routes/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    settings: Ember.inject.service(),
    beforeModel: function beforeModel() {
      var _this = this;

      var op = function op() {
        _this.replaceWith('challenges', {
          queryParams: {
            miniProfile: null
          }
        });
      };

      return this.settings.checkAppVersion(op, op);
    }
  });

  _exports.default = _default;
});