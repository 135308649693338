define("challingo-mobile/components/user-tag", ["exports", "challingo-mobile/utils/fulltext-search"], function (_exports, _fulltextSearch) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    searchInput: '',
    required: false,
    placeholder: 'comments.new_placeholder',
    multiline: false,
    searchParts: null,
    searchText: '',
    selected: null,
    lastUserNames: null,
    selectedUsername: null,
    selectedHT: null,
    lastHashtags: null,
    selectedHashtag: null,
    doSuggestUsers: true,
    doSuggestHashtags: true,
    maximumLength: 140,
    _data: null,
    _dataHT: null,
    textWrapperObserver: Ember.observer('searchInput', function () {
      var _this2 = this;

      var searchInput = this.searchInput || '';
      var searchParts = searchInput.split(/(@(?:[\0-\x08\x0E-\x1F!-\x9F\xA1-\u167F\u1681-\u1FFF\u200B-\u2027\u202A-\u202E\u2030-\u205E\u2060-\u2FFF\u3001-\uD7FF\uE000-\uFEFE\uFF00-\uFFFF]|[\uD800-\uDBFF][\uDC00-\uDFFF]|[\uD800-\uDBFF](?![\uDC00-\uDFFF])|(?:[^\uD800-\uDBFF]|^)[\uDC00-\uDFFF])+)/gi);

      var _loop = function _loop(index) {
        var searchPart = searchParts[index];

        if (searchPart.indexOf('@') > -1) {
          var username = searchPart.substr(1);

          _this2.set('searchText', '');

          _this2._loadUser(username).then(function (data) {
            return data.findBy('nameTrim', username);
          }).then(function (user) {
            if (user) {
              searchParts[index] = "".concat(searchPart, "{").concat(user.id, "}");

              _this2.set('searchParts', searchParts);

              searchInput = searchParts.join('');

              _this2.set('searchText', searchInput);
            }
          });
        }
      };

      for (var index = 0; index < searchParts.length; index++) {
        _loop(index);
      }
    }),
    _setTimer: function _setTimer(f) {
      this.set('_timer', f);
    },
    _setData: function _setData(data) {
      this.set('_data', data);
    },
    _setDataHT: function _setDataHT(data) {
      this.set('_dataHT', data);
    },
    suggestedUsers: Ember.computed('_data', 'selectedUsername', function () {
      var _this3 = this;

      var userNameHash = this.selectedUsername;

      if (this._data) {
        return this._data;
      }

      if (userNameHash && userNameHash.nick) {
        Ember.run.cancel(this._timer);

        this._setTimer(Ember.run.later(function () {
          _this3._loadUser(userNameHash.nick).then(function (data) {
            _this3._setData(data);
          });
        }, 300));
      }

      return null;
    }),
    suggestedHashtags: Ember.computed('_dataHT', 'selectedHashtag', function () {
      var _this4 = this;

      var hashtagHash = this.selectedHashtag;

      if (this._dataHT) {
        return this._dataHT;
      }

      if (hashtagHash && hashtagHash.hashtag) {
        Ember.run.cancel(this._timer);

        this._setTimer(Ember.run.later(function () {
          _this4._loadHashtag(hashtagHash.hashtag).then(function (data) {
            _this4._setDataHT(data);
          });
        }, 300));
      }

      return null;
    }),
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      var _this = this;

      if (this.get('multiline') && this.get('autosize') && autosize) {
        var input = this.element && this.element.querySelector('textarea');
        input && autosize(input);
      }

      var inputHandler = function inputHandler(e) {
        // array of hash - nickname, index of nickname beggining
        var userNames = _this._getNicknames(e.target.value);

        var caretPosition = this.selectionStart;
        var selectedUsername = userNames.find(function (usernameHash) {
          return usernameHash.start <= caretPosition && caretPosition <= usernameHash.end;
        });

        _this.set('selectedUsername', selectedUsername);

        _this.set('_data', null);

        var hashTags = _this._getHashtags(e.target.value);

        var selectedHashtag = hashTags.find(function (hashtagHash) {
          return hashtagHash.start <= caretPosition && caretPosition <= hashtagHash.end;
        });

        _this.set('selectedHashtag', selectedHashtag);

        _this.set('_dataHT', null);
      };

      var events = ['keyup', 'mouseup'];
      var inputs = this.element && this.element.querySelectorAll('input,textarea');
      inputs && Array.from(inputs).forEach(function (input) {
        events.forEach(function (eventName) {
          input.addEventListener(eventName, inputHandler);
        });
      });
    },
    actions: {
      selectUser: function selectUser(user) {
        var _this5 = this;

        var name = user.name;
        var userNameHash = this.selectedUsername;

        if (userNameHash) {
          this.selected.pushObject(user);
          var searchInput = this.searchInput;
          var replacedText = searchInput.substr(0, userNameHash.start) + "@".concat(name) + searchInput.substr(userNameHash.end) + ' ';
          this.set('searchInput', replacedText);
        }

        this.setProperties({
          _data: null,
          selectedUsername: null
        });
        Ember.run.scheduleOnce('afterRender', function () {
          var input = _this5.get('element').querySelector("textarea") || _this5.get('element').querySelector("input");

          input && input.focus();
        });
      },
      selectHashtag: function selectHashtag(hashtag) {
        var _this6 = this;

        var name = hashtag.tagName;
        var hashtagHash = this.selectedHashtag;

        if (hashtagHash) {
          var searchInput = this.searchInput;
          var replacedText = searchInput.substr(0, hashtagHash.start) + "#".concat(name) + searchInput.substr(hashtagHash.end) + ' ';
          this.set('searchInput', replacedText);
        }

        this.setProperties({
          _dataHT: null,
          selectedHashtag: null
        });
        Ember.run.scheduleOnce('afterRender', function () {
          var input = _this6.get('element').querySelector("textarea") || _this6.get('element').querySelector("input");

          input && input.focus();
        });
      }
    },
    _getNicknames: function _getNicknames(inputText) {
      var regex = /(?:^|\s)(?:@)(\S+)/g;
      var matches = [];
      var match;

      while (match = regex.exec(inputText)) {
        var start = match[0].match(/^\s/) ? match.index + 1 : match.index;
        matches.push({
          tag: match[0].replace(/\s/, ''),
          nick: match[1],
          start: start,
          end: start + match[1].length + 1,
          //with @
          length: match[1].length
        });
      }

      return matches;
    },
    _loadUser: function _loadUser(userName) {
      var options = {
        attributesToRetrieve: ['name', 'mediaUrl'],
        hitsPerPage: 50
      };
      return (0, _fulltextSearch.default)(userName, ['users'], options).then(function (data) {
        var out = (data.results[0].hits || []).map(function (item) {
          return Object.assign(item, {
            id: item.objectID,
            nameTrim: (item.name || '').trim(),
            photoSmall: (item.mediaUrl || '').replace('/upload', '/upload/c_thumb,h_112,w_112')
          });
        }).filter(function (item) {
          return item.nameTrim.toLowerCase().includes(userName.toLowerCase());
        });
        return out;
      }, function () {});
    },
    _getHashtags: function _getHashtags(inputText) {
      var regex = /(?:^|\s)(?:#)(\S+)/g;
      var matches = [];
      var match;

      while (match = regex.exec(inputText)) {
        var start = match[0].match(/^\s/) ? match.index + 1 : match.index;
        matches.push({
          tag: match[0].replace(/\s/, ''),
          hashtag: match[1],
          start: start,
          end: start + match[1].length + 1,
          //with #
          length: match[1].length
        });
      }

      return matches;
    },
    _loadHashtag: function _loadHashtag(hashtag) {
      var options = {
        hitsPerPage: 50
      };
      return (0, _fulltextSearch.default)(hashtag, ['hashtags'], options).then(function (data) {
        var out = (data.results[0].hits || []).map(function (item) {
          var url = (item.mediaUrl || '').replace('/upload', '/upload/c_thumb,h_112,w_112/so_0');
          return Object.assign(item, {
            id: item.objectID,
            challyCount: item._total || 0,
            photoSmall: url.substr(0, url.lastIndexOf('.')) + '.jpg'
          });
        }).filter(function (item) {
          return item.tagName.toLowerCase().includes(hashtag.toLowerCase());
        });
        return out;
      }, function () {});
    }
  });

  _exports.default = _default;
});