define("challingo-mobile/services/cordova", ["exports", "challingo-mobile/utils/logger", "challingo-mobile/utils/detect-mobile-os", "challingo-mobile/utils/status-bar"], function (_exports, _logger, _detectMobileOs, _statusBar) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _typeof(obj) { "@babel/helpers - typeof"; if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

  var _default = Ember.Service.extend({
    currentUser: Ember.inject.service(),
    router: Ember.inject.service(),
    session: Ember.inject.service(),
    store: Ember.inject.service(),
    enabled: false,
    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);

      this.set('enabled', this._isCordova());

      if (window.cordova) {
        document.addEventListener('deviceready', function () {
          _logger.default.debug('cordova ready');

          _this._setupBadgePlugin();

          _this._universalLinking();

          _this._registerOnResume(); //this.registerFCM();

        });
      }
    },
    _isCordova: function _isCordova() {
      return _typeof(window.cordova) === 'object';
    },
    _setupBadgePlugin: function _setupBadgePlugin() {
      var _this2 = this;

      document.addEventListener('resume', function () {
        _this2.clearNotifications();

        _this2._resetBadges(); // skip if not logged in


        _this2.currentUser.user && _this2.currentUser.set('user.wallLastActivity', new Date());
      });
    },
    _universalLinking: function _universalLinking() {
      if (window && window.universalLinks) {
        window.universalLinks.subscribe('universallink', function (eventData) {
          if (eventData.url && eventData.url.includes('__/auth/action')) {
            if (window.cordova && window.cordova.InAppBrowser) {
              window.cordova.InAppBrowser.open(eventData.url, '_blank', 'location=no');
            }
          } else {
            var url = new URL(eventData.url);
            var host = url.origin;
            var urlResult = eventData.url.replace(host, '');

            if (urlResult[0] === '/' && urlResult[1] === '#') {
              urlResult = urlResult.substr(2);
            }

            window.location.hash = urlResult;
          }
        });
      }
    },
    _registerOnResume: function _registerOnResume() {
      document.addEventListener('resume', function () {
        (0, _detectMobileOs.default)() === 'iOS' && (0, _statusBar.resetStatusBar)();
      });
      document.addEventListener('pause', function () {});
    },
    registerFCM: function registerFCM() {
      var _this3 = this;

      if (!window || !window.cordova || !window.cordova.plugins || !window.cordova.plugins.firebase || !window.cordova.plugins.firebase.messaging) {
        return;
      }

      var pushService = window.cordova.plugins.firebase.messaging;
      this.set('pushService', pushService);

      try {
        pushService.requestPermission();
      } catch (error) {
        _logger.default.log(error);
      }

      this._resetBadges();

      this.clearNotifications();
      pushService.getToken().then(function (fcmToken) {
        _logger.default.debug(fcmToken);

        _this3.store.query('device', {
          where: ['devId', '==', window.device.uuid]
        }).then(function (devices) {
          var device = devices.get('firstObject');
          var deviceByUser = devices.findBy('user.id', _this3.get('currentUser.user.id'));
          var deviceHash = {
            cordova: window.device.cordova,
            manufacturer: window.device.manufacturer,
            platform: window.device.platform,
            version: window.device.version
          };
          deviceHash['regToken'] = fcmToken;
          deviceHash['devId'] = window.device.uuid;
          deviceHash['user'] = _this3.get('currentUser.user');

          if (deviceByUser != undefined) {
            deviceByUser.setProperties(deviceHash);
            deviceByUser.save();
          } else if (device != undefined) {
            _this3.store.createRecord('device', deviceHash).save().then(function (newDevice) {
              _this3.get('currentUser.user.devices').removeObject(device);

              _this3.get('currentUser.user.devices').pushObject(newDevice);

              _this3.get('currentUser.user').save({
                adapterOptions: {
                  saveOnly: ['devices']
                }
              });
            });
          } else {
            _this3.store.createRecord('device', deviceHash).save().then(function (newDevice) {
              _this3.get('currentUser.user.devices').pushObject(newDevice);

              _this3.get('currentUser.user').save({
                adapterOptions: {
                  saveOnly: ['devices']
                }
              });
            });
          }
        });
      }).catch(function (error) {
        _logger.default.log(error);
      });
      pushService.onMessage(function () {});
      pushService.onBackgroundMessage(function (data) {
        if (data && data.notificationType === 'dm' && data.fromUser) {
          _this3.router.transitionTo('conversation', data.fromUser);
        } else {
          _this3.router.transitionTo('notification');
        }

        _this3.increaseBadge();
      });
    },
    increaseBadge: function increaseBadge() {
      var _this4 = this;

      Ember.tryInvoke(this.pushService, 'getBadge', [function (value) {
        _this4.pushService.getBadge(value + 1);
      }, function (error) {
        _logger.default.log(error);
      }]);
    },
    _resetBadges: function _resetBadges() {
      Ember.tryInvoke(this.pushService, 'setBadge', [0]);
    },
    clearNotifications: function clearNotifications() {
      Ember.tryInvoke(this.pushService, 'clearNotifications');
    }
  });

  _exports.default = _default;
});