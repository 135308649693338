define("challingo-mobile/controllers/profile/followings", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    challenges: Ember.inject.service(),
    actions: {
      loadNextPage: function loadNextPage() {
        this.challenges.following.nextPage();
      }
    }
  });

  _exports.default = _default;
});