define("challingo-mobile/router", ["exports", "challingo-mobile/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var plainRoutes = ['blocked-users', 'calendar', 'direct-messages', 'feedback', 'login', 'logout', 'notification', 'password-change', 'password-forgot', 'search', 'settings', 'signup', 'privacy-policy', 'update-app', 'welcome'];
  var Router = Ember.Router.extend({
    currentUser: Ember.inject.service(),
    menuProfile: Ember.inject.service(),
    modal: Ember.inject.service(),
    location: _environment.default.locationType,
    rootURL: _environment.default.rootURL,
    fromPages: Object.freeze(['users.edit', 'blocked-users', 'password-change', 'settings', 'feedback', 'logout']),
    pages: null,
    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);

      this.set('pages', []);
      this.on('routeWillChange', function (transition) {
        var owner = Ember.getOwner(_this); // router.js

        _this.pages.pushObject(_this.currentRouteName);

        if (_this.fromPages.indexOf(_this.pages.get('lastObject')) !== -1) {
          if (_this.get('menuProfile.stayOpen')) {
            _this.menuProfile.check();

            _this.set('menuProfile.stayOpen', false);
          }
        } // routes/calendar.js


        if (_this.currentRouteName === 'calendar' && !(transition && ["challenge.new", "challenges.accepted", "challenges.show"].includes(transition.targetName))) {
          window.calendar_date = null;
        } // routes/search.js


        var searchRoute = owner.lookup('route:search');

        if (_this.currentRouteName === 'search' && searchRoute && transition) {
          if (['challenges.show', 'profile.show'].includes(transition.targetName)) {
            var el = document.querySelector(searchRoute.get('scrollSelector'));
            el && searchRoute.set('lastScroll', el.scrollTop);
          } else {
            searchRoute.set('lastScroll', 0);
          }
        } // routes/challenges/index.js


        var chIndexRoute = owner.lookup('route:challenges.index');

        if (_this.currentRouteName && _this.currentRouteName.includes('challenges.index') && chIndexRoute && transition) {
          var _el = document.querySelector('.challenges-page .lazy-loading-list');

          if (_el) {
            chIndexRoute.set('lastScroll', _el.scrollTop);
          }
        } // routes/challenges/show.js


        var chShowRoute = owner.lookup('route:challenges.show');

        if (_this.currentRouteName && _this.currentRouteName.includes('challenges.show') && chShowRoute && transition) {
          var lastChallyStrId = chShowRoute.controller.get('model.id') + ' ' + chShowRoute.controller.challengeAcceptedId;
          chShowRoute.setProperties({
            lastChallyStrId: lastChallyStrId
          });

          if (['challenges.likedby', 'challenges.nominated', 'hashtag'].includes(transition.targetName)) {
            var _el2 = document.querySelector(chShowRoute.get('scrollSelector'));

            _el2 && chShowRoute.set('lastScroll', _el2.scrollTop);
          } else {
            chShowRoute.set('lastScroll', 0);
          }
        } // Restore NF scroll on iPhones


        if (chIndexRoute && chIndexRoute.get('lastScroll') && transition.targetName === 'challenges.index') {
          transition.then(function () {
            var st = chIndexRoute.get('lastScroll');

            if (st) {
              chIndexRoute.set('lastScroll', null);
              Ember.run.next(function () {
                var el = document.querySelector('.challenges-page .lazy-loading-list');
                el && el.scrollTo(0, st);
              });
            }
          });
        } // routes/hashtag.js


        var hashtagRoute = owner.lookup('route:hashtag');

        if (_this.currentRouteName && hashtagRoute && transition) {
          if (['challenges.show'].includes(transition.targetName) && chShowRoute) {
            var challyStrId = transition.to.params.challenge_id + ' ' + transition.to.queryParams.challengeAcceptedId;

            if (challyStrId !== chShowRoute.get('lastChallyStrId')) {
              chShowRoute.set('lastScroll', 0);
            }
          }
        } // routes/profile.js


        var profileRoute = owner.lookup('route:profile');

        if (_this.currentRouteName && _this.currentRouteName.includes('profile.') && profileRoute && transition) {
          if (['challenges.show'].includes(transition.targetName)) {
            var _el3 = document.querySelector(profileRoute.get('scrollSelector'));

            _el3 && profileRoute.set('lastScroll', _el3.scrollTop);
          } else {
            profileRoute.set('lastScroll', 0);
          }
        } // routes/direct-messages.js


        var directMessagesRoute = owner.lookup('route:direct-messages');

        if (_this.currentRouteName && _this.currentRouteName.includes('direct-messages') && directMessagesRoute && transition) {
          if (['conversation'].includes(transition.targetName)) {
            var _el4 = document.querySelector(directMessagesRoute.get('scrollSelector'));

            _el4 && directMessagesRoute.set('lastScroll', _el4.scrollTop);
          } else {
            directMessagesRoute.set('lastScroll', 0);
          }
        } // routes/notification.js


        if (_this.currentRouteName === 'notification' && transition) {
          var sessionId = 'notifications-page-1';

          if (!(transition.targetName == 'challenges.show' || transition.targetName == 'profile.show' && transition.to.params.profile_id != _this.currentUser.uid)) {
            Ember.run.next(function () {
              if (window.session && window.session[sessionId]) {
                delete window.session[sessionId];
              }
            });
          }
        } // routes/applications.js


        if (_this.get('modal.isOpen')) {
          var goBack = _this.get('modal.options.goBack');

          _this.modal.close();

          if (!goBack) {
            transition && transition.abort();
            window.history.forward();
          }
        }
      });
    }
  });
  Router.map(function () {
    var _this2 = this;

    this.route('category', {
      path: '/category/:category_id'
    });
    this.route('challenge', function () {
      this.route('complete', {
        path: ':accepted_challenge_id'
      });
      this.route('new');
    });
    this.route('challenges', function () {
      this.route('accepted');
      this.route('comments', {
        path: ':challenge_id/comments'
      });
      this.route('dm', {
        path: ':challenge_id/dm'
      });
      this.route('likedby', {
        path: ':challenge_id/likedby'
      });
      this.route('nominated', {
        path: ':challenge_id/nominated'
      });
      this.route('show', {
        path: ':challenge_id'
      });
    });
    this.route('conversation', {
      path: '/conversation/:user_id'
    });
    this.route('hashtag', {
      path: '/tags/:hashtag'
    });
    this.route('profile', function () {
      this.route('chally', {
        path: '/chally/:profile_id'
      });
      this.route('followers', {
        path: '/followers/:profile_id'
      });
      this.route('followings', {
        path: '/followings/:profile_id'
      });
      this.route('show', {
        path: ':profile_id'
      });
    });
    this.route('users', function () {
      this.route('edit', {
        path: ':user_id'
      });
      this.route('photo', {
        path: ':user_id/photo'
      });
    });
    plainRoutes.forEach(function (routeName) {
      _this2.route(routeName);
    });
    this.route('page-not-found', {
      path: '/*wildcard'
    });
  });
  var _default = Router;
  _exports.default = _default;
});