define("challingo-mobile/components/challenges/challenges-page", ["exports", "challingo-mobile/components/welcome-page"], function (_exports, _welcomePage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    challenges: Ember.inject.service(),
    modal: Ember.inject.service(),
    session: Ember.inject.service(),
    classNames: ['challenges-page', 'full-height'],
    classNameBindings: ['isPageHidden:hidden'],
    isPageHidden: false,
    pageSliderMoving: false,
    selected: Ember.Object.create({
      tab: 0
    }),
    showExploreTitles: false,
    tabs: Object.freeze([{
      name: 'news-feed'
    }, {
      name: 'explore'
    }]),
    tabCodeNF: 0,
    tabCodeTrendy: 1,
    selectedObserver: Ember.observer('selected.tab', function () {
      if (this.get('selected.tab') === 0 && !this.get('session.isAuthenticated')) {
        this.modal.show('modals/login-popup', null, {
          noStyle: true
        });
      }
    }),
    showExploreTitlesObserver: Ember.observer('selected.tab', 'pageSliderMoving', function () {
      if (this.get('selected.tab') === this.get('tabCodeTrendy') || this.get('pageSliderMoving')) {
        this.set('showExploreTitles', true);
      }
    }),
    init: function init() {
      this._super.apply(this, arguments);

      if (!this.get('session.isAuthenticated')) {
        this.set('selected.tab', 1);
      }
    },
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      (0, _welcomePage.hideSplashscreen)();
      this.notifyPropertyChange('selected.tab');
    },
    actions: {
      changePageSliderMoving: function changePageSliderMoving(moving) {
        this.set('pageSliderMoving', moving);
      },
      loadNextNFPage: function loadNextNFPage() {
        this.challenges.newsfeed.nextPage();
      },
      loadNextTrendyPage: function loadNextTrendyPage() {
        this.challenges.trendy.nextPage();
      }
    }
  });

  _exports.default = _default;
});