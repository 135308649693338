define("challingo-mobile/routes/feedback", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    menuMain: Ember.inject.service(),
    resetController: function resetController() {
      this._super.apply(this, arguments);

      this.menuMain.open();
    }
  });

  _exports.default = _default;
});