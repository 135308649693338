define("challingo-mobile/templates/components/welcome-page", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "/UvdLYNE",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"welcome-logo\"],[9],[0,\"\\n  \"],[7,\"img\"],[11,\"src\",\"assets/images/logo-welcome-3x.png\"],[12,\"alt\",[27,\"t\",[\"welcome-home.logoAlt\"],null]],[11,\"width\",\"220\"],[11,\"height\",\"48\"],[9],[10],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"welcome-text\"],[9],[0,\"\\n    \"],[1,[27,\"t\",[\"welcome-home.doSomething\"],null],false],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"],[7,\"div\"],[11,\"class\",\"welcome-buttons\"],[9],[0,\"\\n\"],[4,\"link-to\",[\"login\"],[[\"tagName\",\"disabled\",\"class\"],[\"button\",[23,[\"loading\"]],\"welcome-button-login\"]],{\"statements\":[[0,\"    \"],[1,[27,\"t\",[\"user.signin\"],null],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"link-to\",[\"signup\"],[[\"tagName\",\"disabled\",\"class\"],[\"button\",[23,[\"loading\"]],\"welcome-button-register\"]],{\"statements\":[[0,\"    \"],[1,[27,\"t\",[\"user.new\"],null],false],[0,\"\\n\"]],\"parameters\":[]},null],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "challingo-mobile/templates/components/welcome-page.hbs"
    }
  });

  _exports.default = _default;
});