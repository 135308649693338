define("challingo-mobile/components/direct-messages-page", ["exports", "challingo-mobile/utils/search-component"], function (_exports, _searchComponent) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _searchComponent.default.extend({
    currentUser: Ember.inject.service(),
    router: Ember.inject.service(),
    search: Ember.inject.service(),
    classNames: ['direct-messages-page'],
    combinedUsers: Ember.computed('usersWithConversationSorted.@each.createdAt', 'searchFinished', 'search.{users.@each.createdAt}', function () {
      return (this.get('searchFinished') ? this.search.get('users') : this.get('usersWithConversationSorted')) || [];
    }),
    dmActivityObserver: Ember.observer('dmLastActivity', function () {
      Ember.tryInvoke(this, 'refreshData');
    }),
    dmLastActivity: Ember.computed.alias('currentUser.user.dmLastActivity'),
    sortFunction: function sortFunction(a, b) {
      var latestDM = this.get('latestDM');
      var dateA = new Date(latestDM[a.id].get('createdAt'));
      var dateB = new Date(latestDM[b.id].get('createdAt'));
      return dateA > dateB ? -1 : dateB > dateA ? 1 : 0;
    },
    usersWithConversation: Ember.computed.alias('currentUser.user.hasConversationWith'),
    usersWithConversationIds: Ember.computed('usersWithConversation.{@each.id,length}', function () {
      return (this.get('usersWithConversation') || []).mapBy('id');
    }),
    usersWithConversationSorted: Ember.computed('usersWithConversation.@each.createdAt', function () {
      return this.get('usersWithConversation').toArray().sort(this.sortFunction.bind(this));
    }),
    actions: {
      openConversation: function openConversation(userId) {
        if (event && event.target && event.target.getAttribute('data-toggle') === 'user-profile') {
          this.router.transitionTo('profile.show', userId);
        } else {
          this.router.transitionTo('conversation', userId);
        }
      }
    }
  });

  _exports.default = _default;
});