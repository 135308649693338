define("challingo-mobile/components/page-slider", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var movingTolerance = 25,
      timeToSwipePage = 750,
      dirLeft = 1,
      dirRight = -1;

  var isPinching = function isPinching() {
    return document.body.classList.contains('in-pinch');
  };

  var _default = Ember.Component.extend({
    classNames: ['page-slider'],
    page: 0,
    pages: 0,
    move: null,
    width: 0,
    isScrolling: false,
    direction: 0,
    moving: false,
    timer: 0,
    railStyle: Ember.computed('move.x', function () {
      window.requestAnimationFrame(function () {});
      return Ember.String.htmlSafe("-webkit-transform: translate3d(".concat(this.get('move.x'), "px, 0, 0);\n       transform: translate3d(").concat(this.get('move.x'), "px, 0, 0);"));
    }),
    pageObserver: Ember.observer('page', function () {
      if (!this.moving) {
        this.alignPage(this.page);
      }
    }),
    setActivePage: function setActivePage(page) {
      var slides = this.element && this.element.querySelectorAll('.page-slide');

      if (slides) {
        var slidesArr = Array.from(slides);
        slidesArr.forEach(function (slide) {
          slide.classList.remove('active');
        });
        slidesArr[page].classList.add('active');
      }
    },
    alignPage: function alignPage(page) {
      var _this = this;

      var vids = this.element && this.element.querySelectorAll('.challenge-item video');

      if (vids) {
        Array.from(vids).forEach(function (video) {
          try {
            video.pause();
          } catch (e) {
            e;
          }
        });
      }

      if (page < 0 || page > this.pages) {
        return;
      }

      this.setProperties({
        page: page,
        'move.x': page * -this.width
      });
      this.setActivePage(page);
      Ember.run.scheduleOnce('afterRender', function () {
        Ember.run.later(function () {
          var listActive = _this.element && _this.element.querySelector('.page-slide.active .lazy-loading-list');

          if (listActive && listActive.scrollTop === 0) {
            var firstChallenge = listActive.querySelector('.challenge-item');

            if (firstChallenge) {
              var video = firstChallenge.querySelector('video');

              if (video) {
                video.play().catch(function () {});
              }
            }
          }
        }, 2000);
      });
    },
    movingDistance: function movingDistance(posX) {
      return Math.abs(posX - this.get('move.startX'));
    },
    stayInBounds: function stayInBounds(posX) {
      if (posX > 0) {
        posX = 0;
      } else if (posX < this.pages * -this.width) {
        posX = this.pages * -this.width;
      }

      return posX;
    },
    scrollCheck: function scrollCheck(posX, posY) {
      if (this.isScrolling) {
        return;
      }

      var angle = Math.atan2(posX, posY) * 180 / Math.PI + 180,
          dirUp = angle > 135 && angle < 225,
          dirDown = angle < 45 || angle > 335;

      if (dirUp || dirDown) {
        this.set('isScrolling', true);
      }
    },
    directionUpdate: function directionUpdate(posX) {
      if (this.direction !== 0) {
        return;
      }

      this.set('direction', posX < 0 ? dirLeft : dirRight);
    },
    movingCheck: function movingCheck(posX) {
      if (this.moving) {
        return;
      }

      if (this.movingDistance(posX) > movingTolerance) {
        if (this.page !== 0 && this.direction === dirRight || this.page !== this.pages && this.direction === dirLeft) {
          this.set('moving', true);
          Ember.tryInvoke(this, 'movingChange', [true]);
        }
      }
    },
    resize: null,
    init: function init() {
      var _this2 = this;

      this._super.apply(this, arguments);

      this.set('move', Ember.Object.create({
        x: 0,
        startX: 0,
        offsetX: 0,
        y: 0,
        startY: 0,
        offsetY: 0
      }));
      this.set('resize', function () {
        var width = _this2.element.offsetWidth;

        if (width) {
          _this2.set('width', width);

          var slides = _this2.element && _this2.element.querySelectorAll('.page-slide');

          if (slides) {
            Array.from(slides).forEach(function (slide) {
              slide.style.width = "".concat(width, "px");
            });
          }
        }
      });
      window.addEventListener('resize', this.resize);
    },
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      this.setProperties({
        pages: Math.max(this.element && this.element.querySelectorAll('.page-slide').length || 0, 1) - 1,
        'move.x': this.page * -this.element.offsetWidth
      });
      this.resize();
      this.setActivePage(this.page);
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);

      window.removeEventListener('resize', this.resize);
    },
    touchStart: function touchStart(e) {
      if (!isPinching()) {
        var clientX = e.touches[0].clientX,
            clientY = e.touches[0].clientY,
            offsetX = clientX - this.get('move.x'),
            offsetY = clientY - this.get('move.y'),
            startX = clientX - offsetX,
            startY = clientY - offsetY;
        this.move.setProperties({
          startX: startX,
          offsetX: offsetX,
          startY: startY,
          offsetY: offsetY
        });
        this.set('timer', new Date().getTime());
      }
    },
    touchMove: function touchMove(e) {
      if (!isPinching()) {
        var posX = e.touches[0].clientX - this.get('move.offsetX'),
            posY = e.touches[0].clientY - this.get('move.offsetY'),
            localX = posX + this.page * this.width;

        if (this.moving) {
          var moveX = posX + movingTolerance * this.direction;
          this.set('move.x', this.stayInBounds(moveX));
        } else {
          this.scrollCheck(localX, posY * -1);
        }

        if (!this.isScrolling) {
          this.directionUpdate(localX);
          this.movingCheck(posX);
        }
      }
    },
    touchEnd: function touchEnd() {
      if (!isPinching()) {
        var moveX = this.get('move.x'),
            nextPage = this.page + this.direction,
            nearestPage = Math.round(moveX / -this.width),
            swipeTime = new Date().getTime() - this.timer;

        if (this.moving) {
          if (this.movingDistance(moveX) > movingTolerance && swipeTime < timeToSwipePage) {
            this.alignPage(nextPage);
          } else {
            this.alignPage(nearestPage);
          }
        }

        this.setProperties({
          isScrolling: false,
          direction: 0,
          moving: false
        });
        Ember.tryInvoke(this, 'movingChange', [false]);
      }
    }
  });

  _exports.default = _default;
});