define("challingo-mobile/components/welcome-page", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.hideSplashscreen = void 0;

  var hideSplashscreen = function hideSplashscreen() {
    if (navigator && navigator.splashscreen && navigator.splashscreen.hide) {
      Ember.run.later(navigator.splashscreen.hide, 300);
    }
  };

  _exports.hideSplashscreen = hideSplashscreen;

  var _default = Ember.Component.extend({
    classNames: ['welcome'],
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      hideSplashscreen();
    }
  });

  _exports.default = _default;
});