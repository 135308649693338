define("challingo-mobile/services/push-notification", ["exports", "emberfire/services/realtime-listener", "challingo-mobile/utils/logger"], function (_exports, _realtimeListener, _logger) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    currentUser: Ember.inject.service(),
    session: Ember.inject.service(),
    store: Ember.inject.service(),
    addedNotifications: null,
    adminNotifications: null,
    pushNotifications: null,
    _all: null,
    loaded: false,
    visiblePushNotifications: Ember.computed.filterBy('pushNotifications', 'hidden', false),
    activeAdminNotifications: Ember.computed.filterBy('adminNotifications', 'isDeleted', false),
    activePushNotifications: Ember.computed.filterBy('visiblePushNotifications', 'isDeleted', false),
    filteredAdminNotifications: Ember.computed('activeAdminNotifications.[]', 'currentUser.createdAt', function () {
      var registrationDate = this.get('currentUser.createdAt');

      if (!registrationDate) {
        return this.activeAdminNotifications;
      } else {
        return this.activeAdminNotifications.filter(function (notification) {
          return notification.createdAt > registrationDate;
        });
      }
    }),
    allNotifications: Ember.computed('filteredAdminNotifications.[]', 'activePushNotifications.[]', 'addedNotifications.[]', function () {
      var adminNotifications = this.filteredAdminNotifications;
      var pushNotifications = (this.activePushNotifications || []).filter(function (item) {
        return item && item.type !== 'dm';
      });
      var allNotifications = [];
      allNotifications.pushObjects(adminNotifications);
      allNotifications.pushObjects(pushNotifications);
      allNotifications.pushObjects(this.get('addedNotifications'));
      return allNotifications.uniqBy('id').sort(function (a, b) {
        var dateA = new Date(a.get('createdAt'));
        var dateB = new Date(b.get('createdAt'));
        return dateA > dateB ? 1 : dateB > dateA ? -1 : 0;
      }).reverse();
    }),
    allNotificationsIds: Ember.computed.mapBy('allNotifications', 'id'),
    notificationsCount: Ember.computed('adminNotifications.{[],length}', 'currentUser.data.notificationsAdminDisplayedIds.[]', 'pushNotifications.@each.{isReceived}', 'session.isAuthenticated', function () {
      var _this = this;

      if (!this.get('session.isAuthenticated')) {
        return 0;
      } else {
        var userAdminDisplayed = this.get('currentUser.data.notificationsAdminDisplayedIds') || [];
        var unreadPush = (this.get('pushNotifications') || []).filterBy('isReceived', false).uniqBy('id');
        var unreadAdmin = (this.get('adminNotifications') || []).filter(function (item) {
          return !userAdminDisplayed.includes(item.get('id'));
        }).filter(function (item) {
          if (!item) {
            return false;
          }

          var d1 = new Date(item.get('createdAt'));
          var d2 = new Date(_this.get('currentUser.data.createdAt'));
          return d1 >= d2;
        }).uniqBy('id');
        return (unreadPush.length || 0) + (unreadAdmin.length || 0);
      }
    }),
    load: function load() {
      var _this2 = this;

      if (!this.get('loaded')) {
        var userId = this.get('currentUser.user.id');

        if (!userId) {
          return;
        }

        var adminNotifications = this.store.findAll('admin-notification', {
          reload: true
        });
        var pushNotifications = this.store.query('push-notification', {
          limit: 100,
          orderBy: {
            createdAt: 'desc'
          },
          where: [['forUser', '==', userId]]
        });
        this.setProperties({
          addedNotifications: Ember.A([]),
          adminNotifications: adminNotifications,
          pushNotifications: pushNotifications
        });
        this.setProperties({
          _all: Ember.RSVP.all([adminNotifications, pushNotifications]),
          loaded: true
        });
        this.get('_all').then(function (data) {
          data.forEach(function (model) {
            (0, _realtimeListener.subscribe)(_this2, model);
          });
        });
      }

      return this.get('_all');
    },
    send: function send(forUser, type, body, fromUser, challenge, acceptedChallenge) {
      if (!forUser || !fromUser) {
        return;
      }

      _logger.default.debug("Sending push for ".concat(forUser.get('id'), " from ").concat(fromUser.get('id')));

      if (forUser.get('id') != fromUser.get('id')) {
        return this.store.createRecord('push-notification', {
          forUser: forUser,
          type: type,
          body: body,
          fromUser: fromUser,
          challenge: challenge,
          acceptedChallenge: acceptedChallenge
        }).save();
      }
    },
    unload: function unload() {
      this.setProperties({
        addedNotifications: Ember.A([]),
        adminNotifications: Ember.A([]),
        pushNotifications: Ember.A([]),
        _all: Ember.A([]),
        loaded: false
      });
    },
    willDestroy: function willDestroy() {
      this._super.apply(this, arguments);

      (0, _realtimeListener.unsubscribe)(this);
    }
  });

  _exports.default = _default;
});