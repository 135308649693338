define("challingo-mobile/templates/components/confirm-dialog", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "fLsoHK1d",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[23,[\"isOpen\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\"],[11,\"class\",\"confirm-dialog-inside\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"box\"],[9],[0,\"\\n      \"],[7,\"div\"],[11,\"class\",\"double-questionmark-svg\"],[9],[0,\"\\n        \"],[7,\"img\"],[11,\"alt\",\"\"],[11,\"height\",\"61\"],[11,\"role\",\"none\"],[11,\"src\",\"assets/images/svg/double-questionmark.svg\"],[11,\"width\",\"60\"],[9],[10],[0,\"\\n      \"],[10],[0,\"\\n      \"],[7,\"h2\"],[11,\"class\",\"message\"],[9],[1,[21,\"message\"],false],[10],[0,\"\\n    \"],[10],[0,\"\\n\\n    \"],[1,[27,\"direction-btns\",null,[[\"showColumnSpace\",\"btnLeft\",\"btnLeftAction\",\"btnRight\",\"btnRightAction\"],[false,[27,\"hash\",null,[[\"link\",\"text\"],[\"action\",[27,\"t\",[\"no\"],null]]]],[27,\"action\",[[22,0,[]],\"close\"],null],[27,\"hash\",null,[[\"link\",\"text\",\"style\"],[\"action\",[27,\"t\",[\"yes\"],null],\"primary-alt\"]]],[27,\"action\",[[22,0,[]],\"confirm\"],null]]]],false],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "challingo-mobile/templates/components/confirm-dialog.hbs"
    }
  });

  _exports.default = _default;
});