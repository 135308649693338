define("challingo-mobile/components/cordova-back-button-handler", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);

      this.set('backButtonHandler', function () {
        Ember.tryInvoke(_this, 'action');
      });
    },
    didInsertElement: function didInsertElement() {
      var _this2 = this;

      this._super.apply(this, arguments);

      document.addEventListener('deviceready', function () {
        document.addEventListener('backbutton', _this2.backButtonHandler);
      });
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);

      document.removeEventListener('backbutton', this.backButtonHandler);
    }
  });

  _exports.default = _default;
});