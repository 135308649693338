define("challingo-mobile/components/forms/user-photo-form", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    currentUser: Ember.inject.service(),
    intl: Ember.inject.service(),
    router: Ember.inject.service(),
    tagName: 'form',
    classNames: ['challenge-form'],
    user: Ember.computed.alias('currentUser.user'),
    mediaSelected: Ember.computed.alias('media.url'),
    loading: false,
    submit: function submit() {
      var _this = this;

      this.set('loading', true);

      var errFunc = function errFunc() {
        if (!_this.isDestroyed) {
          _this.set('loading', false);
        }
      };

      var user = this.user;
      var media = this.media;

      if (media.get('isEmpty')) {
        this.set('error', this.get('intl').t("media.required"));
        this.set('loading', false);
        return false;
      }

      var oldMediaPromise = user.get('_media');
      media.save().then(function (savedMedia) {
        user.setProperties({
          _media: savedMedia,
          media: null,
          mediaHeight: savedMedia.get('height') || null,
          mediaType: savedMedia.get('type'),
          mediaUrl: savedMedia.get('url'),
          mediaWidth: savedMedia.get('width') || null
        });
        user.save().then(function () {
          if (oldMediaPromise) {
            oldMediaPromise.then(function (oldMedia) {
              oldMedia && oldMedia.destroyRecord().finally(function () {});
            });
          }

          history.back();
        }, errFunc);
      }, errFunc);
      return false;
    }
  });

  _exports.default = _default;
});