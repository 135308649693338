define("challingo-mobile/components/tab-bar", ["exports", "challingo-mobile/mixins/scroll-events"], function (_exports, _scrollEvents) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var categoryScrollKey = 'challingoCategoriesScroll';

  var _default = Ember.Component.extend(_scrollEvents.default, {
    currentUser: Ember.inject.service(),
    menuMain: Ember.inject.service(),
    modal: Ember.inject.service(),
    router: Ember.inject.service(),
    session: Ember.inject.service(),
    categorySort: null,
    classNames: ['tab-bar'],
    classNameBindings: ['fixed', 'icons', 'iconsTitles', 'menu:has-menu', 'selectedClass', 'transparent:tab-bar--transparent'],
    fixed: true,
    hasCalendar: false,
    icons: false,
    iconsTitles: false,
    menu: false,
    messages: false,
    selected: Ember.Object.create({
      tab: 0
    }),
    showHide: true,
    showOnSelected: true,
    slider: true,
    tabs: null,
    transparent: false,
    unreadDMCount: 0,
    categoriesSorted: Ember.computed.sort('categories', 'categorySort'),
    dmActivityObserver: Ember.observer('dmLastActivity', function () {
      var _this = this;

      var user = this.get('currentUser.user');

      if (user && user.getUnreadDMCount) {
        user.getUnreadDMCount().then(function (count) {
          _this.set('unreadDMCount', count);
        });
      }
    }),
    dmLastActivity: Ember.computed.alias('currentUser.user.dmLastActivity'),
    locale: Ember.computed.alias('currentUser.user.localeAugmented'),
    selectedClass: Ember.computed('selected.tab', function () {
      return "selected-tab-".concat(this.get('selected.tab'));
    }),
    selectedObserver: Ember.observer('selected.tab', 'showOnSelected', function () {
      var _this2 = this;

      Ember.run.next(function () {
        _this2.restoreCategoryScroll();
      });

      if (this.showOnSelected) {
        this.element.classList.remove('hide');
      }
    }),
    sliderStyle: Ember.computed('selected.tab', function () {
      var tabWidth = 100 / this.get('tabs.length');
      return Ember.String.htmlSafe("left: ".concat(this.get('selected.tab') * tabWidth, "%; width: ").concat(tabWidth, "%;"));
    }),
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      if (this) {
        this.restoreCategoryScroll();
        this.notifyPropertyChange('dmLastActivity');
      }
    },
    init: function init() {
      this._super.apply(this, arguments);

      this.scrollSetup('.page-slide .lazy-loading-list');
      var locale = this.get('locale');
      this.set('categorySort', ["name_".concat(locale, ":asc")]);
    },
    restoreCategoryScroll: function restoreCategoryScroll() {
      var el = this && this.element && this.element.querySelector('.explore-categories');

      if (el && window) {
        el.scrollLeft = window[categoryScrollKey];
      }
    },
    saveCategoryScroll: function saveCategoryScroll() {
      var el = this && this.element && this.element.querySelector('.explore-categories');

      if (el && window) {
        window[categoryScrollKey] = el.scrollLeft;
      }
    },
    scroll: function scroll(scrollTop, direction, previousScroll) {
      var _this3 = this;

      if (!this.get('showHide')) {
        return;
      }

      var showBar = function showBar() {
        _this3.element.classList.remove('hide');
      };

      var hideBar = function hideBar() {
        _this3.element.classList.add('hide');
      };

      if (scrollTop <= 100) {
        showBar();
      } else if (this.fixed && previousScroll > 0) {
        if (direction === 'up') {
          showBar();
        } else if (direction === 'down') {
          hideBar();
        }
      }
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);

      this.saveCategoryScroll();
    },
    actions: {
      checkLoginDM: function checkLoginDM() {
        if (!this.get('session.isAuthenticated')) {
          this.modal.show('modals/login-popup', null, {
            goBack: true,
            noStyle: true
          });
        }
      },
      menuOpen: function menuOpen() {
        if (!this.get('session.isAuthenticated')) {
          this.modal.show('modals/login-popup', null, {
            goBack: true,
            noStyle: true
          });
          return;
        }

        if (!this.menuMain.isOpen) {
          this.router.transitionTo({
            queryParams: {
              miniProfile: true
            }
          });
        }

        this.menuMain.open();
      },
      showCategory: function showCategory(category) {
        if (category && category.id) {
          this.router.transitionTo('category', category.id);
        }
      },
      switchTab: function switchTab(index) {
        if (this.get('selected.tab') === index) {
          var el = document.querySelector('.page-slide.active .lazy-loading-list');

          if (el) {
            el.scrollTo({
              behavior: 'smooth',
              top: 0
            });
          }
        } else {
          this.set('selected.tab', index);
          this.saveCategoryScroll();
          Ember.tryInvoke(this, 'onTabChange', [index]);
        }
      }
    }
  });

  _exports.default = _default;
});