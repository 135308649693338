define("challingo-mobile/utils/open-external-url", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;

  function _default(url) {
    if (typeof window !== 'undefined') {
      if (window.cordova && window.cordova.InAppBrowser) {
        window.cordova.InAppBrowser.open(url, '_system', 'usewkwebview=true');
      } else {
        window.open(url, '_blank');
      }
    }
  }
});