define("challingo-mobile/components/menu-main", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var menuWidth = 260;

  var _default = Ember.Component.extend({
    currentUser: Ember.inject.service(),
    menuMain: Ember.inject.service(),
    router: Ember.inject.service(),
    classNames: ['menu-main'],
    classNameBindings: ['inTouch', 'isOpen'],
    isOpen: Ember.computed.alias('menuMain.isOpen'),
    inTouch: false,
    isOpenNeedle: "miniProfile=true",
    posX: -menuWidth,
    offsetX: 0,
    openObserver: Ember.observer('isOpen', 'inTouch', function () {
      this.set('posX', this.isOpen ? 0 : -menuWidth);
    }),
    menuStyle: Ember.computed('posX', function () {
      window.requestAnimationFrame(function () {});
      return Ember.String.htmlSafe("-webkit-transform: translate3d(".concat(this.posX, "px, 0, 0);\n       transform: translate3d(").concat(this.posX, "px, 0, 0);"));
    }),
    overlayStyle: Ember.computed('posX', function () {
      var alpha = ((this.posX + menuWidth) / menuWidth / 2).toFixed(2);
      return Ember.String.htmlSafe("background-color: rgba(17, 17, 17, ".concat(alpha, ");"));
    }),
    user: Ember.computed.alias('currentUser.user'),
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      this.set('posX', this.isOpen ? 0 : -menuWidth);

      if (this.router && this.router.currentURL) {
        if (this.router.currentURL.includes(this.isOpenNeedle)) {
          this.send('open');
        } else {
          this.send('close');
        }
      }
    },
    stayInBounds: function stayInBounds(posX) {
      return Math.min(-0.01, Math.max(-menuWidth, posX));
    },
    toggle: function toggle(posX) {
      if (!this.menuMain.isOpen && posX >= -menuWidth * .75) {
        this.send('open');
      } else if (this.menuMain.isOpen && posX <= -menuWidth * .25) {
        this.send('close');
      }
    },
    touchStart: function touchStart(event) {
      if (window.location.hash === '#/management') {
        return;
      }

      var clientX = event.touches[0].clientX,
          offsetX = clientX - this.posX;
      this.setProperties({
        offsetX: offsetX,
        inTouch: true
      });
    },
    touchMove: function touchMove(event) {
      if (window.location.hash === '#/management') {
        return;
      }

      var posX = event.touches[0].clientX - this.offsetX;
      this.set('posX', this.stayInBounds(posX));
    },
    touchEnd: function touchEnd() {
      if (window.location.hash === '#/management') {
        return;
      }

      if (this.posX) {
        this.toggle(this.posX);
      }

      this.set('inTouch', false);
    },
    miniProfileOpenObserver: Ember.observer('router.{currentRouteName,currentURL}', function () {
      var href = this.router.currentURL;

      if (href && ["challenges.index", "challenges.show"].includes(this.router.currentRouteName)) {
        if (this.menuMain.isOpen && !href.includes(this.isOpenNeedle)) {
          this.send('close');
        } else if (!this.menuMain.isOpen && href.includes(this.isOpenNeedle)) {
          this.send('open');
        }
      }
    }),
    actions: {
      close: function close() {
        var _this = this;

        if (this.menuMain.isOpen) {
          Ember.run.later(function () {
            if (!_this.menuMain.isOpen && _this.router && _this.router.currentURL && _this.router.currentURL.includes(_this.isOpenNeedle)) {
              history.go(-1);
            }
          }, 100);
        }

        this.menuMain.close();
      },
      logout: function logout() {
        this.send('close');
        this.send('transitionTo', 'logout');
      },
      open: function open() {
        if (!this.menuMain.isOpen) {
          this.router.transitionTo({
            queryParams: {
              miniProfile: true
            }
          });
        }

        this.menuMain.open();
      },
      transitionTo: function transitionTo() {
        var _this$router;

        (_this$router = this.router).transitionTo.apply(_this$router, arguments);
      },
      transitionToAndClose: function transitionToAndClose() {
        var _this$router2;

        (_this$router2 = this.router).transitionTo.apply(_this$router2, Array.prototype.slice.call(arguments).concat([{
          queryParams: {
            miniProfile: null
          }
        }]));

        this.menuMain.close();
      }
    }
  });

  _exports.default = _default;
});