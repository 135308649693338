define("challingo-mobile/templates/components/modal-component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "AqOTro3Y",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[23,[\"isOpen\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\"],[11,\"class\",\"modal\"],[9],[0,\"\\n    \"],[1,[27,\"component\",[[23,[\"componentName\"]]],[[\"model\",\"modalClose\",\"options\"],[[23,[\"model\"]],[27,\"action\",[[22,0,[]],\"close\"],null],[23,[\"options\"]]]]],false],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "challingo-mobile/templates/components/modal-component.hbs"
    }
  });

  _exports.default = _default;
});