define("challingo-mobile/components/liked-by-page", ["exports", "challingo-mobile/mixins/swipe-events"], function (_exports, _swipeEvents) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_swipeEvents.default, {
    classNames: ['followers-list'],
    classNameBindings: ['isOpen'],
    _update: null,
    challenge: null,
    challengeAcceptedId: null,
    isOpen: true,
    pageTitle: '',
    prop: 'likedBy',
    challengeAccepted: Ember.computed('challenge.accepted.[]', 'challengeAcceptedId', function () {
      return this.get('challenge.accepted').findBy('id', this.challengeAcceptedId);
    }),
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      this.notifyPropertyChange('_update');
    },
    selectedChallenge: Ember.computed('challengeAccepted', 'challengeAcceptedId', function () {
      return this.challengeAcceptedId ? this.challengeAccepted : this.challenge;
    }),
    items: null,
    itemsObserver: Ember.observer('{_update,prop,selectedChallenge}', function () {
      var _this = this;

      var challenge = this.get('selectedChallenge');

      if (challenge) {
        var arr = challenge.get(this.get('prop')).toArray();
        Ember.RSVP.all(arr).then(function (result) {
          var items = result && result.toArray() || [];

          _this.setProperties({
            items: items
          });
        });
      } else {
        this.set('items', []);
      }
    }),
    swipeLeft: function swipeLeft() {},
    swipeRight: function swipeRight() {
      history.back();
    },
    actions: {
      closeLikedBy: function closeLikedBy() {
        history.back();
      }
    }
  });

  _exports.default = _default;
});