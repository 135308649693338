define("challingo-mobile/mixins/swipe-events", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    swipeStart: {},
    swipeTimer: 0,
    distanceLimit: 50,
    timeToSwipe: 750,
    isScrolling: function isScrolling(_ref) {
      var x = _ref.x,
          y = _ref.y;
      var angle = Math.atan2(x, y) * 180 / Math.PI + 180,
          dirUp = angle < 45 || angle > 335,
          dirDown = angle > 135 && angle < 225;
      return dirUp || dirDown;
    },
    swipeLeft: function swipeLeft() {},
    swipeRight: function swipeRight() {},
    touchStart: function touchStart(event) {
      this.setProperties({
        swipeStart: {
          x: event.touches[0].clientX,
          y: event.touches[0].clientY
        },
        swipeTimer: new Date().getTime()
      });
    },
    touchEnd: function touchEnd(event) {
      var start = {
        x: this.get('swipeStart.x'),
        y: this.get('swipeStart.y')
      };
      var end = {
        x: event.changedTouches[0].clientX,
        y: event.changedTouches[0].clientY
      };
      var distance = {
        x: end.x - start.x,
        y: end.y - start.y
      };
      var swipeTime = new Date().getTime() - this.swipeTimer;

      if (this.isScrolling(distance)) {
        return;
      }

      if (Math.abs(distance.x) > this.get('distanceLimit') && swipeTime < this.get('timeToSwipe')) {
        if (end.x < start.x) {
          this.swipeLeft();
        } else if (end.x > start.x) {
          this.swipeRight();
        }
      }
    }
  });

  _exports.default = _default;
});