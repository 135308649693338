define("challingo-mobile/templates/users/photo", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "gH0tRIiK",
    "block": "{\"symbols\":[],\"statements\":[[1,[27,\"nav-bar\",null,[[\"back\",\"title\"],[\"back\",[27,\"t\",[\"photo.change\"],null]]]],false],[0,\"\\n\\n\"],[1,[27,\"forms/user-photo-form\",null,[[\"media\",\"user\"],[[23,[\"model\",\"media\"]],[23,[\"model\",\"user\"]]]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "challingo-mobile/templates/users/photo.hbs"
    }
  });

  _exports.default = _default;
});