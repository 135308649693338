define("challingo-mobile/services/confirm-dialog", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    isOpen: false,
    message: '',
    callback: null,
    show: function show() {
      var message = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
      var callback = arguments.length > 1 ? arguments[1] : undefined;
      this.setProperties({
        message: message,
        callback: callback,
        isOpen: true
      });
      document.body.classList.add('no-scroll');
    },
    close: function close() {
      this.setProperties({
        message: '',
        callback: null,
        isOpen: false
      });
      document.body.classList.remove('no-scroll');
    }
  });

  _exports.default = _default;
});