define("challingo-mobile/components/forms/challenge-nominate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    modal: Ember.inject.service(),
    classNames: ['challenge-nominate'],
    model: null,
    actions: {
      nominateUser: function nominateUser() {
        this.modal.show('nominate-users', this.get('model'));
      }
    }
  });

  _exports.default = _default;
});