define("challingo-mobile/templates/components/forms/password-forgot-form", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "tUSQoFb7",
    "block": "{\"symbols\":[],\"statements\":[[7,\"p\"],[9],[1,[27,\"t\",[\"password_forgot.info\"],null],false],[10],[0,\"\\n\\n\"],[7,\"br\"],[9],[10],[0,\"\\n\\n\"],[7,\"div\"],[11,\"class\",\"input-field\"],[9],[0,\"\\n  \"],[1,[27,\"input\",null,[[\"type\",\"id\",\"value\",\"placeholder\",\"autocomplete\",\"required\"],[\"email\",\"email\",[23,[\"email\"]],\" \",\"off\",true]]],false],[0,\"\\n  \"],[7,\"label\"],[11,\"for\",\"email\"],[9],[1,[27,\"t\",[\"user.email\"],null],false],[10],[0,\"\\n\"],[10],[0,\"\\n\\n\"],[7,\"div\"],[11,\"class\",\"errors\"],[9],[1,[21,\"error\"],false],[10],[7,\"br\"],[9],[10],[0,\"\\n\\n\"],[7,\"button\"],[11,\"class\",\"btn btn-action\"],[12,\"disabled\",[21,\"loading\"]],[11,\"style\",\"touch-action: manipulation; -ms-touch-action: manipulation; cursor: pointer;\"],[11,\"type\",\"submit\"],[9],[0,\"\\n  \"],[1,[27,\"t\",[\"confirm\"],null],false],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "challingo-mobile/templates/components/forms/password-forgot-form.hbs"
    }
  });

  _exports.default = _default;
});