define("challingo-mobile/templates/components/conversation-page", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "LnenAiHE",
    "block": "{\"symbols\":[\"message\"],\"statements\":[[1,[27,\"nav-bar\",null,[[\"back\",\"title\"],[\"back\",[23,[\"model\",\"otherUser\",\"name\"]]]]],false],[0,\"\\n\\n\"],[7,\"div\"],[11,\"class\",\"conversation-messages-wrap\"],[9],[0,\"\\n\"],[4,\"each\",[[23,[\"sortedMessages\"]]],null,{\"statements\":[[0,\"    \"],[1,[27,\"conversation-message\",null,[[\"model\"],[[22,1,[]]]]],false],[0,\"\\n\"]],\"parameters\":[1]},null],[10],[0,\"\\n\"],[1,[27,\"scroll-restore\",null,[[\"target\",\"defaultScrollPosition\"],[\".conversation-messages-wrap\",\"bottom\"]]],false],[0,\"\\n\\n\"],[7,\"form\"],[11,\"class\",\"new-comment\"],[9],[0,\"\\n  \"],[1,[27,\"textarea\",null,[[\"autocomplete\",\"maxlength\",\"placeholder\",\"required\",\"value\"],[\"off\",2200,[27,\"t\",[\"dm.new_placeholder\"],null],true,[23,[\"newMessageBody\"]]]]],false],[0,\"\\n\\n  \"],[7,\"button\"],[11,\"class\",\"button-send loading-hidden\"],[12,\"disabled\",[27,\"or\",[[23,[\"loading\"]],[27,\"not\",[[23,[\"newMessageBody\"]]],null]],null]],[11,\"style\",\"touch-action: manipulation; -ms-touch-action: manipulation; cursor: pointer;\"],[11,\"type\",\"submit\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"challingo-icons moonicon-send\"],[9],[10],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "challingo-mobile/templates/components/conversation-page.hbs"
    }
  });

  _exports.default = _default;
});