define("challingo-mobile/utils/jquery-polyfills", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.offset = offset;
  _exports.visible = visible;
  _exports.position = _exports.parents = void 0;

  var parents = function parents(element, selector) {
    var parents = [];

    if (!element) {
      return parents;
    }

    while ((element = element.parentElement) !== null) {
      if (element.nodeType !== Node.ELEMENT_NODE) {
        continue;
      }

      if (!selector || element.matches(selector)) {
        parents.push(element);
      }
    }

    return parents;
  };

  _exports.parents = parents;

  function offset(element) {
    var rect = element.getBoundingClientRect();
    var win = element.ownerDocument.defaultView;
    return {
      top: rect.top + win.pageYOffset,
      left: rect.left + win.pageXOffset
    };
  }

  function visible(element) {
    return !!(element.offsetWidth || element.offsetHeight || element.getClientRects().length);
  }

  var position = function position(element) {
    var osParent = element.offsetParent;

    if (!osParent) {
      return {
        left: 0,
        top: 0
      };
    }

    var os = offset(element);
    var osP = ['body', 'html'].includes(osParent.nodeName.toLowerCase()) ? {
      left: 0,
      top: 0
    } : offset(osParent);

    if (typeof getComputedStyle === 'function') {
      var style = getComputedStyle(element);
      os.left -= parseFloat(style.marginLeft) || 0;
      os.top -= parseFloat(style.marginTop) || 0;
      osP.left += parseFloat(style.borderLeftWidth) || 0;
      osP.top += parseFloat(style.borderTopWidth) || 0;
    }

    return {
      left: os.left - osP.left,
      top: os.top - osP.top
    };
  };

  _exports.position = position;
});