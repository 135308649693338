define("challingo-mobile/models/hashtag", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    tagName: _emberData.default.attr('string'),
    challenges: _emberData.default.hasMany('challenge', {
      inverse: null
    }),
    challengesAccepted: _emberData.default.hasMany('accepted-challenge', {
      inverse: null
    }),
    challengesVisible: Ember.computed('challenges.@each.{hidden}', function () {
      return this.challenges.compact().filterBy('hidden', false).sortBy('createdAt').reverse();
    }),
    challengesAcceptedVisible: Ember.computed('challengesAccepted.@each.{hidden,completed}', function () {
      return this.challengesAccepted.compact().filterBy('hidden', false).sortBy('completed').reverse();
    }),
    allChallenges: Ember.computed('challengesVisible.@each.createdAt', 'challengesAcceptedVisible.@each.completed', function () {
      return this.challengesVisible.toArray().concat(this.challengesAcceptedVisible.toArray()).uniqBy('id').sortBy('sorting').reverse();
    }),
    challyCount: Ember.computed('challengesVisible.length', 'challengesAcceptedVisible.length', function () {
      return (this.challengesVisible.length || 0) + (this.challengesAcceptedVisible.length || 0);
    }),
    photoSmall: Ember.computed.alias('allChallenges.firstObject.photoSmall')
  });

  _exports.default = _default;
});