define("challingo-mobile/services/xp-message", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    isOpen: false,
    text: '',
    show: function show() {
      var xp = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 0;
      var text = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '';
      this.setProperties({
        xp: xp,
        text: text,
        isOpen: true
      });
    }
  });

  _exports.default = _default;
});