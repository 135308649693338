define("challingo-mobile/templates/components/category-select", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "QDB3UjTl",
    "block": "{\"symbols\":[\"category\"],\"statements\":[[4,\"each\",[[23,[\"categoriesSorted\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\"],[12,\"aria-label\",[27,\"get\",[[22,1,[]],[27,\"concat\",[\"name_\",[23,[\"locale\"]]],null]],null]],[12,\"class\",[28,[\"rank \",[27,\"if\",[[27,\"eq\",[[22,1,[\"id\"]],[23,[\"challenge\",\"category\",\"id\"]]],null],\"selected\"],null]]]],[11,\"role\",\"button\"],[11,\"style\",\"touch-action: manipulation; -ms-touch-action: manipulation; cursor: pointer;\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"rank-left\"],[9],[0,\"\\n      \"],[1,[27,\"get\",[[22,1,[]],[27,\"concat\",[\"name_\",[23,[\"locale\"]]],null]],null],false],[0,\"\\n    \"],[10],[0,\"\\n  \"],[3,\"action\",[[22,0,[]],\"selectCategory\",[22,1,[]]]],[10],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "challingo-mobile/templates/components/category-select.hbs"
    }
  });

  _exports.default = _default;
});