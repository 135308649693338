define("challingo-mobile/templates/components/profile-page", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "RkGSaC50",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[27,\"not\",[[27,\"eq\",[[23,[\"user\",\"id\"]],[23,[\"currentUser\",\"user\",\"id\"]]],null]],null]],null,{\"statements\":[[0,\"  \"],[1,[27,\"nav-bar\",null,[[\"back\",\"title\"],[\"back\",[23,[\"scrolledTitle\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"if\",[[23,[\"user\"]]],null,{\"statements\":[[0,\"  \"],[1,[27,\"user-profile\",null,[[\"badges\",\"fullProfil\",\"user\"],[[23,[\"badges\"]],true,[23,[\"user\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "challingo-mobile/templates/components/profile-page.hbs"
    }
  });

  _exports.default = _default;
});