define("challingo-mobile/templates/components/blocked-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ocPwA0T+",
    "block": "{\"symbols\":[\"item\"],\"statements\":[[7,\"div\"],[11,\"class\",\"nav-bar\"],[9],[0,\"\\n  \"],[7,\"a\"],[11,\"href\",\"#\"],[11,\"class\",\"back\"],[11,\"style\",\"touch-action: manipulation; -ms-touch-action: manipulation; cursor: pointer;\"],[9],[0,\"\\n    \"],[7,\"i\"],[11,\"class\",\"challingo-icons moonicon-back\"],[9],[10],[0,\"\\n  \"],[3,\"action\",[[22,0,[]],\"back\"]],[10],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"title\"],[9],[0,\"\\n    \"],[1,[27,\"t\",[\"blockeds.blocked_users\"],null],false],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\\n\"],[7,\"div\"],[11,\"class\",\"nav-bar-spacer\"],[9],[0,\" \"],[10],[0,\"\\n\\n\"],[7,\"div\"],[11,\"class\",\"content\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"blocked-list page-offset\"],[9],[0,\"\\n\"],[4,\"each\",[[23,[\"model\",\"blockeds\"]]],null,{\"statements\":[[0,\"      \"],[7,\"div\"],[11,\"class\",\"blocked-user\"],[9],[0,\"\\n        \"],[7,\"img\"],[11,\"alt\",\"\"],[11,\"class\",\"photo\"],[11,\"role\",\"presentation\"],[12,\"src\",[22,1,[\"photo\"]]],[9],[10],[0,\"\\n        \"],[7,\"div\"],[12,\"aria-label\",[22,1,[\"name\"]]],[11,\"class\",\"name\"],[11,\"role\",\"button\"],[11,\"style\",\"touch-action: manipulation; -ms-touch-action: manipulation; cursor: pointer;\"],[9],[0,\"\\n          \"],[1,[22,1,[\"name\"]],false],[0,\"\\n        \"],[3,\"action\",[[22,0,[]],\"hideProfileMenu\",[22,1,[\"id\"]]]],[10],[0,\"\\n      \"],[10],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "challingo-mobile/templates/components/blocked-list.hbs"
    }
  });

  _exports.default = _default;
});