define("challingo-mobile/models/like", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    challenge: _emberData.default.belongsTo('challenge', {
      inverse: null
    }),
    challengeAccepted: _emberData.default.belongsTo('accepted-challenge', {
      inverse: null
    }),
    createdAt: _emberData.default.attr('date', {
      defaultValue: function defaultValue() {
        return new Date();
      }
    }),
    userFrom: _emberData.default.belongsTo('user', {
      inverse: null
    }),
    userTo: _emberData.default.belongsTo('user', {
      inverse: null
    })
  });

  _exports.default = _default;
});