define("challingo-mobile/components/calendar-page", ["exports", "challingo-mobile/mixins/swipe-events", "moment", "challingo-mobile/utils/jquery-polyfills"], function (_exports, _swipeEvents, _moment, _jqueryPolyfills) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var date2key = function date2key(d) {
    return "".concat(d.getFullYear(), "-").concat(d.getMonth(), "-").concat(d.getDate());
  };

  var _default = Ember.Component.extend(_swipeEvents.default, {
    currentUser: Ember.inject.service(),
    intl: Ember.inject.service(),
    classNames: ['calendar-page'],
    challenges: null,
    filteredChallenges: Ember.computed('challenges.[]', 'selectedDate', function () {
      var _this = this;

      var selectedDate = this.get('selectedDate');
      return this.challenges.filter(function (chally) {
        var type = chally.get('type');
        var prop = type == 'challenge' ? 'createdAt' : 'completed';
        return _this._datesEqual(chally.get(prop), selectedDate);
      });
    }),
    drawnChallies: null,
    filteredDrawnChallies: Ember.computed('drawnChallies.@each.{drawnChally}', 'selectedDate', function () {
      var _this2 = this;

      return this.drawnChallies.filter(function (chally) {
        return !chally.get('drawnChally.challenge.isCompletedByUser') && _this2._datesEqual(chally.get('drawnChally.createdAt'), _this2.get('selectedDate'));
      });
    }),
    mergedChallenges: Ember.computed('filteredChallenges.[]', 'filteredDrawnChallies.[]', function () {
      return this.filteredChallenges.toArray().concat(this.filteredDrawnChallies.map(function (drawnChally) {
        return drawnChally.get('challenge');
      }).toArray()).uniqBy('id');
    }),
    daysWithChally: Ember.computed('challenges.[]', 'drawnChallies.[]', function () {
      var days = [];
      this.challenges.forEach(function (chally) {
        var prop = chally.get('type') == 'challenge' ? 'createdAt' : 'completed';
        var d = chally.get(prop);
        if (d) days.push(date2key(new Date(d)));
      });
      this.drawnChallies.forEach(function (chally) {
        if (!chally.get('drawnChally.challenge.isCompletedByUser')) {
          var d = chally.get('drawnChally.createdAt');
          if (d) days.push(date2key(new Date(d)));
        }
      });
      return days.uniq();
    }),
    daysToFuture: 3,
    monthName: '',
    selectedDate: null,
    selectedDateDisplay: Ember.computed('selectedDate', function () {
      var selectedDate = this.get('selectedDate');
      return selectedDate ? "".concat(selectedDate.getMonth() + 1, ". ").concat(selectedDate.getDate(), ".") : '';
    }),
    startDate: null,
    dateToday: null,
    endDate: Ember.computed(function () {
      var d = new Date();
      d.setDate(d.getDate() + this.get('daysToFuture'));
      return d;
    }),
    _datesEqual: function _datesEqual(d1, d2) {
      return d1 instanceof Date && d2 instanceof Date && d1.getDate() == d2.getDate() && d1.getMonth() == d2.getMonth() && d1.getFullYear() == d2.getFullYear();
    },
    isToday: Ember.computed('selectedDate', function () {
      return this._datesEqual(this.get('selectedDate'), new Date());
    }),
    days: Ember.computed(function () {
      var days = [],
          i = new Date(this.get('startDate'));
      var end = this.get('endDate'),
          dow = ['S', 'M', 'T', 'W', 'T', 'F', 'S'],
          dateToday = new Date();

      for (; i <= end; i.setDate(i.getDate() + 1)) {
        days.push({
          date: new Date(i),
          dateKey: date2key(i),
          dayNumber: i.getDate(),
          dayOfWeek: dow[i.getDay()],
          isToday: this._datesEqual(i, dateToday)
        });
      }

      return days;
    }),
    _scrollCalendarToDate: function _scrollCalendarToDate(d) {
      var dateSelector = document.querySelector('.date-selector');

      if (dateSelector) {
        var weekWidth = dateSelector.offsetWidth,
            dayWidth = weekWidth / 7,
            a = (0, _moment.default)(d),
            b = (0, _moment.default)(this.get('startDate')),
            days = a.diff(b, 'days');
        dateSelector.scrollTo((days - 3) * dayWidth, 0);
      }
    },
    swipeLeft: function swipeLeft() {
      if (event && event.target) {
        var p = (0, _jqueryPolyfills.parents)(event.target, '.date-selector-wrap');

        if (!p.length) {
          var d = this.get('selectedDate');

          if (d && !this._datesEqual(this.get('endDate'), d)) {
            d = new Date(d);
            d.setDate(d.getDate() + 1);

            this._selectDay({
              date: d
            });

            this._scrollCalendarToDate(d);
          }
        }
      }
    },
    swipeRight: function swipeRight() {
      if (event && event.target) {
        var p = (0, _jqueryPolyfills.parents)(event.target, '.date-selector-wrap');

        if (!p.length) {
          var d = this.get('selectedDate');

          if (d && !this._datesEqual(this.get('startDate'), d)) {
            d = new Date(d);
            d.setDate(d.getDate() - 1);

            this._selectDay({
              date: d
            });

            this._scrollCalendarToDate(d);
          }
        }
      }
    },
    daysString: Ember.computed('days.[]', 'daysWithChally.[]', function () {
      var days = this.get('days');
      var daysWithChally = this.get('daysWithChally') || [];
      var sdm = (0, _moment.default)(this.get('selectedDate'));
      return Ember.String.htmlSafe(days.map(function (day, index) {
        return "\n      <div\n        class=\"\n          calendar-day\n          ".concat(day.isToday ? 'today' : '', "\n          ").concat(sdm.isSame(day.date, 'day') ? 'selected' : '', "\n          ").concat(daysWithChally.includes(day.dateKey) ? 'has-chally' : '', "\n        \"\n        data-key=\"").concat(index, "\"\n      >\n        <div class=\"calendar-day-dow\" data-key=\"").concat(index, "\">").concat(day.dayOfWeek, "</div>\n        <div class=\"calendar-day-number\" data-key=\"").concat(index, "\">").concat(day.dayNumber, "</div>\n      </div>\n    ");
      }).join(''));
    }),
    init: function init() {
      this._super.apply(this, arguments);

      this.setProperties({
        'selectedDate': window.calendar_date ? window.calendar_date : new Date(),
        'startDate': new Date("2018-01-01"),
        'dateToday': new Date()
      });
    },
    didInsertElement: function didInsertElement() {
      var _this3 = this;

      this._super.apply(this, arguments);

      Ember.run.later(function () {
        _this3._scrollCalendarToDate(_this3.get('selectedDate'));
      }, 200);
      var dateSelector = document.querySelector('.date-selector');

      if (dateSelector) {
        dateSelector.addEventListener('scroll', function () {
          var weekWidth = dateSelector.offsetWidth,
              dayWidth = weekWidth / 7,
              scrollWidth = dateSelector.scrollWidth,
              scrollLeft = dateSelector.scrollLeft;

          if (dayWidth != 0) {
            var daysInPast = Math.round((scrollWidth - weekWidth - scrollLeft) / dayWidth);
            var d = new Date();
            d.setDate(d.getDate() - daysInPast);

            _this3.set('monthName', _this3.get('intl').t("calendar.months.".concat(d.getMonth() + 1)).toString());
          }
        });
      }
    },
    _selectDay: function _selectDay(day) {
      this.set('selectedDate', day.date);
      window.calendar_date = day.date;

      if (this && this.element) {
        var k = date2key(day.date);
        var index = this.get('days').findIndex(function (d) {
          return d.dateKey === k;
        });
        var elOld = this.element && this.element.querySelector('.date-selector .selected');

        if (elOld) {
          elOld.classList.remove('selected');
        }

        var elNew = this.element && this.element.querySelector(".date-selector .calendar-day[data-key=\"".concat(index, "\"]"));

        if (elNew) {
          elNew.classList.add('selected');
        }
      }
    },
    actions: {
      selectDay: function selectDay() {
        if (event && event.target) {
          var indexStr = event.target.getAttribute('data-key');

          if (indexStr) {
            var index = parseInt(indexStr);
            var days = this.get('days');

            if (days && days[index]) {
              var day = days[index];

              this._selectDay(day);
            }
          }
        }
      }
    }
  });

  _exports.default = _default;
});