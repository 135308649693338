define("challingo-mobile/utils/model-with-media", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    mediaHeight: _emberData.default.attr('number'),
    mediaType: _emberData.default.attr('string'),
    mediaUrl: _emberData.default.attr('string'),
    mediaWidth: _emberData.default.attr('number'),
    _media: _emberData.default.belongsTo('media', {
      inverse: null
    }),
    media: null,
    buildMedia: function buildMedia() {
      var props = this.getProperties('mediaHeight', 'mediaType', 'mediaUrl', 'mediaWidth');

      if (!this.get('media') && props.mediaType && props.mediaUrl) {
        this.set('media', this.store.createRecord('media', {
          height: this.get('mediaHeight') || null,
          type: this.get('mediaType'),
          url: this.get('mediaUrl'),
          width: this.get('mediaWidth') || null
        }));
      }
    },
    mediaObserver: Ember.observer('{media,mediaHeight,mediaType,mediaUrl,mediaWidth}', function () {
      this.buildMedia();
    }),
    init: function init() {
      this._super.apply(this, arguments);

      this.buildMedia();
    }
  });

  _exports.default = _default;
});