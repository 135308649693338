define("challingo-mobile/utils/dm-model", ["exports", "challingo-mobile/utils/get-has-many-ids"], function (_exports, _getHasManyIds) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;

  function _default() {
    var _this = this;

    var currentUserId = this.get('currentUser.uid');

    var processUser = function processUser(user) {
      var promises = [];
      var usersWithConversation = (0, _getHasManyIds.default)(user, 'hasConversationWith');

      var getQueryOptions = function getQueryOptions(userFromId, userToId) {
        return {
          limit: 1,
          orderBy: {
            createdAt: 'desc'
          },
          where: [['userFrom', '==', userFromId], ['userTo', '==', userToId]]
        };
      };

      usersWithConversation.forEach(function (userId) {
        promises.push(_this.store.query('direct-message', getQueryOptions(currentUserId, userId)));

        if (currentUserId !== userId) {
          promises.push(_this.store.query('direct-message', getQueryOptions(userId, currentUserId)));
        }
      });
      return Ember.RSVP.all(promises).then(function (queries) {
        var result = {};
        queries.forEach(function (query) {
          query.forEach(function (dm) {
            var userFromId = dm.belongsTo('userFrom').id();
            var userToId = dm.belongsTo('userTo').id();
            var userId = userFromId === currentUserId ? userToId : userFromId;

            if (!result[userId] || result[userId].get('createdAt') < dm.get('createdAt')) {
              result[userId] = dm;
            }
          });
        });
        return result;
      });
    };

    var userPromise = this.get('currentUser.user');

    if (userPromise && userPromise.then) {
      return userPromise.then(function (user) {
        return processUser(user);
      });
    } else {
      return processUser(userPromise);
    }
  }
});