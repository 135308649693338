define("challingo-mobile/utils/fit-photo-to-element", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;

  function _default(photo, element, _ref, isVideo) {
    var height = _ref.height,
        mediaHeight = _ref.mediaHeight,
        mediaOrientation = _ref.mediaOrientation,
        mediaWidth = _ref.mediaWidth,
        pt = _ref.pt,
        sizeMultiplier = _ref.sizeMultiplier,
        width = _ref.width;

    if (!photo || !element) {
      return Ember.String.htmlSafe('');
    }

    var assetId = photo.split('/').pop().split('.')[0];
    var calcHeight = height || Math.floor(element.offsetHeight) - pt;
    var calcWidth = width || Math.floor(element.offsetWidth);
    var useBlur = false;

    if (calcWidth && calcHeight) {
      if (mediaHeight && mediaWidth) {
        var aspectMedia = mediaWidth / mediaHeight;
        var aspectScreen = calcWidth / calcHeight;

        if (aspectMedia > aspectScreen) {
          useBlur = true;
        }
      } else if (mediaOrientation === 'landscape') {
        useBlur = true;
      }
    } // blur doesn't work on video


    if (useBlur && !isVideo) {
      var mul = sizeMultiplier || 1;
      photo = photo.replace('/upload/', "/upload/u_".concat(assetId, ",w_1.0,ar_").concat(mul * calcWidth, ":").concat(mul * calcHeight, ",c_thumb,e_colorize,co_black/so_0,h_").concat(mul * calcHeight, ",w_").concat(mul * calcWidth, "/"));
    } // use black background instead


    if (useBlur && isVideo) {
      var _style = "background-image: url('".concat(photo, "'); background-color: black; background-size: contain");

      return Ember.String.htmlSafe(_style);
    }

    var style = "background-image: url('".concat(photo, "');");
    return Ember.String.htmlSafe(style);
  }
});