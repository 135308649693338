define("challingo-mobile/translations/en", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "accepted-challenge": "Accepted",
    "added-challenge": "Created",
    "all": "{count, plural, =1 {all} =2 {all} =3 {all} =4 {all} other {all}}",
    "aria-labels": {
      "cancel": "Cancel",
      "close": "Close",
      "delete": "Delete",
      "description": "Description",
      "like": "Like",
      "management": "Manage chally",
      "scroll-down": "Scroll down",
      "show": "Show",
      "user-level": "Level"
    },
    "available_soon": "Available soon",
    "back": "Back",
    "badges": {
      "badge_messages": {
        "creative_text": "Amazing! You've earned Creative Badge",
        "creative_title": "Congratulations! You've created 50 chally!",
        "doer_text": "Amazing! You've earned Doer Badge",
        "doer_title": "Congratulations! You've completed 50 chally!",
        "influencer_text": "Amazing! You've earned Influencer Badge",
        "influencer_title": "Congratulations! You have got 10 k Followers!",
        "keeper_text": "Amazing! You've earned Keeper Badge",
        "keeper_title": "Congratulations! You've completed one month of Daily Trend!",
        "savage_text": "Amazing! You've earned Savage Badge",
        "savage_title": "Congratulations! You've completed one year of Daily Trend!",
        "yesman_text": "Amazing! You've earned Yes Man Badge",
        "yesman_title": "Congratulations! You've completed one week of Daily Trend!"
      },
      "badges": "Badges",
      "creative": "Creative",
      "doer": "Doer",
      "earn_messages": {
        "creative": "create {count}/50 chally",
        "doer": "complete {count}/50 chally",
        "keeper": "month of daily trend chally",
        "savage": "year of daily trend chally",
        "yesman": "week of daily trend chally"
      },
      "keeper": "Keeper",
      "no_badges": "No badges here",
      "savage": "Savage",
      "yesman": "Yes Man"
    },
    "blockeds": {
      "block": "Are you sure you want to block this user?",
      "blocked_users": "Blocked users",
      "unblock": "Are you sure you want to unblock this user?"
    },
    "calendar": {
      "daily_trend": "Daily trend",
      "months": {
        "1": "January",
        "10": "October",
        "11": "November",
        "12": "December",
        "2": "February",
        "3": "March",
        "4": "April",
        "5": "May",
        "6": "June",
        "7": "July",
        "8": "August",
        "9": "September"
      },
      "page_text": "Create or Complete chally today and complete your Daily Trend",
      "page_text_today": "Create or Complete at least one chally and complete your Daily Trend",
      "page_title": "No Chally {day}",
      "today": "Today"
    },
    "cancel": "Cancel",
    "category": "Category",
    "challenge": {
      "accept": "Accept chally",
      "accept_confirm": "Are you sure you want to accept chally?",
      "accept_random_chally": "Accept random chally",
      "accept_submit_error": "Failed to accept chally.",
      "accept_submit_success": "<i class=\"challingo-icons ci-added-challenge\"></i><span>You have a new chally!</span>",
      "challingo": "Challingo chally",
      "chally_name": "Name",
      "complete": "Enter & complete chally",
      "complete_description": "Write description of your chally and @tag your friends",
      "complete_short": "Complete",
      "complete_short_excl": "a chally!",
      "complete_submit_error": "Unable to meet the chally.",
      "complete_submit_success": "You have completed<br> a chally!",
      "complete_tags": "Write #tags of your chally",
      "congratulations": "Congratulations!",
      "create_short": "Create",
      "create_short_excl": "my new chally!",
      "delete": "Delete chally",
      "delete_confirm": "Are you sure you want to delete the chally?",
      "delete_error": "Unable to delete the chally.",
      "delete_success": "The chally has been deleted.",
      "difficulty": "Difficulty",
      "difficulty_choose": "Choose a difficulty level",
      "difficulty_levels": {
        "text_1": "This chally is very easy.",
        "text_2": "This chally is a chill out.",
        "text_3": "This chally is challenging.",
        "text_4": "This chally is hard. Very difficult to complete.",
        "text_5": "This chally is insane. Almost impossible to complete.",
        "title_1": "Very Easy",
        "title_2": "Chill Out",
        "title_3": "Challenging",
        "title_4": "Hard",
        "title_5": "Insane"
      },
      "edit_description": "Edit description",
      "enter": "Enter chally",
      "for_whom": "For whom is the chally",
      "friends": "Friends",
      "fulfillment": "Complete chally",
      "give_up": "Give Up",
      "give_up_confirm": "Are you sure you want to give up on this chally?",
      "give_up_error": "Unable to give up the chally.",
      "give_up_success": "The chally has been given up.",
      "hide": "Hide the chally",
      "hide_error": "Chally failed to hide.",
      "hide_question": "Do you really want to hide the chally?",
      "hide_success": "The chally was hidden.",
      "incompleted_challenges": "You have {count} uncompleted chally",
      "is_ok": "Chally is OK",
      "latest_chally_likes": "Your latest chally had {count} likes",
      "markRandom": "Add to random chally",
      "meet": "Complete chally",
      "new": "Create chally",
      "new_description": "Write description of your chally and @tag your friends",
      "new_friends_tag": "Chally your friends with @tag",
      "new_submit_error": "Unable to enter the chally.",
      "new_submit_success": "You have created<br> a chally!",
      "new_tags": "Write #tags of your chally",
      "new_title": "Type the name of the chally",
      "no_accepted_chally": "You have not accepted any chally yet.",
      "no_created_chally": "No chally here",
      "nominate": "Nominate",
      "nominate_max": "You can nominate up to {count} users",
      "nominated": "Nominated",
      "plus1": "+1 chally",
      "private": "Private",
      "proceed_to_complete": "Complete chally",
      "public": "Public",
      "random_error": "Failed to remove chally from random lottery",
      "random_question_add": "Do you want to add this chally to random lottery?",
      "random_question_remove": "Do you want to remove this chally from random lottery?",
      "random_success_add": "The chally was added to random lottery",
      "random_success_remove": "The chally was removed from random lottery",
      "rate": "Rate chally",
      "report_error": "Chally failed to report.",
      "report_question": "Do you want to report this chally?",
      "report_success": "The chally was reported.",
      "unmarkRandom": "Remove from random chally",
      "unreport_error": "Chally failed to approve.",
      "unreport_question": "Do you want to approve this chally?",
      "unreport_success": "The chally was approved.",
      "you_have_to_wait": "You have to wait {count} hours"
    },
    "challenge-completed": "Completed",
    "challenges": {
      "another_completed": "Other completed challenges",
      "chally": "Chally",
      "completed": "Completed",
      "created": "Created"
    },
    "chally": "Chally",
    "comments": {
      "delete_confirm": "Are you sure you want to delete the comment?",
      "delete_error": "Unable to delete the comment.",
      "delete_success": "The comment has been deleted.",
      "empty_list": "No comments yet. Be the first who comments.",
      "few": "comments",
      "new_placeholder": "message ...",
      "one": "comment",
      "other": "comments"
    },
    "comments_count": "{count, plural, =1 {comment} =2 {comments} =3 {comments} =4 {comments} other {comments}}",
    "confirm": "Confirm",
    "continue": "Continue",
    "continue_with": {
      "facebook": "Continue with Facebook",
      "google": "Continue with Gmail"
    },
    "cool": "Cool!",
    "copy_to_clipboard": "Copy Link",
    "copy_to_clipboard_message": "The link has been copied to the clipboard.",
    "description": "Description",
    "direct-message": "Direct Message",
    "direct-messages": "Direct Messages",
    "disclaimer": {
      "pdf1": "assets/files/zasady_ochrany_osobnich_udaju_challingo_en.pdf",
      "pdf2": "assets/files/podminky_pouziti_sluzeb_challingo_en.pdf",
      "terms_of_use": "terms of use",
      "text1": "By registering, you agree to the wording of our document",
      "text2": "and you confirm that you have read",
      "user_informations": "user informations"
    },
    "dm": {
      "new_placeholder": "message ...",
      "select_max": "You can select up to {count} users",
      "send_as_dm": "Send as a direct message",
      "shared_chally": "<strong>{user}</strong> shared a new chally",
      "success": "Chally was sent in DM"
    },
    "download": "Download chally",
    "empty_list": "Empty list",
    "empty_list_chally": "You must first accept a chally to complete it.",
    "error": {
      "message": "An error has occured.",
      "type": "Error"
    },
    "explore": "Explore",
    "feedback": {
      "send_us": "Send us your feedback",
      "submit_error": "Failed to send Feedback.",
      "submit_success": "Feedback sent.",
      "type": "Feedback"
    },
    "file": {
      "errors": {
        "required": "Please upload your file"
      }
    },
    "follow": {
      "delete_following": "Are you sure you want to unfollow this user?",
      "facebook": "follow Challingo on Facebook and vote",
      "follow": "Follow",
      "followers": "Followers",
      "following": "Following",
      "followings": "Following"
    },
    "form": {
      "errors": {
        "email": "Please enter a valid e-mail.",
        "required": "Please fill this field.",
        "server": "Unable to verify data."
      }
    },
    "history": "History",
    "icons_titles": {
      "accepted-challenge": "Created",
      "added-challenge": "Accepted",
      "challenge": "Completed",
      "explore": "Explore",
      "news-feed": "Newsfeed"
    },
    "join": "join",
    "levels": {
      "level_x": "level {level}",
      "levelup_message": "Good job! Now you are level {level}!",
      "levelup_message_rank": "Good job! Now you are level {level} and you have earned {rank} rank!",
      "levelup_modal_title": "Level Up!",
      "xp": "xp",
      "xp_modal_title": "Congratulations!"
    },
    "liked_by": "Liked By",
    "login_popup": {
      "dont_have_account": "Dont have an account?",
      "have_account": "You already have an account?",
      "info": "To enjoy all the features of the Challingo app, please create an account.",
      "switch": {
        "button": "Switch to the App",
        "not-now": "Not Now",
        "text-1": "OOOPS!",
        "text-2": "Looks like you don't have the App.<br>True challenger needs all the features."
      },
      "title": {
        "login": "Login to your account",
        "signup": "Account Registration"
      }
    },
    "media": {
      "camera": "Camera",
      "gallery": "Gallery",
      "image_upload_error": "Your photo exceeds the maximum limit of 10 MB",
      "max_size_error": "Max. file size is",
      "new_chally": "Create chally",
      "one_option": "Please select one option",
      "photo": "Photo",
      "remove_error": "Failed to remove photo / video.",
      "required": "Please upload your file",
      "type_error": "Insert photo or video.",
      "upload_error": "This file could not be uploaded from your cloud storage. Please download the file to your phone first, then upload again, thank you.",
      "video_upload_error": "Your video exceeds the maximum limit of 100 MB"
    },
    "menu": "Home",
    "messages": {
      "nodirectmessages": "Direct Messages available soon."
    },
    "new-chally-accepted": {
      "info": "You've got a <b>{title}</b> Chally. Good Luck! 🤞",
      "not-found": "Chally not found",
      "title": "New chally accepted!"
    },
    "news-feed": "Newsfeed",
    "next": "Next",
    "no": "No",
    "notifications": {
      "empty_list": "No notifications",
      "new_placeholder": "Add a notification...",
      "plus99": "99+"
    },
    "ok": "Ok",
    "or": "or",
    "password_forgot": {
      "info": "Enter the e-mail associated with your account and we will send you instructions for resetting your password."
    },
    "permission": {
      "denied": "Required permission denied",
      "failed": "Permission request failed"
    },
    "photo": {
      "change": "Change photo",
      "name": "Photo",
      "profile": "Profile photo",
      "upload": "Upload photo"
    },
    "prepare_preview": "Preparing preview...",
    "privacy": {
      "consent": {
        "1": " You agree with the processing of the following personal data:",
        "2": "You declare that all of the personal and other data provided to the Company is precise, complete and true, and you undertake to immediately inform the Company of any potential changes.",
        "3": "You declare that you are aware of the fact that providing consent to the processing of your personal data is voluntary, and that you may revoke such consent at any time via a written notification sent by post to the address of the Company registered office, or by e-mail to: info@challingo.com. However, you also acknowledge that revoking such consent may lead to the termination of provision of Services by the Company.",
        "a": "name and surname;",
        "b": "user name and password;",
        "c": "e-mail address;",
        "d": "photograph or other likeness;",
        "e": "communication between you and the Company",
        "f": "other information and the Content that you specify in your Account.Processing of personal data means in particular its gathering, saving on carriers, use, storage, sorting and transfer.",
        "g": "",
        "title": "Consent with the processing of personal data and its scope"
      },
      "intro": "These Principles for the Protection of the Personal and Other Data (hereinafter the &ldquo;<strong>Principles</strong>&rdquo;) regulate the mutual rights and obligations between you and Challingo s.r.o., Company Id No: 06047688, registered office at address: Rybn&aacute; 716/24, Star&eacute; Město, 110 00 Prague 1, Czech Republic, registered in the Commercial Register of the Municipal Court in Prague under File No. C 274936 (&ldquo;<strong>Company</strong>&rdquo;), relating to the handling of your personal and other data that you provide to the Company when using Challingo services (&ldquo;<strong>Services</strong>&rdquo;), or in relation to the use thereof. These Principles are an integral part of the Terms and Conditions of Use of the Services of Challingo (&ldquo;<strong>Terms and Conditions</strong>&rdquo;).",
      "introductory": {
        "1": "By registering through the Challingo.com website or related mobile applications, you grant your consent to the Terms and Conditions, including these Principles. If you do not agree with them, you shall not be entitled to use the Services.",
        "2": "All of the terms used in these Principles that begin with a capital letter have the same significance as the same terms used in the Terms and Conditions.",
        "3": "These Principles regulate the mutual rights and obligations relating to the handling of your personal and other data that you provide to the Company when using the Services, or in relation to the use thereof. The Company handles the personal data of every user who is an individual in accordance with Act No. 101/2000 Coll., on the Protection of Personal Data, as amended. If you set up sharing of your personal data with third parties in your Account, then you do so at your own responsibility and acknowledge that the processing of your personal data by such third parties is not subject to these Principles.",
        "4": "These Principles shall be valid and effective from the date of the beginning of use of services. The wording of these Principles may be unilaterally changed or supplemented by the Company at any time. You will be informed about any change or supplement to the Principles in the manner specified in Article V of the Terms and Conditions. The effect of a change or supplement may occur as soon as a new version of the Principles is published, or on a specific date determined by the Company. By using the Services after the date of effect of a new version of the Principles, you express your consent with the updated wording of the Principles. This provision shall not affect the rights and obligations arisen during the period of effect of the prior wording of the Principles.",
        "5": "These Principles, including their previous versions, are available through the Challingo.com website and related mobile applications, where they can be viewed by anyone.",
        "title": "Introductory Provisions"
      },
      "other": {
        "1": "You acknowledge that when using the Services, the Company may store cookies and other data files in the devices on which you use the Services for the purpose of gathering data about how you use the Services, which devices and browsers you use to access them, and for the purpose of adapting the Services. You also acknowledge that when using the Services, the Company may gather information about your geographic location, IP address, internet browser, internet provider, the device and the operating system. The procedure according to this point is used for the adaptation and development of the Services, and for analytical and statistical purposes, not for your direct identification. You can restrict the gathering of certain information or storing of files on your device by configuring it, but this may affect the provision of the Services.",
        "2": "The Company shall be entitled to enter metadata into your Content.",
        "3": "The Company shall be entitled to share anonymized analytical and statistical data with third parties.",
        "title": "Other information and data"
      },
      "purpose": {
        "1": "The Company shall process personal data for the purpose of:",
        "2": "You agree with the processing of your personal data in the Czech Republic.",
        "3": "The Company will process your personal data for an indefinite period of time, but always for the period during which you have an Account, and after its cancellation for the duration of the rights and obligations arising from the Terms and Conditions and from the provision of Services. After the expiration of the period of processing of personal data, the Company shall be entitled to process the data for analytical purposes in an anonymous manner in such a way that it will not be possible to identify you on the basis thereof.",
        "4": "The Company will process your personal data in an automated or other manner and using other means.",
        "5": "As an administrator, the Company may authorize a third party as a processor to process personal data.",
        "6": "The Company may provide your personal data in the necessary scope to business partners that allow for the provision of the Services or take part in their provision, and only for the purpose of providing the Services. The Company will not share your personal data with any other third parties or sell it to third parties. However, you also acknowledge that in certain cases, based on the law, other legal standards or decisions of public authorities, the Company may be obliged to provide your personal data to public authorities.",
        "a": "keeping your Account and providing the Services;",
        "b": "implementation of rights and obligations arising from Terms and Conditions and from provision of the Services;",
        "c": "developing, testing, monitoring and improving the Services;",
        "d": "analysing and adapting the displayed Content;",
        "e": "displaying and sending of advertisements and commercial messages.",
        "title": "Purpose, period and method of processing personal data"
      },
      "rights": {
        "1": "You are entitled to access your personal data and correct it.",
        "2": "If you are of the opinion that the Company or other processor is processing your personal data at variance with the protection of private or personal life, or at variance with the law, in particular if the personal data is inaccurate with regard to the purpose of its processing, you may request an explanation from the Company or processor, and you may request the Company or processor to remove such an arisen state. If your request is justified, the Company or processor shall be obliged to immediately remove the defective state.",
        "3": "If you request information on the processing of your personal data, the Company shall be obliged to submit it to you. In such a case the Company shall be entitled to request a reasonable payment not exceeding the costs necessary for providing the information.",
        "title": "Your rights in terms of protection of personal data"
      },
      "title": "PRINCIPLES FOR THE PROTECTION OF PERSONAL AND OTHER DATA"
    },
    "profile_edit": {
      "bio": "Bio",
      "button": "Save profile",
      "linktree": "Social network URL",
      "name": "Name",
      "no_rank": "No rank",
      "rank": "Rank"
    },
    "profile_menu": {
      "account": "Account",
      "block": "Block",
      "blocked": "Blocked users",
      "edit": "Edit profile",
      "language": "Language",
      "password": "Change password",
      "settings": "Settings",
      "title": "Options",
      "unblock": "Unblock"
    },
    "push-notifications": {
      "accept": "accepted your chally.",
      "call_to": "nominated you to complete chally.",
      "comment": "commented your chally.",
      "complete": "completed your chally.",
      "dm": "has sent you a message",
      "follow": "started following you.",
      "like": "liked your chally.",
      "mention": "mentioned you in a comment.",
      "welcome": "Welcome to Challingo"
    },
    "random": "Random",
    "ranks": {
      "beast": "Beast",
      "challenger": "Challenger",
      "chief": "Chief",
      "coach": "Coach",
      "elder": "Elder",
      "expert": "Expert",
      "influencer": "Influencer",
      "influencer_message": "get 10 000 followers",
      "legend": "Legend",
      "master": "Master",
      "newbie": "Newbie",
      "norank": "No rank",
      "player": "Player",
      "pro": "Pro",
      "rookie": "Rookie"
    },
    "report": "Report",
    "reported": "Reported",
    "score": "Score",
    "search": {
      "nochallyfound": "No chally found.",
      "nousersfound": "No users found.",
      "search": "Search"
    },
    "searching": "Searching...",
    "send": "Send",
    "send_separately": "Send separately",
    "settings": {
      "locale_cz": "CZ",
      "locale_en": "EN",
      "locale_title": "Choose language",
      "title": "Settings"
    },
    "show-more": "Show more",
    "tags": "Tags",
    "terms": {
      "choice": {
        "1": "These Terms and Conditions and their interpretation shall be exclusively governed by the legal order of the Czech Republic, even if the legal relationships established by these Terms and Conditions, or relating to the use of the Services, contains an international element. The application of the UN Convention on Contracts for the International Sale of Goods is expressly excluded.",
        "2": "The general courts of the Czech Republic are competent to decide on all disputes arising from these Terms and Conditions.",
        "3": "The Czech Trade Inspection, Registered office: &Scaron;těp&aacute;nsk&aacute; 567/15, 120 00 Prague 2, Company Id No: 000 20 869, internet address: www.coi.cz, is applicable to out-of-court settlement of consumer disputes.",
        "4": "The Company reserves the right, but not the obligation, to intervene in disputes between users. You undertake to provide to the Company reasonable cooperation to remove any Content that violates these Terms and Conditions, and in resolving disputes between you and other users.",
        "5": "If you have any complaints, you can contact the Company directly, or the relevant supervision authority or state supervision.",
        "title": "Choice of Law and Resolving Disputes"
      },
      "closing": {
        "1": "You shall not be entitled to assign any of your receivables or part thereof toward the Company arising from these Terms and Conditions, or from the use of the Services, to a third party without the prior written consent of the Company. You may not assign the rights and obligations from these Terms and Conditions or part thereof to a third party without the prior written consent of the Company.",
        "2": "You grant consent to the Company to assign receivables or part thereof toward you arising from these Terms and Conditions, or from the use of the Services, to a third party. You grant consent to the Company to assign its rights and obligations from these Terms and Conditions or part thereof to a third party.",
        "3": "The Company shall not be bound toward you by any codes of conduct pursuant to the provisions of Section 1826, paragraph 1, letter e) of Act No. 89/2012 Coll., Civil Code, as amended.",
        "4": "These Terms and Conditions were created in the Czech and English languages. In the event of a conflict between language versions, the Czech version shall take precedence.",
        "5": "If any of the provisions of these Terms and Conditions are or become invalid, ineffective, or unenforceable, the other provisions shall remain separate, valid, effective and enforceable. Such invalid, ineffective, or unenforceable provisions shall be replaced by a provision whose purpose is as close as possible to the invalid, ineffective, or unenforceable provision.&nbsp;",
        "title": "Closing Provisions"
      },
      "communication": {
        "1": "You agree that for all communication with you, the Company will use the environment of your Account and/or the contact information you provide. This communication may include, but is not limited to, a notice of a change or supplement to these Terms and Conditions, dispute resolution with other users, or a confirmation of withdrawal from these Terms and Conditions. You agree that the Company may contact you in this way for any other purposes related to the Services and their provision.",
        "2": "If you believe that someone is using the Services at variance with legal regulations and standards or these Terms and Conditions, you can report this behaviour through the &ldquo;Report&rdquo; option for each post. You can also proceed in the same manner if you believe someone has infringed your copyright by acting in this manner.",
        "title": "Communication and Reporting Unsuitable Content"
      },
      "company_disclaimer": {
        "1": "Use of the Services is at your own risk and responsibility. If you decide to proceed on the basis of any request, then this shall be your free choice. When fulfilling requests or using the Services, you shall be obliged to adhere to locally valid legal regulations and standards.",
        "2": "The Company shall not be responsible for any Content of users and third parties. The Company does not create the Content, nor does it provide it, approve it, promote it or guarantee it, and the Company is not obliged monitor and check it.",
        "3": "The Company never makes any legally binding action through user requests toward you or other users, not even in cases wherein the Company shared or otherwise disseminated such a request through its official account. No user request may be perceived as a public promise, a price announcement, promise of indemnity or a similar obligation on the part of the Company. If such a commitment is made by any of the users, the Company does not guarantee its enforceability, nor is the Company responsible for it. The Company never under any circumstances accepts such a commitment from any user. Disputes concerning the enforceability of user requests are a private matter for users and the Company is not obliged to interfere with them or provide any cooperation in their resolution, unless it results as such from legal regulations or a decision of public authorities. To the Company can only be attributed the requests whose original publication was made through the official Company account under the name of Challingo, and which are labelled with the Company logo. It is prohibited for any other user to use this label when making requests or sharing or otherwise distributing requests other than those specified in the previous sentence.",
        "4": "The Company does not make any declarations regarding the properties of the Services or their quality or design. The Services are provided as they are with all of their defects, and the Company does not guarantee the availability, functionality, accuracy and reliability of the Services, or their suitability for a particular purpose. The Company shall not be obliged to remove defects to the Services. The Company renounces all explicit or implicit guarantees relating to the functionality, purpose, accuracy, quality, suitability, design, utility and security of the Services, as well as the absence of viruses in the Services. If you have any suggestions or comments for improving the Services, you can contact us through the &ldquo;Send us feedback&rdquo; application, or at <a href=\"mailto:info@challingo.com;\">info@challingo.com;</a>.",
        "5": "The Company shall not be responsible for any damages or non-pecuniary damages originating from, or relating to:",
        "6": " You undertake to pay all of the damages and non-pecuniary damages that could be incurred by the Company or other users as a consequence of your breach of these Terms and Conditions.",
        "a": "user Content;",
        "b": "the behaviour and demeanour of users;",
        "c": "the loss of your Content;",
        "d": "the inaccessibility, non-functionality, errors or inaccuracy of the Services;",
        "e": "the presence of viruses in the Services;",
        "f": "damage to your hardware and/or software due to the use of Services;",
        "g": "fulfilment of requests; and/or",
        "h": "non-fulfilment of user obligations made through requests.",
        "title": "Company Disclaimer and Your Responsibility"
      },
      "intro": "These Terms and Conditions for using Challingo services (hereinafter the &ldquo;<strong>Terms and Conditions</strong>&rdquo;) of Challingo s.r.o., Company Id No: 06047688, registered office at address: Rybn&aacute; 716/24, Star&eacute; Město, 110 00 Prague 1, Czech Republic, registered in the Commercial Register of the Municipal Court in Prague under File No. C 274936 (&ldquo;<strong>Company</strong>&rdquo;), regulate the mutual rights and obligations between the Company and you as a user of the services provided by the Company, which arise during the use of these services, or in relation to their use. Each user shall be obliged to become familiar with these Terms and Conditions and express consent to them before the beginning of the use of the services. Otherwise, you shall not be entitled to use the services.",
      "introductory": {
        "1": " The Company provides through the Challingo.com website or related mobile applications services enabling its users to create and share with each other requests and other content (hereinafter the &ldquo;<strong>Services</strong>&rdquo;). These requests of users and their other content are not created and provided by the Company, and the Company shall not bear responsibility for them. Use of the Services it is at your own responsibility and risk and is governed by these Terms and Conditions, which have the nature of an agreement concluded between you and the Company. An integral part of these Terms and Conditions are the Principles for the Protection of Personal and Other Data.",
        "2": "In order to use the Services, you will need to register with the Company via the Challingo.com website or related mobile applications. By registering, you confirm that you were familiarized with these Terms and Conditions, that you understood them and that they represent a binding determination of rights and obligations between you and the Company arising during the use of the Services, or in relation to the use thereof. If you do not agree with these Terms and Conditions, you shall not be entitled to use the Services. You may approach the Services in various way and these may vary. These Terms and Conditions are binding for any use of the Services regardless of the method and place of access to them, and they replace all other verbal and written declarations and arrangements between you and the Company. By registering, you confirm that the use of the Services is not illegal for you from the location from which you are registering, and at all of the locations where you will be accessing the Services, and that you are eligible to be bound by these Terms and Conditions for an indefinite period of time.",
        "3": "Use of some of the Services or their functions may be governed by special terms and conditions which may supplement or replace these Terms and Conditions in whole or in part. Unless specified otherwise in these special terms and conditions, these special terms and conditions have the nature of addendum to these Terms and Conditions, and these Terms and Conditions shall be used in the scope in which they are not at variance with the special terms and conditions.",
        "4": " Use of some of the Services or their functions may be territorially restricted.",
        "5": "The Company reserves the right to provide the Services to certain users under individually-agreed terms and conditions.",
        "6": "These Terms and Conditions shall be valid and effective from the date of the beginning of the use of the Services. The wording of these Terms and Conditions may be unilaterally changed or supplemented by the Company at any time. You will be informed about any change or supplement to the Terms and Conditions in the manner specified in Article V of these Terms and Conditions. The effect of a change or supplement may occur as soon as a new version of the Terms and Conditions is published, or on a specific date determined by the Company. By using the Services after the date of effect of a new version of the Terms and Conditions, you express your consent with the updated wording of the Terms and Conditions. This provision shall not affect the rights and obligations arisen during the period of effect of the prior wording of the Terms and Conditions.",
        "7": "These Terms and Conditions, including their previous versions, are available through the Challingo.com website and related mobile applications, where they can be viewed by anyone. The Company stores the Terms and Conditions concluded with individual users, but it does not provide users access to them.",
        "title": "Introductory Provisions"
      },
      "registration": {
        "1": "You can register by filling-in the required data via the Challingo.com website or related mobile applications. After filling-in the required data, you will be given login information and a user account will be created for you (hereinafter the &ldquo;<strong>Account</strong>&rdquo;). You are expressly requesting the Company to begin providing the Services to you immediately after you register. You can find information about the software and hardware requirements for the use of the Services on the Challingo.com website in the &ldquo;About us&rdquo; section. Your Account environment will guide you in the use of the Services.",
        "10": "The Company shall be entitled to temporarily deny you access to your Account or cancel your Account without compensation, without giving any reason and without notice. These steps will be taken by the Company in particular in the event of breach or suspected breach of these Terms and Conditions or binding legal regulations and standards, and in the event that you do not login to your Account for two years. If your Account is cancelled by the Company, you are not authorized to create a new Account without the Company&rsquo;s consent. Cancellation of your Account by the Company will result in a withdrawal from these Terms and Conditions (and therefore a withdrawal from the provision of the Services) by the Company.",
        "11": "You may permanently cancel your Account at any time without giving a reason, and thereby withdraw from these Terms and Conditions. For example, you may make a notice on withdrawal through your Account in the &ldquo;Send us feedback&rdquo; section, or by sending a sample form to the e-mail or postal address of the Company. A withdrawal shall come into effect as soon as the notice on a withdrawal is delivered to the Company. After the Company receives such a notice, the Company will send you a confirmation on having received the notice on withdrawal from these Terms and Conditions.",
        "12": "Cancelling your Account shall not affect the rights and obligations arising before the cancellation.",
        "13": "After cancelling your Account, the Company shall be entitled to store the Content and information relating to your Account for a reasonable period, and for the period required by valid legal regulations in relation to specific information.",
        "14": "You acknowledge that if you shared the Content with other users of the Services, the Content may be available without a time restriction even after your Account is cancelled.",
        "15": "Cancelling your Account means the loss and revocation of all entitlements and benefits provided by the Company in relation to your Account.",
        "16": "The Services are provided via remote means of communication. You are responsible for paying the costs for the use of these means, such as the internet and phone connection, and these do not differ from basic rates.",
        "2": "The Services may only be used by persons older than the age of 13. If you are under 18, you will only be entitled to user the Services with the consent of a legal representative and under his or her supervision.",
        "3": "Each person may only have one Account.",
        "4": "Access to an Account is protected by login data. You may not make the login data accessible to a third party or allow any third party to use your Account. You undertake to take the necessary steps to protect your login data and Account. It is not permitted to create an Account for another person, use the Account of another person or handle an Account in any way other than what arises from these Terms and Conditions, and from the purpose of the Services.",
        "5": "You declare that that all of the data you provided to the Company during registration or otherwise is precise, complete and true, and you undertake to immediately inform the Company of any potential changes thereto.",
        "6": "Handling of personal and other data that you provide to the Company is governed by the Principles for the Protection of the Personal and Other Data.",
        "7": "The Company does not guarantee the availability of the Services or access to your Account. The availability of the Services and access to your Account may be restricted in particular in view of planned or necessary maintenance and system changes.",
        "8": "The Company shall not be responsible for the loss of any information, texts, user names, nicknames, passwords, messages and other communications, designations, pictures, photographs, graphics, videos, sounds, songs, voice recordings, applications, programs, copyright works, links, data, files or any other content (hereinafter the &ldquo;<strong>Content</strong>&rdquo;) created, uploaded, published, stored, shared or distributed through your Account. The term Content used in these Terms and Conditions includes requests.",
        "9": "The Company shall be entitled to remove the Content without any compensation, without giving any reason and without notice. These steps will be taken by the Company in particular in the event of breach or suspected breach of these Terms and Conditions or binding legal regulations and standards. The Company shall be entitled to monitor the Content and determine technical and other restrictions for the Content.",
        "title": "Registration, Account and Availability of the Services"
      },
      "title": "TERMS AND CONDITIONS FOR USING CHALLINGO SERVICES",
      "use": {
        "1": "The Services provided by the Company allow their users to create, upload, publish, store, share or distribute the Content and otherwise communicate with each other. However, the Company does not create the Content on its own, nor does it provide it, approve it, promote it or guarantee it, and the Company shall not be responsible for it. Use of the Services is solely at your responsibility and risk. The fulfilment of requests is your voluntary decision, and you do so at your own responsibility and risk. The Company does not take any legally enforceable action through user requests toward you or other users. When creating, fulfilling, sharing or distributing requests, and during the use of the Services, you are generally obliged to adhere to valid legal regulations and standards. You acknowledge that creating, fulfilling, sharing or distributing requests may fulfil the characteristics of a legal action by which you will be committed to a certain legally enforceable fulfilment.",
        "10": "When using the Services, prudence is recommended with respect to your privacy. It is up to your decisions, responsibilities and risk what content you publish through the Account, who you share it with and who you distribute it amongst. You can affect the publishing, sharing, and distributing of Content via your Account settings. You acknowledge that certain Account settings allow you to subsequently share and distribute your Content without your subsequent consent or knowledge, even after you have changed the settings or cancelled your Account. You acknowledge that the Services are provided over the Internet, which can lead to a security breach, and through which computer viruses can spread. Linking the Services and your Account to third party services and products is at your own responsibility and risk. The use of third party services and products may be subject to the specific terms and conditions of such third parties.",
        "11": "It is prohibited to in any way affect the software and environment of the Services and change them. It is prohibited to in any way distort or threaten the operation of the Services, in particular by implementing or spreading viruses, spyware and malware, and by affecting servers or source codes.",
        "12": "It is prohibited to in any way use the Services using automated procedures and algorithms. It is prohibited to use the Services in a manner that could lead to their overloading.",
        "2": "You shall be fully responsible for all of the Content created, uploaded, published, stored, shared or distributed through your Account. The Company does not check the Content. You shall also be fully responsible for all of your communication and other interaction with other users.",
        "3": "It is prohibited to create, upload, publish, store, share or distribute Content that contains, depicts, illustrates, promotes, approves or encourages:",
        "4": "It is prohibited to upload, publish, store, share or distribute Content that affects copyright or other rights of third parties.",
        "5": " With regard to all of the Content that you publish through your Account, you grant the Company non-exclusive authorization for all ways of use (hereinafter the &ldquo;<strong>Licence</strong>&rdquo;). The Company shall be entitled to use the subject of the Licence in its original, processed, modified or otherwise altered form. You grant the License to the Company for the entire world without any time and quantity restrictions. You grant the License to the Company for free and without any entitlement to additional remuneration. You also grant the Company consent to assign the License or part thereof to a third party and consent to the granting of the authorization forming License or part thereof to a third party.",
        "6": "You grant the Company consent with the commercial use of your attributes of a personal nature, such as your name, nickname, photographs or likeness, and others that that you publish through your Account.",
        "7": "You grant the Company consent with the placement of advertisements and commercial communications in your Content.",
        "8": "You declare that:",
        "8a": "you are authorized to upload, publish, store, share or distribute all of the Content that you do upload, publish, store, share or distribute, and that through such conduct you are not illegally affecting&nbsp; the copyright or other rights of third parties;",
        "8b": "you are eligible to grant to the Company the Licence in the scope specified in Item 5 of this Article; and",
        "8c": "that you shall compensate all damages and non-pecuniary damages, due royalty payments, remunerations and other entitlements that may arise for third parties and the Company in the event that your declarations specified in letters a) and/or b) of this point prove to be untrue.",
        "9": "When using the Services, you shall be obliged to respect other users, not restrict them in the use of the Services and not to send them undesired content (spam).",
        "a": "violence and endangering life and health;",
        "b": "hatred and discrimination;",
        "c": "insults, humiliation, bullying and abuse;",
        "d": "stalking, harassment, threats and extortion;",
        "e": "interference with privacy, impersonation of others, unauthorized sharing of personal or other private or secret data;",
        "f": "nudity, pornography and other sexually explicit content;",
        "g": "the use of prohibited substances and the use of prohibited substances by minors;",
        "h": "fraud and dissemination of alarm messages;",
        "i": "trafficking of prohibited items; and",
        "j": "any other illegal or unlawful actions.",
        "title": " Use of the Services, Requests and Content"
      }
    },
    "to_homepage": "Go to homepage",
    "updateApp": "Please update the application",
    "user": {
      "auth/email-already-in-use": "The e-mail address already uses another account.",
      "auth/invalid-email": "E-mail address is incorrectly typed.",
      "auth/network-request-failed": "Network request failed.",
      "auth/too-many-requests": "Too many authorization requests.",
      "auth/user-not-found": "The user does not exist.",
      "auth/weak-password": "The password must contain at least 6 characters.",
      "auth/wrong-password": "The password is invalid.",
      "bad_linktree_url": "Allowed URLs: Instagram, Facebook, Twitter, TikTok, Youtube, LinkedIn, Linktr.ee, Twitch, Pinterest",
      "bio_too_long": "Your bio is too long",
      "create_account": "Create account",
      "email": "E-mail",
      "email_question": "What’s Your e-mail?",
      "email_used": "The e-mail already uses another account.",
      "email_was_sent": "E-mail was sent.",
      "emoji_in_username": "Emoji in username are prohibited",
      "login": "Log in",
      "login_to_continue": "Log in to continue",
      "logout": "Log out",
      "name": "Nickname",
      "name_error_space": "You cannot use space in your name.",
      "name_question": "What’s Your Nickname?",
      "name_used": "The nickname already uses another account.",
      "new": "New",
      "password": "Password",
      "password_changed": "The password has been changed. You can now log in.",
      "password_confirm": "Password confirmation",
      "password_current": "Current password",
      "password_current_error": "Current password does not match.",
      "password_forgot": "Forgot password?",
      "password_info": "Create Your Password",
      "passwords_must_match": "Passwords must match.",
      "passwords_required": "Please fill in your password and password confirmation.",
      "signin": "Log in",
      "signup": "Sign up",
      "submit_error": "Unable to create user.",
      "type": "User",
      "username_too_long": "Username is too long",
      "username_too_short": "Username is too short",
      "welcome": "Welcome to Challingo!",
      "welcome_tip": "We will help you create a <b>new account</b> in a few easy steps."
    },
    "users": "Users",
    "users-recommended": "Recommended Users",
    "video": {
      "max_length_error": "Max. length of the video is"
    },
    "view": "View",
    "wait": "Wait...",
    "welcome-home": {
      "btn": "Start following",
      "doSomething": "do something",
      "info": "Start following users and explore more chally",
      "logoAlt": "challingo",
      "title": "One last thing ..."
    },
    "yes": "Yes",
    "zero": "0"
  };
  _exports.default = _default;
});