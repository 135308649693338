define("challingo-mobile/templates/challenges/show", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "fal1/cKM",
    "block": "{\"symbols\":[],\"statements\":[[1,[27,\"nav-bar\",null,[[\"hasCalendar\",\"title\",\"back\",\"messages\",\"spacer\",\"transparent\"],[true,[23,[\"model\",\"title\"]],\"back\",true,false,true]]],false],[0,\"\\n\\n\"],[1,[27,\"challenges/challenge-detail\",null,[[\"challenge\",\"challengeAcceptedId\",\"scrollToDescription\",\"toComments\"],[[23,[\"model\"]],[23,[\"challengeAcceptedId\"]],[23,[\"scrollToDescription\"]],[23,[\"toComments\"]]]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "challingo-mobile/templates/challenges/show.hbs"
    }
  });

  _exports.default = _default;
});