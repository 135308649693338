define("challingo-mobile/components/followers-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    currentUser: Ember.inject.service(),
    intl: Ember.inject.service(),
    store: Ember.inject.service(),
    classNames: ['followers-list'],
    classNameBindings: ['isOpen'],
    model: null,
    isOpen: true,
    followedBy: true,
    actions: {
      closeFollow: function closeFollow() {
        history.back();
      }
    }
  });

  _exports.default = _default;
});