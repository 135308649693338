define("challingo-mobile/utils/log-analytics-event", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = logAnalyticsEvent;

  function logAnalyticsEvent(firebaseApp, eventName) {
    var eventOptions = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};

    if (window && window.cordova && window.cordova.plugins && window.cordova.plugins.firebase && window.cordova.plugins.firebase.analytics && window.cordova.plugins.firebase.analytics.logEvent) {
      return window.cordova.plugins.firebase.analytics.logEvent(eventName, eventOptions);
    } else {
      return firebaseApp && firebaseApp.analytics().then(function (analytics) {
        return analytics && analytics.logEvent(eventName, eventOptions);
      });
    }
  }
});