define("challingo-mobile/templates/challenges/nominated", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "x21eBN62",
    "block": "{\"symbols\":[],\"statements\":[[1,[27,\"liked-by-page\",null,[[\"challenge\",\"challengeAcceptedId\",\"pageTitle\",\"prop\"],[[23,[\"model\"]],[23,[\"challengeAcceptedId\"]],[27,\"t\",[\"challenge.nominated\"],null],\"nominated\"]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "challingo-mobile/templates/challenges/nominated.hbs"
    }
  });

  _exports.default = _default;
});