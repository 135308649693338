define("challingo-mobile/models/challenge", ["exports", "ember-data", "challingo-mobile/utils/logger", "challingo-mobile/utils/model-with-media", "challingo-mobile/utils/log-analytics-event", "emberfire/services/realtime-listener", "challingo-mobile/utils/hashtags"], function (_exports, _emberData, _logger, _modelWithMedia, _logAnalyticsEvent, _realtimeListener, _hashtags) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.commentSortFunc = void 0;

  var commentSortFunc = function commentSortFunc(a, b) {
    var dateA = new Date(a.get('createdAt'));
    var dateB = new Date(b.get('createdAt'));
    return dateA > dateB ? 1 : dateB > dateA ? -1 : 0;
  };

  _exports.commentSortFunc = commentSortFunc;

  var _default = _modelWithMedia.default.extend({
    challenges: Ember.inject.service(),
    confirmDialog: Ember.inject.service(),
    currentUser: Ember.inject.service(),
    firebaseApp: Ember.inject.service(),
    intl: Ember.inject.service(),
    modal: Ember.inject.service(),
    pushNotification: Ember.inject.service(),
    session: Ember.inject.service(),
    store: Ember.inject.service(),
    toastMessage: Ember.inject.service(),
    _isReported: _emberData.default.attr('boolean'),
    accepted: _emberData.default.hasMany('accepted-challenge', {
      inverse: 'challenge'
    }),
    averageDifficulty: _emberData.default.attr('number', {
      defaultValue: 0
    }),
    averageRating: _emberData.default.attr('number', {
      defaultValue: 0
    }),
    category: _emberData.default.belongsTo('category', {
      inverse: null
    }),
    comments: _emberData.default.hasMany('comment', {
      inverse: null
    }),
    createdAt: _emberData.default.attr('date', {
      defaultValue: function defaultValue() {
        return new Date();
      }
    }),
    creator: _emberData.default.belongsTo('user', {
      inverse: null
    }),
    description: _emberData.default.attr('string'),
    difficulties: _emberData.default.attr('number', {
      defaultValue: 0
    }),
    hidden: _emberData.default.attr('boolean'),
    isAvailableForDrawn: _emberData.default.attr('boolean'),
    lastLikedAt: _emberData.default.attr('date'),
    likes: _emberData.default.attr('number', {
      defaultValue: 0
    }),
    nominated: _emberData.default.hasMany('user', {
      inverse: null
    }),
    ratings: _emberData.default.attr('number', {
      defaultValue: 0
    }),
    reported: _emberData.default.attr('date'),
    shortUrl: _emberData.default.attr('string'),
    tags: _emberData.default.attr('string'),
    title: _emberData.default.attr('string'),
    trendyWeight: _emberData.default.attr('number', {
      defaultValue: 0
    }),
    acceptedByUser: Ember.computed('accepted.@each.isAcceptedByUser', function () {
      return this.accepted.findBy('isAcceptedByUser');
    }),
    boomerang: Ember.computed.alias('media.boomerang'),
    commentsSorted: Ember.computed.sort('comments', commentSortFunc),
    completed: Ember.computed('accepted.@each.{completed,hidden}', function () {
      return this.accepted.filterBy('completed').filterBy('hidden', false);
    }),
    completedByUser: Ember.computed('completed.@each.isCompletedByUser', function () {
      return this.completed.findBy('isCompletedByUser');
    }),
    completedCount: Ember.computed.alias('completed.length'),
    creatorId: Ember.computed.alias('creator.id'),
    descriptionParsed: Ember.computed('description', function () {
      return (0, _hashtags.wrapHashtags)(this.get('description'));
    }),
    isCompletedByUser: Ember.computed.bool('completedByUser'),
    isCreatedByUser: Ember.computed('creator.id', 'currentUser.user.id', function () {
      return this.get('creator.id') === this.get('currentUser.user.id');
    }),
    kingsChallenge: Ember.computed.alias('sortedSliderCards.firstObject'),
    lastCompleted: Ember.computed('completed.@each.completed', function () {
      return this.completed.sortBy('completed').get('lastObject');
    }),
    likedBy: null,
    likedByIds: Ember.computed('likedBy.{[],length}', function () {
      var likedBy = this.get('likedBy');
      return (likedBy || []).map(function (item) {
        return item && item.belongsTo('userFrom').id();
      }).compact();
    }),
    likedByLoading: null,
    likedByUsers: Ember.computed('likedBy.{[],length}', function () {
      var likedBy = this.get('likedBy');
      return (likedBy || []).map(function (item) {
        return item.get('userFrom');
      });
    }),
    photo: Ember.computed.alias('media.photo'),
    photoMedium: Ember.computed('photo', function () {
      if (!this.photo) {
        return null;
      }

      return this.photo.replace('/upload', '/upload/w_768');
    }),
    photoSmall: Ember.computed('photo', function () {
      if (!this.photo) {
        return null;
      }

      return this.photo.replace('/upload', '/upload/c_thumb,h_180,w_180');
    }),
    photoThumb: Ember.computed('photo', function () {
      if (!this.photo) {
        return null;
      }

      return this.photo.replace('/upload', '/upload/w_300');
    }),
    sortedSliderCards: Ember.computed('likes', 'completed.@each.{sorting,likes,hidden}', function () {
      var out = this.get('completed').slice();
      out.pushObject(this);
      return out.sortBy('sorting').reverse().sortBy('likes').filterBy('hidden', false).reverse();
    }),
    sorting: Ember.computed.alias('createdAt'),
    tagsParsed: Ember.computed('tags', function () {
      if (this.tags) {
        return (0, _hashtags.wrapHashtags)(this.tags);
      }
    }),
    type: Ember.computed(function () {
      return this.get('constructor.modelName');
    }),
    video: Ember.computed.alias('media.video'),
    acceptAction: function acceptAction(initCallback, finallyCallback) {
      var _this = this;

      if (initCallback) {
        initCallback();
      }

      var user = this.get('currentUser.user');
      return this.store.createRecord('accepted-challenge', {
        user: user,
        challenge: this
      }).save().then(function (savedAcceptedChallenge) {
        _this.store.query('push-notification', {
          where: [['challenge', '==', _this.get('id')], ['forUser', '==', _this.get('creator.id')], ['fromUser', '==', user.get('id')], ['type', '==', 'accept']]
        }).then(function (items) {
          if (!items || !items.length) {
            var forUser = _this.get('creator');

            _this.pushNotification.send(forUser, 'accept', _this.intl.t('push-notifications.accept', {
              locale: forUser && forUser.get('localeAugmented')
            }), user, _this, null);
          }
        });

        _this.store.createRecord('user-accepted-challenge', {
          challenge: _this,
          challengeAccepted: savedAcceptedChallenge,
          createdAt: new Date(),
          hidden: false,
          user: user
        }).save();

        _this.challenges.userProfileAccepted.challenges.unshiftObject(_this);

        _this.save({
          adapterOptions: {
            saveOnly: ['accepted']
          }
        }).then(function () {
          (0, _logAnalyticsEvent.default)(_this.firebaseApp, 'chally_accepted', {
            chally_id: _this.id,
            user_id: _this.get('currentUser.uid')
          });

          _this.toastMessage.show(_this.intl.t('challenge.accept_submit_success', {
            htmlSafe: true
          }), true);
        }).finally(function () {
          if (finallyCallback) {
            finallyCallback();
          }
        });
      }, function () {
        _this.toastMessage.show(_this.intl.t('challenge.accept_submit_error'));
      }).finally(function () {
        if (finallyCallback) {
          finallyCallback();
        }
      });
    },
    accept: function accept(initCallback, finallyCallback) {
      var _this2 = this;

      var confirm = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : true;

      if (!this.get('session.isAuthenticated')) {
        this.modal.show('modals/login-popup', null, {
          noStyle: true
        });
        return;
      }

      if (confirm) {
        this.confirmDialog.show(this.intl.t('challenge.accept_confirm'), function () {
          return _this2.acceptAction(initCallback, finallyCallback);
        });
      } else {
        return this.acceptAction(initCallback, finallyCallback);
      }
    },
    loadLikes: function loadLikes() {
      var _this3 = this;

      var force = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;

      if (!this.get('likedBy') && !this.get('likedByLoading') || force) {
        this.store.query('like', {
          orderBy: {
            'createdAt': 'desc'
          },
          where: [['challenge', '==', this.id]]
        }).then(function (likedBy) {
          _this3.setProperties({
            likedBy: likedBy,
            likedByLoading: false
          });

          (0, _realtimeListener.subscribe)(_this3, likedBy);
          return likedBy;
        }, function (e) {
          _this3.setProperties({
            likedByLoading: false
          });

          _logger.default.error(e);
        });
      }
    }
  });

  _exports.default = _default;
});