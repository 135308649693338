define("challingo-mobile/components/search-page", ["exports", "challingo-mobile/utils/search-component"], function (_exports, _searchComponent) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // import { loaderHide, loaderShow } from '../routes/application';
  var FILTERED_CHALLY_ALL_LIMIT = 15;
  var FILTERED_USERS_ALL_LIMIT = 10;

  var getSortFunc = function getSortFunc(prop) {
    return function (a, b) {
      return b.get(prop) - a.get(prop);
    };
  };

  var _default = _searchComponent.default.extend({
    challengesService: Ember.inject.service('challenges'),
    currentUser: Ember.inject.service(),
    search: Ember.inject.service(),
    challenges: null,
    challengesAccepted: null,
    challengesAcceptedReported: null,
    challengesReported: null,
    classNames: ['search-page'],
    pageFilter: 'all',
    resetSearchUponInit: false,
    resetSearchUponDestroy: false,
    searchValue: '',
    users: null,
    challengesRandomFiltered: Ember.computed.filterBy('challengesRandom', 'isAvailableForDrawn', true),
    challyReported: Ember.computed('challengesAcceptedReported.@each.{hidden,reported}', 'challengesReported.@each.{hidden,reported}', function () {
      var challenges = (this.challengesReported || []).filterBy('hidden', false).filterBy('reported');
      var challengesAccepted = (this.challengesAcceptedReported || []).filterBy('hidden', false).filterBy('reported');
      return challenges.toArray().concat(challengesAccepted.toArray()).uniqBy('id').sortBy('reported').reverse();
    }),
    usersCombined: Ember.computed('users.@each.createdAt', 'search.{searchStatus,users.@each.createdAt}', function () {
      return this.get('searchFinished') ? this.get('search.users') : this.get('challengesService.usersall.users');
    }),
    challengesCombined: Ember.computed('challengesService.searchall.challenges.@each.createdAt', 'search.{searchStatus,challenges.@each.createdAt}', function () {
      return this.get('searchFinished') ? this.get('search.challenges') : this.get('challengesService.searchall.challenges');
    }),
    challengesAcceptedCombined: Ember.computed('challengesService.searchall.challengesAccepted.@each.completed', 'search.{searchStatus,acceptedChallenges.@each.completed}', function () {
      return this.get('searchFinished') ? this.get('search.acceptedChallenges') : this.get('challengesService.searchall.challengesAccepted');
    }),
    usersSorted: Ember.computed.sort('usersCombined.@each._totalChally', getSortFunc('_totalChally')),
    challySorted: Ember.computed.sort('challengesCombined.@each.createdAt', getSortFunc('createdAt')),
    challyAcceptedSorted: Ember.computed.sort('challengesAcceptedCombined.@each.completed', getSortFunc('completed')),
    challyVisible: Ember.computed.filterBy('challySorted', 'hidden', false),
    challyAcceptedVisible: Ember.computed.filterBy('challyAcceptedSorted', 'hidden', false),
    usersUniq: Ember.computed.uniqBy('usersSorted', 'id'),
    challyUniq: Ember.computed.uniqBy('challyVisible', 'id'),
    challyAcceptedUniq: Ember.computed.uniqBy('challyAcceptedVisible', 'id'),
    firstThreeUsers: Ember.computed('usersUniq.@each.createdAt', function () {
      return this.usersUniq.slice(0, 3);
    }),
    firstThreeChally: Ember.computed('challyAll.@each.createdAt', function () {
      return this.challyAll.slice(0, 3);
    }),
    firstNineChally: Ember.computed('challyAll.@each.createdAt', function () {
      return this.challyAll.slice(0, 9);
    }),
    usersFiltered: Ember.computed.alias('usersUniq'),
    usersFilteredLimit: Ember.computed('usersFiltered.@each.createdAt', function () {
      return this.usersFiltered.slice(0, FILTERED_USERS_ALL_LIMIT);
    }),
    challyAll: Ember.computed('challyUniq.@each.createdAt', 'challyAcceptedUniq.@each.createdAt', function () {
      var challenges = (this.challyUniq || []).filterBy('hidden', false);
      var challengesAccepted = (this.challyAcceptedUniq || []).filterBy('hidden', false).filterBy('completed');
      return challenges.toArray().concat(challengesAccepted.toArray()).uniqBy('id').sortBy('sorting').reverse();
    }),
    challyFiltered: Ember.computed.alias('challyAll'),
    challyFilteredLimit: Ember.computed('challyFiltered.@each.createdAt', function () {
      return this.challyFiltered.slice(0, FILTERED_CHALLY_ALL_LIMIT);
    }),
    showButtonUsers: Ember.computed('searchValue', 'usersFiltered.length', 'usersFilteredLimit.length', function () {
      return this.get('usersFiltered.length') > this.get('usersFilteredLimit.length');
    }),
    showButtonChally: Ember.computed('searchValue', 'challyFiltered.length', 'challyFilteredLimit.length', function () {
      return this.get('challyFiltered.length') > this.get('challyFilteredLimit.length');
    }),
    actions: {
      chooseFilter: function chooseFilter(filterName) {
        this.set('pageFilter', filterName);
      },
      nextChallyPage: function nextChallyPage() {
        if (this.get('searchValue')) {
          this.search.nextPage();
        } else {
          this.challengesService.searchall.nextPage();
        }
      },
      nextUserPage: function nextUserPage() {
        if (this.get('searchValue')) {
          this.search.nextPage();
        } else {
          this.challengesService.usersall.nextPage();
        }
      }
    }
  });

  _exports.default = _default;
});