define("challingo-mobile/templates/components/lazy-loading-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "fae0W17d",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"if\",[[27,\"gt\",[[23,[\"pageBoundary\",\"top\"]],0],null]],null,{\"statements\":[[0,\"  \"],[7,\"div\"],[11,\"class\",\"loading\"],[9],[0,\"\\n    \"],[7,\"i\"],[11,\"class\",\"challingo-icons ci-refresh rotate hidden\"],[9],[10],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[7,\"div\"],[11,\"class\",\"list\"],[9],[0,\"\\n  \"],[14,1,[[23,[\"list\"]]]],[0,\"\\n\"],[10],[0,\"\\n\"],[1,[27,\"scroll-restore\",null,[[\"target\"],[\".lazy-loading-list\"]]],false],[0,\"\\n\"],[4,\"if\",[[27,\"lt\",[[23,[\"pageBoundary\",\"bottom\"]],[23,[\"items\",\"length\"]]],null]],null,{\"statements\":[[0,\"  \"],[7,\"div\"],[11,\"class\",\"loading\"],[9],[0,\"\\n    \"],[7,\"i\"],[11,\"class\",\"challingo-icons ci-refresh rotate hidden\"],[9],[10],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "challingo-mobile/templates/components/lazy-loading-list.hbs"
    }
  });

  _exports.default = _default;
});