define("challingo-mobile/routes/search", ["exports", "challingo-mobile/mixins/remember-scroll"], function (_exports, _rememberScroll) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_rememberScroll.default, {
    challenges: Ember.inject.service(),
    currentUser: Ember.inject.service(),
    firstLoad: true,
    model: function model() {
      if (this.get('firstLoad')) {
        // this.set('firstLoad', false);
        var result = {
          challenges: this.challenges.searchall.load(),
          usersall: this.challenges.usersall.load()
        };

        if (this.currentUser.isAdmin) {
          result.challengesAcceptedReported = this.store.query('accepted-challenge', {
            orderBy: {
              completed: 'desc'
            },
            where: [['hidden', '==', false], ['_isReported', '==', true]]
          });
          result.challengesRandom = this.store.query('challenge', {
            orderBy: {
              createdAt: 'desc'
            },
            where: [['hidden', '==', false], ['isAvailableForDrawn', '==', true]]
          });
          result.challengesReported = this.store.query('challenge', {
            orderBy: {
              createdAt: 'desc'
            },
            where: [['hidden', '==', false], ['_isReported', '==', true]]
          });
        }

        return Ember.RSVP.hash(result);
      }
    }
  });

  _exports.default = _default;
});