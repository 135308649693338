define("challingo-mobile/utils/challenges/user-profile", ["exports", "challingo-mobile/utils/challenges/collection", "challingo-mobile/utils/logger"], function (_exports, _collection, _logger) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var callbackGenerator = function callbackGenerator(context, collection) {
    return function (items) {
      if (items && items.length) {
        items.compact().forEach(function (item) {
          return item.get('media');
        });
        context.get(collection).pushObjects(items.compact().toArray());
      }
    };
  };

  var _default = _collection.default.extend({
    modelName: 'user-challenge',
    perPage: 24,
    user: null,
    getQueryWhere: function getQueryWhere() {
      return [['hidden', '==', false], ['user', '==', this.get('user.id')]];
    },
    load: function load() {
      var _this = this;

      return this.store.query(this.get('modelName'), {
        limit: this.get('perPage'),
        orderBy: {
          'createdAt': 'desc'
        },
        startAfter: this.get('startAfter.user'),
        where: this.getQueryWhere()
      }).then(function (userItems) {
        var dates = userItems.mapBy('createdAt');

        if (dates.length) {
          var date = dates.pop();

          _this.set("startAfter.user", date.toISOString());
        }

        return Ember.RSVP.hash({
          challenges: Ember.RSVP.all(userItems.map(function (item) {
            return item.get('challenge');
          })).then(callbackGenerator(_this, 'challenges')),
          challengesAccepted: Ember.RSVP.all(userItems.map(function (item) {
            return item.get('challengeAccepted');
          })).then(callbackGenerator(_this, 'challengesAccepted'))
        });
      }, function (e) {
        _logger.default.error(e);
      });
    },
    resetUser: function resetUser(user) {
      if (user && user.id !== this.get('user.id')) {
        this.setProperties({
          user: user
        });
        this.unload();
      }
    },
    unload: function unload() {
      this._super.apply(this, arguments);

      this.setProperties({
        challenges: Ember.A([]),
        challengesAccepted: Ember.A([]),
        startAfter: {
          user: null
        }
      });
    }
  });

  _exports.default = _default;
});