define("challingo-mobile/components/user-rank", ["exports", "challingo-mobile/config/environment", "challingo-mobile/utils/badges-list", "challingo-mobile/utils/level-ranks", "challingo-mobile/utils/jquery-polyfills"], function (_exports, _environment, _badgesList, _levelRanks, _jqueryPolyfills) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    modal: Ember.inject.service(),
    badgeData: null,
    classNames: ['user-rank'],
    influencerFollowers: null,
    influencerIcon: null,
    levelRankData: null,
    maxLevel: null,
    ranks: Object.freeze(['Newbie', 'No rank']),
    userActual: Ember.computed.alias('model'),
    creativeObserver: Ember.observer('userActual.createdCount', function () {
      var _this = this;

      var badges = this.get('badgeData');

      if (badges) {
        badges.forEach(function (badge) {
          if (badge.id == 'creative') {
            Ember.set(badge, 'count', _this.get('userActual.createdCount'));
          }
        });
      }
    }),
    doerObserver: Ember.observer('userActual.completedCount', function () {
      var _this2 = this;

      var badges = this.get('badgeData');

      if (badges) {
        badges.forEach(function (badge) {
          if (badge.id == 'doer') {
            Ember.set(badge, 'count', _this2.get('userActual.completedCount'));
          }
        });
      }
    }),
    isInfluencer: Ember.computed('userActual.followersCount', function () {
      return this.get('userActual.followersCount') >= this.get('influencerFollowers');
    }),
    init: function init() {
      this._super.apply(this, arguments);

      var badges = (0, _badgesList.default)();
      var user = this.get('userActual');

      if (badges) {
        badges.forEach(function (badge) {
          switch (badge.id) {
            case 'creative':
              badge.count = user && user.get('createdCount') || 0;
              break;

            case 'doer':
              badge.count = user && user.get('completedCount') || 0;
              break;
          }
        });
      }

      this.setProperties({
        badgeData: badges,
        influencerFollowers: _environment.default.APP.influencerFollowers,
        influencerIcon: _environment.default.APP.influencerIcon,
        levelRankData: (0, _levelRanks.default)(),
        maxLevel: _environment.default.APP.maxLevel
      });
    },
    actions: {
      setRank: function setRank(rank) {
        if (event.target) {
          var p = (0, _jqueryPolyfills.parents)(event.target, '.disabled');

          if (!event.target.classList.contains('disabled') && !p.length) {
            var user = this.userActual;
            user.set('rank', rank);
            this.modal.close();
          }
        }
      }
    }
  });

  _exports.default = _default;
});