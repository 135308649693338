define("challingo-mobile/components/comment-item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    currentUser: Ember.inject.service(),
    tagName: 'tr',
    classNames: ['comment-item'],
    comments: null,
    item: null,
    didInsertElement: function didInsertElement() {
      var _this = this;

      this._super.apply(this, arguments);

      this.element && this.element.addEventListener('press', function () {
        if (_this.get('item').get('user.id') === _this.get('currentUser.user.id')) {
          Ember.tryInvoke(_this, 'onDelete');
        }
      });
      longClick(this.element, 500);
    }
  });

  _exports.default = _default;
});