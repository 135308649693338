define("challingo-mobile/components/menu-profile", ["exports", "challingo-mobile/mixins/swipe-events"], function (_exports, _swipeEvents) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_swipeEvents.default, {
    currentUser: Ember.inject.service(),
    menuProfile: Ember.inject.service(),
    router: Ember.inject.service(),
    classNames: ['menu-profile'],
    classNameBindings: ['isOpen'],
    isOpen: Ember.computed.alias('menuProfile.isOpen'),
    user: Ember.computed.alias('currentUser.user'),
    stayOpen: Ember.computed.alias('menuProfile.stayOpen'),
    previousPage: '',
    swipeLeft: function swipeLeft() {},
    swipeRight: function swipeRight() {
      this.send('close');
    },
    actions: {
      check: function check() {
        this.menuProfile.check();
      },
      close: function close() {
        this.menuProfile.close();
      },
      logout: function logout() {
        this.send('close');
        this.send('transitionTo', 'logout');
      },
      open: function open() {
        this.menuProfile.open();
      },
      transitionTo: function transitionTo() {
        var _this$router;

        (_this$router = this.router).transitionTo.apply(_this$router, arguments);
      }
    }
  });

  _exports.default = _default;
});