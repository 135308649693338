define("challingo-mobile/components/toast-message", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    toastMessage: Ember.inject.service(),
    classNames: ['toast-message'],
    classNameBindings: ['isOpen:open', 'isBlue:blue'],
    isOpen: Ember.computed.alias('toastMessage.isOpen'),
    isBlue: Ember.computed.alias('toastMessage.isBlue'),
    text: Ember.computed.alias('toastMessage.text')
  });

  _exports.default = _default;
});