define("challingo-mobile/components/forms/user-edit-form", ["exports", "challingo-mobile/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    intl: Ember.inject.service(),
    modal: Ember.inject.service(),
    router: Ember.inject.service(),
    tagName: 'form',
    classNames: ['user-edit-form'],
    userActual: Ember.computed.alias('user'),
    user: null,
    loading: false,
    oldName: '',
    errorName: '',
    errorUrl: '',
    maxBioLength: Ember.computed(function () {
      return _environment.default.APP.maxBioLength;
    }),
    maxUsernameLength: Ember.computed(function () {
      return _environment.default.APP.maxUsernameLength;
    }),
    init: function init() {
      this._super.apply(this, arguments);

      this.set('oldName', this.get('user.name'));
    },
    saveInformations: function saveInformations() {
      var _this = this;

      this.user.set('nameLowercase', this.user.get('name').toLowerCase());
      this.user.save().then(function () {
        history.back();
      }, function () {
        if (!_this.isDestroyed) {
          _this.set('loading', false);
        }
      });
    },
    validateLinktreeUrl: function validateLinktreeUrl(url) {
      if (!url) {
        return true;
      }

      var re = /^(https?:\/\/)?(www\.)?(instagram\.com|facebook\.com|twitter\.com|tiktok\.com|youtube\.com|youtu\.be|linkedin\.com|linktr\.ee|twitch\.com|pinterest\.com)\/\S+$/;
      return Boolean(url.match(re));
    },
    submit: function submit() {
      var _this2 = this;

      this.setProperties({
        errorName: '',
        errorUrl: '',
        loading: true
      });

      if (this.oldName.toLowerCase() != this.get('user.name').toLowerCase()) {
        this.user.get('nameValidate').then(function () {
          _this2.saveInformations();
        }, function (error) {
          if (!_this2.isDestroyed) {
            _this2.setProperties({
              errorName: error,
              loading: false
            });
          }
        });
      } else if (this.get('user.bio.length') > this.maxBioLength) {
        this.setProperties({
          errorName: this.intl.t('user.bio_too_long'),
          loading: false
        });
      } else if (!this.validateLinktreeUrl(this.get('user.linktreeUrl'))) {
        this.setProperties({
          errorUrl: this.intl.t('user.bad_linktree_url'),
          loading: false
        });
      } else {
        this.saveInformations();
      }

      return false;
    },
    actions: {
      rankModalOpen: function rankModalOpen(e) {
        if (e.target) {
          e.target.blur();
        }

        this.modal.show('user-rank', this.userActual);
      },
      toPhotoUpload: function toPhotoUpload() {
        this.router.transitionTo('users.photo', this.get('user.id'));
      }
    }
  });

  _exports.default = _default;
});