define("challingo-mobile/components/challenges/challenge-item-mini", ["exports", "challingo-mobile/components/viewport-component"], function (_exports, _viewportComponent) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _viewportComponent.default.extend({
    router: Ember.inject.service(),
    currentUser: Ember.inject.service(),
    classNames: ['challenge-item-mini', 'clickable'],
    classNameBindings: ['media.isVideo:has-video', 'media.orientation'],
    attributeBindings: ['photo:style'],
    item: null,
    useViewport: true,
    isChallenge: Ember.computed.equal('item.type', 'challenge'),
    challenge: Ember.computed('isChallenge', 'item', 'item.challenge', function () {
      return this.isChallenge ? this.item : this.get('item.challenge');
    }),
    isChallengeAccepted: Ember.computed.equal('item.type', 'accepted-challenge'),
    challengeAccepted: Ember.computed('isChallengeAccepted', 'item', function () {
      return this.isChallengeAccepted ? this.item : undefined;
    }),
    supportsWebp: Ember.computed.alias('currentUser.hasWebP'),
    media: Ember.computed.alias('item.media'),
    photo: Ember.computed('{isInViewport,useViewport}', 'item.{boomerang,photoThumb}', 'media.isVideo', function () {
      var photo = this.get('supportsWebp') && this.get('media.isVideo') ? this.get('item.boomerang') : this.get('item.photoThumb');
      var style = "background-image: url('".concat(photo, "');");

      if (this.get('useViewport') && !this.get('isInViewport')) {
        return;
      }

      return Ember.String.htmlSafe(style);
    }),
    click: function click() {
      this.send('toDetail', this.get('challengeAccepted.id'));
    },
    actions: {
      toDetail: function toDetail(challengeAcceptedId) {
        this.router.transitionTo('challenges.show', this.challenge, {
          queryParams: {
            challengeAcceptedId: challengeAcceptedId,
            miniProfile: null
          }
        });
      }
    }
  });

  _exports.default = _default;
});