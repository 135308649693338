define("challingo-mobile/utils/challenges/usersall", ["exports", "challingo-mobile/utils/challenges/collection", "challingo-mobile/utils/logger"], function (_exports, _collection, _logger) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _collection.default.extend({
    users: null,
    load: function load() {
      var _this = this;

      return this.store.query('user', {
        limit: this.get('perPage'),
        orderBy: {
          _totalChally: 'desc',
          createdAt: 'desc'
        },
        startAfter: this.get('startAfter.users')
      }).then(function (items) {
        if (items && items.length) {
          var itemsArray = items.toArray();

          _this.get('users').pushObjects(itemsArray);

          if (itemsArray && itemsArray.length) {
            var last = itemsArray[itemsArray.length - 1];
            var date = last.get('createdAt');

            _this.set('startAfter.users', [last.get('_totalChally'), date && date.toISOString()]);
          }
        }
      }, function (e) {
        _logger.default.error(e);
      });
    },
    sortFunction: function sortFunction(a, b) {
      return (b.get('_totalChally') || 0) - (a.get('_totalChally') || 0);
    },
    unload: function unload() {
      this.setProperties({
        users: Ember.A([]),
        startAfter: {
          users: null
        }
      });
    }
  });

  _exports.default = _default;
});