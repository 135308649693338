define("challingo-mobile/utils/challenges/collection", ["exports", "challingo-mobile/utils/get-has-many-ids"], function (_exports, _getHasManyIds) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  var _default = Ember.Object.extend({
    currentUser: Ember.inject.service(),
    store: Ember.inject.service(),
    challenges: null,
    challengesAccepted: null,
    currentPage: 0,
    perPage: 20,
    startAfter: null,
    unsubscribedFrom: null,
    all: Ember.computed('challenges.@each.{hidden,sorting}', 'challengesAccepted.@each.{hidden,completed,sorting}', 'currentUser.user.blockeds.[]', 'unsubscribedFrom.[]', function () {
      var _this = this;

      var challenges = (this.challenges || []).filterBy('hidden', false);
      var challengesAccepted = (this.challengesAccepted || []).filterBy('completed').filterBy('hidden', false);
      var merged = challenges.toArray().concat(challengesAccepted.toArray()).uniqBy('id').sort(this.sortFunction);
      var blockedIds = (0, _getHasManyIds.default)(this.get('currentUser.user'), 'blockeds');
      return merged.reject(function (challenge) {
        var relationName = challenge.constructor.modelName === 'challenge' ? 'creator' : 'user';
        var userId = challenge.belongsTo(relationName).id();
        return [].concat(_toConsumableArray(blockedIds), _toConsumableArray(_this.get('unsubscribedFrom'))).includes(userId);
      });
    }),
    init: function init() {
      this._super.apply(this, arguments);

      this.unload();
    },
    load: function load() {// ...
    },
    nextPage: function nextPage() {
      this.incrementProperty('currentPage');
      this.load();
    },
    removeAcceptedChallenge: function removeAcceptedChallenge(id) {
      var item = this.challengesAccepted.findBy('id', id);

      if (item) {
        this.challengesAccepted.removeObject(item);
      }
    },
    removeChallenge: function removeChallenge(id) {
      var item = this.challenges.findBy('id', id);

      if (item) {
        this.challenges.removeObject(item);
      }
    },
    sortFunction: function sortFunction(a, b) {
      var dateA = new Date(a.get('sorting'));
      var dateB = new Date(b.get('sorting'));
      return dateA > dateB ? -1 : dateB > dateA ? 1 : 0;
    },
    unload: function unload() {
      this.setProperties({
        challenges: Ember.A([]),
        challengesAccepted: Ember.A([]),
        startAfter: {
          challenges: null,
          challengesAccepted: null
        },
        unsubscribedFrom: Ember.A([])
      });
    }
  });

  _exports.default = _default;
});