define("challingo-mobile/components/xp-message", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    xpMessage: Ember.inject.service(),
    classNames: ['xp-message'],
    classNameBindings: ['isOpen:open'],
    isOpen: Ember.computed.alias('xpMessage.isOpen'),
    text: Ember.computed.alias('xpMessage.text'),
    xp: Ember.computed.alias('xpMessage.xp'),
    actions: {
      btnAction: function btnAction() {
        this.setProperties({
          isOpen: false
        });
      }
    }
  });

  _exports.default = _default;
});