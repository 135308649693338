define("challingo-mobile/templates/components/challenges/challenge-difficulty", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "mzoEH9Ud",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[23,[\"difficulty\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\"],[11,\"class\",\"challenge-difficulty\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"difficulty-title\"],[9],[1,[27,\"t\",[[27,\"concat\",[\"challenge.difficulty_levels.title_\",[23,[\"displayDifficulty\"]]],null]],null],false],[10],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"difficulty-description\"],[9],[1,[27,\"t\",[[27,\"concat\",[\"challenge.difficulty_levels.text_\",[23,[\"displayDifficulty\"]]],null]],null],false],[10],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "challingo-mobile/templates/components/challenges/challenge-difficulty.hbs"
    }
  });

  _exports.default = _default;
});