define("challingo-mobile/templates/components/hashtag-page", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "BFU8AVhY",
    "block": "{\"symbols\":[\"list\"],\"statements\":[[1,[27,\"nav-bar\",null,[[\"back\",\"spacer\",\"title\"],[\"back\",false,[27,\"concat\",[\"#\",[23,[\"hashtag\"]]],null]]]],false],[0,\"\\n\\n\"],[4,\"lazy-loading-list\",null,[[\"items\",\"itemsPerPage\",\"pageNavBar\",\"sessionId\"],[[23,[\"challenges\",\"hashtag\",\"all\"]],10,false,\"hashtag-chally\"]],{\"statements\":[[0,\"  \"],[1,[27,\"challenges/challenges-search-list\",null,[[\"challenges\",\"scrollObserverOnEnter\",\"showHeading\"],[[22,1,[]],[27,\"action\",[[22,0,[]],\"nextChallyPage\"],null],false]]],false],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "challingo-mobile/templates/components/hashtag-page.hbs"
    }
  });

  _exports.default = _default;
});