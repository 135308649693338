define("challingo-mobile/templates/direct-messages", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "adO3/i+N",
    "block": "{\"symbols\":[],\"statements\":[[1,[27,\"nav-bar\",null,[[\"title\",\"back\"],[[27,\"t\",[\"direct-messages\"],null],\"back\"]]],false],[0,\"\\n\\n\"],[1,[27,\"direct-messages-page\",null,[[\"latestDM\",\"refreshData\"],[[23,[\"model\"]],[27,\"action\",[[22,0,[]],\"refreshData\"],null]]]],false],[0,\"\\n\\n\"],[1,[21,\"menu-main\"],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "challingo-mobile/templates/direct-messages.hbs"
    }
  });

  _exports.default = _default;
});