define("challingo-mobile/components/conversation-message", ["exports", "challingo-mobile/utils/fit-photo-to-element", "challingo-mobile/utils/open-external-url"], function (_exports, _fitPhotoToElement, _openExternalUrl) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    currentUser: Ember.inject.service(),
    router: Ember.inject.service(),
    classNameBindings: ['classNameStr'],
    loadingAccept: false,
    model: null,
    sizeMultiplier: 2,
    attachmentPhoto: Ember.computed('model.attachment.photo', 'media.{height,orientation,width,isVideo}', function () {
      return (0, _fitPhotoToElement.default)(this.get('model.attachment.photo'), this.getImageLement(), {
        mediaHeight: this.get('media.height'),
        mediaOrientation: this.get('media.orientation'),
        mediaWidth: this.get('media.width'),
        pt: 0,
        sizeMultiplier: this.get('sizeMultiplier')
      }, this.get('media.isVideo'));
    }),
    attachmentTitle: Ember.computed('model.attachment.{challenge.title,title}', function () {
      return this.get('model.attachment.title') || this.get('model.attachment.challenge.title');
    }),
    challenge: Ember.computed('model.{challenge,challengeAccepted.challenge}', function () {
      var challengeId = this.get('model').belongsTo('challenge').id();
      return challengeId ? this.get('model.challenge') : this.get('model.challengeAccepted.challenge');
    }),
    classNameStr: Ember.computed('hasAttachment,isOwn}', function () {
      if (this.get('hasAttachment')) {
        return 'conversation-attachment';
      }

      if (this.get('isOwn')) {
        return 'conversation-message dm-own';
      }

      return 'conversation-message dm-other-user';
    }),
    hasAction: Ember.computed('challenge.acceptedByUser.completed', 'isCreator', function () {
      return !(this.get('challenge.acceptedByUser.completed') || this.isCreator);
    }),
    hasAttachment: Ember.computed.alias('model.hasAttachment'),
    isCreator: Ember.computed('currentUser.uid', 'challenge.creator.id', function () {
      return this.get('currentUser.uid') === this.get('challenge.creator.id');
    }),
    isOwn: Ember.computed('currentUser.uid', 'model.userFrom.id', function () {
      var currentUserId = this.get('currentUser.uid');
      return currentUserId === this.get('model.userFrom.id');
    }),
    media: Ember.computed.alias('model.attachment.media'),
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      if (this.getImageLement()) {
        this.notifyPropertyChange('media.orientation');
      }
    },
    getImageLement: function getImageLement() {
      return this && this.element && this.element.querySelector('.dm-attachment-item-image');
    },
    actions: {
      accept: function accept() {
        var _this = this;

        this.get('challenge').then(function (challenge) {
          Ember.tryInvoke(challenge, 'accept', [function () {
            _this.set('loadingAccept', true);
          }, function () {
            if (!_this.isDestroyed && !_this.isDestroying) {
              _this.set('loadingAccept', false);
            }
          }]);
        });
      },
      complete: function complete() {
        this.router.transitionTo('challenge.complete', this.get('challenge.acceptedByUser.id'));
      },
      messageClick: function messageClick() {
        if (typeof event !== 'undefined') {
          event.preventDefault();
          var url = event.target.getAttribute('href');
          url && (0, _openExternalUrl.default)(url);
        }
      },
      showAttachment: function showAttachment() {
        var _this2 = this;

        this.get('challenge').then(function (challenge) {
          var challengeAcceptedId = _this2.get('model').belongsTo('challengeAccepted').id();

          _this2.router.transitionTo('challenges.show', challenge.id, {
            queryParams: {
              challengeAcceptedId: challengeAcceptedId,
              miniProfile: null
            }
          });
        });
      }
    }
  });

  _exports.default = _default;
});