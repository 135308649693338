define("challingo-mobile/templates/components/comments-page", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "FGIpD/lE",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"page-fixed page-nav-bar\"],[9],[0,\"\\n  \"],[1,[27,\"comments-list\",null,[[\"commentPage\",\"comments\",\"challenge\"],[true,[23,[\"item\",\"comments\"]],[23,[\"item\"]]]]],false],[0,\"\\n\"],[10],[0,\"\\n\\n\"],[7,\"form\"],[11,\"class\",\"new-comment\"],[12,\"onclick\",[27,\"action\",[[22,0,[]],\"newCommentClick\"],null]],[11,\"style\",\"touch-action: manipulation; -ms-touch-action: manipulation; cursor: pointer;\"],[9],[0,\"\\n  \"],[1,[27,\"user-tag\",null,[[\"autosize\",\"maximumLength\",\"multiline\",\"searchText\",\"selected\",\"searchInput\"],[true,2200,true,[23,[\"newCommentBody\"]],[23,[\"taggedUsers\"]],[23,[\"searchInput\"]]]]],false],[0,\"\\n\\n  \"],[7,\"button\"],[11,\"class\",\"button-send loading-hidden\"],[12,\"disabled\",[27,\"or\",[[23,[\"loading\"]],[27,\"not\",[[23,[\"searchInput\"]]],null]],null]],[11,\"style\",\"touch-action: manipulation; -ms-touch-action: manipulation; cursor: pointer;\"],[11,\"type\",\"submit\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"challingo-icons moonicon-send\"],[9],[10],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "challingo-mobile/templates/components/comments-page.hbs"
    }
  });

  _exports.default = _default;
});