define("challingo-mobile/components/challenges/challenges-explore-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    challenges: null,
    longPressAction: null,
    scrollObserverOnEnter: null,
    scrollObserverOnExit: null,
    showTitles: false,
    useViewport: true,
    lengthMinus2: Ember.computed('challenges.length', function () {
      return this.get('challenges.length') - 2;
    })
  });

  _exports.default = _default;
});