define("challingo-mobile/initializers/ember-hammertime", ["exports", "ember-hammertime/components/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // activate touch action css
  var _default = {
    name: 'ember-hammertime',
    initialize: function initialize() {}
  };
  _exports.default = _default;
});