define("challingo-mobile/components/viewport-component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    inViewport: Ember.inject.service(),
    isInViewport: false,
    didEnterViewport: function didEnterViewport() {
      if (!this.isDestroyed && !this.isDestroying) {
        this.set('isInViewport', true);
      }
    },
    didExitViewport: function didExitViewport() {
      if (!this.isDestroyed && !this.isDestroying) {
        this.set('isInViewport', false);
      }
    },
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      if (this.element) {
        var _this$inViewport$watc = this.inViewport.watchElement(this.element),
            onEnter = _this$inViewport$watc.onEnter,
            onExit = _this$inViewport$watc.onExit;

        onEnter(this.didEnterViewport.bind(this));
        onExit(this.didExitViewport.bind(this));
      }
    }
  });

  _exports.default = _default;
});