define("challingo-mobile/components/nominate-users", ["exports", "challingo-mobile/utils/search-component"], function (_exports, _searchComponent) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _searchComponent.default.extend({
    intl: Ember.inject.service(),
    modal: Ember.inject.service(),
    search: Ember.inject.service(),
    store: Ember.inject.service(),
    toastMessage: Ember.inject.service(),
    classNames: ['nominate-users'],
    maxNominated: 15,
    combinedUsers: Ember.computed('model.nominated.@each.createdAt', 'searchFinished', 'search.{users.@each.createdAt}', function () {
      return (this.get('searchFinished') ? this.search.get('users') : this.get('model.nominated')) || [];
    }),
    nominatedIds: Ember.computed('model.nominated.{@each.id,length}', function () {
      return (this.get('model.nominated') || []).mapBy('id');
    }),
    actions: {
      back: function back() {
        this.modal.close();
      },
      decideUser: function decideUser(user) {
        if (user && user.id) {
          if (this.get('nominatedIds').includes(user.id)) {
            this.send('denominate', user.id);
          } else {
            this.send('nominate', user.id);
          }
        }
      },
      denominate: function denominate(id) {
        var chally = this.get('model');

        if (!chally || !chally.nominated) {
          return;
        }

        var user = this.store.peekRecord('user', id);

        if (user) {
          chally.nominated.removeObject(user);
        } else {
          this.store.findRecord('user', id).then(function (user) {
            chally.nominated.removeObject(user);
          }, function () {});
        }
      },
      nominate: function nominate(id) {
        var chally = this.get('model');

        if (!chally) {
          return;
        }

        var nominatedCount = chally.get('nominated.length');

        if (nominatedCount >= this.get('maxNominated')) {
          this.toastMessage.show(this.intl.t('challenge.nominate_max', {
            count: this.get('maxNominated')
          }));
        } else {
          var user = this.store.peekRecord('user', id);

          if (user) {
            chally.nominated.pushObject(user);
          } else {
            this.store.findRecord('user', id).then(function (user) {
              chally.nominated.pushObject(user);
            }, function () {});
          }
        }
      }
    }
  });

  _exports.default = _default;
});