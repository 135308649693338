define("challingo-mobile/components/followers-item", ["exports", "emberfire/services/realtime-listener"], function (_exports, _realtimeListener) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    confirmDialog: Ember.inject.service(),
    currentUser: Ember.inject.service(),
    intl: Ember.inject.service(),
    modal: Ember.inject.service(),
    session: Ember.inject.service(),
    store: Ember.inject.service(),
    classNames: ['followers-item'],
    item: null,
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      var item = this.get('item');

      if (item) {
        Ember.tryInvoke(item, 'checkIsFollowed');

        if (!this.get('item.isVirtualModel')) {
          (0, _realtimeListener.subscribe)(this, item);
        }
      }
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);
    },
    actions: {
      follow: function follow() {
        if (!this.get('session.isAuthenticated')) {
          this.modal.show('modals/login-popup', null, {
            noStyle: true
          });
          return;
        }

        Ember.tryInvoke(this.item, 'follow');
      },
      unfollow: function unfollow() {
        var _this = this;

        this.confirmDialog.show(this.intl.t('follow.delete_following'), function () {
          Ember.tryInvoke(_this.item, 'unfollow');
        });
      }
    }
  });

  _exports.default = _default;
});