define("challingo-mobile/utils/challenges/user-profile-created", ["exports", "challingo-mobile/utils/challenges/user-profile"], function (_exports, _userProfile) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _userProfile.default.extend({
    getQueryWhere: function getQueryWhere() {
      return [['challengeAccepted', '==', null], ['hidden', '==', false], ['user', '==', this.get('user.id')]];
    }
  });

  _exports.default = _default;
});