define("challingo-mobile/templates/components/challenges/challenges-explore-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "xrukhIre",
    "block": "{\"symbols\":[\"challenge\",\"index\"],\"statements\":[[7,\"div\"],[11,\"class\",\"challenges-explore-list\"],[9],[0,\"\\n\"],[4,\"each\",[[23,[\"challenges\"]]],null,{\"statements\":[[0,\"    \"],[1,[27,\"challenges/challenge-simple-item\",null,[[\"item\",\"longPressAction\",\"showTitle\",\"useViewport\"],[[22,1,[]],[23,[\"longPressAction\"]],[23,[\"showTitles\"]],[23,[\"useViewport\"]]]]],false],[0,\"\\n\"],[4,\"if\",[[27,\"and\",[[27,\"or\",[[23,[\"scrollObserverOnEnter\"]],[23,[\"scrollObserverOnExit\"]]],null],[27,\"eq\",[[22,2,[]],[23,[\"lengthMinus2\"]]],null]],null]],null,{\"statements\":[[0,\"      \"],[1,[27,\"scroll-observer\",null,[[\"onEnterViewport\",\"onExitViewport\"],[[23,[\"scrollObserverOnEnter\"]],[23,[\"scrollObserverOnExit\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[1,2]},{\"statements\":[[0,\"    \"],[7,\"div\"],[11,\"class\",\"page-empty\"],[9],[0,\"\\n      \"],[7,\"i\"],[11,\"class\",\"challingo-icons ci-challenge\"],[9],[10],[0,\"\\n      \"],[7,\"div\"],[9],[1,[21,\"placeholder\"],false],[10],[0,\"\\n    \"],[10],[0,\"\\n\"]],\"parameters\":[]}],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "challingo-mobile/templates/components/challenges/challenges-explore-list.hbs"
    }
  });

  _exports.default = _default;
});