define("challingo-mobile/helpers/or", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.or = or;
  _exports.default = void 0;

  function or(params
  /*, hash*/
  ) {
    return params.reduce(function (a, b) {
      return a || b;
    });
  }

  var _default = Ember.Helper.helper(or);

  _exports.default = _default;
});