define("challingo-mobile/routes/profile/chally", ["exports", "emberfire/services/realtime-listener"], function (_exports, _realtimeListener) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    challenges: Ember.inject.service(),
    model: function model(params) {
      return this.store.findRecord('user', params.profile_id);
    },
    afterModel: function afterModel(model) {
      if (model && !model.get('isVirtualModel')) {
        (0, _realtimeListener.subscribe)(this, model);
      }
    }
  });

  _exports.default = _default;
});