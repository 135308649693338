define("challingo-mobile/utils/challenges/category", ["exports", "challingo-mobile/utils/challenges/collection", "challingo-mobile/utils/logger"], function (_exports, _collection, _logger) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var callbackGenerator = function callbackGenerator(context, collection) {
    return function (items) {
      if (items && items.length) {
        items.compact().forEach(function (item) {
          return item.get('media');
        });
        context.get(collection).pushObjects(items.compact().toArray());
      }
    };
  };

  var _default = _collection.default.extend({
    category: null,
    load: function load() {
      var _this = this;

      return this.store.query('category-challenge', {
        limit: this.get('perPage'),
        orderBy: {
          'createdAt': 'desc'
        },
        startAfter: this.get('startAfter.category'),
        where: [['category', '==', this.get('category.id')]]
      }).then(function (categoryItems) {
        var dates = categoryItems.mapBy('createdAt');

        if (dates.length) {
          var date = dates.pop();

          _this.set("startAfter.category", date.toISOString());
        }

        return Ember.RSVP.hash({
          challenges: Ember.RSVP.all(categoryItems.map(function (item) {
            return item.get('challenge');
          })).then(callbackGenerator(_this, 'challenges')),
          challengesAccepted: Ember.RSVP.all(categoryItems.map(function (item) {
            return item.get('challengeAccepted');
          })).then(callbackGenerator(_this, 'challengesAccepted'))
        });
      }, function (e) {
        _logger.default.error(e);
      });
    },
    resetCategory: function resetCategory(category) {
      if (category && category.id !== this.get('category.id')) {
        this.setProperties({
          category: category
        });
        this.unload();
      }
    },
    unload: function unload() {
      this._super.apply(this, arguments);

      this.setProperties({
        challenges: Ember.A([]),
        challengesAccepted: Ember.A([]),
        startAfter: {
          category: null
        }
      });
    }
  });

  _exports.default = _default;
});