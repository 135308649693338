define("challingo-mobile/utils/challenges/trendy", ["exports", "challingo-mobile/utils/challenges/collection"], function (_exports, _collection) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _collection.default.extend({
    batchNumber: 1,
    perPage: 10,
    load: function load() {
      var _this = this;

      var callbackGenerator = function callbackGenerator(collection, dateAttr, batchNumber) {
        return function (items) {
          if (items && items.length) {
            // Preload media
            items.forEach(function (item) {
              item.get('media');
              item.batchNumber = batchNumber;
            });

            var collectionArr = _this.get(collection);

            var itemsArray = items.toArray();
            collectionArr.pushObjects(itemsArray);
            var filtered = itemsArray.filter(function (item) {
              return item.get(dateAttr);
            });

            if (filtered && filtered.length) {
              var last = filtered[filtered.length - 1];
              var date = last.get(dateAttr);

              _this.set("startAfter.".concat(collection), [last.get('trendyWeight'), date.toISOString()]);
            }
          }
        };
      };

      var batchNumber = this.get('batchNumber');
      this.incrementProperty('batchNumber');
      return Ember.RSVP.hash({
        challenges: this.store.query('challenge', {
          limit: this.get('perPage'),
          orderBy: {
            'trendyWeight': 'desc',
            'createdAt': 'desc'
          },
          startAfter: this.get('startAfter.challenges'),
          where: [['hidden', '==', false]]
        }).then(callbackGenerator('challenges', 'createdAt', batchNumber)),
        challengesAccepted: this.store.query('accepted-challenge', {
          limit: this.get('perPage'),
          orderBy: {
            'trendyWeight': 'desc',
            'completed': 'desc'
          },
          startAfter: this.get('startAfter.challengesAccepted'),
          where: [['hidden', '==', false]]
        }).then(callbackGenerator('challengesAccepted', 'completed', batchNumber))
      });
    },
    sortFunction: function sortFunction(a, b) {
      if (a.batchNumber !== b.batchNumber) {
        return a.batchNumber - b.batchNumber;
      }

      if (a.trendyWeight && b.trendyWeight) {
        return b.trendyWeight - a.trendyWeight;
      }

      if (a.trendyWeight) return -1;
      if (b.trendyWeight) return 1;
      var dateA = new Date(a.sorting);
      var dateB = new Date(b.sorting);
      return dateB.getTime() - dateA.getTime();
    }
  });

  _exports.default = _default;
});