define("challingo-mobile/models/accepted-challenge", ["exports", "ember-data", "challingo-mobile/utils/logger", "challingo-mobile/utils/model-with-media", "challingo-mobile/models/challenge", "emberfire/services/realtime-listener", "challingo-mobile/utils/hashtags"], function (_exports, _emberData, _logger, _modelWithMedia, _challenge, _realtimeListener, _hashtags) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _modelWithMedia.default.extend({
    currentUser: Ember.inject.service(),
    _isReported: _emberData.default.attr('boolean'),
    category: _emberData.default.belongsTo('category', {
      inverse: null
    }),
    challenge: _emberData.default.belongsTo('challenge', {
      inverse: 'accepted'
    }),
    comments: _emberData.default.hasMany('comment', {
      inverse: null
    }),
    completed: _emberData.default.attr('date', {
      defaultValue: null
    }),
    completedFromDrawn: _emberData.default.attr('boolean'),
    createdAt: _emberData.default.attr('date', {
      defaultValue: function defaultValue() {
        return new Date();
      }
    }),
    description: _emberData.default.attr('string'),
    difficulty: _emberData.default.attr('number'),
    hidden: _emberData.default.attr('boolean'),
    lastLikedAt: _emberData.default.attr('date'),
    likes: _emberData.default.attr('number', {
      defaultValue: 0
    }),
    nominated: _emberData.default.hasMany('user', {
      inverse: null
    }),
    rating: _emberData.default.attr('number'),
    reported: _emberData.default.attr('date'),
    shortUrl: _emberData.default.attr('string'),
    tags: _emberData.default.attr('string'),
    trendyWeight: _emberData.default.attr('number', {
      defaultValue: 0
    }),
    user: _emberData.default.belongsTo('user', {
      inverse: null
    }),
    boomerang: Ember.computed.alias('media.boomerang'),
    commentsSorted: Ember.computed.sort('comments', _challenge.commentSortFunc),
    completedCount: Ember.computed.alias('challenge.completedCount'),
    descriptionParsed: Ember.computed('description', function () {
      return (0, _hashtags.wrapHashtags)(this.get('description'));
    }),
    isAcceptedByUser: Ember.computed('challenge.hidden', 'currentUser.user.id', 'hidden', 'user.id', function () {
      if (!this.get('currentUser.user')) {
        return null;
      }

      return !this.get('hidden') && this.get('user.id') === this.get('currentUser.user.id');
    }),
    isChallengeHidden: Ember.computed('challenge.hidden', function () {
      return this.get('challenge.hidden');
    }),
    isCompletedByUser: Ember.computed.and('completed', 'isAcceptedByUser'),
    likedBy: null,
    likedByIds: Ember.computed('likedBy.{[],length}', function () {
      var likedBy = this.get('likedBy');
      return (likedBy || []).map(function (item) {
        return item && item.belongsTo('userFrom').id();
      }).compact();
    }),
    likedByLoading: null,
    likedByUsers: Ember.computed('likedBy.{[],length}', function () {
      var likedBy = this.get('likedBy');
      return (likedBy || []).mapBy('userFrom');
    }),
    photo: Ember.computed.alias('media.photo'),
    photoMedium: Ember.computed('photo', function () {
      if (!this.photo) {
        return null;
      }

      return this.photo.replace('/upload', '/upload/w_768');
    }),
    photoSmall: Ember.computed('photo', function () {
      if (!this.photo) {
        return null;
      }

      return this.photo.replace('/upload', '/upload/c_thumb,h_180,w_180');
    }),
    photoThumb: Ember.computed('photo', function () {
      if (!this.photo) {
        return null;
      }

      return this.photo.replace('/upload', '/upload/w_300');
    }),
    sorting: Ember.computed.alias('completed'),
    tagsParsed: Ember.computed('tags', function () {
      if (this.tags) {
        return (0, _hashtags.wrapHashtags)(this.tags);
      }
    }),
    type: Ember.computed(function () {
      return this.get('constructor.modelName');
    }),
    userId: Ember.computed.alias('user.id'),
    video: Ember.computed.alias('media.video'),
    loadLikes: function loadLikes() {
      var _this = this;

      var force = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;

      if (!this.get('likedBy') && !this.get('likedByLoading') || force) {
        this.store.query('like', {
          orderBy: {
            'createdAt': 'desc'
          },
          where: [['challengeAccepted', '==', this.id]]
        }).then(function (likedBy) {
          _this.setProperties({
            likedBy: likedBy,
            likedByLoading: false
          });

          (0, _realtimeListener.subscribe)(_this, likedBy);
          return likedBy;
        }, function (e) {
          _this.setProperties({
            likedByLoading: false
          });

          _logger.default.error(e);
        });
      }
    }
  });

  _exports.default = _default;
});