define("challingo-mobile/components/challenges/challenge-actions", ["exports", "challingo-mobile/utils/log-analytics-event"], function (_exports, _logAnalyticsEvent) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    confirmDialog: Ember.inject.service(),
    currentUser: Ember.inject.service(),
    firebaseApp: Ember.inject.service(),
    intl: Ember.inject.service(),
    modal: Ember.inject.service(),
    router: Ember.inject.service(),
    store: Ember.inject.service(),
    toastMessage: Ember.inject.service(),
    classNames: ['challenge-actions'],
    item: Ember.computed.alias('model'),
    toast: function toast(msg) {
      this.toastMessage.show(this.intl.t(msg));
    },
    afterDelete: function afterDelete(item) {
      var collection = item.get('type') === 'challenge' ? 'challenge' : 'challengeAccepted';
      this.store.query('user-challenge', {
        where: [[collection, '==', item.id]]
      }).then(function (items) {
        if (items && items.length) {
          items.forEach(function (item) {
            if (item) {
              item.set('hidden', true);
              item.save({
                adapterOptions: {
                  saveOnly: ['hidden']
                }
              });
            }
          });
        }
      });
    },
    challenge: Ember.computed('item', function () {
      return this.get('item.type') === 'challenge' ? this.item : null;
    }),
    challengeAccepted: Ember.computed('item', function () {
      return this.get('item.type') === 'accepted-challenge' ? this.item : null;
    }),
    _changePropWithConfirmation: function _changePropWithConfirmation(item, prop, value, _ref) {
      var _this = this;

      var errorMessage = _ref.errorMessage,
          onSuccess = _ref.onSuccess,
          question = _ref.question,
          successMessage = _ref.successMessage;
      this.confirmDialog.show(this.intl.t(question), function () {
        item.set(prop, value);

        if (prop === 'reported') {
          item.set('_isReported', Boolean(value));
        }

        item.save({
          adapterOptions: {
            saveOnly: [prop, '_isReported']
          }
        }).then(function () {
          _this.toast(successMessage);

          if (onSuccess) {
            onSuccess();
          }

          if (prop === 'reported') {
            (0, _logAnalyticsEvent.default)(_this.firebaseApp, 'chally_reported', {
              chally_id: item.id,
              user_id: _this.get('currentUser.uid')
            });
          }
        }, function () {
          _this.toast(errorMessage);
        });
      });
    },
    actions: {
      hide: function hide(item) {
        var _this2 = this;

        Ember.tryInvoke(this, 'modalClose');

        this._changePropWithConfirmation(item, 'hidden', true, {
          errorMessage: 'challenge.hide_error',
          onSuccess: function onSuccess() {
            _this2.afterDelete(item);
          },
          question: 'challenge.hide_question',
          successMessage: 'challenge.hide_success'
        });
      },
      markRandom: function markRandom(item, newState) {
        Ember.tryInvoke(this, 'modalClose');

        this._changePropWithConfirmation(item, 'isAvailableForDrawn', newState, {
          question: "challenge.random_question_".concat(newState ? 'add' : 'remove'),
          successMessage: "challenge.random_success_".concat(newState ? 'add' : 'remove'),
          errorMessage: 'challenge.random_error'
        });
      },
      report: function report(item) {
        Ember.tryInvoke(this, 'modalClose');

        this._changePropWithConfirmation(item, 'reported', new Date(), {
          question: 'challenge.report_question',
          successMessage: 'challenge.report_success',
          errorMessage: 'challenge.report_error'
        });
      },
      unreport: function unreport(item) {
        Ember.tryInvoke(this, 'modalClose');

        this._changePropWithConfirmation(item, 'reported', null, {
          question: 'challenge.unreport_question',
          successMessage: 'challenge.unreport_success',
          errorMessage: 'challenge.unreport_error'
        });
      },
      editDescription: function editDescription(item) {
        Ember.tryInvoke(this, 'modalClose');
        this.modal.show('forms/challenge-description-edit-form', item);
      },
      delete: function _delete(item) {
        var _this3 = this;

        Ember.tryInvoke(this, 'modalClose');
        this.confirmDialog.show(this.intl.t('challenge.delete_confirm'), function () {
          item.setProperties({
            hidden: true
          });
          item.save({
            adapterOptions: {
              saveOnly: ['hidden']
            }
          }).then(function () {
            _this3.afterDelete(item);

            _this3.toast('challenge.delete_success');

            _this3.router.transitionTo('challenges', {
              queryParams: {
                miniProfile: null
              }
            });
          }, function () {
            _this3.toast('challenge.delete_error');
          });
        });
      }
    }
  });

  _exports.default = _default;
});