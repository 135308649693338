define("challingo-mobile/templates/login", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "0MEVn0fE",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"login\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"login-header\"],[9],[0,\"\\n    \"],[1,[27,\"nav-bar\",null,[[\"back\"],[\"back\"]]],false],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"login-title\"],[9],[1,[27,\"t\",[\"user.welcome\"],null],false],[10],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"login-title-text\"],[9],[1,[27,\"t\",[\"user.login_to_continue\"],null],false],[10],[0,\"\\n  \"],[10],[0,\"\\n\\n  \"],[7,\"div\"],[11,\"class\",\"login-form-wrap\"],[9],[0,\"\\n    \"],[1,[27,\"forms/login-form\",null,[[\"loading\",\"devices\"],[[23,[\"loading\"]],[23,[\"model\"]]]]],false],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "challingo-mobile/templates/login.hbs"
    }
  });

  _exports.default = _default;
});