define("challingo-mobile/components/forms/signup-form", ["exports", "firebase/auth", "firebase/app", "challingo-mobile/utils/log-analytics-event"], function (_exports, _auth, _app, _logAnalyticsEvent) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _typeof(obj) { "@babel/helpers - typeof"; if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

  // import { subscribe } from 'emberfire/services/realtime-listener';
  var _default = Ember.Component.extend({
    cordova: Ember.inject.service(),
    currentUser: Ember.inject.service(),
    firebaseApp: Ember.inject.service(),
    intl: Ember.inject.service(),
    pushNotification: Ember.inject.service(),
    router: Ember.inject.service(),
    session: Ember.inject.service(),
    store: Ember.inject.service(),
    tagName: 'form',
    classNames: ['signup-form'],
    attributeBindings: ['novalidate'],
    novalidate: true,
    user: null,
    password: '',
    passwordConfirm: '',
    loading: false,
    error: '',
    page: 1,
    modal: Ember.inject.service(),
    pagesEnum: Ember.computed(function () {
      return {
        welcome: 1,
        name: 2,
        email: 3,
        password: 4
      };
    }),
    pages: Ember.computed('pagesEnum', function () {
      return Object.keys(this.pagesEnum).length;
    }),
    pagesStyle: Ember.computed('page', function () {
      var left = -(this.page - 1) * 100 / this.pages;
      return Ember.String.htmlSafe("-webkit-transform: translate3d(".concat(left, "%, 0, 0);\n       transform: translate3d(").concat(left, "%, 0, 0);"));
    }),
    pageObserver: Ember.observer('page', function () {
      this.set('error', '');
    }),
    pageValidate: function pageValidate() {
      var _this = this;

      return new Ember.RSVP.Promise(function (resolve, reject) {
        var user = _this.user;

        switch (_this.page) {
          case _this.get('pagesEnum.name'):
            user.get('nameValidate').then(resolve, reject);
            break;

          case _this.get('pagesEnum.email'):
            user.get('emailValidate').then(resolve, reject);
            break;

          default:
            resolve();
            break;
        }
      });
    },
    prevPage: function prevPage() {
      if (this.page > 1) {
        this.decrementProperty('page');
      }
    },
    nextPage: function nextPage() {
      var _this2 = this;

      if (this.page < this.pages) {
        this.set('loading', true);
        this.pageValidate().then(function () {
          _this2.incrementProperty('page');

          Ember.run.later(function () {
            var el = _this2.element && _this2.element.querySelector('input:enabled');

            if (el) {
              el.focus();
            }
          }, 500);
        }, function (error) {
          _this2.set('error', error);
        }).finally(function () {
          if (!_this2.isDestroyed) {
            _this2.set('loading', false);
          }
        });
      }
    },
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      this.set('user', this.store.createRecord('user'));
    },
    keyDown: function keyDown(event) {
      if (event.key === 'Enter') {
        if (this.page === this.pages) {
          var el = this.element && this.element.querySelector('[type="submit"]');

          if (el) {
            el.click();
          }
        } else {
          this.nextPage();
        }

        event.preventDefault();
      } else {
        this.set('error', '');
      }
    },
    submit: function submit() {
      var _this3 = this;

      this.set('error', '');

      var _this$get = this.get('user'),
          email = _this$get.email,
          name = _this$get.name;

      var password = this.password,
          passwordConfirm = this.passwordConfirm;

      if (!name || !email) {
        return false;
      } else if (!password || !passwordConfirm) {
        this.set('error', this.intl.t('user.passwords_required'));
        return false;
      } else if (password !== passwordConfirm) {
        this.set('error', this.intl.t('user.passwords_must_match'));
        return false;
      }

      this.set('loading', true);

      var auth = _app.default.auth();

      auth.createUserWithEmailAndPassword(email, password).then(function (response) {
        var name = _this3.user.name.trim();

        _this3.user.rollbackAttributes();

        var user = _this3.store.createRecord('user', {
          email: email,
          id: response.user.uid,
          name: name,
          nameLowercase: name.toLowerCase(),
          password: password
        });

        user.save().then(function () {
          var promises = [];
          promises.push(_this3.store.query('user', {
            where: ['name', '==', 'Challingo']
          }).then(function (users) {
            return _this3.pushNotification.send(user, 'welcome', _this3.intl.t('push-notifications.welcome', {
              locale: user && user.get('localeAugmented')
            }), users.get('firstObject'));
          }));
          promises.push((0, _logAnalyticsEvent.default)(_this3.firebaseApp, 'sign_up', {
            type: 'email_and_password'
          }));

          if (_this3.get('cordova.enabled')) {
            _this3.cordova.registerFCM();
          }

          user.set('createdAt', new Date());
          promises.push(user.save().then(function (userSaved) {
            _this3.currentUser.setProperties({
              data: userSaved,
              uidReg: userSaved.get('id')
            });
          }));
          Promise.all(promises).catch(function () {}).finally(function () {
            if (window && window.location) {
              window.location = _typeof(window.cordova) === 'object' ? 'index.html' : '/';
            } else {
              // subscribe(this, userSaved);
              _this3.router.transitionTo('challenges', {
                queryParams: {
                  miniProfile: null
                }
              });
            }
          });
        }, function ()
        /* error */
        {
          _this3.setProperties({
            loading: false,
            error: _this3.intl.t('user.submit_error')
          });
        });
      }, function (error) {
        _this3.setProperties({
          loading: false,
          error: _this3.intl.t("user.".concat(error.code))
        });
      });
      return false;
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);
    },
    actions: {
      pageBack: function pageBack() {
        this.prevPage();
      },
      btnRightAction: function btnRightAction() {
        this.nextPage();
      },
      openModal: function openModal(modalName) {
        this.modal.show(modalName);
      }
    }
  });

  _exports.default = _default;
});