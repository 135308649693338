define("challingo-mobile/routes/challenge/new", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model: function model() {
      return Ember.RSVP.hash({
        categories: this.store.findAll('category'),
        challenge: this.store.createRecord('challenge'),
        media: this.store.createRecord('media')
      });
    },
    afterModel: function afterModel(model) {
      model.challenge.set('category', model.categories.find(function (category) {
        return category && category.get('name_en') === 'Chally';
      }));
    },
    resetController: function resetController(controller) {
      this._super.apply(this, arguments);

      ['challenge', 'media'].forEach(function (prop) {
        controller.model[prop].rollbackAttributes();
      });
    }
  });

  _exports.default = _default;
});