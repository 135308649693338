define("challingo-mobile/components/challenges/challenge-difficulty", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    difficulty: null,
    displayDifficulty: Ember.computed('difficulty', function () {
      return Math.min(5, Math.max(0, Math.round(this.get('difficulty'))));
    })
  });

  _exports.default = _default;
});