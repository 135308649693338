define("challingo-mobile/routes/direct-messages", ["exports", "challingo-mobile/mixins/remember-scroll", "challingo-mobile/utils/dm-model"], function (_exports, _rememberScroll, _dmModel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_rememberScroll.default, {
    currentUser: Ember.inject.service(),
    modal: Ember.inject.service(),
    session: Ember.inject.service(),
    beforeModel: function beforeModel() {
      if (!this.get('session.isAuthenticated')) {
        this.modal.show('modals/login-popup', null, {
          goBack: true,
          noStyle: true
        });
      }
    },
    model: function model() {
      var dmModelBound = _dmModel.default.bind(this);

      return dmModelBound();
    },
    actions: {
      refresh: function refresh() {
        this.refresh();
      }
    }
  });

  _exports.default = _default;
});