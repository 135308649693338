define("challingo-mobile/services/modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    isOpen: false,
    componentName: '',
    model: null,
    options: null,
    show: function show() {
      var componentName = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
      var model = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;
      var options = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : null;
      this.setProperties({
        componentName: componentName,
        model: model,
        options: options,
        isOpen: true
      });
      document.body.classList.add('no-scroll');
    },
    close: function close() {
      this.setProperties({
        componentName: '',
        model: null,
        options: null,
        isOpen: false
      });
      document.body.classList.remove('no-scroll');
    }
  });

  _exports.default = _default;
});