define("challingo-mobile/components/ok-message", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    okMessage: Ember.inject.service(),
    classNames: ['ok-message'],
    classNameBindings: ['isOpen:open'],
    isOpen: Ember.computed.alias('okMessage.isOpen'),
    text: Ember.computed.alias('okMessage.text'),
    actions: {
      btnRightAction: function btnRightAction() {
        this.setProperties({
          isOpen: false,
          text: ''
        });
      }
    }
  });

  _exports.default = _default;
});