define("challingo-mobile/models/user-virtual", ["exports", "challingo-mobile/models/user"], function (_exports, _user) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _user.default.extend({
    store: Ember.inject.service(),
    virtualAction: function virtualAction(actionName) {
      var user = this.store.peekRecord('user', this.get('id'));

      if (user) {
        Ember.tryInvoke(user, actionName);
      } else {
        this.store.findRecord('user', this.get('id')).then(function (user) {
          Ember.tryInvoke(user, actionName);
        });
      }
    },
    follow: function follow() {
      this.virtualAction('follow');
    },
    unfollow: function unfollow() {
      this.virtualAction('unfollow');
    }
  });

  _exports.default = _default;
});