define("challingo-mobile/templates/components/challenges/challenges-search-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "w2Du57hE",
    "block": "{\"symbols\":[\"challenge\",\"index\"],\"statements\":[[4,\"if\",[[23,[\"showHeading\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\"],[11,\"class\",\"heading\"],[9],[1,[27,\"t\",[\"chally\"],null],false],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"if\",[[23,[\"challenges\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\"],[11,\"class\",\"search-challenges-body\"],[9],[0,\"\\n\"],[4,\"each\",[[23,[\"challenges\"]]],null,{\"statements\":[[0,\"      \"],[1,[27,\"challenges/challenge-simple-item\",null,[[\"item\",\"showTitle\"],[[22,1,[]],false]]],false],[0,\"\\n\"],[4,\"if\",[[27,\"and\",[[27,\"or\",[[23,[\"scrollObserverOnEnter\"]],[23,[\"scrollObserverOnExit\"]]],null],[27,\"eq\",[[22,2,[]],[23,[\"lengthMinus2\"]]],null]],null]],null,{\"statements\":[[0,\"        \"],[1,[27,\"scroll-observer\",null,[[\"onEnterViewport\",\"onExitViewport\"],[[23,[\"scrollObserverOnEnter\"]],[23,[\"scrollObserverOnExit\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[1,2]},null],[0,\"  \"],[10],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[7,\"div\"],[11,\"class\",\"not-found\"],[9],[0,\"\\n    \"],[1,[27,\"t\",[\"search.nochallyfound\"],null],false],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "challingo-mobile/templates/components/challenges/challenges-search-list.hbs"
    }
  });

  _exports.default = _default;
});