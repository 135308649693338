define("challingo-mobile/models/media", ["exports", "ember-data", "challingo-mobile/config/environment"], function (_exports, _emberData, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    settings: Ember.inject.service(),
    url: _emberData.default.attr('string'),
    type: _emberData.default.attr('string'),
    width: _emberData.default.attr('number'),
    height: _emberData.default.attr('number'),
    isPhoto: Ember.computed.equal('type', 'image'),
    isVideo: Ember.computed.equal('type', 'video'),
    isEmpty: Ember.computed.not('type'),
    tmpUrl: null,
    _setTmpUrl: function _setTmpUrl(url) {
      this.set('tmpUrl', url);
    },
    boomerang: Ember.computed('url', 'isVideo', function () {
      if (this.isVideo) {
        var quality = this.settings.siteSettings && this.settings.siteSettings.boomerangQuality || 85;
        return this.url.replace('/upload', "/upload/e_boomerang/w_200,h_200,e_volume:mute,c_fill,eo_1,fl_awebp,fl_animated,f_auto,e_loop:50000,q_".concat(quality)).replace('.mp4', '.webp');
      }

      return null;
    }),
    photo: Ember.computed('tmpUrl', 'url', 'isPhoto', 'isVideo', function () {
      var _this = this;

      var url = this.url;
      var tmpUrl = this.tmpUrl;

      if (!url) {
        return "".concat(_environment.default.rootURL, "assets/images/black.gif");
      }

      if (tmpUrl) {
        return tmpUrl;
      }

      if (this.isVideo) {
        url = url.replace('/upload', '/upload/so_0');
        url = url.substr(0, url.lastIndexOf('.')) + '.jpg';
      }

      var tmpImg = new Image();
      tmpImg.src = url;

      tmpImg.onload = function () {
        if (_this && !_this.isDestroyed) {
          _this._setTmpUrl(url);

          return url;
        }
      };

      return "".concat(_environment.default.rootURL, "assets/images/black.gif");
    }),
    _orientation: null,
    _setOrientation: function _setOrientation(orientation) {
      this.set('_orientation', orientation);
    },
    orientation: Ember.computed('{url,_orientation,width,height}', function () {
      var _this2 = this;

      var tmp = this._orientation;

      if (tmp) {
        return tmp;
      }

      if (this.width && this.height) {
        var orientation = this.width == this.height ? 'landscape' : this.width > this.height ? 'landscape' : 'portrait';

        this._setOrientation(orientation);

        return orientation;
      }

      if (!this.isPhoto) {
        return null;
      }

      var img = new Image();

      if (img) {
        img.src = this.url;

        img.onload = function () {
          var orientation = img.width == img.height ? 'landscape' : img.width > img.height ? 'landscape' : 'portrait';

          _this2._setOrientation(orientation);
        };
      }
    }),
    video: Ember.computed('url', 'isVideo', function () {
      if (this.isVideo) {
        /*
          if(this.orientation == 'landscape'){
            return this.url.replace('/upload', '/upload/vc_auto,c_pad,b_blurred:1000,q_99');
          }
        */
        return this.url;
      }

      return null;
    }),
    downloadUrl: Ember.computed('url', function () {
      return this.url.replace('/upload', '/upload/fl_attachment');
    }),
    resetUrl: function resetUrl() {
      if (!this.isDeleted) {
        this.setProperties({
          url: null,
          type: null,
          width: null,
          height: null,
          tmpUrl: null,
          _orientation: null
        });
      }
    }
  });

  _exports.default = _default;
});