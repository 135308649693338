define("challingo-mobile/templates/components/challenges/challenges-list-mini", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ZRd9Go1U",
    "block": "{\"symbols\":[\"challenge\"],\"statements\":[[4,\"if\",[[23,[\"challenges\",\"length\"]]],null,{\"statements\":[[4,\"each\",[[23,[\"challenges\"]]],null,{\"statements\":[[0,\"    \"],[1,[27,\"challenges/challenge-item-mini\",null,[[\"item\"],[[22,1,[]]]]],false],[0,\"\\n\"]],\"parameters\":[1]},null],[4,\"if\",[[27,\"or\",[[23,[\"scrollObserverOnEnter\"]],[23,[\"scrollObserverOnExit\"]]],null]],null,{\"statements\":[[0,\"    \"],[1,[27,\"scroll-observer\",null,[[\"onEnterViewport\",\"onExitViewport\"],[[23,[\"scrollObserverOnEnter\"]],[23,[\"scrollObserverOnExit\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[7,\"div\"],[11,\"class\",\"page-empty\"],[9],[0,\"\\n    \"],[7,\"i\"],[11,\"class\",\"challingo-icons ci-challenge\"],[9],[10],[0,\"\\n    \"],[7,\"div\"],[9],[0,\"\\n\"],[4,\"if\",[[23,[\"placeholder\"]]],null,{\"statements\":[[0,\"        \"],[1,[21,\"placeholder\"],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"        \"],[1,[27,\"t\",[\"empty_list\"],null],false],[0,\"\\n\"]],\"parameters\":[]}],[0,\"    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "challingo-mobile/templates/components/challenges/challenges-list-mini.hbs"
    }
  });

  _exports.default = _default;
});