define("challingo-mobile/components/forms/password-change-form", ["exports", "firebase/auth", "firebase/app"], function (_exports, _auth, _app) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    currentUser: Ember.inject.service(),
    firebaseApp: Ember.inject.service(),
    intl: Ember.inject.service(),
    router: Ember.inject.service(),
    toastMessage: Ember.inject.service(),
    tagName: 'form',
    classNames: ['password-change-form', 'page-offset'],
    passwordCurrent: '',
    password: '',
    passwordConfirm: '',
    loading: false,
    error: '',
    submit: function submit() {
      var _this = this;

      this.set('error', '');

      var credential = _app.default.auth.EmailAuthProvider.credential(this.get('currentUser.user.email'), this.passwordCurrent);

      this.firebaseApp.auth().then(function (userObject) {
        var user = userObject.currentUser;
        user.reauthenticateWithCredential(credential).then(function () {
          var password = _this.password,
              passwordConfirm = _this.passwordConfirm;

          if (!password || !passwordConfirm) {
            _this.set('error', _this.intl.t('user.passwords_required'));

            return false;
          } else if (password !== passwordConfirm) {
            _this.set('error', _this.intl.t('user.passwords_must_match'));

            return false;
          }

          _this.set('loading', true);

          user.updatePassword(password).then(function () {
            _this.router.transitionTo('logout');

            _this.toastMessage.show(_this.intl.t('user.password_changed'));

            if (!_this.isDestroyed) {
              _this.set('loading', false);
            }
          }).catch(function (error) {
            _this.setProperties({
              loading: false,
              error: _this.intl.t("user.".concat(error.code))
            });
          });
        }).catch(function () {
          _this.set('error', _this.intl.t('user.password_current_error'));
        });
      });
      return false;
    }
  });

  _exports.default = _default;
});