define("challingo-mobile/components/pinch-zoom", ["exports", "challingo-mobile/utils/detect-mobile-os", "ember-gestures/mixins/recognizers"], function (_exports, _detectMobileOs, _recognizers) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_recognizers.default, {
    classNames: ['pinch-zoom'],
    classNameBindings: ['mobileOS'],
    mobileOS: null,
    recognizers: Ember.computed('conditionalPinch', function () {
      return this.get('conditionalPinch') ? (0, _detectMobileOs.default)() === 'iOS' && (0, _detectMobileOs.iOSgetVersion)() < 13 ? null : 'pinch' : 'pinch';
    }),
    zIndexActive: 5000,
    conditionalPinch: true,
    pinchStart: function pinchStart() {
      var styleObject = this.element.style;
      styleObject.transform = "scale(1) translateX(0) translateY(0)";
      styleObject.zIndex = this.get('zIndexActive');
      Ember.tryInvoke(this, 'onPinchStart');
      document.body.classList.add('in-pinch');
    },
    pinchEnd: function pinchEnd() {
      var styleObject = this.element.style;
      styleObject.transform = "scale(1) translateX(0) translateY(0)";
      styleObject.zIndex = 'auto';
      Ember.tryInvoke(this, 'onPinchEnd');
      document.body.classList.remove('in-pinch');
    },
    pinchMove: function pinchMove() {
      if (event && event.preventDefault) {
        event.preventDefault();
      }

      if (event && event.stopPropagation) {
        event.stopPropagation();
      }

      this.element.style.transform = "scale(".concat(event.gesture.scale, ") translateX(").concat(event.gesture.deltaX, "px) translateY(").concat(event.gesture.deltaY, "px)");
      return false;
    },
    init: function init() {
      this._super.apply(this, arguments);

      var mobileOS = (0, _detectMobileOs.default)();

      if (mobileOS) {
        this.setProperties({
          mobileOS: mobileOS
        });
      }
    }
  });

  _exports.default = _default;
});