define("challingo-mobile/routes/login", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    beforeModel: function beforeModel() {
      if (this.get('session.isAuthenticated')) {
        this.replaceWith('challenges', {
          queryParams: {
            miniProfile: null
          }
        });
      }
    },
    resetController: function resetController(controller) {
      this._super.apply(this, arguments);

      controller.set('loading', false);
    }
  });

  _exports.default = _default;
});