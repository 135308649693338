define("challingo-mobile/components/challenges/challenges-list-mini", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['challenges-list-mini'],
    challenges: null,
    placeholder: null
  });

  _exports.default = _default;
});