define("challingo-mobile/components/hashtag-page", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    challenges: Ember.inject.service(),
    classNames: ['hashtag-page'],
    actions: {
      nextChallyPage: function nextChallyPage() {
        this.challenges.hashtag.nextPage();
      }
    }
  });

  _exports.default = _default;
});