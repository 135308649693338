define("challingo-mobile/templates/components/forms/login-form", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "uk22tWKA",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"input-field gray\"],[9],[0,\"\\n  \"],[1,[27,\"input\",null,[[\"type\",\"id\",\"value\",\"placeholder\",\"autocomplete\",\"required\"],[\"email\",\"email\",[23,[\"email\"]],\" \",\"off\",true]]],false],[0,\"\\n  \"],[7,\"label\"],[11,\"for\",\"email\"],[9],[1,[27,\"t\",[\"user.email\"],null],false],[10],[0,\"\\n\"],[10],[0,\"\\n\"],[7,\"div\"],[11,\"class\",\"input-field gray\"],[9],[0,\"\\n  \"],[1,[27,\"input\",null,[[\"type\",\"id\",\"value\",\"placeholder\",\"autocomplete\",\"required\"],[\"password\",\"password\",[23,[\"password\"]],\" \",\"off\",true]]],false],[0,\"\\n  \"],[7,\"label\"],[11,\"for\",\"password\"],[9],[1,[27,\"t\",[\"user.password\"],null],false],[10],[0,\"\\n\"],[10],[0,\"\\n\\n\"],[7,\"div\"],[11,\"class\",\"errors\"],[9],[0,\"\\n  \"],[1,[21,\"error\"],false],[0,\"\\n\"],[10],[0,\"\\n\\n\"],[7,\"button\"],[11,\"class\",\"btn btn-action signup-button\"],[12,\"disabled\",[21,\"loading\"]],[11,\"style\",\"touch-action: manipulation; -ms-touch-action: manipulation; cursor: pointer;\"],[11,\"type\",\"submit\"],[9],[0,\"\\n  \"],[1,[27,\"t\",[\"user.login\"],null],false],[0,\"\\n\"],[10],[0,\"\\n\\n\"],[7,\"div\"],[11,\"class\",\"password-forgot\"],[9],[0,\"\\n  \"],[4,\"link-to\",[\"password-forgot\"],null,{\"statements\":[[1,[27,\"t\",[\"user.password_forgot\"],null],false]],\"parameters\":[]},null],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "challingo-mobile/templates/components/forms/login-form.hbs"
    }
  });

  _exports.default = _default;
});