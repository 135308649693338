define("challingo-mobile/utils/is-following", ["exports", "challingo-mobile/utils/logger"], function (_exports, _logger) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = isFollowing;

  function isFollowing(store, followerId, followingId) {
    var itemInStore = store.peekAll('follower').find(function (item) {
      return item && item.belongsTo('follower').id() == followerId && item.belongsTo('following').id() == followingId;
    });

    if (itemInStore) {
      return itemInStore;
    }

    return store.query('follower', {
      limit: 1,
      where: [['follower', '==', followerId], ['following', '==', followingId]]
    }).then(function (items) {
      return items && items.length && items.firstObject || null;
    }, function (e) {
      _logger.default.error(e);
    });
  }
});