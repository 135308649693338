define("challingo-mobile/components/challenges/challenges-list", ["exports", "challingo-mobile/utils/jquery-polyfills"], function (_exports, _jqueryPolyfills) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['challenges-list'],
    challenges: null,
    placeholder: 'empty_list',
    scrollHandler: null,
    scrollTarget: null,
    scrollDelay: null,
    scrollObserverOnEnter: null,
    scrollObserverOnExit: null,
    lengthMinus2: Ember.computed('challenges.length', function () {
      return this.get('challenges.length') - 2;
    }),
    videoPlay: function videoPlay() {
      if (!this || !this.element || !this.element.querySelector('.challenge-item')) {
        return;
      }

      var items = this.element && this.element.querySelectorAll('.challenge-item');
      Array.from(items).forEach(function (item) {
        if (item.classList.contains('has-video')) {
          var p = (0, _jqueryPolyfills.position)(item);
          var elmTop = p.top,
              windowHalfHeight = window.innerHeight / 2,
              video = item.querySelector('video');

          if (elmTop - windowHalfHeight < 0 && elmTop + item.offsetHeight - windowHalfHeight > 0) {
            video && video.play().catch(function () {});
          } else {
            if (video && !video.paused) {
              try {
                video.pause();
              } catch (e) {
                e;
              }
            }
          }
        }
      });
    },
    videoLoadEvents: function videoLoadEvents() {
      if (!this.element.querySelector('.challenge-item')) {
        return;
      }

      var items = this.element && this.element.querySelectorAll('.challenge-item');
      items && Array.from(items).forEach(function (item) {
        var video = item.querySelector('video');

        if (video) {
          video.addEventListener('loadeddata', function () {
            item.classList.add('loaded');
          });
        }
      });
    },
    didInsertElement: function didInsertElement() {
      var _this = this;

      this._super.apply(this, arguments);

      var p = (0, _jqueryPolyfills.parents)(this.element, '.lazy-loading-list');

      if (p && p.length) {
        this.set('scrollTarget', p[0]);
      }

      this.set('scrollHandler', function () {
        Ember.run.cancel(_this.scrollDelay);

        _this.set('scrollDelay', Ember.run.later(function () {
          _this.videoPlay();
        }, 250));
      });
      this.scrollTarget.addEventListener('scroll', this.scrollHandler);
      Ember.run.scheduleOnce('afterRender', function () {
        Ember.run.later(function () {
          var item = document.querySelector('.page-slide.active .lazy-loading-list .challenge-item');

          if (item && item.classList.contains('has-video')) {
            var video = item.querySelector('video');
            video && video.play().catch(function () {});
          }

          var challengeItem = document.querySelector('.challenge-detail .challenge-item');

          if (challengeItem && challengeItem.classList.contains('has-video')) {
            var _video = challengeItem.querySelector('video');

            _video && _video.play().catch(function () {});
          }
        }, 2000);
      });
    },
    didRender: function didRender() {
      this._super.apply(this, arguments);

      this.videoLoadEvents();
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);

      this.scrollTarget.removeEventListener('scroll', this.scrollHandler);
    }
  });

  _exports.default = _default;
});