define("challingo-mobile/components/forms/feedback-form", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    currentUser: Ember.inject.service(),
    intl: Ember.inject.service(),
    toastMessage: Ember.inject.service(),
    tagName: 'form',
    classNames: ['feedback-form'],
    message: '',
    loading: false,
    toast: function toast(msg) {
      if (this && this.toastMessage && this.toastMessage.show) {
        this.toastMessage.show(this.intl.t(msg));
      }
    },
    submit: function submit() {
      var _this = this;

      this.set('loading', true);
      var user = this.get('currentUser.user');
      window.emailjs.send('default_service', 'feedback', {
        user_name: user.get('name'),
        user_email: user.get('email'),
        message: this.message
      }).then(function () {
        _this.toast('feedback.submit_success');

        _this.setProperties({
          loading: false,
          message: ''
        });
      }, function () {
        _this.toast('feedback.submit_error');

        if (!_this.isDestroyed) {
          _this.set('loading', false);
        }
      });
      return false;
    }
  });

  _exports.default = _default;
});